import React, { useState } from 'react';
import Footer from './Footer';

import Navtradebar from './Navtradebar'

export default function Allnotification() {

    const [notificationcard, setnotificationcard] = useState([
        {
            "id": 1,
        },
        {
            "id": 2,
        },
        {
            "id": 3,
        },
    ])

    return (
        <>



            <div className='home'>
                <Navtradebar />
                <div className='notification-card pt-5 pb-5'>
                    <div className='container'>
                        <div className='banner'>
                            <div class="card">
                                <div class="card-body">
                                    <h2 className='mb-4'>Notifications</h2>
                                    {!notificationcard.length ?
                                        <div>
                                            <p className='text-center'>No More Notification.</p>
                                        </div>
                                        :
                                        <div>
                                            <button class="btn btn-link ml-auto text-capital f-12 py-0 pr-2 shrink-0 pl-2 d-sm-block d-none mb-2">Mark all as read</button>
                                            <ul className='mt-3 mb-3'>
                                                    <li className='chatread'>
                                                        <div class="d-flex align-items-center pl-sm-2"><span class="stat mr-2 shrink-0"></span><div>
                                                            <p class="f-12 lighttxt">Login Successfully</p>
                                                            <p class="text-muted f-12 mb-2">2023-05-15 07:02</p>
                                                        </div>
                                                            <button class="btn btn-link ml-auto text-capital f-12 py-0 pr-2 shrink-0 pl-2 d-sm-block">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M374.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 178.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l80 80c12.5 12.5 32.8 12.5 45.3 0l160-160zm96 128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 402.7 86.6 297.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l256-256z"></path></svg>Mark as read</button>
                                                        </div>
                                                    </li>
                                                </ul>
                                          
                                            {notificationcard.map((e, i) =>
                                                <ul className='mt-3 mb-3'>
                                                    <li>
                                                        <div class="d-flex align-items-center pl-sm-2"><span class="stat mr-2 shrink-0"></span><div>
                                                            <p class="f-12 lighttxt">Login Successfully</p>
                                                            <p class="text-muted f-12 mb-2">2023-05-15 07:02</p>
                                                        </div>
                                                            <button class="btn btn-link ml-auto text-capital f-12 py-0 pr-2 shrink-0 pl-2 d-sm-block">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M374.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 178.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l80 80c12.5 12.5 32.8 12.5 45.3 0l160-160zm96 128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 402.7 86.6 297.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l256-256z"></path></svg>Mark as read</button>
                                                        </div>
                                                    </li>
                                                </ul>
                                            )}
                                       
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>



        </>
    )

};