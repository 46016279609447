import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Navbar from './Navbar'
import Footer from './Footer'

import axios from "axios";
import keys from "../actions/config";
const url = keys.baseUrl;
class Risk extends Component {

    constructor(props) {
        super(props);
        this.state = {
            identifier: "",
            subject: "",
            content: "",
            errors: {},
            terms: {},
        };

        //console.log(this.state, 'state');
    }

    componentDidMount() {
    window.scrollTo(0, 0);

        this.getData()
    };
    createMarkup = () => {
        return { __html: this.state.terms.data.content };
    }
    getData() {
        axios
            .get(url + "cryptoapi/risk")
            .then(res => {
                this.setState({ terms: res });
            })
            .catch()
        //console.log(this.setState, 'this.setState');
    }



    render() {
        return (<div className='bg_strip'>
            <section class="headerCrediantials homeBanner">
                <Navbar />
            </section>
            <section className="innerCMS">
                {this.state.terms.data ?
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 mx-auto">

                                <div className="darkBox contentPage">
                                    <div className="tableHead tableHeadBlock">
                                        <h2>Risk Disclosure Statement</h2>
                                    </div>
                                    <div className="darkBoxSpace">
                                        <h4>{this.state.terms.data.identifier}</h4>
                                        <p><div dangerouslySetInnerHTML={this.createMarkup()} className='editor'></div> </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}
            </section>
            <Footer />
        </div>
        );
    }
}

export default Risk;
