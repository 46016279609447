import React, { Component } from 'react'

class LoaderSmall extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <div className="loader-small">
        <svg xmlns="http://www.w3.org/2000/svg" style={{ margin: 'auto', display: 'block' }} width="48px" height="48px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <circle cx="50" cy="50" fill="none" stroke="#ffd046" stroke-width="4" r="36" stroke-dasharray="169.64600329384882 58.548667764616276">
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
          </circle>
        </svg>
      </div>
    )
  }
}

export default LoaderSmall;
