import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import Navbar from './Navbar'
import Footer from './Footer'
import AboutImg from "../images/aboutus.png"
import { withTranslation } from 'react-i18next';
class About extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);


  }
	render() {
    const {t} = this.props;
 		return (<div className='bg_strip'>
			<Navbar />
			<section className="innerCMS mt-5 begin_page">
  <div className="container">
    <div className="row">
      <div className="col-md-12 mx-auto">
        {/* <img src={AboutImg} className="img-fluid cmsTopImg mt-1" /> */}
        <div className="darkBox contentPage px-3 px-xl-4">
          {/* <div className="tableHead tableHeadBlock helpMainTab begin_tab">
            <nav>
              <div className="nav nav-tabs" id="navLink-tab" role="tablist"> */}
                {/* <a href="help-main.html" className="nav-item nav-link">Trading Academy</a> */}
                {/* <a href="beginners-guide.html" className="nav-item nav-link active">Beginner's Guide</a> */}
                {/* <a href="#" className="nav-item nav-link">Reference</a>
                <a href="#" className="nav-item nav-link">FAQ</a>
                <a href="#" className="nav-item nav-link">News</a> */}
              {/* </div>
            </nav>
          </div> */}
          <div className="darkBoxSpace helpPage px-3 px-xl-4">
            <div className="wow fadeIn">
              <h3 className="helpPageSubTitle text-center text-white pb-4">{t("BEGINNERS_GUIDE")}</h3>
              <div id="accordion">
                <div className="card">
                  <div className="card-header wow flipInX" id="accordianheadingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link" data-toggle="collapse" data-target="#accordiancollapseOne" aria-expanded="true" aria-controls="accordiancollapseOne"><span className="question">{t("ACCOUNT_REGISTRATION")}</span> <i className="fa fa-plus" aria-hidden="true"></i></button>
                    </h5>
                  </div>

                  <div id="accordiancollapseOne" className="collapse show" aria-labelledby="accordianheadingOne" data-parent="#accordion">
                    <div className="card-body">
                      <ul className="helpList">
                        <li><a href="#">{t("REGISTER_REAL_ACCOUNT")}</a></li>
                        <li><a href="#">{t("REGISTER_TESNET_ACCOUNT")}</a></li>
                        <li><a href="#">{t("FORGOT_PASSWORD")} </a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header wow flipInX" id="accordianheadingTwo">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#accordiancollapseTwo" aria-expanded="false" aria-controls="accordiancollapseTwo"><span className="question">{t("ACCOUNT_AND_SECURITY")}</span> <i className="fa fa-plus" aria-hidden="true"></i></button>
                    </h5>
                  </div>
                  <div id="accordiancollapseTwo" className="collapse" aria-labelledby="accordianheadingTwo" data-parent="#accordion">
                    <div className="card-body">
                      <ul className="helpList">
                        <li><a href="#">{t("BYBIT_INSTALL")}</a></li>
                        <li><a href="#">{t("ACCOUNT_OVERVIEW")}</a></li>
                        <li><a href="#">{t("ACCOUNT_DETAILS")}</a></li>
                        <li><a href="#">{t("SECURITY")}</a></li>
                        <li><a href="#">{t("SET_CHANGE_EMAIL")}</a></li>
                        <li><a href="#">{t("SET_CHANGE_GOOGLE_AITHENTICATION")}</a></li>
                        <li><a href="#">{t("SET_CHANGE_MOBILE_PHONE")}</a></li>
                        <li><a href="#">{t("API_MANAGEMENT")} </a></li>
                        <li><a href="#">{t("ADD_NEW_API_KEY")}</a></li>
                        <li><a href="#">{t("SETTINGS")}</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header wow flipInX" id="accordianheadingThree">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#accordiancollapseThree" aria-expanded="false" aria-controls="accordiancollapseThree"><span className="question">{t("DEPOSIT_AND_WITHDRAWAL")}</span> <i className="fa fa-plus" aria-hidden="true"></i></button>
                    </h5>
                  </div>
                  <div id="accordiancollapseThree" className="collapse" aria-labelledby="accordianheadingThree" data-parent="#accordion">
                    <div className="card-body">
                      <ul className="helpList">
                        <li><a href="#">{t("BYBIT_DEPOSIT_OR_WITHDRAW")}</a></li>
                        <li><a href="#">{t("WITHDRAWAL_REQUEST")}</a></li>
                        <li><a href="#">{t("ADD_YOUR_WALLET_ADDRESS")}</a></li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header wow flipInX" id="accordianheadingFour">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#accordiancollapseFour" aria-expanded="false" aria-controls="accordiancollapseFour"><span className="question">{t("PLACING_ORDER")}</span> <i className="fa fa-plus" aria-hidden="true"></i></button>
                    </h5>
                  </div>
                  <div id="accordiancollapseFour" className="collapse" aria-labelledby="accordianheadingFour" data-parent="#accordion">
                    <div className="card-body">
                      <ul className="helpList">
                        <li><a href="#">{t("DIFFERENT_RERERENCE_PRICE")}</a></li>
                        <li><a href="#">{t("ORDER_PLACING_OVERVIEW")}</a></li>
                        <li><a href="#">{t("MARKET_ORDER")}</a></li>
                        <li><a href="#">{t("LIMIT_ORDER")}</a></li>
                        <li><a href="#">{t("CONDITIONAL_ORDER")}</a></li>
                        <li><a href="#">{t("PARTIAL_CLOSE")}</a></li>
                        <li><a href="#">{t("TAKE_PROFIT_STOP_LOSS")}</a></li>
                        <li><a href="#">{t("TIME_IN_FORCE")}</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header wow flipInX" id="accordianheadingFive">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#accordiancollapseFive" aria-expanded="false" aria-controls="accordiancollapseFive"><span className="question">My Position</span> <i className="fa fa-plus" aria-hidden="true"></i></button>
                    </h5>
                  </div>
                  <div id="accordiancollapseFive" className="collapse" aria-labelledby="accordianheadingFive" data-parent="#accordion">
                    <div className="card-body">
                      <ul className="helpList">
                        <li><a href="#">{t("POSITION")}</a></li>
                        <li><a href="#">{t("ACTIVE_ORDER")}</a></li>
                        <li><a href="#">{t("CONDITIONAL_ORDER")}</a></li>
                        <li><a href="#">{t("ORDER_HISTORY")}</a></li>
                        <li><a href="#">{t("POSITION_CLOSING")}</a></li>
                        {/* <li><a href="#">Set or Adjust Leverage and Margin</a></li> */}
                        <li><a href="#">{t("ADJUST_TAKE_PROFIT")}</a></li>
                        <li><a href="#">{t("ADJUST_TRAILING_STOP")}</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
			<Footer />
		</div>
		);
	}
}

export default (withTranslation()(About),About)