import React, { Component } from 'react'
import { a } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Navbar from './Navbar'
import Footer from './Footer'
import Navtradebar from './Navtradebar'
import axios from "axios";
import { Link, withRouter } from 'react-router-dom';
import keys from "../actions/config";
import LogoNewFixHead from "../images/logo.png"
import LogoLightTheme from "../images/logo.png";
import * as moment from "moment";

import { unrealizedPnLPercentage } from '../lib/bybit';
import config from '../actions/config';
import io from "socket.io-client";
import { withTranslation } from 'react-i18next';

// Datatable
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
toast.configure();
const url = keys.baseUrl;

// Copy Trade Table
// const tdata_history = [
//   {position: <div><span class="badge badge-danger">Short10X</span> BTC/USDT</div>, openprice: '51711.88 USDT ', closeprice: '51252.50 USDT', opentime: '09-06 02:28:35', closetime: "09-06 17:35:28", "plratio": <span className="green_txt">+8.88%</span>, "ordernumber": '516726906915232'},
//   {position: <div><span class="badge badge-danger">Short10X</span> BTC/USDT</div>, openprice: '51711.88 USDT ', closeprice: '51252.50 USDT', opentime: '09-06 02:28:35', closetime: "09-06 17:35:28", "plratio": <span className="green_txt">+8.88%</span>, "ordernumber": '516726906915232'},
//   {position: <div><span class="badge badge-danger">Short10X</span> BTC/USDT</div>, openprice: '51711.88 USDT ', closeprice: '51252.50 USDT', opentime: '09-06 02:28:35', closetime: "09-06 17:35:28", "plratio": <span className="green_txt">+8.88%</span>, "ordernumber": '516726906915232'},
//   {position: <div><span class="badge badge-danger">Short10X</span> BTC/USDT</div>, openprice: '51711.88 USDT ', closeprice: '51252.50 USDT', opentime: '09-06 02:28:35', closetime: "09-06 17:35:28", "plratio": <span className="green_txt">+8.88%</span>, "ordernumber": '516726906915232'},
//   {position: <div><span class="badge badge-danger">Short10X</span> BTC/USDT</div>, openprice: '51711.88 USDT ', closeprice: '51252.50 USDT', opentime: '09-06 02:28:35', closetime: "09-06 17:35:28", "plratio": <span className="green_txt">+8.88%</span>, "ordernumber": '516726906915232'},
//   {position: <div><span class="badge badge-danger">Short10X</span> BTC/USDT</div>, openprice: '51711.88 USDT ', closeprice: '51252.50 USDT', opentime: '09-06 02:28:35', closetime: "09-06 17:35:28", "plratio": <span className="green_txt">+8.88%</span>, "ordernumber": '516726906915232'},
// ];

const table_columns = [
  {
    name: 'Position',
    selector: 'position',
  },
  {
    name: 'Open Price',
    selector: 'openprice',
  },
  {
    name: 'Close Price',
    selector: 'closeprice',
  },
  {
    name: 'Open time',
    selector: 'opentime',
  },
  {
    name: 'Close time',
    selector: 'closetime',
  },
  {
    name: 'P/L Ratio(%)',
    selector: 'plratio',
  },
  {
    name: 'Order number',
    selector: 'ordernumber',
  },
];

class CopyTraderInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      subject:"",
      content: "",
      errors: {},
      privacy : {},
      trader: {},
      myProfile: false,
      traderDetail: {},
      userDetail: {},
      followerList: [],
      followDetail: {},
      userId: '',
      traderId: '',
      pairs: [],
      tdata_history:[],
      tdata_followerList: [],
      tcol_followerList: [
        {
          name: 'Followers',
          selector: 'followers',
        },
        {
          name: 'Total vol(USDT)',
          selector: 'totalvol',
        },
        {
          name: 'Profit(USDT)',
          selector: 'profit',
        }
      ],

      tcol_copyTradeDetail: [
        {name: 'Position',selector: 'position'},
        {name: 'Open Price',selector: 'openPrice'},
        {name: 'Current Price',selector: 'currentPrice'},
        {name: 'Open Time',selector: 'openTime'},
        {name: 'P/L Ratio(%)',selector: 'pandl'},
        {name: 'Order Number',selector: 'orderNumber'}
      ],
      tcol_copyTradeSummary: [
        {name: 'Position',selector: 'position'},
        {name: 'Open Price',selector: 'openPrice'},
        {name: 'Current Price',selector: 'currentPrice'},
        {name: 'P/L Ratio(%)',selector: 'pandl'},
      ],
      tcol_tradeHistory: [
        {name: 'Position',selector: 'position'},
        {name: 'Open Price',selector: 'openPrice'},
        {name: 'Close Price',selector: 'closePrice'},
        {name: 'Open Time',selector: 'opentime'},
        {name: 'Close Time',selector: 'closetime'},
        {name: 'P/L Ratio(%)',selector: 'pandl'},
        {name: 'Order Number',selector: 'orderNumber'}
      ],

      tdata_copyTradeDetail: [],
      tdata_copyTradeSummary: [],
      tdata_tradeHistory: [],

      tdata_copyTradeTarget: 'copyTradeDetail',

      markpriceAll: {},
      positionDetailAll: []
    };

    this.getCopyTrade = this.getCopyTrade.bind(this);
    this.getFollower = this.getFollower.bind(this);
    this.followTrader = this.followTrader.bind(this);
    this.cancelByTrader = this.cancelByTrader.bind(this);
    this.resetCopyTradeList = this.resetCopyTradeList.bind(this);
    this.copyTradeNav = this.copyTradeNav.bind(this);

    this.socket = io(keys.socketUrl);

    this.socket.on('perpetualMarketPrice', (result) => {
      indexPriceWS(result)
    })

    const indexPriceWS = (result) => {
      // console.log('indexPriceWS : ', result);
      if (result && result.first_currency && result.second_currency && result.data && result.data.markprice) {
        var markpriceAll = this.state.markpriceAll;
        markpriceAll[result.first_currency+result.second_currency] = parseFloat(result.data.markprice);
        this.setState({ markpriceAll: markpriceAll });
        this.resetCopyTradeList();
      }
    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    let traderId = this.useQuery().get('id');
    if(traderId) {
      var jsonState = {};
      jsonState.traderId = traderId;
      this.setState(jsonState);
    }
    if(this.props.auth.user && this.props.auth.user.id) {
      var jsonState = {};
      jsonState.userId = this.props.auth.user.id;
      let id = this.useQuery().get('id');
      if(id && id == this.props.auth.user.id) {
        jsonState.myProfile = true;
        jsonState.tcol_followerList = [
          {name: 'Followers',selector: 'followers'},
          {name: 'Total vol(USDT)',selector: 'totalvol'},
          {name: 'Profit(USDT)',selector: 'profit'},
          {name: 'Action',selector: 'action'}
        ];
      }
      this.setState(jsonState);
    }
    await this.getPairs();
    await this.getTraderDetail();
    await this.getTraderHistory();
    this.getCopyTrade();
    // this.resetCopyTradeList(this.state.getCopyTradeList);
  };

  getPairs() {
    axios.post(url+'cryptoapi/perpetual-data').then(response => {
      this.setState({pairs: response.data.data});
    }).catch(e => {
      console.log(e);
    });
  }

  renderPrivacyPolicyText() {
    return (
      <div>
      </div>
    )
  }

  useQuery() {
    const { search } = this.props.location;
    return new URLSearchParams(search);
  }

  getTraderHistory() {
    let traderId = this.useQuery().get('id');

    var data = {
      traderId: traderId,
      target: 'traderProfile'
    }
    axios.post(url+'api/v1/copytrade/trader/history', data).then(resp => {
      var result = resp.data;
      var jsonState = {tdata_tradeHistory:[]};

      if(result.list) {
        var history = result.list;
        for (let i = 0; i < history.length; i++) {
          const element = history[i];
          var newObj = {
            position: '',
            openPrice: '',
            closePrice: '',
            opentime: '',
            closetime: '',
            pandl: '',
            orderNumber: ''
          };

          var buyorsell = (element.closing_direction == 'Closed long') ? 'buy' : 'sell';
          var leverage = element.leverage;
          var pairName = element.pairName;

          newObj.position = <div> <div class={buyorsell=='buy'?'greenText':'pinkText'}>{buyorsell=='buy' ? 'Long' : 'Short'}</div> {leverage}X {pairName} Isolated</div>;
          newObj.openPrice = element.entry_price;
          newObj.closePrice = element.exit_price;
          newObj.opentime = (element.orderId && element.orderId.filled && element.orderId.filled[0] && element.orderId.filled[0].created_at) ? moment(element.orderId.filled[0].created_at).format("DD-MM-YYYY  h:mm a ") : '';;
          newObj.closetime = moment(element.createdDate).format("DD-MM-YYYY  h:mm a ");
          newObj.pandl = <div class={element.pAndL_per>=0?'greenText':'pinkText'}>{(element.pAndL_per).toFixed(4)}</div>;
          newObj.orderNumber = (element.orderId && element.orderId._id) ? element.orderId._id : '';

          jsonState.tdata_tradeHistory.push(newObj);

        }
      }
      this.setState(jsonState);
    });
  }

  getTraderDetail() {
    let id = this.useQuery().get('id');

    var { userId } = this.state;

    if(id) {
      this.setState({traderId:id});
      var data = {
        traderId: id,
        userId: this.props.auth.user.id,
      }
      axios.post(url+'api/v1/copytrade/trader/getDetail', data).then(resp => {
        var result = resp.data;
        var jsonState = {};
        if(result.traderDetail) {
          jsonState.trader = result.traderDetail;
          if(result.traderDetail) {
            jsonState.traderDetail = result.traderDetail;
            if(result.traderDetail.userDetail) {
              jsonState.userDetail = result.traderDetail.userDetail;
            }
          }
        }
        if(result.followerList) {
          jsonState.followerList = result.followerList.filter((val)=>(val.followStatus=='1'));
          // var index = result.followerList.findIndex(e=>e.followerId.toString() == userId);
          // if(index > -1) {
          //   jsonState.followDetail = result.followerList[index];
          // }
        }
        if(result.followDetail) {
          jsonState.followDetail = result.followDetail;
        }
        if(Object.keys(jsonState).length > 0) {
          this.setState(jsonState);
        }
      }).catch(e => {
        console.log(e);
      });
    }
  }

  copyTradeNav(target, e) {
    this.setState({tdata_copyTradeTarget: target});
    this.getCopyTrade(target);
  }

  cancelByTrader(followerId, e) {
    let data = {
      traderId: this.useQuery().get('id'),
      followerId: followerId,
      by: 'trader'
    };
    axios.post(url+'api/v1/copytrade/follow/cancel',data).then(response => {
      if(response.data.toast) {
        toast(response.data.toast.message, {position: toast.POSITION.TOP_CENTER});
      }
      this.getFollower();
    });
  }

  getCopyTrade(target = this.state.tdata_copyTradeTarget) {
    var {
      userId,
      traderId,
    } = this.state;

    if(traderId) {
      var data = {
        traderId: traderId,
        target: target
      }
      axios.post(url+'api/v1/copytrade/trader/getCopyTradeList', data).then(resp => {
        var result = resp.data;
        var jsonState = {getCopyTradeList : []};
        if(result.success == true && result.results) {
          if(result.positionDetailAll) {
            jsonState.positionDetailAll = result.positionDetailAll;
          }
          jsonState.getCopyTradeList = result.results;
        }
        this.setState(jsonState);
        this.resetCopyTradeList(jsonState.getCopyTradeList);
      }).catch((err)=>{
        console.log("error",err)
      })
    }
  }

  resetCopyTradeList(getCopyTradeList = this.state.getCopyTradeList) {
    var {
      userId,
      myProfile,
      traderId,
      tdata_copyTradeTarget,
      markpriceAll,
      positionDetailAll,
    } = this.state;
    var newResult = [];
    if(getCopyTradeList) {
      for (let i = 0; i < getCopyTradeList.length; i++) {
        const element = getCopyTradeList[i];
       console.log(element.orderValue,"element")
        var newObj = {};
        if(tdata_copyTradeTarget == 'copyTradeSummary') {
          newObj = {
            position: '',
            openPrice: '',
            currentPrice: '',
            pandl: '',
          };

          var openPrice = element.openPrice;

          var {
            buyorsell,
            leverage,
            pairName
          } = element._id;
        }
        else {
          newObj = {
            position: '',
            openPrice: '',
            currentPrice: '',
            openTime: '',
            pandl: '',
            orderNumber: '',
          };
          //var openPrice = element.orderValue;
          var {
            buyorsell,
            leverage,
            openPrice,
            pairName
          } = element;

          newObj.openTime = moment(element.openTime).format("DD-MM-YYYY  h:mm a ");
          newObj.orderNumber = element._id;
        }

        newObj.position = <div> <div class={buyorsell=='buy'?'greenText':'pinkText'}>{buyorsell=='buy' ? 'Long' : 'Short'}</div> {leverage}X {pairName} Isolated</div>;
        newObj.openPrice = openPrice;

        if(markpriceAll && markpriceAll[pairName]) {
          newObj.currentPrice = markpriceAll[pairName];

          var index = positionDetailAll.findIndex(el => el.tiker_root == pairName);
          var positionDetail = (index > -1) ? positionDetailAll[index] : {};

          if(positionDetail.taker_fees) {
            var det = {
              'price': newObj && newObj.openPrice != undefined ? newObj.openPrice : 0,
              'quantity': 1,
              'lastPrice': newObj.currentPrice,
              'leverage': leverage,
              'takerFee': positionDetail.taker_fees,
              'buyorsell': (buyorsell == 'sell') ? 'buy' : 'sell'
            };
            var pandl = unrealizedPnLPercentage(det).toFixed(2);
            if(pandl >= 0) {
              newObj.pandl = <div class='greenText'>{pandl}</div>;
            } else {
              newObj.pandl = <div class='pinkText'>{pandl}</div>;
            }
          }
        }
        newResult.push(newObj);
      }
    }

    if(tdata_copyTradeTarget == 'copyTradeSummary') {
      var jsonState = {tdata_copyTradeSummary: newResult};
      this.setState(jsonState);
    }
    else if(tdata_copyTradeTarget == 'copyTradeDetail') {
      var jsonState = {tdata_copyTradeDetail: newResult};
      this.setState(jsonState);
    }
  }

  getFollower() {
    var { userId, myProfile, traderId } = this.state;

    if(traderId) {
      var data = {
        traderId: traderId,
        userId: userId,
      }
      axios.post(url+'api/v1/copytrade/trader/getFollower', data).then(resp => {
        var result = resp.data;
        var jsonState = {};
        if(result.followerList) {
          jsonState.followerList = result.followerList;
          jsonState.tdata_followerList = [];
          for (let i = 0; i < result.followerList.length; i++) {
            const itemdata = result.followerList[i];
            if(itemdata && itemdata.userDetail && itemdata.userDetail._id) {
              var rowHtml = {
                followers: (itemdata.userDetail.email) ? itemdata.userDetail.email : (itemdata.userDetail.name) ? itemdata.userDetail.name : '-',
                totalvol: '0',
                profit: '0'
              };
              if(traderId && traderId == this.props.auth.user.id) {
                rowHtml.action = <div class='cancelbytrader' onClick={() => this.cancelByTrader(itemdata.userDetail._id)}>Cancel</div>;
              }
              if(itemdata.pAndLDet && itemdata.pAndLDet[0] && itemdata.pAndLDet[0].profitnloss) {
                rowHtml.totalvol = (itemdata.pAndLDet[0].profitnloss).toFixed(4);
              }
              if(itemdata.pAndLDet && itemdata.pAndLDet[0] && itemdata.pAndLDet[0].costInUsdt) {
                rowHtml.profit = (itemdata.pAndLDet[0].costInUsdt).toFixed(4);
              }
              jsonState.tdata_followerList.push(rowHtml);
            }
          }
        }
        else {
          jsonState.followerList = [];
          jsonState.tdata_followerList = [];
        }
        this.setState(jsonState);
      }).catch(e => {
        console.log(e);
      });
    }
  }

  followTrader(id='') {
    if(id == 'traderFollorBtn') {
      const { userId, traderId } = this.state;
      if(traderId == userId) {
      } else if(userId) {
        this.props.history.push("/copy-trading-setting?id="+traderId);
      } else {
        this.props.history.push("/login");
      }
    }
  }

	render() {
    const { user } = this.props.auth;
    const {
      userId,
      traderId,
      myProfile,
      followDetail,
      trader,
      traderDetail,
      userDetail,
      followerList,
      tdata_history,

      pairs,

      tcol_followerList,
      tdata_followerList,

      tcol_copyTradeDetail,
      tcol_copyTradeSummary,
      tcol_tradeHistory,

      tdata_copyTradeDetail,
      tdata_copyTradeSummary,
      tdata_tradeHistory,

      tdata_copyTradeTarget,
    } = this.state;

    const paginationRowsPerPageOptions = [5, 10, 15, 20];
console.log('userdetails',userDetail)
const {t} = this.props;
		return (<div className='bg_strip padding_top_str'>
			<section class="container-fluid px-0">
      <Navtradebar />
	    </section>
			<section className="privacy-policy-container px-0 pt-0">
        <div className="inner_pageheader container-fluid px-0">
          <div className="py-3">
            <div className="container text-center">
              <div className="row">
                <div className="col-md-12">
                  <div className="copy_trading_panel_top d-block">
                    {userDetail && userDetail._id &&
                    <div className="justify-content-center">
                      {(userDetail?.profile ==null || userDetail?.profile ==undefined || userDetail?.profile == '') ? 
                                   (<img src={require("../images/user.png")} alt="User" className="" />) :
                                   (<img src={`${config.imageUrl}profile_images/${userDetail?.profile}`} alt="User" className="" />)
                                   }
                      {/* <img src={require("../images/user.png")} alt="User" className="" /> */}
                      <div className="copy_trading_panel_userdetail text-left">
                        <h2>{userDetail.name ? userDetail.name : userDetail.email ? userDetail.email : '-'}</h2>
                        <h5 className="text-white">{t("EARNING_MONEY_NOW")}</h5>
                      </div>
                    </div>}
                  </div>
                  <div><span class="text_blue font_12 p-3 d-block"> {t("CURRENTLY_FOLLOWING")} {followerList.length}/{trader.maxFollow} {t("PEOPLE")}</span></div>
                    {
                    (myProfile === true)
                    ?
                      ''
                    :
                    (followDetail._id) ?
                      <button className="full_btn">
                        <span id="traderFollorBtn" className="full_btn" onClick={() => this.followTrader('traderFollorBtn')}>{t("EDIT")}</span>
                      </button>
                    :
                    (followerList.length >= trader.maxFollow)
                    ?
                      <button className="full_btn">
                        <span id="traderFollorBtn" className="full_btn">{t("FULL")}</span>
                      </button>
                    :
                      <button className="full_btn">
                        <span id="traderFollorBtn" className="full_btn" onClick={() => this.followTrader('traderFollorBtn')}>{t("FOLLOW")}</span>
                      </button>
                    }
                </div>
              </div>
            </div>
          </div>
          <div className="inner_content_wrapper">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="whitebox">
                    <div className="copy_trade_user_data copy_trade_user_data_sm">
                      <h3 className='text-white'>{t("TRADER_DETAILS")}</h3>
                      <div>
                        <h3 className="text-white">{traderDetail.history && traderDetail.history.AccumPLRatio ? (traderDetail.history.AccumPLRatio).toFixed(4) : 0}%</h3>
                        <p>{t("PL_RATIO")}</p>
                      </div>
                      <div>
                        <h3 className="text-white">{traderDetail.history && traderDetail.history.AccumTransactions ? traderDetail.history.AccumTransactions : 0}</h3>
                        <p>{t("TRADING_ORDER")}</p>
                      </div>
                      <div>
                        {/*<h3 className="green_txt">{traderDetail.history && traderDetail.history.AccumFollowers ? traderDetail.history.AccumFollowers : 0}</h3>*/}
                        <h3 className="text-white">{followerList.length}</h3>
                        <p>{t("ACCUM_FOLLOWERS")}</p>
                      </div>
                      <div>
                        <h3 className="text-white">{traderDetail.history && traderDetail.history.TotalPL_3wUSDT ? (traderDetail.history.TotalPL_3wUSDT).toFixed(8) : 0}</h3>
                        <p>{t("PROFIT_USDT")}</p>
                      </div>
                      <div>
                        <h3 className="text-white">{traderDetail.history && traderDetail.history.profitCount ? traderDetail.history.profitCount : 0}</h3>
                        <p>{t("GAIN")}</p>
                      </div>
                      <div>
                        <h3 className="text-white">{traderDetail.history && traderDetail.history.lossCount ? traderDetail.history.lossCount : 0}</h3>
                        <p>{t("LOSS")}</p>
                      </div>
                      {/* <div>
                        <h3 className="green_txt">811.52</h3>
                        <p>P/L Ratio</p>
                      </div>
                      <div>
                        <h3>811</h3>
                        <p>Trading Order</p>
                      </div>
                      <div>
                        <h3>439</h3>
                        <p>Accum Followers</p>
                      </div>
                      <div>
                        <h3>32523.41</h3>
                        <p>Profit(USDT)</p>
                      </div>
                      <div>
                        <h3>679</h3>
                        <p>Gain</p>
                      </div>
                      <div>
                        <h3>132</h3>
                        <p>Loss</p>
                      </div> */}
                    </div>
                  </div>
                  <div className="whitebox">
                    <div className="copy_trade_user_data">
                      <h3 className='text-white'>{t("COPY_TRADE_ORDER")}</h3>
                      {pairs && pairs.map(pair => (
                        <div className="trade_order_pair">{pair.tiker_root}</div>
                      ))}
                    </div>
                  </div>
                  <div className="whitebox p-0">
                    <ul class="nav nav-tabs primary_tab tab_bottom_border" id="myTab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <a class="nav-link active" id="pills-history-tab" data-toggle="pill" href="#pills-history" role="tab" aria-controls="pills-ranking" aria-selected="true">{t("HISTORY")}</a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a class="nav-link" id="pills-copytrade-tab" data-toggle="pill" href="#pills-copytrade" role="tab" aria-controls="pills-copytrade" aria-selected="false" onClick={() => this.getCopyTrade('copyTradeDetail')}>{t("COPY_TRADE")} ({tdata_copyTradeDetail && tdata_copyTradeDetail.length})</a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a class="nav-link" id="pills-followers-tab" data-toggle="pill" href="#pills-followers" role="tab" aria-controls="pills-transaction" aria-selected="false" onClick={this.getFollower}>{t("FOLLOWERS")}</a>
                      </li>
                    </ul>
                    <div class="tab-content px-3 pb-3" id="pills-tabContent">
                      <div class="tab-pane fade show active" id="pills-history" role="tabpanel" aria-labelledby="pills-history-tab">
                        <DataTable className="history_table" columns={tcol_tradeHistory} data={tdata_tradeHistory} pagination={true} paginationPerPage="5" paginationRowsPerPageOptions={paginationRowsPerPageOptions} />
                      </div>
                      <div class="tab-pane fade" id="pills-copytrade" role="tabpanel" aria-labelledby="pills-copytrade-tab">
                        <ul class="nav nav-tabs primary_tab inner_tab_style_blue" id="myTab" role="tablist">
                          <li class="nav-item" role="presentation">
                            <a class={tdata_copyTradeTarget == 'copyTradeDetail'?'nav-link active':'nav-link'} onClick={() => this.copyTradeNav('copyTradeDetail')}>{t("DETAILS")}</a>
                          </li>
                          <li class="nav-item" role="presentation">
                            <a class={tdata_copyTradeTarget == 'copyTradeSummary'?'nav-link active':'nav-link'} onClick={() => this.copyTradeNav('copyTradeSummary')}>{t("SUMMARY")}</a>
                          </li>
                        </ul>
                        {tdata_copyTradeTarget == 'copyTradeDetail'?
                          <DataTable className="history_table" columns={tcol_copyTradeDetail} data={tdata_copyTradeDetail} pagination={true} paginationPerPage="5" paginationRowsPerPageOptions={paginationRowsPerPageOptions} />
                        :
                          <DataTable className="history_table" columns={tcol_copyTradeSummary} data={tdata_copyTradeSummary} pagination={true} paginationPerPage="5" paginationRowsPerPageOptions={paginationRowsPerPageOptions} />
                        }
                      </div>
                      <div class="tab-pane fade" id="pills-followers" role="tabpanel" aria-labelledby="pills-followers-tab">
                        <DataTable className="history_table" columns={tcol_followerList} data={tdata_followerList} pagination={true} paginationPerPage="5" paginationRowsPerPageOptions={paginationRowsPerPageOptions} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
			</section>
			<Footer />
		</div>
		);
	}
}

CopyTraderInfo.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(  mapStateToProps,  {}  )(withTranslation()(CopyTraderInfo),withRouter(CopyTraderInfo));