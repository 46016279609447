import React, { Component } from 'react'
import TradeHeader from './TradeHeader'
import Footer from './Footer'
import DepositeQR from "../images/depositeQR.jpg"
import BtcIcon from "../images/btcIcon.png"
import EthIcon from "../images/ethIcon.png"
import XrpIcon from "../images/xrpIcon.png"
import LtcIcon from "../images/ltcIcon.png"
import BchIcon from "../images/bchIcon.png"
import { store } from 'react-notifications-component';
import keys from "../actions/config";
import Select from 'react-select';
import axios from "axios";
import DatePicker from "react-datepicker";
import {bonushistory,Searchdata} from "../actions/authActions";
import {Link,withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CSVLink, CSVDownload } from "react-csv";
import { Scrollbars } from 'react-custom-scrollbars';
import { withTranslation } from 'react-i18next'
import "react-datepicker/dist/react-datepicker.css";
const url = keys.baseUrl;


const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    color: state.isSelected ? 'red' : 'blue',
    padding: 20,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}

const options1 = [
	{ value: 'All', label: 'All' },
  { value: 'Buy', label: 'Buy' },
  { value: 'Sell', label: 'Sell' },
];

const options = [{ value: 'All', label: 'All' },];

class BonusHistory extends Component {
	constructor(props) {
    super(props);
    this.state = {
      historydetails  : [],
			records:[],
			csvData:[],
			contract:{value:'All',label:'All'},
			type:{value:'All',label:'All'},
			startDate:'',
			endDate:'',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
            this.getData()
        };
    componentWillReceiveProps(nextProps) {

        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        //console.log(nextProps.auth.trade,'resultkjljflsjdfsdf')
				if (nextProps.auth !== undefined
            && nextProps.auth.trade !== undefined
            && nextProps.auth.trade.data !== undefined
            && nextProps.auth.trade.data.data !== undefined
            && nextProps.auth.trade.data.type !== undefined
            && nextProps.auth.trade.data.type == 'bonushistory'
            ) {
          //console.log(nextProps.auth.trade.data.data,'daajdflskdjflskdjf')
						        this.setState({historydetails:nextProps.auth.trade.data.data});
                    this.buildarray(nextProps.auth.trade.data.data);
        }
    }
		changecontract = contract => {
	    this.setState({ contract });
	  };

		changetype = type => {
	    this.setState({ type });
	  };

		getData() {
      var input = {"userid":this.props.auth.user.id}
			  this.props.bonushistory(input);
		}

    buildarray = (historydata) =>{
    //   var csvdata = [];
    //   var titledata = ["Contracts","Qty","Filled remaining","Exec price","Trigger price","Order price","Order value","Trading fess","Type","Status","Order#","Order Time"];
    //   //console.log(csvdata,'test');
    //   csvdata.push(titledata);
    //     //console.log('if');
				// historydata.map((item,i)=>{
    //       var pairName = item.pairName?item.pairName:0;
    //       var quantity = item.quantity?item.quantity:0;
    //       var price = item.quantity?item.price:0;
    //       var buyorsell = item.buyorsell?item.buyorsell:0;
    //       var filledAmount = item.filledAmount?item.filledAmount:0;
    //       var orderValue = item.orderValue?item.orderValue:0;
    //       var orderType = item.orderType?item.orderType:0;
    //       var orderDate = item.orderDate?item.orderDate:0;
    //       var _id = item._id?item._id:0;
    //       var status1 = item.status;
    //       var e_price = item.filled.length>0?item.filled[0].Price:0;
    //       var tradingfees = item.filled.length>0?item.filled[0].Fees:0;
    //       var Remaining = parseFloat(quantity) - parseFloat(filledAmount);
    //       var data1 = new Date(orderDate);
    //       let date12 = data1.getFullYear() + '-' + (data1.getMonth() +1) + '-' + data1.getDate() + ' ' + data1.getHours() +':'+ data1.getMinutes() + ':'+data1.getSeconds();

    //       var status = (status1 == 0)?"New":(status1 == 1)?"Completed":(status1 == 2)?"Partial":(status1 == 3)?"Cancel":''
    //       var newarr = [];
    //       newarr.push(pairName);
    //       newarr.push(quantity);
    //       newarr.push(filledAmount+'/'+Remaining);
    //       newarr.push(e_price);
    //       newarr.push(price);
    //       newarr.push('-/-');
    //       newarr.push(orderValue);
    //       newarr.push(parseFloat(tradingfees).toFixed(8));
    //       newarr.push(buyorsell);
    //       newarr.push(status);
    //       newarr.push(_id);
    //       newarr.push(date12);
    //       csvdata.push(newarr);
				// });
    //     //console.log(csvdata,'csvdata');
    //     this.setState({csvData:csvdata})

    }

		Searchdata = () => {
      var postdetails = {
        "contract"  : this.state.contract.value,
        "type"      : this.state.type.value,
        "startDate" : this.state.startDate,
        "endDate"   : this.state.endDate,
        "userid"    : this.props.auth.user.id
      }
			this.props.Searchdata(postdetails);
		}

		pairdatachange = data =>{
			this.setState({records:data});
			if(this.state.records.length==0)
			{
        if(data.length>0){
  				data.map((item,i)=>{
            if(item.first_currency != '' && item.second_currency != '')
            {
              var one = {"value":item.first_currency+item.second_currency,"label":item.first_currency+item.second_currency}
              options.push(one);
            }
  				});
        }
			}
    }

		handleChange = date => {
			this.setState({
				startDate: date
			});
		};


		handleChange1 = date => {
			this.setState({
				endDate: date
			});
		};


  render() {
  	 const {historydetails,timeinforcetype,contract,type} = this.state;
     const {t} = this.props;
    return (
			<div className='bg_strip'>
			<div className="modal fade primary-modal" id="deposite" tabindex="-1" role="dialog" aria-labelledby="deposite" aria-hidden="true" data-backdrop="static">
  <div className="modal-dialog modal-md modal-dialog-centered" role="document">
    <div className="modal-content depositeContent">
    <div className="modal-header">
									<h4 className='modal-title h4'>{t("BTC_DEPOSIT")}</h4>
									<button type="button" className="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
      <div className="modal-body">
      
        <div className="popUpSpace">
          <div className="depositeQr">
            <img src={DepositeQR} className="img-fluid" />
          </div>
          <div className="walletKey">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group inputWithText">
                    <input name="" className="form-control" placeholder="" value="3CMCRgEm8HVz3DrWaCCid3vAANE42jcEv9" type="text" />
                    <button className="input-group-append-icon"><i className="far fa-copy"></i></button>
                </div>
              </div>
            </div>
          </div>
          <div className="noteCont">
            <h6>{t("NOTES")}:</h6>
            <p>1.{t("NOTES1")}</p>
            <p>2.{t("NOTES2")}</p>
            <p>3.{t("NOTES3")}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
    <div className="modal fade primary-modal" id="Withdraw" tabindex="-1" role="dialog" aria-labelledby="Withdraw" aria-hidden="true" data-backdrop="static">
  <div className="modal-dialog modal-md modal-dialog-centered" role="document">
  <div className="modal-header">
									<h4 className='modal-title h4'>{t("BTC_WITHDRAW")}</h4>
									<button type="button" className="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
    <div className="modal-content WithdrawContent">
      <div className="modal-body">
        
        <div className="popUpSpace withdrawPopupForm">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>{t("RECEIVER_WALLET_ADDRESS")} <a href="#" className="float-right">{t("ADD_WALLET")}</a></label>
                <input type="text" name="" className="form-control" value="3CMCRgEm8HVz3DrWaCCid3vAANE42jcEv9" />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>{t("BTC_AMOUNT")} <span className="float-right">{t("BALANCE")}: <small>10.23569878 {t("BTC")}</small> | <a href="#">{t("SEND_ALL")}</a></span></label>
                <input type="text" name="" className="form-control" value="0.00000000" />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>{t("FINAL_AMOUNT")} <span className="float-right">+ {t("FEES")}: 0.00005 {t("BTC")}</span></label>
                <input type="text" name="" className="form-control" value="0.00000000" disabled="" />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>{t("ENTER_AUTHENTICATION_CODE")}</label>
                <input type="text" name="" className="form-control" value="123456" />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <input type="submit" name="" value="Withdraw" className="btn buttonType1" />
              </div>
            </div>
          </div>
          <div className="noteCont">
            <h6>{t("NOTES")}</h6>
            <p>1.{t("MINUMUM_WITHDRAW")} : 0.002BTC </p>
            <p>{t("INSIDE_YOUR_ACCOUNT")}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
				<TradeHeader/>
				<div>
    		<section className='container'>
			  <div className="row mx-0">
			    <div className="col-md-12 px-0">
			      <div className="assetsTable">
			        {/* <nav>
			          <div className="nav nav-tabs" id="navLink-tab" role="tablist">
			            <a href="/myAssets" className="nav-item nav-link">My Assets</a>
                  <a href="/closedPandL" className="nav-item nav-link ">Closed P&L</a>
                  <a href="/AssetsHistory" className="nav-item nav-link">Deposit History</a>
                  <a href="/OrderHistory" className="nav-item nav-link">Order History</a>
                  <a href="/tradeHistory" className="nav-item nav-link">Trade History</a>
                  <a href="/withdrawalHistory" className="nav-item nav-link">Withdrawal History</a>
			          </div>
			        </nav> */}
              <h3 className="assetTitle p-3 nav-tabs mb-0">Bonus History</h3>
			        <div className="tab-content card-body">

			           <div className="table-responsive table_radius">
                {this.state.historydetails?
			                <table id="assetsTable" className="table walletBalanceTable_td_cen">
                        <thead>
                          <tr>
                            <th>{t("TYPE")}</th>
                            <th>{t("BONUS")}</th>
                            <th>{t("DEPOSIT_AMOUNT")}</th>
                            <th>{t("DATE")}</th>
                            <th>{t("REFERREDUSER")}</th>
                          </tr>
                        </thead>
                        <tbody>
                         {
                          historydetails.map((item,i)=>{ //console.log(item,'itemitem')
                       var type = item.type?item.type:'';
                      var depositamount = item.depositamount?item.depositamount:0;
                      var bonus_amount = item.bonus_amount?item.bonus_amount:0;
                      var createdDate = item.createdDate?item.createdDate:0;
                      var referuser = item.referId?item.referId.email:'';

                      var data1 = new Date(createdDate);
                      let date12 = data1.getFullYear() + '-' + (data1.getMonth() +1) + '-' + data1.getDate() + ' ' + data1.getHours() +':'+ data1.getMinutes() + ':'+data1.getSeconds();
                          return <tr>
                                <td>{(type=='0')?'Signup':(type=='1')?'Referral':(type=='2')?'Deposit':(type=='3')?'Social media promotion':(type=='4')?"Trade":''}</td>

                                 <td className="">{bonus_amount}</td>
                                <td>{depositamount}</td>
                                <td>{date12}</td>
                                <td>{referuser}</td>
                            </tr>
                             })
                      }
                        </tbody>
			                </table>
                    : ''}
			            </div>

			        </div>
			      </div>
			    </div>
			  </div>
			</section>
			</div>
    	{/* <Footer /> */}
			</div>
			);
    }
}

BonusHistory.propTypes = {
  Searchdata    : PropTypes.func.isRequired,
  bonushistory    : PropTypes.func.isRequired,
  auth             : PropTypes.object.isRequired,
  errors           : PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(

  mapStateToProps,
  {
    bonushistory,
    Searchdata,
  }
)(withTranslation()(BonusHistory),withRouter(BonusHistory));
