import React, { Component } from 'react'
import BannerX from "../images/xicon.png"
import BannerImg from "../images/bannerImg.png"
import Trademonitor from "../images/tradeMonitor.png"
import MobilePhones from "../images/mobilePhones.png"
import MacAppIcon from "../images/macAppIcon.png"
import GooglePlayIcon from "../images/googlePlayIcon.png"
import { store } from 'react-notifications-component';
import Navbar from './Navbar'
import Footer from './Footer'
import axios from "axios";
import {Link,withRouter} from 'react-router-dom';
import keys from "../actions/config";
const url = keys.baseUrl;


class Maintanance extends Component {
  constructor(props) {
        super(props);
        this.state = {
            identifier   : "",
            subject      : "",
            content      : "",
            errors       : {},
            banner       : {},
            banner_sec   : {},
            banner_sec_1 : {},
            features     : {},
            features_1   : {},
            features_2   : {},
            features_3   : {},
            features_4   : {},
            start        : {},
            start_1      : {},
            trading      : {},
            coming       : {},
            records      : [],
            faq          : [],

        };

        //console.log(this.state,'state');
        }

        componentDidMount() {
             let withdraw_id = this.props.match.params.id;
        };






	render() {
		return(
		<div className='flex_center_main bg_strip'>
	 <h1>Maintanance Page Router Loading</h1>
</div>
);
	}
}

export default Maintanance
