import React, { Component } from 'react'
import TradeHeader from './TradeHeader'
import Footer from './Footer'
import DepositeQR from "../images/depositeQR.jpg"
import BtcIcon from "../images/btcIcon.png"
import EthIcon from "../images/ethIcon.png"
import XrpIcon from "../images/xrpIcon.png"
import LtcIcon from "../images/ltcIcon.png"
import BchIcon from "../images/bchIcon.png"
import { store } from 'react-notifications-component';
import keys from "../actions/config";
import axios from "axios";
import DatePicker from "react-datepicker";
import { tradehistory, tradeSearchdata } from "../actions/authActions";
import { Link, withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CSVLink, CSVDownload } from "react-csv";
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars';
const url = keys.baseUrl;


const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    color: state.isSelected ? 'red' : 'blue',
    padding: 20,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}

const options1 = [
  { value: 'All', label: 'All' },
  { value: 'Buy', label: 'Buy' },
  { value: 'Sell', label: 'Sell' },
];

const options = [{ value: 'All', label: 'All' },];

class TradeHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tradehistory: [],
      records: [],
      csvData: [],
      contract: { value: 'All', label: 'All' },
      type: { value: 'All', label: 'All' },
      startDate: '',
      endDate: '',
      // exchangetype : 'Derivatives',
      exchangetype: 'Spot',
    };
  }

  styles = {
    option: (provided, state) => ({
      ...provided,
      color: "white",
      backgroundColor: "#000",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '52px',
      padding: '0 6px',
      backgroundColor: "#03081f",
      borderColor: '#81c8f6',
    borderRadius: 10,
    borderStyle: 'solid',
    borderWidth: '1px'
     
    }),
    control: (provided, state) => ({
      ...provided,
      height: '52px',
      borderRadius:10,
      backgroundColor: "#03081f",
      border:'none'
     
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '52px',
      position: 'absolute',
      right: 0,
      top: 0,
      color:'#fff' 
    }),    
    singleValue: (provided, state) => ({
      ...provided,
      color: "#fff"
    })
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getData()
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }

    if (nextProps.auth !== undefined
      && nextProps.auth.trade !== undefined
      && nextProps.auth.trade.data !== undefined
      && nextProps.auth.trade.data.data !== undefined
      && nextProps.auth.trade.data.type !== undefined) {
      this.setState({ tradehistory: nextProps.auth.trade.data.data });
      this.buildarray(nextProps.auth.trade.data.data);
    }
  }

  getData() {
    var input = { "userid": this.props.auth.user.id, "exchangetype": this.state.exchangetype }
    this.pairdatachanges();
    this.props.tradehistory(input);
  }

  changecontract = contract => {
    this.setState({ contract });
  };

  changetype = type => {
    this.setState({ type });
  };
  exchangetype = e => {
    var type = e.target.innerHTML;
    this.setState({ exchangetype: type, records: [] }
      , () => {
        this.getData();
      });
  };

  buildarray = (historydata) => {
    var csvdata = [];

    var titledata = ["Contracts", "Filled Type", "Filled Qty", "Exec price", "Value", "Fee Rate", "Fees Paid", "Order Price", "Unfilled Qty", "Type", "Transaction ID", "Order Time"];
    csvdata.push(titledata);
    if (historydata.length > 0) {
      historydata.map((item, i) => {

        // console.log("itemaaaaaaaaaaa",item)
        var quantity = item.quantity ? item.quantity : 0;
        var price = item.price ? item.price : 0;
        var orderValue = item.orderValue ? item.orderValue : 0;
        var orderType = item.buyorsell ? item.buyorsell : 0;
        var _id = item._id ? item._id : 0;
        var classs = item.buyorsell == 'buy' ? 'greenText' : 'pinkText';
        var filledAmount = item.filledAmount ? item.filledAmount : 0;
        var Remaining = parseFloat(quantity) - parseFloat(filledAmount);
        var newarr = [];
        let fees_rate=item. maker_rebate
        if (typeof item.filled != 'undefined') {
          item.filled.map((itemdata, i) => {
            //console.log(itemdata, 'itemdatasss');
            var created_at = itemdata.created_at ? itemdata.created_at : '';
            var Price = itemdata.Price ? itemdata.Price : 0;
            var pairname = itemdata.pairname ? itemdata.pairname : 0;
            var data1 = new Date(created_at);
            let date = data1.getFullYear() + '-' + (data1.getMonth() + 1) + '-' + data1.getDate() + ' ' + data1.getHours() + ':' + data1.getMinutes() + ':' + data1.getSeconds();
            // let fees_rate=0.25 maker_rebate
            let fees=   itemdata.Fees
            newarr.push(pairname);
            newarr.push("Trade");
            newarr.push(filledAmount);
            newarr.push(Price);
            newarr.push(orderValue);
            newarr.push(fees_rate);
            newarr.push(fees);
            newarr.push(orderValue);
            // newarr.push(orderValue);
            // newarr.push(orderValue);
            newarr.push(Remaining);
            newarr.push(orderType);
            newarr.push(_id);
            newarr.push(date);

            csvdata.push(newarr);
            // console.log("newwwwwwwwwwwwwwwwwwwww",csvdata)

          })
        }
      })
    }
    this.setState({ csvData: csvdata })
  }

  Searchdata = () => {
    var typeexchange = this.state.exchangetype == "Derivative" ? "Futures" : "Spot"

    var postdetails = {
      "contract": this.state.contract.value,
      "type": this.state.type.value,
      "startDate": this.state.startDate,
      "endDate": this.state.endDate,
      "userid": this.props.auth.user.id,
      "exchangetype": typeexchange
    }
    this.props.tradeSearchdata(postdetails);
  }

  pairdatachanges = data => {
    axios
      .post(url + "cryptoapi/pair-data", { exchangetype: this.state.exchangetype })
      .then(res => {
        if (res.data.status) {
          options.splice(1);
          // //console.log(res.data.data,'ressssss');
          this.setState({ records: res.data.data });
          res.data.data.map((item, i) => {
            if (item.first_currency != '' && item.second_currency != '') {
              var one = { "value": item.first_currency + item.second_currency, "label": item.first_currency + item.second_currency }
              options.push(one);
            }
          });
        }
      })
      .catch();

  }

  handleChange = date => {
    this.setState({
      startDate: date,
      endDate: ''
    });
  };


  handleChange1 = date => {
    this.setState({
      endDate: date
    });
  };


  render() {
    const {t} = this.props;
    const { tradehistory, contract, type } = this.state
    return (
      <div className='bg_strip'>
        <div className="modal fade primary-modal" id="deposite" tabindex="-1" role="dialog" aria-labelledby="deposite" aria-hidden="true" data-backdrop="static">
          <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <div className="modal-content depositeContent">
            <div className="modal-header">
									<h4 className='modal-title h4'>{t("BTC_DEPOSIT")}</h4>
									<button type="button" className="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
              <div className="modal-body">
               
                <div className="popUpSpace">
                  <div className="depositeQr">
                    <img src={DepositeQR} className="img-fluid" />
                  </div>
                  <div className="walletKey">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group inputWithText">
                          <input name="" className="form-control" placeholder="" value="3CMCRgEm8HVz3DrWaCCid3vAANE42jcEv9" type="text" />
                          <button className="input-group-append-icon"><i className="far fa-copy"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="noteCont">
                    <h6>{t("NOTES")}:</h6>
                    <p>1. {t("NOTES1")}</p>
                    <p>2. {t("NOTES2")}</p>
                    <p>3. {t("NOTES3")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade primary-modal" id="Withdraw" tabindex="-1" role="dialog" aria-labelledby="Withdraw" aria-hidden="true" data-backdrop="static">
          <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <div className="modal-content WithdrawContent">
            <div className="modal-header">
									<h4 className='modal-title h4'>{t("BTC_WITHDRAW")}</h4>
									<button type="button" className="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
              <div className="modal-body">
                
                <div className="popUpSpace withdrawPopupForm">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>{t("RECEIVER_WALLET_ADDRESS")} <a href="#" className="float-right">{t("ADD_WALLET")}</a></label>
                        <input type="text" name="" className="form-control" value="3CMCRgEm8HVz3DrWaCCid3vAANE42jcEv9" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>{t("BTC_AMOUNT")} <span className="float-right">{t("BALANCE")}: <small>10.23569878 {t("BTC")}</small> | <a href="#">{t("SEND_ALL")}</a></span></label>
                        <input type="text" name="" className="form-control" value="0.00000000" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>{t("FINAL_AMOUNT")} <span className="float-right">+ {t("FEES")}: 0.00005  {t("BTC")}</span></label>
                        <input type="text" name="" className="form-control" value="0.00000000" disabled="" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>{t("ENTER_AUTHENTICATION_CODE")}</label>
                        <input type="text" name="" className="form-control" value="123456" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input type="submit" name="" value="Withdraw" className="btn buttonType1" />
                      </div>
                    </div>
                  </div>
                  <div className="noteCont">
                    <h6>{t("NOTES")}:</h6>
                    <p>1. {t("MINUMUM_WITHDRAW")}:0.002BTC.</p>
                    <p>2. {t("INSIDE_YOUR_ACCOUNT")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TradeHeader pairdatachange={this.pairdatachange} />
        <div>
          <section className=''>
            <div className="row mx-0 myasset">
              <div className="col-md-12 px-0">
                <div className="assetsTable ">
                  {/* <nav>
                    <div className="nav nav-tabs" id="navLink-tab" role="tablist">
                      <a href="/closedPandL" className="nav-item nav-link ">Closed P&L</a>

                      <Link to="/AssetsHistory" className="nav-item nav-link active">Deposit History</Link>
                      <Link to="/OrderHistory" className="nav-item nav-link">Order History</Link>
                      <Link to="/tradeHistory" className="nav-item nav-link">Trade History</Link>
                      <Link to="/withdrawalHistory" className="nav-item nav-link">Withdrawal History</Link>
                    </div>
                  </nav> */}
                
                  <div className="tab-content card-body" id="nav-tabContent">
                  <h3 className="assetTitle">{t("TRADE_HISTORY")}</h3>
                  <nav>
                    <div className="nav nav-tabs paddingTopTen nav_border_new" id="nav-tab" role="tablist">
                      <a
                        className={this.state.exchangetype == 'Derivatives' ? "nav-item nav-link active pl-0 ml-0" : "nav-item nav-link pl-0 ml-0"}
                        id="nav-USD-tab"
                        data-toggle="tab" href="#nav-USD" role="tab" aria-controls="nav-USD" aria-selected="false" onClick={this.exchangetype}>{t("DERIVATIVES")}</a>
                      <a
                        className={this.state.exchangetype == 'Spot' ? "nav-item nav-link active" : "nav-item nav-link"}
                        id="nav-INR-tab" data-toggle="tab" href="#nav-INR" role="tab" aria-controls="nav-INR" aria-selected="false" onClick={this.exchangetype}>{t("SPOT")}</a>
                    </div>
                  </nav>
                    <div className="tab-pane fade show active" id="nav-USD" role="tabpanel" aria-labelledby="nav-USD-tab">
                      <div className="">
                        <div className="assetHistoryTableTop clearfix mx-0 mt-0">
                          <div className="row">
                            <div className="col-lg-12 col-xl-12 col-md-11 col-sm-12">
                              <div className="row">
                                <div className="col-lg-3 col-xl-2 col-md-4 col-sm-6">
                                  <div className="form-group clearfix">
                                    <label>{t("PAIRS")}</label>

                                    <Select
                                      styles={this.styles} className="border_blue_select"
                                      width='100%'
                                      menuColor='red'
                                      options={options}
                                      value={contract}
                                      onChange={this.changecontract} isSearchable={false}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-3 col-xl-2 col-md-4 col-sm-6">
                                  <div className="form-group clearfix">
                                    <label>{t("TYPE")}</label>
                                    <Select
                                     styles={this.styles} className="border_blue_select"
                                      width='100%'
                                      menuColor='red'
                                      options={options1}
                                      value={type}
                                      onChange={this.changetype} isSearchable={false}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-3 col-xl-2 col-md-4 col-sm-6">
                                  <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DatePicker
                                      selected={this.state.startDate}
                                      onChange={this.handleChange}
                                      showYearDropdown
                                      showMonthDropdown
                                      // minDate={new Date()}
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-3 col-xl-2 col-md-4 col-sm-6">
                                  <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DatePicker
                                      selected={this.state.endDate}
                                      onChange={this.handleChange1}
                                      showYearDropdown
                                      showMonthDropdown
                                      minDate={new Date(new Date(this.state.startDate).setDate(new Date(this.state.startDate).getDate() + 1))}
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-3 col-xl-2 col-md-4 col-sm-6">
                                  <div className="form-group">
                                    <label className="d-none d-md-block invisible">s</label>
                                    <input type="button" name="" value={t("SEARCH")} onClick={this.Searchdata} className=" themebtn" />
                                  </div>
                                </div>
                                <div className="col-lg-3 col-xl-2 col-md-4 col-sm-6">
                              <div className="downLoadBTN float-xl-right">
                                <div className="form-group">
                                  <label className="d-none d-md-block invisible">s</label>

                                  {(this.state.csvData.length > 0) ?
                                    <CSVLink data={this.state.csvData} className="btn transYellowButton themebtn">{t("CSV")}</CSVLink> : ''
                                  }
                                </div>
                              </div>
                            </div>
                              </div>
                            </div>
                           
                          </div>
                        </div>

                      </div>
                      <div className="tab-content">
                        <div className="table-responsive table_radius walletBalanceTable_td_cen">
                          {this.state.tradehistory ?
                          
                              <table id="assetsTable" className="table table-striped rembordor">
                                <thead>
                                  <tr>
                                    <th>{t("CONTRACTS")}</th>
                                    <th>{t("FILLED_TYPE")}</th>
                                    <th>{t("FILLED_QTY")}</th>
                                    <th>{t("EXEC_PRICE")}</th>
                                    <th>{t("VALUE")}</th>
                                    <th>{t("FEE_RATE")}</th>
                                    <th>{t("FEES_PAID")}</th>
                                    <th>{t("ORDER_PRICE")}</th>
                                    <th>{t("UNFILLED_QTY")}</th>
                                    <th>{t("TYPE")}</th>
                                    <th>{t("TRANSACTION_ID")}</th>
                                    <th>{t("TIME")}</th>
                                  </tr>
                                </thead>
                                {this.state.tradehistory.length>0 ?
                              <>
                                {
                                  tradehistory.map((item, i) => {
                                    //console.log(item, 'itemsss');
                                    var quantity = item.quantity ? item.quantity : 0;
                                    var price = item.price ? item.price : 0;
                                    var orderValue = item.orderValue ? item.orderValue : 0;
                                    var orderType = item.buyorsell ? item.buyorsell : 0;
                                    var taker_fees = item.taker_fees ? item.taker_fees : 0;

                                    var _id = item._id ? item._id : 0;
                                    var classs = item.buyorsell == 'buy' ? 'greenText' : 'pinkText';
                                    var filledAmount = item.filledAmount ? item.filledAmount : 0;
                                    var Remaining = parseFloat(quantity) - parseFloat(filledAmount);
                                    return (<tbody>
                                      { (typeof item.filled != 'undefined') ? item.filled.map((itemdata, i) => {
                                        //console.log(itemdata, 'itemdatasss');
                                        var created_at = itemdata.created_at ? itemdata.created_at : '';
                                        var Fees = itemdata.Fees ? itemdata.Fees : 0;
                                        var Price = itemdata.Price ? itemdata.Price : 0;
                                        var pairname = itemdata.pairname ? itemdata.pairname : 0;
                                        var data1 = new Date(created_at);
                                        let date = data1.getFullYear() + '-' + (data1.getMonth() + 1) + '-' + data1.getDate() + ' ' + data1.getHours() + ':' + data1.getMinutes() + ':' + data1.getSeconds();
                                        return (<tr>
                                          <td className="text-center">{pairname}</td>
                                          <td className="text-center">{t("TRADE")}</td>
                                          <td className="text-center">{parseFloat(filledAmount).toFixed(8)}</td>
                                          <td className="text-center">{parseFloat(Price).toFixed(2)}</td>
                                          <td className="text-center">{parseFloat(orderValue).toFixed(8)}</td>
                                          <td className="text-center">{parseFloat(taker_fees).toFixed(8)}</td>
                                          <td className="text-center">{parseFloat(Fees).toFixed(8)}</td>
                                          <td className="text-center">{parseFloat(Price).toFixed(2)}</td>
                                          <td className="text-center">{parseFloat(Remaining).toFixed(8)}</td>
                                          <td className="text-center">{orderType}</td>
                                          <td className="text-center">{_id}</td>
                                          <td className="text-center">{date}</td>
                                        </tr>
                                        );
                                      }) : ''
                                      }
                                    </tbody>);

                                  })
                                }
                                </>
                                : (<tbody>
                                  <tr ><td colspan="12" className="text-center">{t("NO_RECORDS")}</td></tr></tbody>)
  }

                              </table>: ''}
                        </div>

                      </div>
                    </div>
                    <div className="tab-pane fade" id="nav-INR" role="tabpanel" aria-labelledby="nav-INR-tab">
                      <div className="tab-content">
                        <div className="assetHistoryTableTop clearfix mx-0  mt-0">
                          <div className="row">
                            <div className="col-lg-12 col-xl-12 col-md-11 col-sm-12">
                              <div className="row">
                                <div className="col-lg-3 col-xl-2 col-md-4 col-sm-6">
                                  <div className="form-group clearfix">
                                    <label>{t("PAIRS")}</label>

                                    <Select
                                      styles={this.styles} className="border_blue_select"
                                      width='100%'
                                      menuColor='red'
                                      options={options}
                                      value={contract}
                                      onChange={this.changecontract} isSearchable={false}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-3 col-xl-2 col-md-4 col-sm-6">
                                  <div className="form-group clearfix">
                                    <label>{t("TYPE")}</label>
                                    <Select
                                      styles={this.styles} className="border_blue_select"
                                      width='100%'
                                      menuColor='red'
                                      options={options1}
                                      value={type}
                                      onChange={this.changetype} isSearchable={false}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-3 col-xl-2 col-md-4 col-sm-6">
                                  <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DatePicker
                                      selected={this.state.startDate}
                                      onChange={this.handleChange}
                                      showYearDropdown
                                      showMonthDropdown
                                      // minDate={new Date()}
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-3 col-xl-2 col-md-4 col-sm-6">
                                  <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DatePicker
                                      selected={this.state.endDate}
                                      onChange={this.handleChange1}
                                      showYearDropdown
                                      showMonthDropdown
                                      minDate={new Date(new Date(this.state.startDate).setDate(new Date(this.state.startDate).getDate() + 1))}
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-3 col-xl-2 col-md-4 col-sm-6">
                                  <div className="form-group">
                                    <label className="d-none d-md-block invisible">s</label>
                                    <input type="button" name="" value="Search" onClick={this.Searchdata} className="buttonType1 ml-0 ml-xl-auto" />
                                  </div>
                                </div>
                                <div className="col-lg-3 col-xl-2 col-md-3 col-sm-4">
                              <div className="downLoadBTN float-xl-right">
                                <div className="form-group">
                                  <label className="d-none d-md-block invisible">s</label>

                                  {(this.state.csvData.length > 0) ?
                                    <CSVLink data={this.state.csvData} className="btn transYellowButton">{t("CSV")}</CSVLink> : ''
                                  }
                                </div>
                              </div>
                            </div>
                              </div>
                            </div>
                           
                          </div>
                        </div>

                      </div>
                      <div className="tab-content">
                        <div className="table-responsive table_radius walletBalanceTable_td_cen">
                          {this.state.tradehistory ?
                          
                              <table id="assetsTable" className="table table-striped rembordor">
                                <thead>
                                  <tr>
                                    <th>{t("CONTRACTS")}</th>
                                    <th>{t("FILLED_TYPE")}Filled Type</th>
                                    <th>{t("FILLED_QTY")}Filled Qty</th>
                                    <th>{t("EXEC_PRICE")}</th>
                                    <th>{t("VALUE")}</th>
                                    <th>{t("FEE_RATE")}Fee Rate</th>
                                    <th>{t("FEES_PAID")}</th>
                                    <th>{t("ORDER_PRICE")}</th>
                                    <th>{t("UNFILLED_QTY")}</th>
                                    <th>{t("TYPE")}</th>
                                    <th>{t("TRANSACTION_ID")}</th>
                                    <th>{t("TIME")}</th>
                                  </tr>
                                </thead>
                                {this.state.tradehistory.length>0 ?
                              <> {
                                  tradehistory.map((item, i) => {
                                    //console.log(item, 'itemsss');
                                    var quantity = item.quantity ? item.quantity : 0;
                                    var price = item.price ? item.price : 0;
                                    var orderValue = item.orderValue ? item.orderValue : 0;
                                    var orderType = item.buyorsell ? item.buyorsell : 0;
                                    var taker_fees = item.taker_fees ? item.taker_fees : 0;

                                    var _id = item._id ? item._id : 0;
                                    var classs = item.buyorsell == 'buy' ? 'greenText' : 'pinkText';
                                    var filledAmount = item.filledAmount ? item.filledAmount : 0;
                                    var Remaining = parseFloat(quantity) - parseFloat(filledAmount);
                                    return (<tbody>
                                      { (typeof item.filled != 'undefined') ? item.filled.map((itemdata, i) => {
                                        //console.log(itemdata, 'itemdatasss');
                                        var created_at = itemdata.created_at ? itemdata.created_at : '';
                                        var Fees = itemdata.Fees ? itemdata.Fees : 0;
                                        var Price = itemdata.Price ? itemdata.Price : 0;
                                        var pairname = itemdata.pairname ? itemdata.pairname : 0;
                                        var data1 = new Date(created_at);
                                        let date = data1.getFullYear() + '-' + (data1.getMonth() + 1) + '-' + data1.getDate() + ' ' + data1.getHours() + ':' + data1.getMinutes() + ':' + data1.getSeconds();
                                        return (<tr>
                                          <td className="text-center">{pairname}</td>
                                          <td className="text-center">{t("TRADE")}</td>
                                          <td className="text-center">{parseFloat(filledAmount).toFixed(8)}</td>
                                          <td className="text-center">{parseFloat(Price).toFixed(2)}</td>
                                          <td className="text-center">{parseFloat(orderValue).toFixed(8)}</td>
                                          <td className="text-center">{parseFloat(taker_fees).toFixed(8)}</td>
                                          <td className="text-center">{parseFloat(Fees).toFixed(8)}</td>
                                          <td className="text-center">{parseFloat(Price).toFixed(2)}</td>
                                          <td className="text-center">{parseFloat(Remaining).toFixed(8)}</td>
                                          <td className="text-center">{orderType}</td>
                                          <td className="text-center">{_id}</td>
                                          <td className="text-center">{date}</td>
                                        </tr>
                                        );
                                      }) : ''
                                      }
                                    </tbody>);

                                  })
                                }</> 
                                :
                                 <tbody>
                                  <tr><td colspan="12" className="text-center">{t("NO_RECORDS")}</td></tr></tbody>
                              }

                              </table> : ''}
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

TradeHistory.propTypes = {
  tradeSearchdata: PropTypes.func.isRequired,
  tradehistory: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(

  mapStateToProps,
  {
    tradehistory,
    tradeSearchdata,
  }
)(withTranslation()(TradeHistory),withRouter(TradeHistory));
