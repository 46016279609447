import React, { Component } from 'react'
import TradeHeader from './TradeHeader'
import Footer from './Footer'
// import LineChart from "../images/line-chart.jpg"
import { store } from 'react-notifications-component';
import keys from "../actions/config";
import Select from 'react-select';
import axios from "axios";
import DatePicker from "react-datepicker";
import {closedposhistory,pnlSearchdata,position_details} from "../actions/authActions";
import {Link,withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CSVLink, CSVDownload } from "react-csv";
import ScatterPlot from 'react-linechart';
import { Scrollbars } from 'react-custom-scrollbars';
import '../../node_modules/react-linechart/dist/styles.css';
import { withTranslation } from 'react-i18next';

import "react-datepicker/dist/react-datepicker.css";
const url = keys.baseUrl;

const menuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  }
}


const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: "white",
    backgroundColor: "#000",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: '52px',
    padding: '0 6px',
    backgroundColor: "#03081f",
    borderColor: '#81c8f6',
  borderRadius: 10,
  borderStyle: 'solid',
  borderWidth: '1px'
   
  }),
  control: (provided, state) => ({
    ...provided,
    height: '52px',
    borderRadius:10,
    backgroundColor: "#03081f",
    border:'none'
   
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '52px',
    position: 'absolute',
    right: 0,
    top: 0,
    color:'#fff' 
  }),    
  singleValue: (provided, state) => ({
    ...provided,
    color: "#fff"
  })
}

const options1 = [
	{ value: 'All', label: 'All' },
  { value: 'Buy', label: 'Buy' },
  { value: 'Sell', label: 'Sell' },
];

const options = [];

class ClosedPandL extends Component {
  constructor(props) {
    super(props);
    this.state = {
      historydetails   : [],
      position_details : [],
      daily_details    : [],
      records          : [],
      csvData          : [],
      chartdata        : [],
      contract         : {value:'BTCUSD',label:'BTCUSD'},
      type             : {value:'All',label:'All'},
      first_currency   : 'BTC',
      second_currency  : 'USD',
      startDate        : '',
      endDate          : '',
      totalpnl         : 0,
    };
  }


    componentDidMount() {
      window.scrollTo(0, 0);
              this.getData()
          };
      componentWillReceiveProps(nextProps) {

          if (nextProps.errors) {
              this.setState({
                  errors: nextProps.errors
              });
          }
          //console.log(nextProps.auth);
  				if (nextProps.auth !== undefined
              && nextProps.auth.trade !== undefined
              && nextProps.auth.trade.data !== undefined
              && nextProps.auth.trade.data.data !== undefined
              && nextProps.auth.trade.data.type !== undefined && nextProps.auth.trade.data.type == 'closedposhistory') {
  						        this.setState({historydetails:nextProps.auth.trade.data.data});
                      this.buildarray(nextProps.auth.trade.data.data);
          }
          if (nextProps.auth !== undefined
              && nextProps.auth.trade !== undefined
              && nextProps.auth.trade.data !== undefined
              && nextProps.auth.trade.data.data !== undefined
              && nextProps.auth.trade.data.type !== undefined && nextProps.auth.trade.data.type == 'position_details') {
                      this.setState({position_details:nextProps.auth.trade.data.data.position_details,daily_details:nextProps.auth.trade.data.data.daily_details});


          }
      }
  		changecontract = contract => {
        if(this.state.records.length>0)
        {
            var index = this.state.records.findIndex(x => (x.tiker_root) === contract.value);
            //console.log(index,'index');
            var first_currency = this.state.records[index].first_currency;
            this.setState({first_currency:first_currency})
        }
  	    this.setState({ contract });
  	  };

  		changetype = type => {
  	    this.setState({ type });
  	  };

  		getData() {
        var input = {"userid":this.props.auth.user.id,pair:this.state.contract.value};
        var input1 = {"userId":this.props.auth.user.id,pair:this.state.contract.value};
  			  this.props.closedposhistory(input);
          this.props.position_details(input1);
  		}

      buildarray = (historydata) =>{
        var csvdata = [];
        var titledata = ["Contracts","Closing Direction","Quantity","Entry Price","Exit Price","Closed P&L","Exit Type","Close Time"];
        csvdata.push(titledata);
        var chartarr = [];
        var totpnl = 0;
  				historydata.map((item,i)=>{
            var pairname = item.pairname?item.pairname:0;
            var quantity = item.quantity?item.quantity:0;
            var entry_price = item.entry_price?item.entry_price:0;
            var exit_price = item.exit_price?item.exit_price:0;
            var profitnloss = item.profitnloss?item.profitnloss:0;
            var createdDate = item.createdDate?item.createdDate:0;
            totpnl = totpnl+parseFloat(profitnloss);
            var data1 = new Date(createdDate);
            let date12 = data1.getFullYear() + '-' + (data1.getMonth() +1) + '-' + data1.getDate() + ' ' + data1.getHours() +':'+ data1.getMinutes() + ':'+data1.getSeconds();
            var newarr = [];
            var date = data1.getFullYear() + '-' + (data1.getMonth() +1) + '-' + data1.getDate();

            newarr.push(pairname);
            newarr.push(quantity>0?"Closed Long":"Closed Short");
            newarr.push(quantity);
            newarr.push(entry_price);
            newarr.push(exit_price);
            newarr.push(parseFloat(profitnloss).toFixed(8));
            newarr.push("Trade");
            newarr.push(date12);
            var index = chartarr.findIndex(x => (x.x) === date);
            if(index==-1)
            {
                chartarr.push({x:date,y:profitnloss})
            }
            else
            {
              chartarr[index].y = chartarr[index].y+profitnloss;
            }

            csvdata.push(newarr);
  				});
          //console.log(chartarr,'csvdata');
          this.setState({csvData:csvdata,totalpnl:totpnl})
          this.setState({chartdata:chartarr});
      }

  		Searchdata = () => {
        var postdetails = {
          "contract"  : this.state.contract.value,
          "startDate" : this.state.startDate,
          "endDate"   : this.state.endDate,
          "userid"    : this.props.auth.user.id
        }
  			this.props.pnlSearchdata(postdetails);
  		}

  		pairdatachange = data =>{
  			this.setState({records:data});
  			if(this.state.records.length==0)
  			{
  				data.map((item,i)=>{
            if(item.first_currency != '' && item.second_currency != '')
            {
              var one = {"value":item.first_currency+item.second_currency,"label":item.first_currency+item.second_currency}
              options.push(one);
            }
  				});
  			}
      }

  		handleChange = date => {
  			this.setState({
  				startDate: date
  			});
  		};


  		handleChange1 = date => {
  			this.setState({
  				endDate: date
  			});
  		};


  render() {
    //console.log(this.state.chartdata);
    const data = [
            {
                color: "yellow",
                points: this.state.chartdata
                // points: [{x: "2016-01-01", y: 2}, {x: "2016-01-02", y: 5}, {x: "2016-01-03", y: -3}]
            }
        ];

// const grouped = parseGroupingBy(data, "date", "value", "id");
    const {historydetails,timeinforcetype,contract,type,position_details,daily_details} = this.state
    var profitnloss = 0
    if(this.state.records.length>0)
    {
    var index = this.state.records.findIndex(x => (x.tiker_root) === contract.value);
    var index11 = this.state.records.findIndex(x => (x.tiker_root) === 'BTCUSD');
    //console.log(index,'index');
    var usd_price = this.state.records[index11].last;
    var last_price = this.state.records[index].last;
    var markprice = this.state.records[index].markprice;

      if(position_details.length>0)
      {
        var pos_pairName       = (position_details[0].pairName)?position_details[0].pairName:0;
        var pos_quantity       = (position_details[0].quantity)?position_details[0].quantity:0;
        var pos_price          = (position_details[0].price)?position_details[0].price:0;
        var pos_leverage       = (position_details[0].leverage)?position_details[0].leverage:0;

        //calculate the initial margin
        var pos_initial_margin = (parseFloat(pos_quantity)/parseFloat(pos_price))*((100/parseFloat(pos_leverage))/100);
        var profitnlosss        = [ (1/parseFloat(pos_price))  - (1/parseFloat(last_price)) ] * parseFloat(pos_quantity);
        profitnlosss = parseFloat(profitnlosss?profitnlosss:0) + parseFloat(this.state.totalpnl);
        profitnloss = parseFloat(profitnloss)+parseFloat(profitnlosss);
        //console.log(profitnlosss);
        //console.log(this.state.totalpnl,'this.state.totalpnl');
        // //console.log(profitnloss,'profitnloss');
        // //console.log(pos_price,'pos_price');
        // //console.log(last_price,'last_price');
        // //console.log(pos_quantity,'pos_quantity');
        var profitnlossper     = (parseFloat(profitnlosss)/parseFloat(pos_initial_margin))*100;
        var profitnlossusd     = (profitnlosss*parseFloat(usd_price));

         var uprofitnloss        = [ (1/parseFloat(pos_price))  - (1/parseFloat(markprice)) ] * parseFloat(pos_quantity);
         var uprofitnlossper     = (parseFloat(uprofitnloss)/parseFloat(pos_initial_margin))*100;
        var uprofitnlossusd     = (uprofitnloss*parseFloat(markprice));


      }
      if(daily_details.length>0)
      {
        var daily_quantity       = (daily_details[0].quantity)?daily_details[0].quantity:0;
        var daily_price          = (daily_details[0].price)?daily_details[0].price:0;
        var daily_leverage       = (daily_details[0].leverage)?daily_details[0].leverage:0;
        var daily_fees           = (daily_details[0].Fees)?daily_details[0].Fees:0;

        //calculate the initial margin
        var daily_initial_margin = (parseFloat(daily_quantity)/parseFloat(daily_price))*((100/parseFloat(daily_leverage))/100);
        var dailyprofitnloss        = [ (1/parseFloat(daily_price))  - (1/parseFloat(last_price)) ] * parseFloat(daily_quantity);
        dailyprofitnloss            = parseFloat(dailyprofitnloss)-parseFloat(daily_fees)
        var dailyprofitnlossper     = (parseFloat(dailyprofitnloss)/parseFloat(daily_initial_margin))*100;
        var dailyprofitnlossusd     = (dailyprofitnloss*parseFloat(markprice));

      }
      else
      {
        var daily_quantity      = 0;
        var daily_price         = 0;
        var daily_leverage      = 0;
        var daily_fees          = 0;
        var dailyprofitnlossper = 0;
        var dailyprofitnloss    = 0;
        var dailyprofitnlossusd = 0;
      }

    }
    else
    {
       // profitnloss =0;
      var uprofitnloss =0;
    }
    if(historydetails)
    {
      //console.log(profitnloss)
       historydetails.map((item,i)=>{
        profitnloss = parseFloat(profitnloss)+parseFloat(item.profitnloss);
       });

    }
    const {t} = this.props;
    return (
			<div className='bg_strip'>
			<TradeHeader pairdatachange={this.pairdatachange}/>
				<div className='container'>
					<section>
					  <div className="row mx-0 margint_top_closed_pandl">
					    <div className="col-md-12 px-0">
					      <div className="darkBox assetsTable px-2 px-md-4">
					        {/* <nav>
					          <div className="nav nav-tabs" id="navLink-tab" role="tablist">
			            		<a href="/closedPandL" className="nav-item nav-link active">Closed P&L</a>
			            		<a href="/AssetsHistory" className="nav-item nav-link">Deposit History</a>
			            		<a href="/OrderHistory" className="nav-item nav-link">Order History</a>
			            		<a href="/tradeHistory" className="nav-item nav-link">Trade History</a>
			            		<a href="/withdrawalHistory" className="nav-item nav-link">Withdrawal History</a>
					          </div>
					        </nav> */}
                  <h3 className="assetTitle p-3 nav-tabs">Closed P&L</h3>
                  <div className="tab-content">
                    <div className="assetHistoryTableTop clearfix">
                      <div className="row closedpand_row_pare">
                        <div className="col-lg-10 col-md-9 col-sm-8">
                          <div className="row closedpand_row">
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="form-group clearfix">
                                    <label>{t("CONTRACTS")}</label>

                                  <Select
                                       className="border_blue_select border_blue_select_bg_blue"
                                       width='100%'
                                       menuColor='red'
                                       MenuProps={menuProps}
                                       options={options}
                                       value={contract}
                                       onChange={this.changecontract} isSearchable={false}
                                       />
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="form-group">
                                  <label>{t("START_DATE")}</label>
                                    <DatePicker
                                        selected={this.state.startDate}
                                        onChange={this.handleChange}
                                        showYearDropdown
                                        showMonthDropdown
                                    />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="form-group">
                                  <label>{t("END_DATE")}</label>
                                  <DatePicker
                                      selected={this.state.endDate}
                                      onChange={this.handleChange1}
                                      showYearDropdown
                                      showMonthDropdown
                                  />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 flex_cen_mob_btn">
                              <div className="form-group">
                                <label className="d-none d-md-block invisible">s</label>
                                <input type="button" name="" value="Search" onClick={this.Searchdata} className="buttonType1" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-4">
                           <div className="downLoadBTN float-right">
                             <div className="form-group">
                              <label className="d-none d-sm-block invisible">s</label>

                              {(this.state.csvData.length>0)?
                                <CSVLink data={this.state.csvData} className="btn transYellowButton">{t("CSV")}</CSVLink> : ''
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
					        <div className="tab-content">

					           <div className="table-responsive">
                   <Scrollbars style={{ width: '100%', height: 400 }} renderTrackVertical={props => <div className="track-vertical"/>}>
					          <table id="assetsTable" className="table">
					                                            <thead>
					                                                <tr className="wow flipInX" data-wow-delay=".5s;">
					                                                    <th>{t("CONTRACTS")}</th>
					                                                    <th>{t("CLOSING_DIRECTION")}</th>
					                                                    <th>{t("QUANTITY")}</th>
					                                                    <th>{t("ENTYRY_PRICE")}</th>
					                                                    <th>{t("EXIT_PRICE")}</th>
					                                                    <th>{t("CLOSED_P&L")}</th>
					                                                    <th>{t("EXIT_TYPE")}</th>
					                                                    <th>{t("CLOSE_TIME")}</th>
					                                                </tr>
					                                            </thead>
                                                      {this.state.historydetails.length>0?(
					                                           <tbody>
                                                     {
                                                      historydetails.map((item,i)=>{
                                                  var pairname = item.pairname?item.pairname:0;
                                                  var quantity = item.quantity?item.quantity:0;
                                                  var exit_type = item.exit_type?item.exit_type:0;
                                                  var entry_price = item.entry_price?item.entry_price:0;
                                                  var exit_price = item.exit_price?item.exit_price:0;
                                                  var profitnloss = item.profitnloss?item.profitnloss:0;
                                                  var createdDate = item.createdDate?item.createdDate:0;

                                                  var data1 = new Date(createdDate);
                                                  let date12 = data1.getFullYear() + '-' + (data1.getMonth() +1) + '-' + data1.getDate() + ' ' + data1.getHours() +':'+ data1.getMinutes() + ':'+data1.getSeconds();
                                                     return <tr>
                                                           <td>{pairname}</td>
                                                           <td>{quantity>0?"Closed Long":"Closed Short"}</td>
                                                           <td>{quantity}</td>
                                                           <td>{entry_price}</td>
                                                           <td>{exit_price}</td>
                                                           {
                                                             (profitnloss<0)?
                                                              <td className="pinkText">{parseFloat(profitnloss).toFixed(8)}</td>:
                                                              <td className="greenText">{parseFloat(profitnloss).toFixed(8)}</td>

                                                           }
                                                           <td>{exit_type}</td>
                                                            <td>{date12}</td>
                                                       </tr>
                                                         })
                                                  }
					                                           </tbody>
                                                   ):(<tbody>
                                                     <tr><td colspan="8" className="text-center">{t("NO_RECORDS")}</td></tr></tbody>)}
					                                        </table>
                                                     </Scrollbars>
					          </div>
					          <div className="closedPandLDataStatus">
					            <div className="row mx-0 mb-4">
					              <div className="col-md-3 col-sm-6 mb-4 mb-md-0"><h3 className="assetTitle"><span>{t("TOTAL_REALIZED_PNL")}</span>{((profitnloss)?+profitnloss:0).toFixed(8)} {this.state.first_currency} <small>= {((profitnloss)?+(profitnloss*usd_price):0).toFixed(2)} {this.state.second_currency}</small></h3></div>
					              <div className="col-md-3 col-sm-6 mb-4 mb-md-0"><h3 className="assetTitle"><span>{t("DAILY_REALIZED")}</span>{(dailyprofitnloss?+dailyprofitnloss:0).toFixed(8)} {this.state.first_currency} <small>= {(dailyprofitnlossusd?+dailyprofitnlossusd:0).toFixed(2)} {this.state.second_currency}</small></h3></div>
					              <div className="col-md-3 col-sm-6 mb-4 mb-md-0"><h3 className="assetTitle"><span>{t("UNREALIZED_P&l_MARK_PRICE")}</span>{((uprofitnloss)?+uprofitnloss:0).toFixed(8)} {this.state.first_currency} <small>= {(uprofitnlossusd?+uprofitnlossusd:0).toFixed(2)} {this.state.second_currency}</small></h3></div>
					              <div className="col-md-3 col-sm-6 mb-4 mb-md-0"><h3 className="assetTitle"><span>{t("UNREALIZED_P&L_LTP")}</span>{((profitnloss)?+profitnloss:0).toFixed(8)} {this.state.first_currency} <small>= {(profitnloss?+(profitnloss*usd_price):0).toFixed(2)} {this.state.second_currency}</small></h3></div>
					            </div>
					            <div className="closedPandLChart">

                           <ScatterPlot id="my-scatter-plot" isDate={true} data={data} yMin="-0.1"  width="1100" height="400"/>

					            </div>
					          </div>
					        </div>
					      </div>
					    </div>
					  </div>
					</section>
				</div>
			<Footer />
    		</div>
		);
    }
}

ClosedPandL.propTypes = {
  pnlSearchdata    : PropTypes.func.isRequired,
  closedposhistory    : PropTypes.func.isRequired,
  position_details    : PropTypes.func.isRequired,
  auth             : PropTypes.object.isRequired,
  errors           : PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(

  mapStateToProps,
  {
    position_details,
    closedposhistory,
    pnlSearchdata,
  }
)(withTranslation()(ClosedPandL),withRouter(ClosedPandL));
