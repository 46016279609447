import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import axios from "axios";
import keys from "../actions/config";

import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from "react-html-parser";

import Footer from "./Footer";
import DeposiImg from "../images/depositScreen.png";
import WithdrawImg from "../images/withdraeScreen.png";

const url = keys.baseUrl;
const imageUrl = keys.imageUrl


class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articledata: [],
      subcatdata: [],
      subcatname: "",
      articleimage: null
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    
    this.getData();
  }
  getData() {
    const articleid = this.props.match.params.aid;

    axios
      .get(url + "api/helpcentrearticle/" + articleid)
      .then(result => {
        //console.log("article detail in ssas",result.data);
        this.setState({ articledata: result.data.articledata });
        this.setState({ subcatdata: result.data.subcatdata });
        this.setState({ subcatname: result.data.articledata.subcategoryId.subcategoryName })
        if (result.data.articledata.article_image != null) {
          this.setState({ articleimage: result.data.articledata.article_image })
        }
        // this.setState({ userselectcategoryid: category.data[0]._id });
      })
      .catch();
  }

  render() {
    const { articledata, subcatdata, subcatname } = this.state;
    return (
      <div>
        <Navbar />
        <section className="innerCMS">
          <div className="container">
            <div className="helpDetailPage">
              <div className="row mt-4">
                <div className="col-md-3 px-md-0">
                  <div className="darkBox contentPage">
                    <div className="tableHead tableHeadBlock">
                      <h3>

                        {subcatname}
                      </h3>
                    </div>
                    <div className="darkBoxSpace helpPage">
                      <div className="wow fadeIn">
                        <ul className="helpList">
                          {subcatdata.map((item, i) => {
                            var subname = item.Articlename;
                            var articleid = item._id;
                            return (
                              <li>
                                <a href={"/helpdetails/" + articleid}>
                                  {" "}
                                  {subname}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="darkBox contentPage">
                    <div className="tableHead tableHeadBlock">
                      <h2>
                        {articledata.Articlename}
                      </h2>
                    </div>
                    <div className="darkBoxSpace helpPage helpDetailContent">
                      <div className="wow fadeIn pt-3">

                        {this.state.articleimage != null ?
                          <img src={imageUrl + "helpcentre/" + this.state.articleimage} className="img-fluid blogFullImg mx-auto d-block" />
                          :
                          " "

                        }

                        {ReactHtmlParser(articledata.content)}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default About;
