/* eslint react/forbid-prop-types: 0 */

import * as React from 'react';
// import './index.css';
import { widget } from '../charting_library/charting_library.min';

import keys from "../actions/config";
const url = keys.baseUrl;
const url1 = "http://139.162.211.153:5000/";

// //console.log(url);
function getLanguageFromURL() {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export class TVChartContainer extends React.PureComponent {

   constructor(props) {
        super(props);
        this.state = {
            theme: "White",
            pair: "BTCUSD",
            errors: {},
        };
    }


  static defaultProps = {
    symbol: 'BTCUSD',
    interval: '5',
    quantityFormatter:4,
    containerId: 'tv_chart_container',
    datafeedUrl: url+"cryptoapi/chart",
    libraryPath: '/charting_library/', //live
    // libraryPath: '/charting_library/', //local
    chartsStorageUrl: 'https://saveload.tradingview.com',
    chartsStorageApiVersion: '1.1',
    clientId: 'tradingview.com',
    userId: 'public_user_id',
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
  };

  tvWidget = null;

  componentWillReceiveProps(nextProps){
    this.setState({theme:nextProps.theme,pair:nextProps.pair});
    if((this.state.theme!=nextProps.theme) || (this.state.pair!=nextProps.pair))
    {
      this.buildchart(nextProps.theme,nextProps.pair);
    }
  }
  buildchart = (theme,pair) =>{
    //console.log("themeeee",theme);

      const widgetOptions = {
      symbol: pair,
      // BEWARE: no trailing slash is expected in feed URL
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(this.props.datafeedUrl),
      interval: this.props.interval,
      container_id: this.props.containerId,
      library_path: this.props.libraryPath,

      locale: getLanguageFromURL() || 'en',
      disabled_features: ['use_localstorage_for_settings'],
      enabled_features: ['study_templates'],
      charts_storage_url: this.props.chartsStorageUrl,
      charts_storage_api_version: this.props.chartsStorageApiVersion,
      client_id: this.props.clientId,
      user_id: this.props.userId,
      fullscreen: this.props.fullscreen,
      autosize: this.props.autosize,
      studies_overrides: this.props.studiesOverrides,
      loading_screen:  { backgroundColor: (document.getElementById("root").classList.contains("darktheme")? "#000" : "#fff") },
      // drawings_access            : { type: 'black', tools: [ { name: "Trend Line" } ] },
      theme:theme,
      toolbar_bg:"#1b1b1b",
           overrides: {
            // "symbolWatermarkProperties.color": "#000657",
            "paneProperties.background": "#000",
            "paneProperties.vertGridProperties.color": "#2b2f36",
            "paneProperties.horzGridProperties.color": "#2b2f36"
          },
    };
if(theme=="White"){
delete widgetOptions.toolbar_bg;
delete widgetOptions.overrides;
}

    const tvWidget = new widget(widgetOptions);
    this.tvWidget = tvWidget;

    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        const button = tvWidget.createButton();
        button.setAttribute('title', 'Click to show a notification popup');
        button.classList.add('apply-common-tooltip');
        button.addEventListener('click', () => tvWidget.showNoticeDialog({
          title: 'Notification',
          body: 'TradingView Charting Library API works correctly',
          callback: () => {
            //console.log('Noticed!');
          },
        }));

        button.innerHTML = 'Check API';
      });
    });
  }
  componentDidMount() {

  }

  componentWillUnmount() {
    if (this.tvWidget !== null) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  }

  render() {
    return (
      <div
        id={ this.props.containerId }
        className={ 'chartcontainer' }
      />
    );
  }
}
