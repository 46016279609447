import React, {
    useEffect,
    forwardRef,
    useImperativeHandle
} from 'react';
import {
    useHistory
} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import axios from "axios";
import keys from "../../actions/config";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, TextField } from '@material-ui/core';

import $ from 'jquery';

import {
    getTableDataDynamic,
    updateTableDataDynamic,
    postCallDynamic
} from "../../actions/authActions";

import { toast } from 'react-toastify';
toast.configure();

const baseUrl = keys.baseUrl;

const BecomeTrader_Ref = forwardRef((props, ref) => {

    const history = useHistory();

    const [CopyTradeSettings, Set_CopyTradeSettings] = React.useState({
        minimumDeposit: 0,
        notes:''
    });

    const [userBalance, Set_userBalance] = React.useState([]);
    const [verifiedstatus, Set_verifiedstatus] = React.useState('Pending');
    const [isToastVisiable, setIsToastVisiable] = React.useState(false)
    var {
        auth,
    } = props;

    function propsChanged (props) {
        console.log('props : ', props);
        
        if(typeof props.auth!='undefined'){
			if(typeof props.auth.trade!='undefined'){
				if(typeof props.auth.trade.data!='undefined'){
                    var data = props.auth.trade.data
                    if(typeof data.toast!='undefined' && isToastVisiable == true){
                        var toastData = data.toast;
                        toast(toastData.message, {position: toast.POSITION.TOP_CENTER});
                    }
                    if(typeof data.CopyTradeSettingsData!='undefined') {
                        var CopyTradeSettingsData = data.CopyTradeSettingsData;
                        Set_CopyTradeSettings(CopyTradeSettingsData);
                    }
                    if(typeof data.assetsData!='undefined') {
                        var assetsData = data.assetsData;
                        Set_userBalance(assetsData);
                    }
                }
            }
        }
    }
    const { t, i18n } = useTranslation();
    function getData() {
        if(auth && auth.user && auth.user.id) {
            const id = auth.user.id;
            axios
            .get(baseUrl + "cryptoapi/userget/" + id)
            .then((res) => {
                if(res && res.data && res.data.verifiedstatus) {
                    Set_verifiedstatus(res.data.verifiedstatus);  
                }
            })
            .catch();
        }
    }

    useEffect(()=>{
        propsChanged(props);
    },[props])

    useEffect(()=>{
        getData();
    },[])

    useImperativeHandle(
        ref,
        () => ({
            async becomeTrader_Click() {
                if(!auth.user || !auth.user.id) {
                    toast('Please login to continue', {position: toast.POSITION.TOP_CENTER});
                } else if(verifiedstatus != 'FINISHED') {
                    toast('Please complete KYC You have not completed KYC yet.', {position: toast.POSITION.TOP_CENTER});
                } else {
                    window.$('#becomeTrader-modal').modal('show');
                    get_becomeTrader_settings();
                }
            }
        }),
    )

    async function CopyTradeRequest() {
        var usdtBalance = 0;
        var usdtIndex = userBalance.findIndex(x => (x.currencySymbol) === "USDT");
        if(usdtIndex > -1) {
            usdtBalance = userBalance[usdtIndex].balance;
        }
        if(usdtBalance < CopyTradeSettings.minimumDeposit){
            toast('Min deposit amount has not been reached', {position: toast.POSITION.TOP_CENTER});
        }
        else {
            var dynobj = {};
            dynobj.url = 'api/v1/copytrade/request/apply';
            dynobj.payload = {
                userid: props.auth.user.id,
            }
            // this.setState({isToastVisiable : true})
            setIsToastVisiable(true)
            props.postCallDynamic(dynobj);
        }
    }

    async function get_becomeTrader_settings() {
        var dynobj = {};
        dynobj.find = {type: 'main'};
        dynobj.table = {name: 'CopyTradeSettingsTable'};
        dynobj.return = {name: 'CopyTradeSettingsData'};
        props.getTableDataDynamic(dynobj);

        var dynobj = {};
        dynobj.find = {userid: props.auth.user.id};
        dynobj.target = {name: 'userallbalances'};
        dynobj.return = {name: 'assetsData'};
        props.getTableDataDynamic(dynobj);
    }
    return (
        <div className="modal fade primary-modal" id="becomeTrader-modal" data-backdrop="static" tabindex="-1" aria-labelledby="becomeTrader-modalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="becomeTrader-modalLabel">{t("APPLY_TO_BECOME_A_TRADER")}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setIsToastVisiable(false)}}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body text_white_moda">
                        <p>{t("CONDITIONS")}</p>
                        <ul><li>{t("CUMULATIVE_DEPOSIT")} {CopyTradeSettings.minimumDeposit}{t("USDT")}</li></ul>
                        <p></p>
                        <p>{CopyTradeSettings.notes!='' && ReactHtmlParser(CopyTradeSettings.notes)}</p>
                        <div className="mt-3 mb-3 d-flex justify-content-end">
                            <button type="button" className="inner_primary_btn mr-3 ml-0" data-dismiss="modal" onClick={()=>{setIsToastVisiable(false)}}>{t("CANCEL")}</button>
                            <button type="button" className="inner_primary_btn ml-0" onClick={() => CopyTradeRequest()}>{t("OK")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    )
})

BecomeTrader_Ref.propTypes = {
    getTableDataDynamic: PropTypes.object.isRequired,
    updateTableDataDynamic: PropTypes.object.isRequired,
    postCallDynamic: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
});  

export default connect(mapStateToProps, {
    getTableDataDynamic,
    updateTableDataDynamic,
    postCallDynamic
}, null, { forwardRef: true })(BecomeTrader_Ref)

