import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import Navbar from './Navbar'
import Footer from './Footer'
import axios from "axios";
import keys from "../actions/config";
import { withTranslation } from 'react-i18next';
const url = keys.baseUrl;
class AntiSpamPolicy extends Component {

  
  componentDidMount() {
    window.scrollTo(0, 0);
    };
    
    render() {
      const {t} = this.props;
        return (<div className='bg_strip'>
            <section class="headerCrediantials homeBanner">
      <Navbar />
      </section>
            <section className="innerCMS">
            
              <div className="container">
                <div className="row">
                  <div className="col-md-10 mx-auto">
                    <div className="darkBox contentPage">
                      <div className="tableHead tableHeadBlock">
                        <h2>Anti-spam Policy</h2>
                      </div>
                      <div className="darkBoxSpace">
                        <p>{t("DELIVER_OUR_SERVICE")}</p>
                        <h4>{t("TO_PROTECT_OUR_USERS")}</h4>
                        <p>{t("PROTECT_OUR_PLANS")}</p>
                        <h4>{t("LEGAL_AND_REGULATORY")}</h4>
                        <p>{t("PRIVACY_AND_SECURITY")}</p>
                        <h4>{t("TO_MEASURE_SITE_PERFORMANCE")}</h4>
                        <p>{t("MEASURE_AND_ANALYSE")}</p>
                        <h4>{t("TO_COMMUNICATE_WITH_YOU")}</h4>
                        <p>{t("PERSONAL_INFORMATION_COLLECTED")}</p>
                        <h4>{t("ENFORCE_OUR_TERMS")}</h4>
                        <p>{t("CUSTOMER")}</p>
                        <h4>{t("PROTECT_USER_DATA")}</h4>
                        <p>{t("SECURITY_BELLEXCHANGE")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
               
            </section>
            <Footer />
        </div>
        );
    }
}

export default (withTranslation()(AntiSpamPolicy),AntiSpamPolicy)
