import React, { Component } from "react";
import TradeHeader from './TradeHeader'
import Footer from './Footer'
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Synaps from "@synaps-io/react-verify";
import axios from "axios";
import keys from "../actions/config";
import "react-toastify/dist/ReactToastify.css";
import * as moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { store } from "react-notifications-component";
import Select from "react-select";
import classnames from "classnames";
import { Modal, Button } from "react-bootstrap/";
import { getUserKycInfo, kycadd } from "../actions/authActions";
import { withTranslation } from 'react-i18next';

import { Scrollbars } from "react-custom-scrollbars";


const url = keys.baseUrl;
const imageurl = keys.imageUrl;

const idproof = [
  { value: "Social Security ID Card", label: "Social Security ID Card" },
  { value: "Passport", label: "Passport" },
  { value: "Drivers Licence", label: "Driver's License" },
];

const adproof = [
  {
    value: "Electricity / Gas / Water tax",
    label: "Electricity / Gas / Water tax",
  },
  { value: "Bank Statement", label: "Bank Statement" },
  { value: "Property tax / Land deed", label: "Property tax / Land deed" },
];

const photoproof = [
  { value: "Social Security ID Card", label: "Social Security ID Card" },
  { value: "Passport", label: "Passport" },
  { value: "Drivers Licence", label: "Drivers Licence" },
];

class Withdraw extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email_assigned: false,
      _id: "",
      Idproof: "",
      IdType: "",
      AddressType: "",
      PhotoIDType: "",
      addressproof: "",
      photoproof: "",
      idfront: null,
      idback: null,
      Addfront: null,
      photofront: null,
      kycdate: "",
      viewidfront: null,
      viewidback: null,
      viewaddressphoto: null,
      viewphoto: null,
      selectedOption: "",
      IDproofno: "",
      Addressproofno: "",
      Phototypeno: "",
      IDstatus: "",
      Addresstatus: "",
      Photostatus: "",
      verifiedstatus: "",
      Addresstype: "",
      IDselected: false,
      Adselected: false,
      errors: {


      },
      idfronterror: "",
      idfrontsize: 0,
      idbacksize: 0,
      idbackerror: "",
      addresssize: 0,
      addresserror: "",
      photosize: 0,
      photoerror: "",
      sessionId: "",
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  styles = {
    option: (provided, state) => ({
      ...provided,
      color: "white",
      backgroundColor: "#000",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '52px',
      padding: '0 6px',
      backgroundColor: "#03081f",
      borderColor: '#81c8f6',
    borderRadius: 10,
    borderStyle: 'solid',
    borderWidth: '1px'
     
    }),
    control: (provided, state) => ({
      ...provided,
      height: '52px',
      borderRadius:10,
      backgroundColor: "#03081f",
      border:'none'
     
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '52px',
      position: 'absolute',
      right: 0,
      top: 0,
      color:'#fff' 
    }),    
    singleValue: (provided, state) => ({
      ...provided,
      color: "#fff"
    })
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getData();
  }

  getData() {
    // //console.log("this,propss", this.props);
    //this.setState()
    const id = this.props.auth.user.id;
   // getUserKycInfo(id)
  axios.get(url+"api/getkycsession/"+id).then((res)=>{
    console.log("getkycsession : ",res?.data)
    this.setState({ sessionId: res?.data?.data })
  })
    axios
      .get(url + "cryptoapi/userget/" + id)
      .then((res) => {
        this.setState(res.data);
        if(res?.data?.IDstatus == "Pending"){
          this.setState({hideField: false})
        }
        console.log("responsedatatta",res.data);
        this.setState({
          IDtype: {
            value: res.data.IDtype,
            label: res.data.IDtype,
          },
          IDselected: true
        });
        this.setState({
          Addresstype: {
            value: res.data.Addresstype,
            label: res.data.Addresstype,
          },
          Adselected: true
        });
      })
      .catch();
  }

  onChange = (e) => {

      this.setState({ [e.target.id]: e.target.value });
  };

  handleChangeIdfront = (e) => {
    let imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG)$/;
    if (e.target.files[0]) {

      var filesize = e.target.files[0].size;
      this.setState({ idfrontsize: filesize });

      if (filesize > 3145728) {
        this.setState({ idfronterror: "*Image is Above 3MB" });
      }else if (!imageFormat.test(e.target.files[0].name)) {
         this.setState({ idfronterror: "Invalid Image" });
        }
         else {
      
        if(this.state.errors&&this.state.errors. viewidfront)
        {
          delete this.state.errors["viewidfront"];

        }
        this.setState({
          idfronterror : "",
          idfront: e.target.files[0],
          viewidfront: URL.createObjectURL(e.target.files[0]),
        });
      }
    }
  };

  handleChangeIdback = (e) => {
   let imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG)$/;  
    if (e.target.files[0]) {
      var filesize = e.target.files[0].size;
      this.setState({ idbacksize: filesize });
      if (filesize > 3145728) {
        this.setState({ idbackerror: "*Image is Above 3MB" });
      } 
    else if (!imageFormat.test(e.target.files[0].name)) {
         this.setState({ idbackerror: "Invalid Image" });
        }else {
        console.log("erorrrrrrrrs",this.state.errors)
        if(this.state.errors&&this.state.errors. viewidback)
        {
          delete this.state.errors["viewidback"];

        }
        this.setState({
          idbackerror:"",
          idback: e.target.files[0],
          viewidback: URL.createObjectURL(e.target.files[0]),
        });
      }
    }
  };

  handleChangephoto = (e) => {
    let imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG)$/;  

    if (e.target.files[0]) {
      var filesize = e.target.files[0].size;
      this.setState({ photosize: filesize });

      if (filesize > 3145728) {
        this.setState({ photoerror: "*Image is Above 5 MB" });
      }else if (!imageFormat.test(e.target.files[0].name)) {
         this.setState({ photoerror: "Invalid Image" });
        } else {
        if(this.state.errors&&this.state.errors. viewphoto)
        {
          delete this.state.errors["viewphoto"];

        }
        this.setState({
          photoerror:"",
          photofront: e.target.files[0],
          viewphoto: URL.createObjectURL(e.target.files[0]),
        });
      }
    }
  };

  handleChangeaddress = (e) => {
    let imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG)$/;

    if (e.target.files[0]) {
      var filesize = e.target.files[0].size;
      this.setState({ addresssize: filesize });

      if (filesize > 3145728) {
        this.setState({ addresserror: "*Image is Above 3MB" });
      }else if (!imageFormat.test(e.target.files[0].name)) {
         this.setState({ addresserror: "Invalid Image" });
        }  else {
        if(this.state.errors&&this.state.errors. viewaddressphoto)
        {
          delete this.state.errors["viewaddressphoto"];

        }
        this.setState({
          addresserror:"",
          Addfront: e.target.files[0],
          viewaddressphoto: URL.createObjectURL(e.target.files[0]),
        });
      }
    }
  };

  handleChange1 = (selectedOption) => { 
    // this.setState({ IdType: selectedOption.value });
    console.log("errrrrrrrrrrrrrrrrrr",this.state.errors)
    if(this.state.errors&&this.state.errors. IDtype)
    {
      delete this.state.errors["IDtype"];

    }
  
   
    this.setState({
      IDtype: {
        value: selectedOption.value,
        label: selectedOption.value,
      },
    });
    this.setState({ IDselected: true });
  };

  handleChange2 = (selectedOption) => {
    // this.setState({ AddressType: selectedOption.value });
    if(this.state.errors&&this.state.errors. Addresstype)
    {
      delete this.state.errors["Addresstype"];

    }
    this.setState({
      Addresstype: {
        value: selectedOption.value,
        label: selectedOption.value,
      },
    });
    this.setState({ Adselected: true });
  };

  componentWillReceiveProps(nextProps) {
    const {t} = this.props;
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }

    if (
      nextProps.auth.updateprofile !== undefined &&
      nextProps.auth.updateprofile.data !== undefined &&
      nextProps.auth.updateprofile.data.message !== undefined
    ) {
      store.addNotification({
        title: t("SUCCESS"),
        message: nextProps.auth.updateprofile.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      this.setState({hideField: false})
      this.getData();
    }
  }

  onSubmit = (e) => {
   const {t} = this.props;
    e.preventDefault();
    console.log("addadss before",this.state.Addresstatus);

    if (this.state.idfrontsize < 3145728 &&
      this.state.idbacksize < 3145728 &&
      this.state.addresssize < 3145728 &&
      this.state.photosize < 3145728) {
        console.log("first condition")
      if (this.validateForm()) {
       console.log("first condition")
        let fields = {};
        fields["IDproofno"] = "";
        fields["Addressproofno"] = "";
        fields["IDselected"] = "";
        fields["Adselected"] = "";
        this.setState({ validation: fields });

        const data = new FormData();
        data.append("id", this.props.auth.user.id);
        data.append("IDproofno", this.state.IDproofno);
        data.append("IdType", this.state.IDtype.value);
        data.append("Addressproofno", this.state.Addressproofno);
        data.append("AddressType", this.state.Addresstype.value);
        // data.append("Phototypeno", this.state.Phototypeno);
        // data.append("PhotoIDType", this.state.Phototype.value);
        data.append("idfront", this.state.idfront);
        data.append("idback", this.state.idback);
        data.append("photofront", this.state.photofront);
        data.append("Addfront", this.state.Addfront);
        //console.log("addadss",data);
       
        this.props.kycadd(data);
      }

    }
    else if(this.state.Addresstatus=="CANCELLED"||this.state.IDstatus=="CANCELLED"||this.state.Photostatus=="CANCELLED")
    {
      console.log("second condition")
      if (this.state.idfrontsize < 3145728 ||
        this.state.idbacksize < 3145728 ||
        this.state.addresssize < 3145728 ||
        this.state.photosize < 3145728) {
        if (this.validateForm()) {
          console.log("second condition")
          let fields = {};
          fields["IDproofno"] = "";
          fields["Addressproofno"] = "";
          fields["IDselected"] = "";
          fields["Adselected"] = "";
          this.setState({ validation: fields });
  
          const data = new FormData();
          data.append("id", this.props.auth.user.id);
          data.append("IDproofno", this.state.IDproofno);
          data.append("IdType", this.state.IDtype.value);
          data.append("Addressproofno", this.state.Addressproofno);
          data.append("AddressType", this.state.Addresstype.value);
          // data.append("Phototypeno", this.state.Phototypeno);
          // data.append("PhotoIDType", this.state.Phototype.value);
          data.append("idfront", this.state.idfront);
          data.append("idback", this.state.idback);
          data.append("photofront", this.state.photofront);
          data.append("Addfront", this.state.Addfront);
          //console.log("addadss",data);
         
          this.props.kycadd(data);
        }
  
      }

    }
    else {
      store.addNotification({
        title: t("ERRORS"),
        message: t("IMAGE_SIZE_IS_TOO_LARGE"),
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
        },
      });
    }
  };

  validateForm() {

    let fields = this.state.validation;
    let errors = {};
    let formIsValid = true;

    //ID proof
    if ((this.state.IDtype.value == "")&& (this.state.IDstatus!="FINISHED")) {
      console.log("first")
      formIsValid = false;
      errors["IDtype"] = "*Please Select Document ID.";
    }
    console.log(this.state.IDproofno,'this.state.IDproofnothis.state.IDproofno')
    if ((this.state.IDproofno == null || this.state.IDproofno == "")&& (this.state.IDstatus!="FINISHED")) {
      console.log("second")
      errors["IDproofno"] = "*Please enter an ID number.";
      formIsValid = false;

    }
    if ((this.state.viewidfront == null || this.state.viewidfront == "")&& (this.state.IDstatus!="FINISHED")) {
      console.log("third")
      formIsValid = false;
      errors["viewidfront"] = "*Please choose a frontside photo.";
    }
    if ((this.state.viewidback == null || this.state.viewidback == "")&& (this.state.IDstatus!="FINISHED")) {
      console.log("four")
      formIsValid = false;
      errors["viewidback"] = "*Please choose a backside photo.";
    }

    //adress proof
    if ((this.state.viewaddressphoto == null || this.state.viewaddressphoto == "")&& (this.state.Addresstatus!="FINISHED")) {
      console.log("five")
      formIsValid = false;
      errors["viewaddressphoto"] = "*Please choose a  address proof photo.";
    }
    if ((this.state.Addresstype.value == "")&&(this.state.Addresstatus!="FINISHED")) {
      console.log("six")
      formIsValid = false;
      errors["Addresstype"] = "*Please select a  document type.";
    }

    if ((this.state.Addressproofno == "" || this.state.Addressproofno == null)&&(this.state.Addresstatus!="FINISHED")) {
      console.log("seven")
      formIsValid = false;
      errors["Addressproofno"] = "*Please enter your addresss.";
    }

    //self proof 
    if ((this.state.viewphoto == "" || this.state.viewphoto == null)&&(this.state.Photostatus!="FINISHED")) {
      console.log("eight")
      formIsValid = false;
      errors["viewphoto"] = "*Please choose a  self proof photo.";
    }

    this.setState({
      errors: errors,
    });
    console.log(formIsValid,"formIsValid")
    return formIsValid;

    // if (this.state.IDselected != true) {
    //   formIsValid = false;
    //   errors["IDselected"] = "*Please select a document type.";
    // }

    // if (this.state.Adselected != true) {
    //   formIsValid = false;
    //   errors["Adselected"] = "*Please select a document type.";  
    // }

  }
  onSkipbutton() {
    this.props.history.push("/Account");
  }

  // onChange = (e) => {

  //     const {id,value}= e.target;
  //     if(id=="IDproofno")
  //     {
  //       if(this.state.errors&&this.state.errors.IDproofno)
  //       {
  //         delete this.state.errors["IDproofno"];
    
  //       }
  //     }
  //     if(id=="Addressproofno")
  //     {
  //       if(this.state.errors&&this.state.errors. Addressproofno)
  //       {
  //         delete this.state.errors["Addressproofno"];
    
  //       }
  //     }
    
  //   this.setState({ [e.target.id]: e.target.value });
 
  // };

  render() {
    const { errors } = this.state;
    const {t} = this.props;
    return (
      <div className="bg_strip">
        <TradeHeader />
        <section className="pt-5">
        
          <div className="row mt-5 mx-0 pt-md-5">
            <div className="col-xl-8 col-12 mx-auto">
              <div className="supportTicket mb-5" style={{ backgroundColor: 'transparent' }}>

              { true ?
          <>
                <div className="d-flex flex-column align-items-center kyc-header pt-5">
                  <h2 className="">{t("KYC_REGISTRATION")}</h2>
                  {this.state.kycdate ? (
                    <p>{t("REGISTER_ON")} {moment(this.state.kycdate).format("DD-MM-YYYY  h:mm a ")}</p>
                  ) : (
                    <p style={{ color: '#c93c3c' }}>{t("NOT_REGISTER_USER")}</p>
                  )}
                  {this.state.verifiedstatus.toLowerCase() == 'finished' && <p style={{ color: '#6de392' }}>{t("VERIFIED")}</p>}
                
                  <div className="darkBoxSpace pb-3 px-1 max_width_100">
                  <form name="kycsubmit" onSubmit={this.onSubmit}>

                    {/*  1. ID */}
                    <div className="proofBox mb-0 mx-auto" style={{ maxWidth: '600px' }}>
                      {this.state.IDstatus == "FINISHED" && (
                        <div className="proofContentText row">
                          <h4>1. {t("PROOF_IDENTITY")}</h4>
                          <p><i className="far fa-check-circle mr-2"></i>{t("VERIFIED")}</p>
                          <h6>{t("DOCUMENT_TYPE")} : {this.state.IDtype.value}</h6>
                          <h6>{t("ID_NUMBER")} :{this.state.IDproofno}</h6>
                        </div>
                      )}
                      {this.state.IDstatus == "Pending" && (
                        <div className="proofContentText row">
                          <h4>1. {t("PROOF_IDENTITY")}</h4>
                          <p><i className="far fa-check-circle mr-2"></i>{t("WAITING_FOR_APPROVAL")}</p>
                          <h6>{t("DOCUMENT_TYPE")}: {this.state.IDtype.value}</h6>
                          <h6>{t("ID_NUMBER")}:{this.state.IDproofno}</h6>
                        </div>
                      )}
                      {this.state.IDstatus == "CANCELLED" || this.state.IDstatus == "Not verified" ? (
                        <div className="proofContentText">
                          <h4>1. {t("PROOF_IDENTITY")}</h4>
                          {this.state.IDstatus == "CANCELLED" && <p><i className="far fa-times-circle mr-2"></i>{t("REJECTED")}</p>}
                          {this.state.IDstatus == "CANCELLED" && <p>{t("UPLOAD_NEW_IMAGE")}.</p>}
                          <p>{t("PLEASE_UPLOAD_CLEAR")}:</p>
                          <p className="text-danger">{t("NOTE")}: {t("MAX_3MB_IMG")}</p>
                          <div className="mt-3 form-group">
                            <h4>{t("SELECT_DOCUMENT_TYPE")}</h4>

                            <Select styles={this.styles} isSearchable={false} className="border_blue_select mb-4" value={this.state.IDtype} defaultValue={{ label: this.state.IDtype.value, value: this.state.IDtype.value, }} onChange={this.handleChange1} options={idproof} />
                            {
                              <span className="text-danger">{errors.IDtype}</span>

                            }
                          </div>
                          <div className="form-group">
                            <h4>{t("ID_NUMBER")}:</h4>
                            <input  onChange={this.onChange} value={this.state.IDproofno} error={errors.IDproofno} id="IDproofno" type="text" name="IDproofno" className={classnames("form-control", { invalid: errors.IDproofno })} />
                            <span className="text-danger">{errors.IDproofno}</span>
                          </div>
                        </div>
                      ) : ''}

                      {/*  ID Documents  */}
                      <div className="row">
                        {/*  ID Front Side  */}
                        <div className="col-md-6 ml-auto">
                          {this.state.IDstatus == "CANCELLED" || this.state.IDstatus == "Not verified" ? (
                            <div className="uploadRightBox">
                              <div className="from-group">
                                <h4>{t("FRONT_SIDE")}</h4>

                                <div className="custom-file">
                                  <input type="file" accept="image/x-png,image/gif,image/jpeg" onChange={this.handleChangeIdfront} name="file1" id="exampleInputFile" aria-describedby="fileHelp" />
                                  <label className="custom-file-label" for="exampleInputFile">
                                  {this.state.idfront != null && 
                                    this.state.idfront.name
                                  }
                                  {this.state.idfront == null && 
                                    "Select File"
                                  }
                                  

                                  </label>

                                </div>

                                <span className="text-danger">{this.state.idfronterror}</span>
                                <span className="custom-file-control form-control-file"></span>
                              </div>
                              <img className="img-fluid proofThumb mt-3" src={this.state.viewidfront} />
                              {
                                <span className="text-danger">{errors.viewidfront}</span>

                              }
                            </div>
                          ) : ''}
                          {this.state.IDstatus == "FINISHED" || this.state.IDstatus == "Pending" ? (
                            <img className="img-fluid proofThumb" src={imageurl + "kyc/" + this.state.IDprooffront} />
                          ) : ''}
                        </div>
                        {/*  ID Back Side  */}
                        <div className="col-md-6 mr-auto">
                          {this.state.IDstatus == "CANCELLED" || this.state.IDstatus == "Not verified" ? (
                            <div className="uploadRightBox">
                              <div className="from-group">
                                <h4>{t("BACK_SIDE")}</h4>
                                <div className="custom-file">
                                  <input type="file" accept="image/x-png,image/gif,image/jpeg" onChange={this.handleChangeIdback} name="file2" id="exampleInputFile2" aria-describedby="fileHelp" />
                                  <label className="custom-file-label" for="exampleInputFile2">
                                    
                                    {this.state.idback != null && 
                                    this.state.idback.name
                                  }
                                  {this.state.idback == null && 
                                    "Select File"
                                  }
                                  </label>
                                </div>
                                <span className="text-danger">{this.state.idbackerror}</span>
                                <span className="custom-file-control form-control-file"></span>
                              </div>

                              <img className="img-fluid proofThumb mt-3" src={this.state.viewidback} />
                              {
                                <span className="text-danger">{errors.viewidback}</span>

                              }
                            </div>
                          ) : ''}
                          {this.state.IDstatus == "FINISHED" || this.state.IDstatus == "Pending" ? (
                            <img className="img-fluid proofThumb" src={imageurl + "kyc/" + this.state.IDproofback} />
                          ) : ''}
                        </div>

                      </div>


                    </div>

                    {/*  2. Address  */}
                    <div className="proofBox mb-0 pt-3 mx-auto" style={{ maxWidth: '600px' }}>
                      {this.state.Addresstatus == "FINISHED" && (
                        <div className="proofContentText row">
                          <h4>2. {t("PROOF_OF_ADDRESS")}</h4>
                          <p><i className="far fa-check-circle mr-2"></i>{t("VERIFIED")}</p>
                          <h6>{t("DOCUMENT_TYPE")}: {this.state.Addresstype.value}</h6>
                          <h6>{t("ADDRESS")}: {this.state.Addressproofno}</h6>
                        </div>
                      )}
                      {this.state.Addresstatus == "Pending" && (
                        <div className="proofContentText row">
                          <h4>2. {t("PROOF_OF_ADDRESS")}</h4>
                          <p><i className="far fa-check-circle mr-2"></i>{t("WAITING_FOR_APPROVAL")} </p>
                          <h6>{t("DOCUMENT_TYPE")}: {this.state.Addresstype.value}</h6>
                          <h6>{t("ADDRESS")}: {this.state.Addressproofno}</h6>
                        </div>
                      )}
                      {this.state.Addresstatus == "Not verified" || this.state.IDstatus == "CANCELLED" ? (
                        <div className="proofContentText">
                          <h4>2. {t("PROOF_OF_ADDRESS")}</h4>
                          {this.state.Addresstatus == "CANCELLED" && <p><i className="far fa-times-circle mr-2"></i>{t("REJECTED")}</p>}
                          {this.state.Addresstatus == "CANCELLED" && <p>{t("UPLOAD_NEW_IMAGE")}</p>}
                          <p>{t("PLEASE_UPLOAD_CLEAR")}:</p>
                          <p className="text-danger">{t("NOTE")}: {t("MAX_3MB_IMG")}</p>
                          <div className="mt-3 form-group">
                            <h4>Select Document Type</h4>
                            <Select styles={this.styles} className="border_blue_select mb-4" value={this.state.Addresstype} defaultValue={{ label: this.state.Addresstype.value, value: this.state.Addresstype.value, }} onChange={this.handleChange2} options={adproof} isSearchable={false}/>

                            {
                              <span className="text-danger">{errors.Addresstype}</span>

                            }

                          </div>
                          <div className="form-group">
                            <h4>{t("ADDRESS")}</h4>
                            <input  onChange={this.onChange} value={this.state.Addressproofno} error={errors.Addressproofno} id="Addressproofno" type="text" name="Addressproofno" className={classnames("form-control", { invalid: errors.Addressproofno, })} />
                            <span className="text-danger">{errors.Addressproofno}</span>
                          </div>
                        </div>
                      ) : ''}

                      {/*  Address Document (Front Side)  */}
                      <div className="col-md-6 pl-0">
                        {this.state.Addresstatus == "CANCELLED" || this.state.Addresstatus == "Not verified" ? (
                          <div className="uploadRightBox">
                            <div className="from-group">
                              <h4>{t("FRONT_SIDE_NAME_AND_ADDRESS")}</h4>
                              <div className="custom-file">
                                <input type="file" accept="image/x-png,image/gif,image/jpeg" className="custom-file-input" id="exampleInputFile3" onChange={this.handleChangeaddress} name="file3" aria-describedby="fileHelp" />
                                <label className="custom-file-label" for="exampleInputFile3">
                                 {this.state.Addfront != null && 
                                    this.state.Addfront.name
                                  }
                                  {this.state.Addfront == null && 
                                    "Select File"
                                  }
                                  </label>
                              </div>
                              <span className="text-danger">{this.state.addresserror}</span>
                              <span className="custom-file-control form-control-file"></span>
                            </div>
                            <img className="img-fluid proofThumb mt-3" src={this.state.viewaddressphoto} />
                            {<span className="text-danger">{errors.viewaddressphoto}</span>}
                          </div>
                        ) : ''}
                        {this.state.Addresstatus == "FINISHED" || this.state.Addresstatus == "Pending" ? (
                          <img className="img-fluid proofThumb" src={imageurl + "kyc/" + this.state.Addressfile} />
                        ) : ''}
                      </div>

                    </div>

                    {/*  3. Self Photo  */}
                    <div className="proofBox mb-0 pt-3 mx-auto" style={{ maxWidth: '600px' }}>
                      {this.state.Photostatus == "FINISHED" && (
                        <div className="proofContentText row">
                          <h4>3. {t("SELF_PHOTO")}</h4>
                          <p><i className="far fa-check-circle mr-2"></i>{t("VERIFIED")}</p>
                        </div>
                      )}
                      {this.state.Photostatus == "Pending" && (
                        <div className="proofContentText row">
                          <h4>3. {t("SELF_PHOTO")}</h4>
                          <p><i className="far fa-check-circle mr-2"></i>{t("WAITING_FOR_APPROVAL")}</p>
                        </div>
                      )}
                      {this.state.Photostatus == "CANCELLED" || this.state.Photostatus == "Not verified" ? (
                        <div className="proofContentText">
                          <h4>3.  {t("SELF_PHOTO")}</h4>
                          {this.state.IDstatus == "CANCELLED" && <p><i className="far fa-times-circle mr-2"></i>{t("REJECTED")}</p>}
                          {this.state.IDstatus == "CANCELLED" && <p>{t("UPLOAD_NEW_IMAGE")}</p>}
                          <p>{t("PLEASE_UPLOAD_CLEAR_PHOTO_YOURSELF")}Please upload a clear photo of yourself.</p>
                          <p className="text-danger">{t("NOTE")}: {t("MAX_3MB_IMG")}</p>
                        </div>
                      ) : ''}

                      {/*  Self Photo Document  */}
                      <div className="col-md-6 mt-3 pl-0">
                        {this.state.Photostatus == "CANCELLED" || this.state.Photostatus == "Not verified" ? (
                          <div className="uploadRightBox">
                            <div className="from-group">
                              <label>{t("SELF_PHOTO")} </label>
                              <div className="custom-file">
                                <input type="file" accept="image/x-png,image/gif,image/jpeg" onChange={this.handleChangephoto} name="file4" id="exampleInputFile4" aria-describedby="fileHelp" />
                                <label className="custom-file-label" for="exampleInputFile4">
                                   {this.state.photofront != null && 
                                    this.state.photofront.name
                                  }
                                  {this.state.photofront == null && 
                                    "Select File"
                                  }
                                </label>
                              </div>
                              <span className="text-danger">{this.state.photoerror}</span>
                              <span className="custom-file-control form-control-file"></span>
                            </div>
                            <img className="img-fluid proofThumb mt-3" src={this.state.viewphoto} />

                            {/* <img className="img-fluid proofThumb" src={this.state.viewphoto} /> */}
                            {
                              <span className="text-danger">{errors.viewphoto}</span>

                            }

                          </div>
                        ) : ''}
                        {this.state.Photostatus == "FINISHED" || this.state.Photostatus == "Pending" ? (
                          <img className="img-fluid proofThumb" src={imageurl + "kyc/" + this.state.Photofile} />
                        ) : ''}
                      </div>
                    </div>

                    <div className="form-group d-flex justify-content-center pt-3 mb-0">
                      {this.state.hideField && <input className="btn normalButton" name="" value="Submit For Verification" type="submit" />}
                    </div>

                  </form>
                </div>
                </div>

                </> : <> <div className="d-flex flex-column align-items-center kyc-header kyc_header_frem pt-5">
                  <h2 className="">{t("KYC_REGISTRATION")}</h2>
                  <Synaps
                  className="synaps_width"
        sessionId={this.state.sessionId}
        service={"individual"}
        lang={"en"}
        onReady={() => console.log("component ready")}
        onFinish={() => console.log("user finish process")}
        color={{
          primary: "212b39",
          secondary: "ffffff"
        }}
        withFinishButton={true}
      />
      </div></>}

              </div>
            </div>
          </div>

        </section>
        <Footer />
      </div>
    );
  }
}

Withdraw.propTypes = {
  auth: PropTypes.object.isRequired,
  kycadd: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { kycadd })(withTranslation()(Withdraw),Withdraw);
