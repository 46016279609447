import React, { Component, ReactDOM } from 'react';
import { Link, withRouter } from 'react-router-dom';
// import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { loadScript } from "@paypal/paypal-js";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TradeHeader from './TradeHeader';

import ClosedPandL from './ClosedPandL';
import AssetsHistory from './AssetsHistory';
import OrderHistory from './Orderhistory';
import TradeHistory from './TradeHistory';
import WithdrawalHistory from './WithdrawHistory';
import BonusHistory from './BonusHistory';
import Footer from './Footer'
import Select from "react-select";
import DepositeQR from "../images/depositeQR.jpg"
import BtcIcon from "../images/btcIcon.png"
import EthIcon from "../images/ethIcon.png"
import XrpIcon from "../images/xrpIcon.png"
import LtcIcon from "../images/ltcIcon.png"
import BchIcon from "../images/bchIcon.png"
import { Modal, Button } from 'react-bootstrap/';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import classnames from "classnames";
import { store } from 'react-notifications-component';
import axios from "axios";
import keys from "../actions/config";
import doubleArrow from "../images/doubleArrow.png";
// var CryptoJS = require("crypto-js");
import CryptoJS from "crypto-js"
import * as moment from "moment";
import { toast } from 'react-toastify';
import Navtradebar from './Navtradebar';
import { withTranslation } from 'react-i18next';
toast.configure();

const url = keys.baseUrl;
const imageurl = keys.imageUrl;
const cryptoPass = keys.cryptoPass


var QRCode = require('qrcode.react');


const styles = {
    option: (provided, state) => ({
      ...provided,
      color: "white",
      backgroundColor: "#000",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '52px',
      padding: '0 6px',
      backgroundColor: "#03081f",
      borderColor: '#81c8f6',
    borderRadius: 10,
    borderStyle: 'solid',
    borderWidth: '1px'
     
    }),
    control: (provided, state) => ({
      ...provided,
      height: '52px',
      borderRadius:10,
      backgroundColor: "#03081f",
      border:'none'
     
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '52px',
      position: 'absolute',
      right: 0,
      top: 0,
      color:'#fff' 
    }),    
    singleValue: (provided, state) => ({
      ...provided,
      color: "#fff"
    })
  };


const customStyles = {
	option: (provided, state) => ({
		...provided,
		borderBottom: "1px dotted pink",
		color: state.isSelected ? "red" : "blue",
		padding: 20,
		display: state.isDisabled || state.isSelected ? "none" : "block"
	}),
	control: () => ({
		// none of react-select's styles are passed to <Control />
	}),
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = "opacity 300ms";

		return { ...provided, opacity, transition };
	}
};
const CurrencyTableData_optionsfrom = [{ "label": "Derivatives", "value": "Derivatives" }, { "label": "Spot", "value": "Spot" }]
const CurrencyTableData_optionsto = [{ "label": "Derivatives", "value": "Derivatives" }, { "label": "Spot", "value": "Spot" }]
const CurrencyTableData_from = [{ "label": "BTC", "value": "BTC" }, { "label": "USD", "value": "USD" }]
const CurrencyTableData_to = [{ "lstylesabel": "BTC", "value": "BTC" }, { "label": "USD", "value": "USD" }]
class MyAssets extends Component {
	constructor() {
		super();
		this.state = {
			currentTab: 0,

			exchangeshow: false,
			withdrawshow: false,
			tranfershow: false,
			depositshow: false,
			depositButton: true,
			cryptoAddress: '',
			cryptoSymbol: '',
			cryptoBalance: 0,
			cryptoFee: 0,
			btcbalance: 0,
			usdbalance: 0,
			copied: false,
			copied1: false,
			getBalanceDetails: [],
			getAddressDetails: [],
			pairdata: [],
			allposition_details: [],
			checkSecurity: false,
			tagid: '',
			receiveraddress: '',
			transferamount: '',
			cryptoType: '',
			tokenType: '',
			finalamount: '',
			twofa: '',
			bonusbalance: 0,
			convertedamount: 0,
			currencyamount: 0,
			btcprice: 0,
			ethprice: 0,
			errors: {},
			xrpdetails: {},
			withdrawshow1: true,
			destinationtag: '',
			fromwallet: { value: 'Derivatives', label: 'Derivatives' },
			towallet: { value: 'Derivatives', label: 'Derivatives' },
			fromcurrency: { value: 'BTC', label: 'BTC' },
			tocurrency: { value: 'BTC', label: 'BTC' },
			CurrencyTableData_optionsfrom: [],
			CurrencyTableData_optionsto: [],
			receiverCryptoAddress: '',
			loader: false,
			amount: "0",
			paypalRendered: false
		}
	}

	styles = {
		option: (provided, state) => ({
		  ...provided,
		  color: "white",
		  backgroundColor: "#000",
		}),
		valueContainer: (provided, state) => ({
		  ...provided,
		  height: '52px',
		  padding: '0 6px',
		  backgroundColor: "#03081f",
		  borderColor: '#81c8f6',
		borderRadius: 10,
		borderStyle: 'solid',
		borderWidth: '1px'
		 
		}),
		control: (provided, state) => ({
		  ...provided,
		  height: '52px',
		  borderRadius:10,
		  backgroundColor: "#03081f",
		  border:'none'
		 
		}),
		indicatorsContainer: (provided, state) => ({
		  ...provided,
		  height: '52px',
		  position: 'absolute',
		  right: 0,
		  top: 0,
		  color:'#fff' 
		}),    
		singleValue: (provided, state) => ({
		  ...provided,
		  color: "#fff"
		})
	  };
	
	componentDidMount() {
		window.scrollTo(0, 0);
		this.generateAddress();
		this.getpositiondetails();
		this.getAllCurrency();
		// this.updateBalance();
		// this.updateusdtBalance();
		// this.xrpupdateBalance();
		this.getBalance();
		this.getAddress();
		localStorage.setItem("userId",this.props.auth.user.id)
		//this.renderButton();
		// this.gettokenupdate();
		// axios
		// 	.post(url + "cryptoapi/perpetual-data")
		// 	.then(res => {
		// 		var btcindex = res.data.data.findIndex(x => (x.tiker_root) === 'BTCUSD');

		// 		if (btcindex != -1) {
		// 			this.setState({ btcprice: res.data.data[btcindex].markprice, pairdata: res.data.data });
		// 		}

		// 	})
		// 	.catch()

		axios
			.post(url + "cryptoapi/spot-data")
			.then(res => {
				var btcindex = res.data.data.findIndex(x => (x.tiker_root) === 'BTCUSDT');

				if (btcindex != -1) {
					this.setState({ btcprice: res.data.data[btcindex].markprice, pairdata: res.data.data });
				}

			})
			.catch()

			loadScript({ "client-id": "AWx1gpCaWDcvW0Tfxwf6gqMuvRkxVyhYPKJIEB6KVO3AdxxZJVnNaFxbQkemez5VwrqpkcMbRN8ZGJ-j" })

			.then((paypal) => {
				window.paypal = paypal;
			}).catch((e)=>{
				console.error("error : ",e)
			})
	}
	
	gettokenupdate() {
		let userid = this.props.auth.user.id;	
		axios
			.get(url + "cryptoapi/tokenupdate/" + userid)
			.then(res => {
			})
			.catch()
	}
	generateAddress() {
		let userid = this.props.auth.user.id;
		axios
			.get(url + "cryptoapi/addresscreate/" + userid)
			.then(res => {
			});
	}
	getAllCurrency() {
		let userid = this.props.auth.user.id;
		axios
			.get(url + "cryptoapi/getAllCurrencies/" + userid)
			.then(res => {
			});
	}

	payDeposit(payObject){
		let userid = this.props.auth.user.id;
		var hash = CryptoJS.AES.encrypt(JSON.stringify(payObject), userid).toString();
			
		axios.post(url + "deposit/paypal/"+userid,hash).then((res)=>{
			console.log("Toast : ",res.data.messages)
			toast(res.data.messages, {position: toast.POSITION.TOP_CENTER});
			
		}).catch((e)=>{
			toast(e, {position: toast.POSITION.TOP_CENTER});
		})
	}
	getAddress() {
		let userid = this.props.auth.user.id;
		axios
			.get(url + "cryptoapi/getaddress/" + userid)
			.then(res => {
				if (res.data.data.length > 0) {
					var addressselect = [];
					var getAllAddress = res.data.data;

					for (var i = 0; i < getAllAddress.length; i++) {
						var from_val = {
							value: getAllAddress[i]._id,
							tagid: getAllAddress[i].tagid,
							address: getAllAddress[i].address,
							label: getAllAddress[i].address + " - " + getAllAddress[i].name,
							currency: getAllAddress[i].currency,
						};
						addressselect.push(from_val);
					}
					this.setState({ getAddressDetails: addressselect });
				}
			});
	}
	updateBalance() {
		let userid = this.props.auth.user.id;
		axios
			.get(url + "cryptoapi/updateBalance/" + userid)
			.then(res => {
			});
	}

	updateusdtBalance() {
		let userid = this.props.auth.user.id;
		axios
			.get(url + "cryptoapi/updateusdtBalance/" + userid)
			.then(res => {
			});
	}

	xrpupdateBalance() {
		let userid = this.props.auth.user.id;
		axios
			.get(url + "cryptoapi/xrpupdateBalance/" + userid)
			.then(res => {
			});
	}
	getBalance() {
		let userid = this.props.auth.user.id;
		
		axios
		.get(url + "cryptoapi/getBalance/" + userid)
		.then(res => {
			console.log("userIDDDDDDD",res);
			var xrpdetails = res.data.xrpdetails;

			var index = res.data.data.findIndex(x => (x.currencySymbol) === "BTC");
			var usdindex = res.data.data.findIndex(x => (x.currencySymbol) === "USD");
			console.log("indexes",index);
			if (index != -1) {
				var perparray = res.data.data;
				console.log(res.data.data,'res.data.datares.data.data')
				// var indexarr = [2, 0, 4,1,3];
				// var outputarr = indexarr.map(i => perparray[i]);
				this.setState({
					getBalanceDetails: res.data.data,
					bonusbalance: res.data.data[index].tempcurrency,
					btcbalance: res.data.data[index].balance,
					xrpdetails: xrpdetails
				});
			}
			if (usdindex != -1) {
				this.setState({ usdbalance: res.data.data[usdindex].spotwallet })
			}
		});
	}

	getpositiondetails() {
		let userid = this.props.auth.user.id;
		axios
			.post(url + "cryptoapi/allposition_details/", { userId: userid })
			.then(res => {
				this.setState({ allposition_details: res.data.data });
			});
	}
	onChangeSelect = selectedOption => {

		const {errors}=this.state
		let receiverData = this.state.getAddressDetails.find((el) => { return el.value.toString() == selectedOption.value.toString() })
		
		console.log("errrrrrrrrrrrrrr",errors)
		if(errors&&errors.receiveraddress)
		{
			delete errors["receiveraddress"]
		}
		this.setState({
			receiveraddress: selectedOption.value,
			receiverCryptoAddress: receiverData.address,
			tagid: receiverData.tagid
		});
	};
	onChangeAmount = (e) => {
     const {t} = this.props;
		const {id,value}=e.target ;
		const {errors}=this.state;
		if(errors&&errors.transferamount)
		{
			delete errors["transferamount"]
		}
		// if(/[^0-9]/g.test(value)){
		// 	return  
		// }
		this.setState({ transferamount: e.target.value });

		if (e.target.value > 0) {
			let finalamountbal = parseFloat(e.target.value) + parseFloat(this.state.cryptoFee)
			this.setState({ finalamount: finalamountbal });
			if (finalamountbal > this.state.cryptoBalance) {
				this.setState({ transferamount: 0 });
				store.addNotification({
					title: t("ERRORS"),
					message: t("INSUFFICIENT_BALANCE"),
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 1500,
						onScreen: true
					}
				});
			}
		} else {
			this.setState({ finalamount: 0 });
		}
	}
	onChange = e => {
		if (e.target.id == 'currencyamount') {
			if ((this.state.fromcurrency.value == 'BTC' && this.state.tocurrency.value == 'BTC') || (this.state.fromcurrency.value == 'USD' && this.state.tocurrency.value == 'USD')) {
				var convertedamount = 0;
			}
			else if (this.state.fromcurrency.value == 'BTC' && e.target.value != '' && !isNaN(e.target.value)) {
				var convertedamount = parseFloat(e.target.value) * parseFloat(this.state.btcprice);
			}
			else if (e.target.value != '' && !isNaN(e.target.value)) {
				var convertedamount = parseFloat(e.target.value) / parseFloat(this.state.btcprice)
			}
			else {
				var convertedamount = 0;
			}
			this.setState({ [e.target.id]: e.target.value, convertedamount: parseFloat(convertedamount).toFixed(8) });
		}
		else {
			this.setState({ [e.target.id]: e.target.value });
		}
	};


	handleDepositShow = (details) => {
		console.log("-----------------------------",details.currency.type)
		console.log(JSON.stringify(details,null,2));
		if (details.currencyAddress == '') {
			// alert("Please refresh the page")
			// window.location.href = "";
		}
		if (details.currencySymbol == 'XRP') {
			this.setState({ cryptoAddress: this.state.xrpdetails.address })
			this.setState({ destinationtag: details.currencyAddress })
		}
		else {
			this.setState({ cryptoAddress: details.currencyAddress })
		}
		this.setState({ cryptoSymbol: details.currencySymbol })
		this.setState({ cryptoType: details.currency.type })
		this.setState({ tokenType: details.currency.tokenType })
		this.setState({ cryptoBalance: details.balance })
		this.setState({ cryptoFee: details.currency.fee })
		this.setState({ depositshow: true });
	}

	movetoSettings = () => {
		this.props.history.push("/Settings",{data: 1});
	}
	manageAddress = () => {
		this.props.history.push("/ManageAddress");
	}

	withdrawClick = (e) => {

		const {transferamount,receiveraddress} =this.state;
		var errors={}
		if(transferamount == 0 || transferamount < 0)
		{
			errors.transferamount="* Please Enter Your Transfer Amount  "
		}
		if(receiveraddress=="")
		{
			errors.receiveraddress="* Please Choose Your Wallet Address"
		}


		if(Object.keys(errors).length!=0)
		{
			this.setState({errors:errors})
		}
		else{

			this.setState({
				withdrawshow1: false });
		}
		
		


	}
	backtoWithdraw = (e) => {
		this.setState({ withdrawshow1: true });
	}
	exchangeshow = (e) => {
		this.setState({ exchangeshow: true });
	}

	convertnow = (e) => {
		const {t} = this.props;
		var index = this.state.getBalanceDetails.findIndex(x => (x.currencySymbol) === this.state.cryptoSymbol);
		var balance = 0;
		// ////console.log("handletransferShow",index);
		// ////console.log("handletransferShow",this.state.fromwallet);
		// ////console.log("handletransferShow inputamount",this.state.inputamount);
		if (index != -1) {
			var balance = this.state.fromwallet.value == "Derivatives" ? this.state.getBalanceDetails[index].balance : this.state.getBalanceDetails[index].spotwallet;
			////console.log("handletransferShow balance", balance);
		}
		if (this.state.fromwallet.value == this.state.towallet.value) {
			store.addNotification({
				title: t("ERRORS"),
				message: t("FROM_TO_WALLET"),
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1500,
					onScreen: true
				}
			});
			return false
		}
		else {
			if (this.state.inputamount == '' || this.state.inputamount < 0 || isNaN(this.state.inputamount)) {
				store.addNotification({
					title: t("ERRORS"),
					message: t("AMOUNT_ENTERED_IS_INVALID"),
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 1500,
						onScreen: true
					}
				});
			}
			else if ((balance < this.state.inputamount)) {
				store.addNotification({
					title:t("ERRORS"),
					message: t("INSUFFICIENT_BALANCE"),
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 1500,
						onScreen: true
					}
				});
			}
			else {
				const transferData = {
					inputamount: this.state.inputamount,
					fromwallet: this.state.fromwallet,
					towallet: this.state.towallet,
					currency: this.state.cryptoSymbol,
					userId: this.props.auth.user.id
				};
				axios
					.post(url + "cryptoapi/convertamount", transferData)
					.then(res => {
						if (!res.data.status) {
							store.addNotification({
								title: t("ERRORS"),
								message: res.data.message,
								type: "danger",
								insert: "top",
								container: "top-right",
								animationIn: ["animated", "fadeIn"],
								animationOut: ["animated", "fadeOut"],
								dismiss: {
									duration: 1500,
									onScreen: true
								}
							});
						}
						else {
							this.setState({ inputamount: 0, tranfershow: false });
							store.addNotification({
								title: t("SUCCESS"),
								message: res.data.message,
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animated", "fadeIn"],
								animationOut: ["animated", "fadeOut"],
								dismiss: {
									duration: 1500,
									onScreen: true
								}
							});
							setTimeout(function () {
								window.location.href = "/MyAssets";
							}, 2000);
						}
					});
			}
		}
	}
	currencyconvertnow = (e) => {
		const {t} = this.props;
		var index = this.state.getBalanceDetails.findIndex(x => (x.currencySymbol) === this.state.fromcurrency.value);
		var balance = 0;
		if (index != -1) {
			var balance = this.state.fromcurrency.value == "BTC" ? this.state.getBalanceDetails[index].balance : this.state.getBalanceDetails[index].spotwallet;
		}
		if (this.state.fromcurrency.value == this.state.tocurrency.value) {
			store.addNotification({
				title: t("ERRORS"),
				message:t("FROM_AND_TO_CURRENCY") ,
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1500,
					onScreen: true
				}
			});
			return false
		}
		else {
			if (this.state.currencyamount == '' || this.state.currencyamount < 0 || isNaN(this.state.currencyamount)) {
				store.addNotification({
					title: t("ERRORS"),
					message: t("AMOUNT_ENTERED_IS_INVALID"),
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 1500,
						onScreen: true
					}
				});
			}
			else if ((balance < this.state.currencyamount)) {
				store.addNotification({
					title: t("ERRORS"),
					message: t("INSUFFICIENT_BALANCE"),
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 1500,
						onScreen: true
					}
				});
			}
			else {
				this.setState({ exchangeshow: false });
				const transferData = {
					currencyamount: this.state.currencyamount,
					fromcurrency: this.state.fromcurrency,
					tocurrency: this.state.tocurrency,
					userId: this.props.auth.user.id
				};
				axios
					.post(url + "cryptoapi/convertcurrency", transferData)
					.then(res => {
						if (!res.data.status) {
							store.addNotification({
								title: t("ERRORS"),
								message: res.data.message,
								type: "danger",
								insert: "top",
								container: "top-right",
								animationIn: ["animated", "fadeIn"],
								animationOut: ["animated", "fadeOut"],
								dismiss: {
									duration: 1500,
									onScreen: true
								}
							});
						}
						else {
							this.setState({ inputamount: 0, tranfershow: false });
							store.addNotification({
								title: t("SUCCESS"),
								message: res.data.message,
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animated", "fadeIn"],
								animationOut: ["animated", "fadeOut"],
								dismiss: {
									duration: 1500,
									onScreen: true
								}
							});
							setTimeout(function () {
								window.location.href = "/MyAssets";
							}, 2000);
						}
					});
			}
		}
	}

	otpSubmit = (e) => {
		const {t} = this.props;
		if (this.state.twofa != "") {
			//check 2fa
			this.setState({ 'loader': true })
			const withdrawData = {
				receiveraddress: this.state.receiveraddress,
				transferamount: this.state.transferamount,
				finalamount: this.state.finalamount,
				tagid: this.state.tagid,
				requestType: 'Withdraw',
				cryptoType: this.state.cryptoSymbol,
				twofa: this.state.twofa,
				id: this.props.auth.user.id,
				receiverCryptoAddress: this.state.receiverCryptoAddress,
				newdate: moment()
			};

			var encryptuserdata = CryptoJS.AES.encrypt(JSON.stringify(withdrawData), cryptoPass).toString();
			var jsonencryt = {
				token: encryptuserdata
			}
			let userid = this.props.auth.user.id;
			axios
				.post(url + "cryptoapi/check2fa", jsonencryt)
				.then(res => {
					this.setState({ 'loader': false })
					if (res.data.status) {
						store.addNotification({
							title:t("SUCCESS"),
							message: res.data.message,
							type: "success",
							insert: "top",
							container: "top-right",
							animationIn: ["animated", "fadeIn"],
							animationOut: ["animated", "fadeOut"],
							dismiss: {
								duration: 1500,
								onScreen: true
							}
						});
						this.setState({ withdrawshow: false });
						this.setState({ withdrawshow1: true });
					} else {
						store.addNotification({
							title: t("ERRORS"),
							message: res.data.message,
							type: "danger",
							insert: "top",
							container: "top-right",
							animationIn: ["animated", "fadeIn"],
							animationOut: ["animated", "fadeOut"],
							dismiss: {
								duration: 1500,
								onScreen: true
							}
						});
					}
					//this.setState({getBalanceDetails:res.data.data});
				});
		} else {
			store.addNotification({
				title:t("ERRORS") ,
				message:t("AUTHENTICATION_CODE_MISSING") ,
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1500,
					onScreen: true
				}
			});
		}
	}
	withdrawSubmit = (e) => {
		e.preventDefault();
		const withdrawData = {
			receiveraddress: this.state.receiveraddress,
			tagid: this.state.tagid,
			transferamount: this.state.transferamount,
			finalamount: this.state.finalamount,
			requestType: 'Withdraw',
			requestType: this.state.cryptoSymbol,
			id: this.props.auth.user.id
		};
		axios
			.post(url + "cryptoapi/withdrawRequest", withdrawData)
			.then(res => {
				if (!res.data.status) {
					////console.log(res);
					this.setState({ errors: res.data.errors });
				}
			});

	}

	onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
			};

	handleDepositClose = (type) => {

		this.setState({ depositshow: false, paypalRendered: false,  depositButton : true, amount : localStorage.removeItem("amount") });

	}
	handletransferClose = (type) => {
		this.setState({ tranfershow: false });
	}
	handleexchangeClose = (type) => {

		this.setState({ exchangeshow: false });
	}
	fromwalletchange = selectedOption => {
		var towallet;
		if(selectedOption == "Derivatives"){
			towallet =  { "label": "Spot", "value": "Spot" }
		}else {
			towallet = { "label": "Derivatives", "value": "Derivatives" }
		}
		this.setState({ fromwallet: selectedOption,towallet: towallet })
	}
	towalletchange = selectedOption => {
		var fromwallet;
		if(selectedOption == "Derivatives"){
			fromwallet = { "label": "Spot", "value": "Spot" }
		}else {
			fromwallet = { "label": "Derivatives", "value": "Derivatives" }
		}
		this.setState({ towallet: selectedOption,fromwallet: fromwallet })
	}
	fromcurrencychange = selectedOption => {
		if ((selectedOption.value == 'BTC' && this.state.tocurrency.value == 'BTC') || (selectedOption.value == 'USD' && this.state.tocurrency.value == 'USD')) {
			var convertedamount = 0;
		}
		else if (selectedOption.value == 'BTC' && this.state.currencyamount != '' && !isNaN(this.state.currencyamount)) {
			var convertedamount = parseFloat(this.state.currencyamount) * parseFloat(this.state.btcprice);
		}
		else if (this.state.currencyamount != '' && !isNaN(this.state.currencyamount)) {
			var convertedamount = parseFloat(this.state.currencyamount) / parseFloat(this.state.btcprice)
		}
		else {
			var convertedamount = 0;
		}
		this.setState({ fromcurrency: selectedOption, convertedamount: parseFloat(convertedamount).toFixed(8) })
	}
	tocurrencychange = selectedOption => {
		if ((selectedOption.value == 'BTC' && this.state.fromcurrency.value == 'BTC') || (selectedOption.value == 'USD' && this.state.fromcurrency.value == 'USD')) {
			var convertedamount = 0;
		}
		else if (selectedOption.value == 'BTC' && this.state.currencyamount != '' && !isNaN(this.state.currencyamount)) {
			var convertedamount = parseFloat(this.state.currencyamount) / parseFloat(this.state.btcprice)
		}
		else if (this.state.currencyamount != '' && !isNaN(this.state.currencyamount)) {
			var convertedamount = parseFloat(this.state.currencyamount) * parseFloat(this.state.btcprice);
		}
		else {
			var convertedamount = 0;
		}
		this.setState({ tocurrency: selectedOption, convertedamount: parseFloat(convertedamount).toFixed(8) })
	}
	clickTranfer = async () =>{
		var fromwallet = this.state.fromwallet;
		var towallet = this.state.towallet;
		this.setState({
			fromwallet: towallet,
			towallet: fromwallet
		})
	}
	handleWithdrawShow = (details) => {
		this.setState({ cryptoAddress: details.currencyAddress })
		this.setState({ cryptoSymbol: details.currencySymbol })
		// this.setState({ cryptoBalance: details.currencySymbol == 'BTC' ? details.balance : details.spotwallet })
		this.setState({ cryptoBalance: details.spotwallet })

		this.setState({ cryptoType: details.currency.type })
		this.setState({ tokenType: details.currency.tokenType })
		this.setState({ cryptoFee: details.currency.fee })
		if (typeof details.userId.google != "undefined" && details.userId.google == "Enabled") {
			this.setState({ checkSecurity: true })
		}
		this.setState({ withdrawshow: true });
	}

	handletransferShow = (details) => {
		// this.setState({cryptoAddress:details.currencyAddress})
		this.setState({ cryptoSymbol: details.currencySymbol })
		this.setState({ cryptoBalance: details.balance })
		
		this.setState({ cryptoType: details.currency.type })
		this.setState({ tokenType: details.currency.tokenType })
		// this.setState({cryptoFee:details.currency.fee})
		// if(typeof details.userId.google !="undefined" && details.userId.google =="Enabled" ){
		// 	this.setState({checkSecurity:true})
		// }
		this.setState({ tranfershow: true });
	}


	handleWithdrawClose = (type) => {
		this.setState({ withdrawshow: false });
	}

	 createOrder = (data, actions) => {

		return actions.order.create({
	
		  purchase_units: [
	
			{
	
			  amount: {
	
				value: "0.01",
	
			  },
	
			},
	
		  ],
	
		});
	
	  };
	
	   onApprove = (data, actions) => {
	
		return actions.order.capture();
	
	  };

	renderButton(){
		if(!this.state.paypalRendered){
		

    // start to use the PayPal JS SDK script
	window.paypal.Buttons({
		createOrder: function (data, actions) {
			return actions.order.create({
				purchase_units: [{
					amount: {
						value: parseFloat(localStorage.getItem("amount")).toFixed(2).toString()
					}
				}]
			});
		},
		onApprove: function (data, actions) {
			return actions.order.capture().then(function (details) {
				console.log("Payaal info : ",details)
				//this.payDeposit(details);
				let userid = localStorage.getItem("userId");
		var hash = CryptoJS.AES.encrypt(JSON.stringify(details), userid).toString();
			
		axios.post(url + "api/deposit/paypal/"+userid,{ data: hash }).then((res)=>{
			console.log("Deposit status : ",this.state.depositshow)
			document.getElementById("error-message").innerHTML = "<center><h5>"+ res.data.messages +"</h5></center>"
			//toast(res.data.messages, {position: toast.POSITION.TOP_CENTER});
		}).catch((e)=>{
			//toast(e, {position: toast.POSITION.TOP_CENTER});
		})
		document.getElementById("error-message").innerHTML = "<center><h5>Success</h5></center>"
		setTimeout(()=>{ window.location.reload() },2000)
				//window.location.href = "approve.html";
			});
			
		},
		onError: function (err) {
			console.log('Something went wrong', err);
			document.getElementById("error-message").innerHTML = "<center><h5>Something went wrong !</h5></center>"
		}
	}).render('#paypal-button-container'); // Display payment options on your web page
	this.setState({ paypalRendered: true });

		
	}
	}


	renderBalanceData() {
		const {t} = this.props;
		return this.state.getBalanceDetails.map((details, index) => {

			console.log("uuuuuuuuuuuuuuuuuuuuu",details.currency)
			var index = this.state.allposition_details.findIndex(x => (x.firstCurrency) === details.currencySymbol);
			var pairindex = this.state.pairdata.findIndex(x => (x.first_currency) === details.currencySymbol);
			var price = 1;
			if (pairindex != -1) {
				price = parseFloat(this.state.pairdata[pairindex].markprice);
			}
			var parposquantity = (index != -1) ? this.state.allposition_details[index].quantity : 0;
			var parposprice = (index != -1) ? this.state.allposition_details[index].price : 0;
			var parposleverage = (index != -1) ? this.state.allposition_details[index].leverage : 0;

			var pos_initial_margin = (parseFloat(parposquantity) / parseFloat(parposprice)) * ((100 / parseFloat(parposleverage)) / 100);
			var profitnloss = [(1 / parseFloat(parposprice)) - (1 / parseFloat(price))] * parseFloat(parposquantity);
			profitnloss = isNaN(profitnloss) ? 0 : profitnloss;
			console.log("parposprice",parposprice, "--", " price",price,"--","parposquantity",parposquantity )
			var unprofitnloss = [(1 / parseFloat(parposprice)) - (1 / parseFloat(price))] * parseFloat(parposquantity);
			unprofitnloss = isNaN(unprofitnloss) ? 0 : unprofitnloss;
			var profitnlossper = (parseFloat(profitnloss) / parseFloat(pos_initial_margin)) * 100;
			var profitnlossusd = (profitnloss * parseFloat(this.state.btcprice));

			pos_initial_margin = isNaN(pos_initial_margin) ? 0 : pos_initial_margin;
			var equitybal = +details.balance + (+pos_initial_margin);

			 // let image = require('../images/currency images/' + details.currencySymbol + '.png');
			 let image = `${keys.baseUrl}/currency/${details?.currency?.currencyimage}`;
			return (
				<tr>
					<td>
						{image ? (<img src={image} className="img-fluid" alt="" />)
									: (<img src={BtcIcon} />)}
						{details.currencySymbol}
					</td>
				
					<td>
						{parseFloat(details.balance + details.spotwallet).toFixed(8)} {details.currencySymbol}
						
						{/* <small>= {parseFloat((details.balance + details.spotwallet) * price).toFixed(2)} USD</small> */}
					</td>
					<td>
						{parseFloat(details.spotwallet).toFixed(8)} {details.currencySymbol}
						
						{/* <small>= {parseFloat(details.spotwallet * price).toFixed(2)} USD</small> */}
					</td>
					 <td>
						{parseFloat(details.balance).toFixed(8)} {details.currencySymbol}
						{/* <small>= {parseFloat(details.balance * price).toFixed(2)} USD</small>*/}
					</td> 
					{console.log("unprofitnloss..",unprofitnloss)}
					<td>
						{parseFloat(unprofitnloss).toFixed(8)} {details.currencySymbol}
						{/* <small>=  {parseFloat(unprofitnloss * price).toFixed(2)} USD</small> */}
					</td>

					{/* <td>
						{parseFloat(pos_initial_margin).toFixed(8)} {details.currencySymbol}
						{/* <small>= {parseFloat(pos_initial_margin * price).toFixed(2)} USD</small>
					</td> */}
					{/* <td>
						{parseFloat(pos_initial_margin).toFixed(8)} {details.currencySymbol}
						{/* <small>= {parseFloat(pos_initial_margin * price).toFixed(2)} USD</small>
					</td> */}
					<td>
						{(details.currencySymbol) ? <div>
							<button className="btn transYellowButton py-1"  >
								<span className="" onClick={() => { this.handleDepositShow(details) }} title="Deposit / Receive">{t("DEPOSIT")}</span>
							</button>
							<button className="btn transYellowButton py-1 btnMarginLeftRight"  >
								<span className="" onClick={() => { this.handleWithdrawShow(details) }} data-toggle="tooltip" data-placement="bottom" title="Withdraw">{t("WITHDRAW")}</span>
							</button>
							 <button class="btn transYellowButton py-1" onClick={() => { this.handletransferShow(details) }}>
								<span class="assetexchange" data-toggle="tooltip" data-placement="bottom" title="Transfer">{t("TRANSFER")}</span>
							</button>
						</div> : ''}

					</td>
				</tr>
			)
		})
	}


	renderWalletBalance() {
		const {t} = this.props;
		return (
			<div className="row mx-0 myasset">
				<div className="col-md-12 px-0">
					<div className="assetsTable pt-0">

						<div className="tab-content card-body">
							<div className="d-flex align-items-center justify-content-between">
								<h3 className="assetTitle">{t("WALLET_BALANCE")}</h3>
								<ul className="assetTableRightLink mb-4">
									<li><Link to="/ManageAddress" className="btn btnDefaultNew themebtn btn_sm">{t("MANAGE_ADDRESS")}</Link> </li>
									{/* }<li><Link onClick={this.exchangeshow}>BTC-USD convert</Link> </li> */}
								</ul>
							</div>
							<div className="table-responsive table_radius">
								<table id="assetsTable" className="table walletBalanceTable mb-0 walletBalanceTable_td_cen">
									<thead className='wallet_table_thead_cen'>
										<tr>
											<th>{t("WALLET")}</th>
											<th>{t("WALLET_BALANCE")}</th>
											<th>{t("SPOT_BALANCE")}</th>
											<th>{t("DERIVATIVES_BALANCE")}</th>
											<th>{t("UNREALIZED_P&L")}</th>
											<th>{t("ACTION")}</th>
											{/* <th>Equity</th> */}
											
											{/* <th>Position Margin</th>
											<th>Order Margin</th> */}
										</tr>
									</thead>
									<tbody>
										{this.renderBalanceData()}
										{/*}	<tr>
												<td>Bonus USD</td>
												<td>0.00000000 USD<small>= 0.00 USD</small></td>
												<td>{parseFloat(this.state.bonusbalance).toFixed(8)} USD</td>
												<td>0.00000000 USD<small>= 0.00 USD</small></td>
												<td>0.00000000 USD<small>= 0.00 USD</small></td>
												<td>0.00000000 USD</td>
												<td>0.00000000 USD</td>
												<td>0.00000000 USD</td>
												<td>

												</td>
												</tr>*/}
									</tbody>
								</table>
							</div>

						</div>
					</div>
				</div>
			</div>
		)
	}


	renderTab(tabIndex) {
		switch (tabIndex) {
			// case 1:
			// 	return <ClosedPandL />
			case 2:
				return <AssetsHistory />
			case 3:
				return <OrderHistory />
			case 4:
				return <TradeHistory />
			case 5:
				return <WithdrawalHistory />
			// case 6:
			// 	return <BonusHistory />
			default:
				return this.renderWalletBalance()
		}
	}


	render() {
		const { errors, loader, currentTab } = this.state;
        const {t} = this.props;
		return (
			<div className='myaset_parent_div'>
				{console.log('localstorage',localStorage.getItem('amount'),this.state)}
				<Modal show={this.state.depositshow} onHide={this.handleDepositClose} aria-labelledby="contained-modal-title-vcenter" centered className="primary-modal" backdrop="static">
					<Modal.Header closeButton>
						<Modal.Title>{this.state.cryptoSymbol}{" "} {this.state.cryptoType && this.state.cryptoType == "Token" && this.state.tokenType }  {t("DEPOSIT_RECEIVE")}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="popUpSpace">
							{ this.state.cryptoType != "Fiat" ?
							<div className="depositeQr">
								<QRCode value={this.state.cryptoAddress} />
							</div>:<></>
							}
							<div className="walletKey">
								<div className="row">
								{ this.state.cryptoType != "Fiat" ?
									<div className="col-md-12">
										<div className="form-group inputWithText">
											<div className="address"><span>{this.state.cryptoAddress}</span></div>
											<CopyToClipboard text={this.state.cryptoAddress}
												onCopy={() => this.setState({ copied: true })}>
												<button className="input-group-append-icon"><i className="far fa-copy"></i></button>
											</CopyToClipboard>
											{this.state.copied ? <span style={{ color: 'green' }}>{t("COPIED")}.</span> : null}
										</div>
									</div>: <>
									<div className="col-md-12">
             <div className="form-group">
               <label>{t("AMOUNT_IN")} {this.state.cryptoSymbol}</label>
               <input
                  onChange={(e)=> {this.onChange(e); localStorage.setItem("amount",e.target.value) }}
                  value={this.state.amount}
                  id="amount"
                  type="number"
                  className={classnames("form-control")}/>
             </div>
			 <div id="paypal-button-container"></div>
			 {console.log('depostiButoon',this.state.depositButton)}
			 { this.state.depositButton ? <button className="btn btnDefaultNew btn_sm" onClick={()=> { this.setState({ depositButton: false }); localStorage.setItem("amount",this.state.amount) }}> {t("DEPOSIT")} </button> : this.renderButton() } 
							{/* { document.getElementById("paypal-button-container") && this.renderButton() } */}
			{console.log("payapal :  ",document.getElementById("paypal-button-container"),this.state.depositButton)}
			 {/* {this.state.depositshow && document.getElementById("paypal-button-container") && this.renderButton() && this.renderButton() } */}
						{/* paypal button */}
						{/* <PayPalScriptProvider options={{ "client-id": "test" }}>
            <PayPalButtons style={{ layout: "horizontal" }} />
        </PayPalScriptProvider> */}

<div class="noteCont" id="error-message"> </div>

           </div></>}
									{
										this.state.cryptoSymbol == 'XRP' ?
											<div className="col-md-12">
												<div className="form-group inputWithText">
													<div className="address">{this.state.destinationtag}</div>
													<CopyToClipboard text={this.state.destinationtag}
														onCopy={() => this.setState({ copied1: true })}>
														<button className="input-group-append-icon"><i className="far fa-copy"></i></button>
													</CopyToClipboard>
													{this.state.copied1 ? <span style={{ color: 'green' }}>{t("COPIED")}.</span> : null}
												</div>
											</div> : ''
									}

								</div>
							</div>
							{ this.state.cryptoType != "Fiat" ?
							<div className="noteCont">
								<h6>{t("NOTES")}:</h6>
								<p>1. {t("NOTES2")}</p>
								<p>2. {t("NOTES3")}</p>
							</div>:<div className="noteCont">
								<h6>{t("NOTES")}:</h6>
								<p>1. {t("NOTES_ONCE_THE_TRANSACTION")}</p>
								<p>2. {t("RAISE_A_TICKET")}</p>
							</div>}
						</div>
					</Modal.Body>

				</Modal>

				<Modal show={this.state.tranfershow} onHide={this.handletransferClose} aria-labelledby="contained-modal-title-vcenter" centered className="primary-modal" backdrop="static">
					<Modal.Header closeButton>
						<Modal.Title>{this.state.cryptoSymbol}{" "} {this.state.cryptoType && this.state.cryptoType == "Token" && this.state.tokenType } {t("WALLET_CONVERSION")} </Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="assetEchangeForm">
							<div className="row mx-0 ">
								<div className="col-sm-5 mb-0 mb-sm-0 px-0">
									<div className="form-group">
										<label>{t("FROM_WALLET")}</label>

										<Select 
											width="100%"
											menuColor="red"
											options={CurrencyTableData_optionsfrom}
											value={this.state.fromwallet}
											onChange={this.fromwalletchange}
											className="border_blue_select"
											styles={styles} isSearchable={false}
											
										/>
									</div>
								</div>
								<div className="col-sm-2 d-flex align-items-center justify-content-center">
									<div className="form-group mb-0">
										<label className="d-none d-sm-block invisible">
										</label>
										<div className="changeSelect">
											<img
												src={doubleArrow}
												className="img-fluid"
												onClick={this.clickTranfer}
											/>
										</div>
									</div>
								</div>
								<div className="col-sm-5 mb-4 mb-sm-0 px-0">
									<div className="form-group">
										<label>{t("TO_WALLET")}</label>
										<Select
											options={CurrencyTableData_optionsto}
											onChange={this.towalletchange}
											value={this.state.towallet}
											className="border_blue_select"
											styles={styles}
											width="100%"
											menuColor="red" isSearchable={false}

										/>
									</div>
								</div>
							</div>
							<div className="form-group inputWithText">
								<label>
								{t("AMOUNT")}{" "}

								</label>
								<input
									name="inputamount"
									className="form-group input_height_52"
									placeholder=""
									type="number"
									id="inputamount"
									value={this.state.inputamount}
									onChange={this.onChange}
								/>
								<div className="input-group-append-icon">
									<small>
										{this.state.cryptoSymbol}
									</small>
								</div>
							</div>

							<div className="form-group clearfix mb-0">
								<input
									className="buttonType1"
									type="button"
									name=""
									value="Convert Now"
									onClick={this.convertnow}
								/>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>

					</Modal.Footer>
				</Modal>

				<Modal show={this.state.exchangeshow} onHide={this.handleexchangeClose} aria-labelledby="contained-modal-title-vcenter" centered className="primary-modal" backdrop="static">
					<Modal.Header closeButton>
						<Modal.Title>{this.state.cryptoSymbol} {t("BTCUSD_CONVERSION")}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="assetEchangeForm">
							<div className="row">
								<div class="col-md-12 px-0">
									<p class="text-center">1 {t("BTC")} = ${parseFloat(this.state.btcprice).toFixed(2)} {t("USD")}</p>
								</div>
								<div className="col-sm-7 mb-4 mb-sm-0 px-0">
								{t("BTC_BALANCE")} : {parseFloat(this.state.btcbalance).toFixed(8)}
								</div>
								<div className="col-sm-5 mb-4 mb-sm-2 px-0">
								{t("USD_BALANCE")} : {parseFloat(this.state.usdbalance).toFixed(2)}
								</div>
							</div>
						</div>

						<div className="assetEchangeForm clearfix mt-2">
							<div className="row mx-0">
								<div className="col-sm-5 mb-4 mb-sm-0 px-0">
									<div className="form-group">
										<label>{t("FROM_CURRENCY")}</label>

										<Select
											width="100%"
											menuColor="red"
											options={CurrencyTableData_from}
											value={this.state.fromcurrency}
											onChange={this.fromcurrencychange}
											className="border_blue_select"
											styles={styles} isSearchable={false}
										/>
									</div>
								</div>
								<div className="col-sm-2">
									<div className="form-group">
										<label className="d-none d-sm-block invisible">
											Sa
                                  </label>
										<div className="changeSelect">
											<img
												src={doubleArrow}
												className="img-fluid"
											/>

										</div>
									</div>
								</div>
								<div className="col-sm-5 mb-4 mb-sm-0 px-0">
									<div className="form-group">
										<label>{t("TO_CURRENCY")}</label>
										<Select
											options={CurrencyTableData_to}
											onChange={this.tocurrencychange}
											value={this.state.tocurrency}
											className="border_blue_select"
											styles={styles}
											width="100%"
											menuColor="red" isSearchable={false}

										/>
									</div>
								</div>
							</div>
							<div className="form-group inputWithText">
								<label>
								{t("AMOUNT")}{" "}

								</label>
								<input
									name="currencyamount"
									className="form-group"
									placeholder=""
									type="text"
									id="currencyamount"
									value={this.state.currencyamount}
									onChange={this.onChange}
								/>
								<div className="input-group-append-icon">
									<small>
										{this.state.fromcurrency.value}
									</small>
								</div>
							</div>

							<div className="form-group inputWithText">
								<label>
								{t("YOU_WILL_GET")}: {this.state.convertedamount + " " + this.state.tocurrency.value}

								</label>


							</div>

							<div className="form-group clearfix">
								<input
									className="buttonType1 float-left"
									type="button"
									name=""
									value="Convert Now"
									onClick={this.currencyconvertnow}
								/>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>

					</Modal.Footer>
				</Modal>

				<Modal show={this.state.withdrawshow} onHide={this.handleWithdrawClose} aria-labelledby="contained-modal-title-vcenter" centered className="primary-modal" backdrop="static">
					<Modal.Header closeButton>
						<Modal.Title>{this.state.cryptoSymbol}{" "} {this.state.cryptoType && this.state.cryptoType == "Token" && this.state.tokenType } {t("WITHDRAW")}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{(() => {
							if (this.state.checkSecurity) {
								let currencyOption = this.state.getAddressDetails;
								currencyOption = currencyOption.filter(el => el.currency == this.state.cryptoSymbol)
								return (
									<div className="popUpSpace withdrawPopupForm">
										{
											(this.state.withdrawshow1) ?
												<div>
													<div className="row">
														<div className="col-md-12">
															<div className="form-group">
																<label>{t("RECEIVER_WALLET_ADDRESS")}s <a href="javascript:void(0)" onClick={this.manageAddress} className="float-right">{t("ADD_WALLET")}</a></label>
															
																<Select
																styles={this.styles} className="border_blue_select"
																	width="100%"
																	menuColor="red"
																	options={currencyOption}
																	onChange={this.onChangeSelect}
																	isSearchable={false}
																/>
															</div>
															<span className="text-danger">{this.state.errors.receiveraddress}</span>
														</div>
														<div className="col-md-12">
															<div className="form-group">
																<label>{this.state.cryptoSymbol} {t("AMOUNT")} <span className="float-right">{t("BALANCE")}: <small>{this.state.cryptoBalance} {this.state.cryptoSymbol}</small> {/* | <a href="#">Send All</a> */}</span></label>
																<input
																	onChange={this.onChangeAmount}
																	value={this.state.transferamount}
																	id="transferamount"
																	type="number"
																	error={errors.transferamount}
																	className={classnames("form-control", {
																		invalid: errors.transferamount
																	})} />
															</div>
														
															<span className="text-danger">{this.state.errors.transferamount}</span>

														</div>
														<div className="col-md-12">
															<div className="form-group">
																<label>{t("FINAL_AMOUNT")}<span className="float-right">+ {t("FEES")}: {this.state.cryptoFee} {this.state.cryptoSymbol}</span></label>
																<input
																	value={this.state.finalamount}
																	id="finalamount"
																	type="text"
																	readOnly
																	error={errors.finalamount}
																	className={classnames("form-control", {
																		invalid: errors.finalamount
																	})} />
															</div>
														</div>
														<div className="col-md-12">
															<div className="form-group">
																<input type="submit" onClick={this.withdrawClick} name="" value="Withdraw" className="btn buttonType1" />
															</div>
														</div>
													</div>
													<div className="noteCont">
														<h6>{t("NOTES")}:</h6>
														<p>1.{t("MINUMUM_WITHDRAW")} :{this.state.cryptoFee} {this.state.cryptoSymbol}.</p>
														<p>2.{t("INSIDE_YOUR_ACCOUNT")} </p>
													</div>
												</div>
												:
												<div className="row">
													<div className="col-md-12">
														<div className="form-group">
															<label>{t("ENTER_AUTHENTICATION_CODE")}</label>
															<input
																onChange={this.onChange}
																value={this.state.twofa}
																id="twofa"
																type="text"
																className={classnames("form-control")} />
														</div>
													</div>
													<div className="col-md-12">
														<div className="form-group flexButton">


															<button type="button" onClick={this.otpSubmit} className="btn buttonType1" disabled={loader}>{
																loader && <i class="fas fa-spinner fa-spin mr-2"></i>
															}{t("SUBMIT")}</button>
															<button type="button" onClick={this.backtoWithdraw} className="btn buttonType1">{t("BACK")}</button>

														</div>
													</div>
												</div>
										}
									</div>


								)
							} else {
								return (
									<div className="popUpSpace withdrawPopupForm">
										<div className="row">
											<div className="col-md-12">
												<div className="form-group">
													<div class="security-setting-content">
														<ul className='popup_ul'>
															<li className='mb-3'><span className='text-white'>{t("REGISTER_EMAIL")}</span><small class="textVerifyGreen"><i class="far fa-check-circle px-1"></i>{t("VERIFIED")}</small></li>
															<li><span className='text-white'>{t("GOOGLE_AUNTHENTICATION")}</span><small class="textUnVerifyRed"><i class="far fa-times-circle px-1"></i>{t("DISABLED")}</small></li>
														</ul>
													</div>
												</div>

												<div className="form-group mb-0">
													<input type="button" onClick={this.movetoSettings} name="" value="Settings" className="btn buttonType1" />
												</div>
											</div>
										</div>
									</div>

								)
							}
						})()}
					</Modal.Body>
				</Modal>
				{/* <TradeHeader /> */}
				<Navtradebar />
				<div className='bg_strip_none'>
					<section className="wallet-container">

						<nav className="wallet-side-menu more_menus">
							<div className="nav nav-tabs" id="navLink-tab" role="tablist">
								<a className={this.state.currentTab == 0?"nav-item active":"nav-item"} onClick={() => this.setState({ currentTab: 0 })}>{t("MY_ASSETS")}</a>
								{/* <a className="nav-item" onClick={() => this.setState({ currentTab: 1 })}>Closed P&L</a> */}
								<a className={this.state.currentTab == 2?"nav-item active":"nav-item"} onClick={() => this.setState({ currentTab: 2 })}>{t("DEPOSIT_HISTORY")}</a>
								<a className={this.state.currentTab == 3?"nav-item active":"nav-item"} onClick={() => this.setState({ currentTab: 3 })}>{t("ORDER_HISTORY")}</a>
								<a className={this.state.currentTab == 4?"nav-item active":"nav-item"} onClick={() => this.setState({ currentTab: 4 })}>{t("TRADE_HISTORY")}</a>
								<a className={this.state.currentTab == 5?"nav-item active":"nav-item"} onClick={() => this.setState({ currentTab: 5 })}>{t("WITHDRAWAL_HISTORY")}</a>
								{/* <a className={this.state.currentTab == 6?"nav-item active":"nav-item"} onClick={() => this.setState({ currentTab: 6 })}>Bonus History</a> */}
							</div>
						</nav>

						<div className="wallet-tab">
							{this.renderTab(currentTab)}
						</div>

					</section>
				</div>
				<Footer />
			</div>
		);
	}
}

MyAssets.propTypes = {
	auth: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
	auth: state.auth,
	errors: state.errors
});
export default connect(
	mapStateToProps,
)(withTranslation()(MyAssets),withRouter(MyAssets));
