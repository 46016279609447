import React, { Component } from 'react'
import TradeHeader from './TradeHeader'
import Footer from './Footer'
import DepositeQR from "../images/depositeQR.jpg"
import BtcIcon from "../images/btcIcon.png"
import EthIcon from "../images/ethIcon.png"
import XrpIcon from "../images/xrpIcon.png"
import LtcIcon from "../images/ltcIcon.png"
import BchIcon from "../images/bchIcon.png"
import { store } from 'react-notifications-component';
import keys from "../actions/config";
import axios from "axios";
import DatePicker from "react-datepicker";
import { assethistory, Depositsearchdata } from "../actions/authActions";
import { Link, withRouter,useLocation } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CSVLink, CSVDownload } from "react-csv";
import Select from 'react-select';
import { Scrollbars } from 'react-custom-scrollbars';
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from 'react-i18next'
const url = keys.baseUrl;


const customStyles = {
	option: (provided, state) => ({
		...provided,
		borderBottom: '1px dotted pink',
		color: state.isSelected ? 'red' : 'blue',
		padding: 20,
	}),
	control: () => ({
		// none of react-select's styles are passed to <Control />
	}),
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = 'opacity 300ms';

		return { ...provided, opacity, transition };
	}
}

const options1 = [
	{ value: 'All', label: 'All' },
	{ value: 'Buy', label: 'Buy' },
	{ value: 'Sell', label: 'Sell' },
];

const options = { value: 'All', label: 'All' };

class TradeHistory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			assethistory: [],
			records: [],
			csvData: [],
			duplicatearray: [],
			contract: { value: 'All', label: 'All' },
			type: { value: 'All', label: 'All' },
			startDate: '',
			endDate: '',
			currentpath:''
		};
	}


	styles = {
		option: (provided, state) => ({
		  ...provided,
		  color: "white",
		  backgroundColor: "black",
		}),
		valueContainer: (provided, state) => ({
		  ...provided,
		  height: '52px',
		  padding: '0 6px',
		  backgroundColor: "#03081f",
		  borderColor: '#81c8f6',
		borderRadius: 10,
		borderStyle: 'solid',
		borderWidth: '1px'
		 
		}),
		control: (provided, state) => ({
		  ...provided,
		  height: '52px',
		  borderRadius:10,
		  backgroundColor: "#03081f",
		  border:'none'
		 
		}),
		indicatorsContainer: (provided, state) => ({
		  ...provided,
		  height: '52px',
		  position: 'absolute',
		  right: 0,
		  top: 0,
		  color:'#fff' 
		}),    
		singleValue: (provided, state) => ({
		  ...provided,
		  color: "#fff"
		})
	  };
	componentDidMount() {
		window.scrollTo(0, 0);
		this.getData()
		this.getCurrencyData();
		// console.log(this.props.location.pathname,"this.props.location.pathnam");
		this.setState({
				currentpath: this?.props?.location?.pathname
			});
		
	};
	getCurrencyData() {
		axios
			.get(url + "cryptoapi/getcurrency")
			.then(res => {
				var currencyselect = [];
				var getAllCurrency = res.data.data;
				//console.log(getAllCurrency);
				for (var i = 0; i < getAllCurrency.length; i++) {
					// if(getAllCurrency[i].currencySymbol=='BTC' || getAllCurrency[i].currencySymbol=='ETH' || getAllCurrency[i].currencySymbol=='USDT')
					// {
					var from_val = {
						value: getAllCurrency[i].currencySymbol,
						label: getAllCurrency[i].currencyName
					};
					currencyselect.push(from_val);

					// }
				}
				currencyselect.push(options)
				this.setState({ currencyData: currencyselect });
			});
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.errors) {
			this.setState({
				errors: nextProps.errors
			});
		}

		if (nextProps.auth !== undefined
			&& nextProps.auth.trade !== undefined
			&& nextProps.auth.trade.data !== undefined
			&& nextProps.auth.trade.data.data !== undefined
			&& nextProps.auth.trade.data.type !== undefined
			&& nextProps.auth.trade.data.type == 'assethistory'
		) {
			console.log("nextProps.auth.trade.data.data", nextProps.auth.trade.data.data);
			this.setState({ assethistory: nextProps.auth.trade.data.data, duplicatearray: nextProps.auth.trade.data.data });
			// this.buildarray(nextProps.auth.trade.data.data);
		}
	}

	getData() {
		var input = { "userid": this.props.auth.user.id }
		this.props.assethistory(input);
	}

	changecontract = contract => {
		this.setState({ contract });
	};

	changetype = type => {
		this.setState({ type });
	};

	buildarray = (historydata) => {
		var csvdata = [];

		var titledata = ["Contracts", "Filled Type", "Filled Qty", "Exec price", "Value", "Fee Rate", "Fees Paid", "Order Price", "Unfilled Qty", "Type", "Transaction ID", "Order Time"];
		csvdata.push(titledata);
		historydata.map((item, i) => {
			var quantity = item.quantity ? item.quantity : 0;
			var price = item.price ? item.price : 0;
			var orderValue = item.orderValue ? item.orderValue : 0;
			var orderType = item.buyorsell ? item.buyorsell : 0;
			var _id = item._id ? item._id : 0;
			var classs = item.buyorsell == 'buy' ? 'greenText' : 'pinkText';
			var filledAmount = item.filledAmount ? item.filledAmount : 0;
			var Remaining = parseFloat(quantity) - parseFloat(filledAmount);
			var newarr = [];
			item.filled.map((itemdata, i) => {
				//console.log(itemdata, 'itemdatasss');
				var created_at = itemdata.created_at ? itemdata.created_at : '';
				var Price = itemdata.Price ? itemdata.Price : 0;
				var pairname = itemdata.pairname ? itemdata.pairname : 0;
				var data1 = new Date(created_at);
				let date = data1.getFullYear() + '-' + (data1.getMonth() + 1) + '-' + data1.getDate() + ' ' + data1.getHours() + ':' + data1.getMinutes() + ':' + data1.getSeconds();
				newarr.push(pairname);
				newarr.push("Trade");
				newarr.push(filledAmount);
				newarr.push(Price);
				newarr.push(orderValue);
				newarr.push(orderValue);
				newarr.push(orderValue);
				newarr.push(orderValue);
				newarr.push(Remaining);
				newarr.push(orderType);
				newarr.push(_id);
				newarr.push(date);
				csvdata.push(newarr);
			})
		})
		this.setState({ csvData: csvdata })
	}

	Searchdata = async() => {
		var postdetails = {
			"contract": this.state.contract.value,
			"startDate": this.state.startDate.toString(),
			"endDate": this.state.endDate.toString(),
			"userid": this.props.auth.user.id
		}
		// Depositsearchdata(postdetails);
		axios
         .post(url+"cryptoapi/Depositsearchdata-history",postdetails).then(res =>{
         	// console.log(res.data.data,'ressssssssssssss')
         	this.setState({assethistory:res.data.data})
         })
	}

	pairdatachange = data => {
		this.setState({ records: data });
		if (this.state.records.length == 0) {
			data.map((item, i) => {
				if (item.first_currency != '' && item.second_currency != '') {
					var one = { "value": item.first_currency + item.second_currency, "label": item.first_currency + item.second_currency }
					options.push(one);
				}
			});
		}
	}

	handleChange = date => {

		this.setState({
			startDate: date,
			endDate: ''
		});
	};


	handleChange1 = date => {
		this.setState({
			endDate: date
		});
	};
	onSearchChange = (e) => {
		this.setState({ [e.target.id]: e.target.value });
		var term = e.target.value
		//console.log("termterm", e.target.value);
		var newarray = {}
		newarray = this.state.duplicatearray.filter(function (tag) {
			return tag.txid.indexOf(term.toLowerCase()) >= 0
		})
		this.setState({ duplicaterecords: newarray })
	}


	render() {
		const {t} = this.props;
		const { tradehistory, contract, type } = this.state
		return (
			<div className='bg_strip'> 
				<div className="modal fade primary-modal" id="deposite" tabindex="-1" role="dialog" aria-labelledby="deposite" aria-hidden="true" data-backdrop="static">
					<div className="modal-dialog modal-md modal-dialog-centered" role="document">
						<div className="modal-content depositeContent">
						<div className="modal-header">
									<h4 className='modal-title h4'>{t("BTC_DEPOSIT")}</h4>
									<button type="button" className="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
							<div className="modal-body">
								
								<div className="popUpSpace">
									<div className="depositeQr">
										<img src={DepositeQR} className="img-fluid" />
									</div>
									<div className="walletKey">
										<div className="row">
											<div className="col-md-12">
												<div className="form-group inputWithText">
													<input name="" className="form-control rigt_paddng_icon" placeholder="" value="3CMCRgEm8HVz3DrWaCCid3vAANE42jcEv9" type="text" />
													<button className="input-group-append-icon"><i className="far fa-copy"></i></button>
												</div>
											</div>
										</div>
									</div>
									<div className="noteCont">
										<h6>{t("NOTES")}:</h6>
										<p>1.{t("NOTES1")}</p>
										<p>2.{t("NOTES2")}</p>
										<p>3.{t("NOTES3")}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="modal fade primary-modal" id="Withdraw" tabindex="-1" role="dialog" aria-labelledby="Withdraw" aria-hidden="true" data-backdrop="static">
					<div className="modal-dialog modal-md modal-dialog-centered" role="document">
						<div className="modal-content WithdrawContent">
						<div className="modal-header">
									<h4 className='modal-title h4'>{t("BTC_WITHDRAW")}</h4>
									<button type="button" className="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
							<div className="modal-body">
							
								<div className="popUpSpace withdrawPopupForm">
									<div className="row">
										<div className="col-md-12">
											<div className="form-group">
												<label>{t("RECEIVER_WALLET_ADDRESS")} <a href="#" className="float-right">{t("ADD_WALLET")}</a></label>
												<input type="text" name="" className="form-control" value="3CMCRgEm8HVz3DrWaCCid3vAANE42jcEv9" />
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-group">
												<label>{t("BTC_AMOUNT")}<span className="float-right">{t("BALANCE")}: <small>10.23569878 {t("BTC")}</small> | <a href="#">{t("SEND_ALL")}</a></span></label>
												<input type="text" name="" className="form-control" value="0.00000000" />
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-group">
												<label>{t("FINAL_AMOUNT")} <span className="float-right">+ {t("FEES")} : 0.00005 BTC</span></label>
												<input type="text" name="" className="form-control" value="0.00000000" disabled="" />
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-group">
												<label>{t("ENTER_AUTHENTICATION_CODE")}</label>
												<input type="text" name="" className="form-control" value="123456" />
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-group">
												<input type="submit" name="" value="Withdraw" className="btn buttonType1" />
											</div>
										</div>
									</div>
									<div className="noteCont">
										<h6>{t("NOTES")}:</h6>
										<p>1.{t("MINUMUM_WITHDRAW")} : 0.002BTC </p>
										<p>{t("INSIDE_YOUR_ACCOUNT")}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <TradeHeader pairdatachange={this.pairdatachange} /> */}
				{/* <div className='container min_height_asset_history'></div> */}
				<TradeHeader pairdatachange={this.pairdatachange} />
				<div className='myasset_height'>
					<section className=''>
						<div className="row mx-0 myasset">
							<div className="col-md-12 px-0">
								<div className="assetsTable">
									{/* <nav>
										<div className="nav nav-tabs" id="navLink-tab" role="tablist">
											<a href="/closedPandL" className="nav-item nav-link ">Closed P&L</a>
											<Link to="/AssetsHistory" className="nav-item nav-link active">Deposit History</Link>
											<Link to="/OrderHistory" className="nav-item nav-link">Order History</Link>
											<Link to="/tradeHistory" className="nav-item nav-link">Trade History</Link>
											<Link to="/withdrawalHistory" className="nav-item nav-link">Withdrawal History</Link>
										</div>
									</nav> */}
									
									<div className="tab-content card-body">
					<h3 className="assetTitle">Deposit History</h3>

										<div className="assetHistoryTableTop clearfix mx-0 mt-0 mb-0">
											<div className="row">
												<div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 px-0">
													<div className="row w-100 mx-0">
														<div className="col-lg-4 col-md-4 col-sm-4 col-xl-3">
															<div className="form-group clearfix">
																<label>{t("COIN")}</label>
																<Select
																	styles={this.styles} className="border_blue_select"
																	width='100%'
																	menuColor='red'
																	options={this.state.currencyData}
																	value={contract}
																	onChange={this.changecontract}
																	isSearchable={false}
																/>
															</div>
														</div>
														<div className="col-lg-4 col-md-4 col-sm-4 col-xl-3">
															<div className="form-group">
																<label>{t("START_DATE")}</label>
																<DatePicker
																	selected={this.state.startDate}
																	onChange={this.handleChange}
																	showYearDropdown
																	showMonthDropdown
																	// minDate={new Date()}
																	onKeyDown={(e) => {
																		e.preventDefault();
																	  }}
																/>
															</div>
														</div>
														<div className="col-lg-4 col-md-4 col-sm-4 col-xl-3">
															<div className="form-group">
																<label>{t("END_DATE")}</label>
																<DatePicker
																	selected={this.state.endDate}
																	onChange={this.handleChange1}
																	showYearDropdown
																	showMonthDropdown
																	minDate={new Date(new Date(this.state.startDate).setDate(new Date(this.state.startDate).getDate() + 1))}
																	onKeyDown={(e) => {
																		e.preventDefault();
																	  }}
																/>
															</div>
														</div>
														<div className="col-lg-4 col-md-4 col-sm-4 col-xl-1">
															<div className="form-group">
																<label className="d-none d-lg-block invisible">s</label>
																<input type="button" name="" value={t("SEARCH")} onClick={this.Searchdata} className="buttonType1 ml-0 themebtn" />
															</div>
														</div>
														<div className="col-lg-4 col-md-4 col-sm-4 col-xl-2">
													<div className="downLoadBTN float-right">
														<div className="form-group">
															<label className="d-none d-sm-block invisible">s</label>

															{(this.state.csvData.length > 0) ?
																<CSVLink data={this.state.csvData} className="btn transYellowButton">{t("CSV")}</CSVLink> : ''
															}
														</div>
													</div>
												</div>


													</div>
												</div>
											
											</div>
										</div>

									</div>
									<div className="tab-content pb-4 card-body pt-0">
										
										<div className="table-responsive table_radius">
											
												
													<table id="assetsTable" className="table table-striped rembordor walletBalanceTable_td_cen">
														<thead>
															<tr className="wow flipInX" data-wow-delay=".5s;">
																<th>{t("TIME")}</th>
																<th> {t("COIN")}</th>
																<th>{t("TYPE")}</th>
																<th>{t("AMOUNT")}</th>
																<th>{t("ADDRESS")}</th>
																<th>{t("TRANSACTION_ID")}</th>
															</tr>
														</thead>
														{this.state.assethistory.length > 0 ? (
															<tbody>
																{
																	this.state.assethistory.map((item, i) => {
																		var created_date = item.created_date ? item.created_date : 0;
																		var currency = item.currency ? item.currency : 0;
																		var transferType = item.transferType ? item.transferType : 0;
																		var amount = item.amount ? item.amount : 0;
																		var toaddress = item.toaddress ? item.toaddress : '';
																		var txid = item.txid ? item.txid : '';


																		var data1 = new Date(created_date);
																		let date12 = data1.getFullYear() + '-' + (data1.getMonth() + 1) + '-' + data1.getDate() + ' ' + data1.getHours() + ':' + data1.getMinutes() + ':' + data1.getSeconds();
																		return <tr className="wow flipInX" data-wow-delay=".5s;">
																			<td>{date12}</td>
																			<td>{currency}</td>
																			<td>{(transferType == "Realized P&L") ? "Realized P&L" : 'Deposit'}</td>
																			<td>{parseFloat(amount).toFixed(8)}</td>
																			<td>{(toaddress != '') ? toaddress : currency + 'USD'}</td>
																			<td>{txid}</td>

																		</tr>
																	})
																}
															</tbody>
														) : (<tbody>
															<tr ><td colspan="8" className="text-center">{t("NO_RECORDS")}</td></tr></tbody>)}

													</table>
										</div>

									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
		{console.log("this.state.currentpath",this.state.currentpath)}
				{/* {this.state.currentpath == "/AssetsHistory"?<Footer />:""} */}
				<Footer />
			</div>
		);
	}
}

TradeHistory.propTypes = {
	Depositsearchdata: PropTypes.func.isRequired,
	assethistory: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
	auth: state.auth,
	errors: state.errors
});
export default connect(

	mapStateToProps,
	{
		assethistory,
		Depositsearchdata,
	}
)(withTranslation()(TradeHistory),withRouter(TradeHistory));
