import React, { useEffect, useState } from 'react';
import Navtradebar from './Navtradebar';
import { useSelector } from "react-redux";
import green from '../images/green.png'
import {Link} from 'react-router-dom'
import red from '../images/red.png'
import { Table, Tab, Tabs } from "react-bootstrap"
import Footer from './Footer';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {useLocation} from 'react-router-dom';
import axios from "axios";
import { store } from "react-notifications-component";
import keys from "../actions/config";
import bannerimg from '../images/bannimg.png'
import bannerdark from '../images/banndark.png'
import { useTranslation } from 'react-i18next';
import img1 from '../images/img1.png'
import img2 from '../images/img2.png'
import img3 from '../images/img3.png'
import img4 from '../images/img4.png'
import img5 from '../images/img5.png'
import img6 from '../images/img6.png'
import img7 from '../images/img7.png'
import img8 from '../images/img8.png'
import img9 from '../images/img9.png'
import img10 from '../images/img10.png'
import gplay from '../images/gplay.png'
import circle from '../images/dashedcircle.png'
import appstore from '../images/appstore.png'
import tradebg from '../images/trade-bg.png'
// import store from '../store';



const Landing = () =>{
    const location = useLocation();
    const {t} = useTranslation();
    let languagesRedex = useSelector(state => state.auth.language);
    console.log("languagesRedex_languagesRedex",languagesRedex);
    console.log('Landing000000',location);
    const [responsive] = React.useState({  
        0:{
            items:1,
        },
        575: {
            items: 2,
        },
        991: {
            item: 3,
        },
        1024: {
            items: 3,
        }
    });
    const [pairdetail, setPairdetails] = useState([]);
    const [pairdetailsList, setPairdetailsList] = useState([])
    const [selectedcurrency, setSelectedcurrency] = useState('USDT');
    const [settingsData, setSettingsData] = useState('')
    const [lang,setLang] = useState('') 
    const [cmsData, setCmsData] = useState();

    const url = keys.baseUrl;

    const getSpotPairDetails = (data) => {
        var pairdetails = pairdetail;
        let pairList = [];
        pairdetails.map(item => {
            if (item._id == data.pairId) {
                pairList.push({
                    ...item,
                    ...{
                        "markprice": data.data.markPrice,
                        "change": data.data.change,
                        "volume": data.data.firstVolume,
                        "secvolume": data.data.secondVolume,
                        "last": data.data.last,
                    }

                })
            }
            else {
                pairList.push(item)
            }
            return pairList;
        })
        setPairdetails(pairList)
    }


    useEffect(() => {
        window.scrollTo(0,0);
     
        //  let withdraw_id
        //  if(typeof withdraw_id != 'undefined' && withdraw_id != '') {
        //     let passdata = { withdrawid: withdraw_id };
        //     axios.post(url + 'cryptoapi/withdrawrequest', passdata)
        //         .then((res) => {
        //             if (res.data.status) {
        //                 store.addNotification({
        //                     title: "Success!",
        //                     message: res.data.message,
        //                     type: "success",
        //                     insert: "top",
        //                     container: "top-right",
        //                     animationIn: ["animated", "fadeIn"],
        //                     animationOut: ["animated", "fadeOut"],
        //                     dismiss: {
        //                         duration: 2000,
        //                         onScreen: true,
        //                     },
        //                 })
        //             }
        //             else {
        //                 store.addNotification({
        //                     title: "Error!",
        //                     message: res.data.message,
        //                     type: "danger",
        //                     insert: "top",
        //                     container: "top-right",
        //                     animationIn: ["animated", "fadeIn"],
        //                     animationOut: ["animated", "fadeOut"],
        //                     dismiss: {
        //                         duration: 2000,
        //                         onScreen: true,
        //                     },
        //                 });
        //             }
        //         })
        //         .catch();
        // }
        get_particular_Pair(selectedcurrency)
    }, [])



    const get_particular_Pair = async (currency) => {
        console.log('getparticularpair',currency)
        let pairdata = { exchangetype : "Spot" }
        console.log('paidasdfaasfasdfasd',pairdata)
        axios.post(url + 'cryptoapi/pair-data', pairdata)
            .then((res) => {
                if (res.status) {
                    console.log('req.sstataseaswerqasderqwerf',res.status,res.data.data)
                    const data = res.data.data;
                    var filterArr = data.filter(function (obj) {
                        return (obj.second_currency === currency || obj.first_currency === currency);
                    });
                    setPairdetails(data)
                }
            })
            .catch()
        setSelectedcurrency(currency)
    }


    useEffect(()=> {
        const withdraw_id = location.pathname.split("/")[2];
        console.log("withdrawID",withdraw_id)
        if (typeof withdraw_id != "undefined" && withdraw_id != "") {
            let passdata = { withdrawid: withdraw_id };
            axios
              .post(url + "cryptoapi/withdrawrequest", passdata)
              .then((res) => {
                if (res.data.status) {
                  store.addNotification({
                    title: t("SUCCESS"),
                    message: res.data.message,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 2000,
                      onScreen: true,
                    },
                  });
                } else {
                  store.addNotification({
                    title: t("ERRORS"),
                    message: res.data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 2000,
                      onScreen: true,
                    },
                  });
                }
                this.props.history.push("/login");
              })
              .catch();
          }
    },[])

    useEffect(() => {
        getallCmsData()
        let lang = localStorage.getItem('lang');
        setLang(lang) 
    }, [languagesRedex])

    const getallCmsData = async () => {
        const result = await axios.get(url + "cryptoapi/getallCmsData");
        setCmsData(result.data.result)
        console.log("result_result_result", result.data.result);
    }

    const cmsFilter = (data) => {
        const filter = cmsData?.find((el) => el.identifier == data);
        console.log("data_data_data", lang);
        if (filter) {
            let checkLang = lang == "en" ? filter.content : filter.spanishText
            return checkLang
        }
    }
    const cmsFilterImage = data => {
        console.log("cmsFilterImage_cmsFilterImage",data);
        const filter = cmsData?.find((el) => el.identifier == data);
        console.log("cmsFilterImage_cmsFilterImage",filter?.bannerImage);

        if(filter){
            return filter.bannerImage
        }   
    }
    return(
        cmsData && cmsData?.length != 0 ? 
        <>
        <div className='home'>
            <Navtradebar/>
            {console.log("lang_lang",cmsData)}
            <div className='bannerbg'>
                <div className='container container-theme '>
                    <div className=' banner  banner_felx'>
                        <div className='row'>
                            <div className='col-md-6'>
                                {/* <h1 className='bighead'>{t("WORLD_CLASS_TRADING")}</h1> */}
                                <h1 className='bighead'>{cmsFilter("landing_banner_01")}</h1>
                                {/* <p>{t("LOREM_PARAGRAPH")}</p> */}
                                <p>{cmsFilter("landing_banner_02")}</p>
                            </div>
                            <div className='col-md-6'>
                                <div className='bannerimg img_banner_home_align'>
                                    <img src={url + "cms_images/" + cmsFilterImage("landing_banner_image_03")} className="light img_banner_home" />
                                    <img src={url + "cms_images/" + cmsFilterImage("landing_banner_image_03")} className="dark img_banner_home" />
                                    {/* <img src={bannerimg} className="light" />
                                    <img src={bannerimg} className="dark" /> */}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='statcarousel'>
                <div className='container container-theme'>
                    <OwlCarousel className='owl-theme' responsive={responsive} dots={false} margin={10} nav>
                        <div class='item'>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <p className='mb-0'>{t("ETHBTC")}-0.13%</p>
                                    <p className='mb-0'>0.157 {t("BTC")}</p>
                                    <p className='mb-0'>Vol 12.15 {t("ETH")}</p>
                                </div>
                                <p className='green'>0.05%</p>
                            </div>
                        </div>
                        <div class='item'>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <p className='mb-0'>{t("ETHBTC")}-0.13%</p>
                                    <p className='mb-0'>0.157 {t("BTC")}</p>
                                    <p className='mb-0'>Vol 12.15 {t("ETH")}</p>
                                </div>
                                <p className='red'>0.05%</p>
                            </div>
                        </div>
                        <div class='item'>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <p className='mb-0'>{t("ETHBTC")}-0.13%</p>
                                    <p className='mb-0'>0.157 {t("BTC")}</p>
                                    <p className='mb-0'>Vol 12.15 {t("ETH")}</p>
                                </div>
                                <p>0.05%</p>
                            </div>
                        </div>
                        <div class='item'>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <p className='mb-0'>{t("ETHBTC")}-0.13%</p>
                                    <p className='mb-0'>0.157 {t("BTC")}</p>
                                    <p className='mb-0'>Vol 12.15 {t("ETH")}</p>
                                </div>
                                <p>0.05%</p>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
            </div>
            <div className='container'>
                <div className='cryptoasset py-5 my-5  px-lg-0 px-4'>
                    <div className='heading text-center pb-4'>
                       <h2 className='bighead'>{cmsFilter("landing_banner_03")}</h2>
                        <p>{cmsFilter("landing_banner_04")}</p>
                    </div>

                    <div className=''>
                        <Tabs
                            defaultActiveKey="BTC"
                            id="uncontrolled-tab-example"
                            className="mb-3 spot"
                            onSelect={(e)=> {get_particular_Pair(e)}}
                        >
                            {/* <Tab eventKey="home" title="Home" className=''>
        <Table hover bordered responsive variant="dark">
            <thead>
                <tr>
                    <th>Pair</th>
                    <th>Spot price</th>
                    <th>Last Price</th>
                    <th>24H Change</th>
                    <th>24H Volume</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>ryrty</td>
                    <td>rtyrty</td>
                    <td className="greenText">425%</td>
                </tr>
            </tbody>
        </Table>
      </Tab> */}
                            <Tab eventKey="BTC" title="BTC" >
                                <Table hover bordered responsive variant="dark">
                                    <thead>
                                        <tr>
                                            <th>{t("PAIR")}</th>
                                            <th>{t("SPOT_PRICE")}</th>
                                            <th>{t("LAST_PRICE")}</th>
                                            <th>{t("24H_CHANGE")}</th>
                                            <th>{t("24H_VOLUME")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pairdetail && pairdetail.map((item, i) => {
                                            if(item.second_currency == selectedcurrency)
                                            {
                                                return (<tr>
                                                    <td>{item.first_currency + "/" + item.second_currency}</td>
                                                    <td>{parseFloat(item.last).toFixed(2)}</td>
                                                    <td className="greenText">{parseFloat(item.change).toFixed(2)}%</td>
                                                    {
                                                        item.botstatus != 'off' && <td>{item.last <= 0 ? "0.00" : parseFloat(item.volume).toFixed(2) + " " + item.first_currency}</td>
                                                    }
                                                    {item.botstatus != "off" &&
                                                        <td>{item.secvolume <= 0 ? "0.00" : parseFloat(item.secvolume).toFixed(2) + " " + item.first_currency}</td>
                                                    }
                                                    <td>
                                                        <a href={"spot/" + item.first_currency + "-" + item.second_currency} className="btn btnMarketBuy">{t("BUY")}</a>
                                                        <a href={"spot/" + item.first_currency + "-" + item.second_currency} className="btn btnMarketSell">{t("SELL")}</a>
                                                    </td>
                                                </tr>)

                                            }
                                        })}

                                    </tbody>
                                </Table>
                            </Tab>


                            <Tab eventKey="USDT" title="USDT">
                                <Table hover bordered responsive variant="dark">
                                <thead>
                                        <tr>
                                            <th>{t("PAIR")}</th>
                                            <th>{t("SPOT_PRICE")}</th>
                                            <th>{t("LAST_PRICE")}</th>
                                            <th>{t("24H_CHANGE")}</th>
                                            <th>{t("24H_VOLUME")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pairdetail && pairdetail.map((item, i) => {
                                            if(item.second_currency == selectedcurrency)
                                            {
                                                return (<tr>
                                                    <td>{item.first_currency + "/" + item.second_currency}</td>
                                                    <td>{parseFloat(item.last).toFixed(2)}</td>
                                                    <td className="greenText">{parseFloat(item.change).toFixed(2)}%</td>
                                                    {
                                                        item.botstatus != 'off' && <td>{item.last <= 0 ? "0.00" : parseFloat(item.volume).toFixed(2) + " " + item.first_currency}</td>
                                                    }
                                                    {item.botstatus != "off" &&
                                                        <td>{item.secvolume <= 0 ? "0.00" : parseFloat(item.secvolume).toFixed(2) + " " + item.first_currency}</td>
                                                    }
                                                    <td>
                                                        <a href={"spot/" + item.first_currency + "-" + item.second_currency} className="btn btnMarketBuy">{t("BUY")}</a>
                                                        <a href={"spot/" + item.first_currency + "-" + item.second_currency} className="btn btnMarketSell">{t("SELL")}</a>
                                                    </td>
                                                </tr>)

                                            }
                                        })}

                                    </tbody>
                                </Table>
                            </Tab>
                        </Tabs>
                        {/* <Table hover bordered responsive variant="dark">
                            <thead>
                                <tr>
                                <th>Pair</th>
                                <th>Spot price</th>
                                <th>Last Price</th>
                                <th>24H Change</th>
                                <th>24H Volume</th>
                                </tr>
                            </thead>
                            <tbody> */}
                        {/* {this.state.pairdetails && this.state.pairdetails.map((item, i) => {
                              if (item.second_currency == this.state.selectedcurrency) {
                                return(
                                  <tr>
                                    <td>{item.first_currency + "/" + item.second_currency}</td>
                                    <td>{parseFloat(item.last).toFixed(2)}</td>
                                    <td className="greenText">{parseFloat(item.change).toFixed(2)}%</td>
                                    {item.botstatus == "off" &&
                                      <td>{item.last <= 0 ? "0.00" : parseFloat(item.volume).toFixed(2) + " " + item.first_currency}</td>
                                    }
                                    {item.botstatus != "off" &&
                                      <td>{item.secvolume <= 0 ? "0.00" : parseFloat(item.secvolume).toFixed(2) + " " + item.first_currency}</td>
                                    }
                                    <td>
                                      <a href={"/spot/" + item.first_currency + "-" + item.second_currency} className="btn btnMarketBuy">Buy</a>
                                      <a href={"/spot/" + item.first_currency + "-" + item.second_currency} className="btn btnMarketSell">Sell</a>
                                    </td>
                                  </tr>)
                              }
                            })} */}
                        {/* </tbody>
                        </Table> */}
                    </div>
                    <div className='startearning'>
                        <p>{cmsFilter("landing_banner_34")}</p>
                       <button><Link to='/spot/ETH-USDT' >{cmsFilter("landing_banner_36")}</Link></button> 
                        
                    </div>
                </div>
            </div>
            <div className='tradeplatform px-lg-0 px-4'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-md-6 d-md-block d-none'>
                            <div className='circleimg'>
                                <img src={circle} />
                                {/* <img src={url + "cms_images/" + cmsFilterImage("landing_banner_image_01")} />   */}
                                <p className='c1'>12+ <br /> {cmsFilter("landing_banner_37")}</p>
                                <p className='c2'>{cmsFilter("landing_banner_38")}</p>
                                <p className='c3'>{cmsFilter("landing_banner_39")}</p>
                                <p className='c4'>${cmsFilter("landing_banner_40")}</p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div>
                                <h4 className='bighead text_white_head'>{cmsFilter("landing_banner_35")}</h4>
                                <p>{cmsFilter("landing_banner_42")}</p>
                            </div>
                            <div className='d-flex mb-3 align-items-center'><img src={img1} className="mr-2" /><p className='mb-0'>{cmsFilter("landing_banner_43")}</p></div>
                            <div className='d-flex mb-3 align-items-center'><img src={img2} className="mr-2" /><p className='mb-0'>{cmsFilter("landing_banner_44")}</p></div>
                            <div className='d-flex mb-3 align-items-center'><img src={img3} className="mr-2" /><p className='mb-0'>{cmsFilter("landing_banner_45")}</p></div>
                            <div className='d-flex mb-3 align-items-center'><img src={img4} className="mr-2" /><p className='mb-0'>{cmsFilter("landing_banner_46")}</p></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='themecards my-5 px-lg-0 px-4'>
                <div className='container container-fluid'>
                    <div className='heading text-center pb-4 mb-5'>
                        <h2 className='bighead'>{cmsFilter("landing_banner_05")}</h2>
                        <p>{cmsFilter("landing_banner_06")}</p>
                    </div>
                    <div className='row img_card_row_resize'>
                        <div className='col-md-4 mb-md-3 mb-5'>
                            <div className='cards'>
                                <div className='img'>
                                    {/* <img src={img5} /> */}
                                    <img src={url + "cms_images/" + cmsFilterImage("landing_banner_image_03")} />
                                    </div>
                                <h6 className='my-3'>{cmsFilter("landing_banner_07")}</h6>
                                <p>{cmsFilter("landing_banner_08")}</p>
                            </div>
                        </div>
                        <div className='col-md-4 mb-md-3 mb-5'>
                            <div className='cards'>
                                <div className='img'>
                                    {/* <img src={img6} /> */}
                                    <img src={url + "cms_images/" + cmsFilterImage("landing_banner_image_03")} />
                                    </div>
                                <h6 className='my-3'>{cmsFilter("landing_banner_09")}</h6>
                                <p>{cmsFilter("landing_banner_10")}</p>
                            </div>
                        </div>
                        <div className='col-md-4 mb-md-3 mb-5'>
                            <div className='cards'>
                                <div className='img'> 
                                {/* <img src={img7} /> */}
                                <img src={url + "cms_images/" + cmsFilterImage("landing_banner_image_03")} />
                                </div>
                                <h6 className='my-3'>{cmsFilter("landing_banner_11")}</h6>
                                <p>{cmsFilter("landing_banner_12")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='tradewithexchange py-5 '>
                <div className='container-theme container mx-lg-5 mx-3'>
                    <div className='row align-items-center'>
                        <div className='col-md-5'>
                            <div>
                                <p>{cmsFilter("landing_banner_13")}</p>
                                <h2 className='bighead text_white_head'>{t("TRADING_WITH")} <br />{t("BELL_EXCHANGE")} </h2>
                                <p>{cmsFilter("landing_banner_14")}</p>
                                <div className='d-flex'>
                                    <img src={gplay} />
                                    <img src={appstore} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-7'>
                            <div className='tradebg img_trade_align'>
                                {/* <img src={tradebg} /> */}
                                 <img src={url + "cms_images/" + cmsFilterImage("landing_banner_image_03")} className="img_trade" />
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div className='themecards my-5 px-lg-0 px-4'>
                <div className='container container-fluid'>
                    <div className='heading text-center pb-4 mb-5'>
                        <h2 className='bighead'>{cmsFilter("landing_banner_15")}</h2>
                    <p>{cmsFilter("landing_banner_16")}</p>
                    </div>
                    <div className='row img_card_row_resize'>
                        <div className='col-md-4 mb-md-3 mb-5'>
                            <div className='cards'>
                                <div className='img'>
                                    {/* <img src={img8} /> */}
                                    <img src={url + "cms_images/" + cmsFilterImage("landing_banner_image_03")} />
                                    </div>
                                <h6 className='my-3'>{cmsFilter("landing_banner_17")}</h6>
                                <p>{cmsFilter("landing_banner_18")}</p>
                            </div>
                        </div>
                        <div className='col-md-4 mb-md-3 mb-5'>
                            <div className='cards'>
                                <div className='img'>
                                    {/* <img src={img9} /> */}
                                    <img src={url + "cms_images/" + cmsFilterImage("landing_banner_image_03")} />
                                    </div>
                                <h6 className='my-3'>{cmsFilter("landing_banner_19")}</h6>
                                <p>{cmsFilter("landing_banner_20")}</p>
                            </div>
                        </div>
                        <div className='col-md-4 mb-md-3'>
                            <div className='cards'>
                                <div className='img'> 
                                {/* <img src={img10} /> */}
                                <img src={url + "cms_images/" + cmsFilterImage("landing_banner_image_03")} />
                                </div>
                                <h6 className='my-3'>{cmsFilter("landing_banner_21")}</h6>
                                <p>{cmsFilter("landing_banner_22")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
        </> : <></>
        
    )
}
// const mapStateToProps = state => ({
//     auth: state.auth,
//   });

// export default connect(
//     mapStateToProps
// )(Landing);
export default Landing