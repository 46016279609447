import {
    SET_CURRENT_USER,
    USER_ADD,
    USER_LOADING,
    USER_UPDATE,
    USER_REGISTER,
    USER_FORGOT,
    CONTACT,
    TWO_FA_UPDATE,
    SUPPORT,
    SUPPORT_REPLY,
    TRADE,
    ORDER_HISTORY,
    TRADE_HISTORY,
    DYN,
    GET_USER,
    CONTACT_ADD,
    Lauchpad_ADD,
    APPLY_TOKEN,
    THEME_CHANGER,
    LANGUAGE_CHANGER
} from "../actions/types";


const isEmpty = require("is-empty");
const initialState = {
    isAuthenticated: false,
    user: {},
    loading: false,
    theme: "",
    language: ""
};

export default function (state = initialState, action) {
    switch (action.type) {
        case USER_REGISTER:
            return {
                ...state,
                newuser: action.payload
            };
        case GET_USER:
            return {
                ...state,
                getUser: action.payload,
                isAuthenticated: !isEmpty(action.payload),

            };
        case USER_UPDATE:
            return {
                ...state,
                updateprofile: action.payload,
            };
        case TWO_FA_UPDATE:
            return {
                ...state,
                update2fa: action.payload,
            };
        case USER_FORGOT:
            return {
                ...state,
                forgotuser: action.payload,
            };
        case CONTACT:
            /* //console.log(action.payload,'action.payload');
             if(typeof action.payload!= 'undefined'){*/
            return {
                ...state,
                contact_us: action.payload,
            };
        case TRADE:
            //console.log(action.payload,'action.payload');
            return {
                ...state,
                trade: action.payload,
            };
        case DYN:
            //console.log(action.payload,'action.payload');
            return {
                ...state,
                dyn: action.payload
            };
        case ORDER_HISTORY:
            // //console.log(action.payload,'action.payload');
            return {
                ...state,
                ordertrade: action.payload,
            };
        case TRADE_HISTORY:
            // //console.log(action.payload,'action.payload');
            return {
                ...state,
                historytrade: action.payload,
            };
        /* }*/
        case SUPPORT:
            return {
                ...state,
                support: action.payload,
            };
        case SUPPORT_REPLY:
            return {
                ...state,
                supportreply: action.payload,
            };
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            };
        case USER_LOADING:
            return {
                ...state,
                loading: true
            };
        case CONTACT_ADD:
            return {
                ...state,
                Contactus: action.payload,
            };
        case Lauchpad_ADD:
            return {
                ...state,
                Lauchpadadd: action.payload,
            };

        case APPLY_TOKEN:
            return {
                ...state,
                APPLY_TOKEN: action.payload,
            };
        case THEME_CHANGER:
            return {
                ...state,
                theme: action.payload
            };
        case LANGUAGE_CHANGER:
            return {
                ...state,
                language: action.payload
            };
        default:
            return state;
    }
}
