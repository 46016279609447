import React, { Component } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import ReactDOM from 'react-dom';
import { store } from "react-notifications-component";
import axios from "axios";
import {Link,withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as moment from "moment";
import { withTranslation } from 'react-i18next';
import blogthumb1 from "../images/blogthumb1.jpg"
import blogthumb2 from "../images/blogthumb2.jpg"
import blogthumb3 from "../images/blogthumb3.jpg"
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from "react-html-parser";

import keys from "../actions/config";
const url = keys.baseUrl;
const imageUrl=keys.imageUrl;

class BlogCategoryListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: [],
      userselectcategoryid: "",
      subcategory: [],
      subcategory1: [],
      articledata: [],
      recentdata:[]
    };
  }
  componentDidMount() {
    this.getData();
    this.getsep()
  }
  getsep(){
    const catid = this.props.match.params.id;
    // //console.log("catidd",catid);
    axios
      .get(url + "api/showcategory/"+catid)
      .then(category => {
        // //console.log("vategorywirse", category.data);
        if(category.data[0]){
          this.setState({articledata:category.data})
        }

      })
      .catch();
  }

  getData() {
    const catid = this.props.match.params.id;

    axios
      .post(url + "api/category-getshow/"+catid)
      .then(category => {
        //console.log("categoryrrs", category.data);
        this.setState({ catname: category.data.categoryName });
        // this.setState({ userselectcategoryid: category.data[0]._id });
      })
      .catch();


      axios
        .post(url + "api/recentblog-data-limit")
        .then(articles => {
          //console.log("Article data in ssssssss ", articles);
          if (articles.data != undefined) {
            this.setState({recentdata: articles.data });
          }
        })
        .catch();

        axios
          .get(url + "api/category")
          .then(category => {
            //console.log("da in sub", category.data);
            if(category.data[0]){
              this.setState({ category: category.data });
              this.setState({ userselectcategoryid: category.data[0]._id });
            }

          })
          .catch();

  }

  render() {
    const { user } = this.props.auth;
    const {
      category,
      articledata,
      recentdata,
      userselectcategoryid
    } = this.state;
    const {t} = this.props;
    return (
<div className='bg_strip'>
  <section className="blogMainContent">
    <section class="headerCrediantials">
          <Navbar />
        </section>
          <div className="container">
            <div className="row mt25">
              <div className="col-xl-9">
              <section className="categoryBlogList">
                <div className="categoryTitle clearfix">
                  <h2 className="float-left">{this.state.catname}</h2>
                </div>

                {articledata.map((itemarticle, i) => {

                    //console.log("itemarticlsss",itemarticle);
                    var fromcontent= itemarticle.content
                    var artcontent=fromcontent.split(" ").splice(0,10).join(" ");
                  return (

                <article className="categoryListPostBox">
                    <div className="row">
                      <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-12 d-none d-sm-block">
                        <div className="blogListImgThumb">
                          <a href={
                            "/BlogDetaile/" + itemarticle._id
                          }
                        >
                        {itemarticle.article_image!=null?
                            <img src={imageUrl + "help_images/" +itemarticle.article_image } className="img-fluid blogthumbimage" />
                          :
                              <img src={blogthumb1} className="img-fluid blogthumbimage" />

                        }

                        </a>
                        </div>
                      </div>
                      <div className="col-xl-9 col-lg-8 col-md-8 col-sm-8 col-12">
                        <div className="postDescriptionSection">
                          <span className="dateText">  {moment(itemarticle.createdDate).format(
                              "DD-MM-YYYY  h:mm a "
                            )}</span>
                          <h4><a href={
                            "/BlogDetaile/" + itemarticle._id
                          }>{itemarticle.Articlename}</a></h4>
                          <p>
                              {ReactHtmlParser(artcontent)}</p>
                          <div className="blogListImgThumb d-block d-sm-none mb-3">
                          <a href={
                            "/BlogDetaile/" + itemarticle._id
                          }><img src={blogthumb1} className="img-fluid" /></a>
                        </div>
                        </div>
                      </div>
                    </div>
                </article>
              )
            })}
              </section>
              </div>


                            <div className="col-xl-3">
                              <div className="blogRightSideBar">
                              {recentdata.length>0?(
                                <aside className="sideBarBox recentBlog">
                                    <h3>{t("CATEGORIES")}</h3>
                                      {category.map((itemrecentdata, i) => {
                                        //console.log("itemrecentdata",itemrecentdata);
                                        return(
                                    <ul>
                                    <li><a href={
                                      "/BlogCategorys/" + itemrecentdata._id
                                    }>{itemrecentdata.categoryName}</a></li>
                                    </ul>
                                  )})}


                                </aside>
                              ):(
                              ""
                              )
                            }

                              </div>
                            </div>

            </div>
          </div>
        </section>
  <Footer />
</div>
);
  }
}

BlogCategoryListing.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps
)(withTranslation()(BlogCategoryListing),withRouter(BlogCategoryListing));
