import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import {Accordion, Card, Button} from "react-bootstrap";
import Navbar from './Navbar'
import Footer from './Footer'
import axios from "axios";
import keys from "../actions/config";
import { withTranslation } from 'react-i18next';
import Navtradebar from './Navtradebar';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';


const url = keys.baseUrl;

class Faq extends Component {
  constructor(props) {
        super(props);
        this.state = {
            question:"",
            answer:"",
            faq:[],
            errors: {},
            minusIcon:false
        };
        //console.log(this.state,'state');
        }

        componentDidMount() {
    window.scrollTo(0, 0);

            this.getData()
        };


        setMinusicon(e)
        {
          // console.log(e.target,"evbemt");
          const minusiconall = document.getElementsByClassName("plus_icon_all");
          for(var x=0;x<minusiconall.length;x++)
          {
            console.log(minusiconall[x],e.target,"ele")
            if(e.target != minusiconall[x])
            {
              // console.log(1);
              minusiconall[x].classList.remove("toggle_icon")

            }
            else
            {
              // console.log(2);
              e.target.classList.toggle("toggle_icon");

            }
          }
          // e.target
        }
    getData() {
           axios
           .get(url+"cryptoapi/faq")
             .then(res => {
             //console.log(res.data,'ressssssdata');
                   this.setState({faq:res.data});
             })
            .catch()
            ////console.log(this.state.faq,'this.setState');
    }

	render() {
    const { t } = this.props;
    { console.log('faq_props_props',this.props)}
		return (<div className='bg_strip faq'>
			<section class="headerCrediantials">
          {/* <Navbar /> */}
          <Navtradebar />
        </section>
				<section className="innerCMS">
        {this.state.faq?
  <div className="container">
    <div className="row pt-5">
      <div className="col-md-10 mx-auto">
        <div className="darkBox contentPage">
          <div className="tableHead tableHeadBlock">
            <h2>{t("FAQ")}</h2>
          </div>
          <div className="darkBoxSpace">
            <div className="faqCont">
          <div className="homeAccordian wow fadeIn accrdin_faq">
     
          <Accordion>
          {this.state.faq.map((answer, i) => {

                 return ( 
  <Card key={i}>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey={i+""} className="accodin_btn_link" onClick={(e)=>this.setMinusicon(e)}>
        <span>
        {i+1}.{answer.question}

        </span>
        {/* {this.state.minusIcon? */}
        <i className="fa fa-plus plus_icon_all" aria-hidden="true"></i>
        {/* : */}
        {/* <i className="fa fa-plus" aria-hidden="true"></i>
        } */}
      
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey={i+""}>
      <Card.Body>{ReactHtmlParser(answer.answer)}</Card.Body>
    </Accordion.Collapse>
  </Card>
)
})}
</Accordion>

              {/* <div id="accordion">
               {this.state.faq.map((answer, i) => {
                  var view = (i==0)?'show':'';
                  // var expand = (i==0)?'true':'false';
                  var btn = (i==0)?'btn btn-link':'btn btn-link collapsed';
           return (  <div className="card">
                  <div className="card-header wow flipInX" id={"heading"+i}>
                    <h5 className="mb-0">
                      <button className={btn} data-toggle="collapse" data-target={"#collapse"+i}  aria-controls={"collapse"+i}><span className="question">{i+1}.{answer.question}</span> <i className="fa fa-plus" aria-hidden="true"></i></button>
                    </h5>
                  </div>

                  <div id={"collapse"+i} className={"collapse"+view} aria-labelledby={"heading"+i} data-parent="#accordion">
                    <div className="card-body">
                      <p>{answer.answer}</p>
                    </div>
                  </div>
                </div>)
             })}
              </div> */}


            </div>
        </div>
          </div>
        </div>
      </div>
    </div>
  </div>
   :''}
</section>
			<Footer />
		</div>
		);
	}
}

// export default (withTranslation()(Faq),Faq)
export default connect()(withTranslation()(Faq),withRouter(Faq));