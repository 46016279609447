import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import TradeHeader from './TradeHeader'
import PropTypes from "prop-types";
import {CopyToClipboard} from 'react-copy-to-clipboard';


import { connect } from "react-redux";
import {withRouter} from 'react-router-dom';
import { getTableDataDynamic } from "../actions/authActions";
import keys from "../actions/config";



import Footer from './Footer'
import ReferralImg from "../images/referral.png"
import ReferIcon1 from "../images/referIcon1.png"
import ReferIcon2 from "../images/referIcon2.png"
import ReferIcon3 from "../images/socialIcon1.jpg"
import ReferIcon4 from "../images/referIcon4.png"
import ReferIcon5 from "../images/referIcon5.png"
import ReferIcon6 from "../images/referIcon6.png"
import ReferIcon7 from "../images/referIcon7.png"

import axios from "axios";

const url = keys.baseUrl;
const refurl = keys.refurl;




class Referral extends Component {

	constructor(props) {
		super(props);

		this.state = {
			user:{},
			referraldata:[],
			referencecode_link:'',
			value: '',
			settings: '',
			social_link1: '',
			social_link3: '',
			social_link4: '',
			referralcontent: '',
			bonus: 0,
			bonus_balance: 0,
			copied: false,
		}
		this.onCopy = this.onCopy.bind(this);
	}



	componentWillReceiveProps(nextProps) {
		
		if (nextProps.errors) {
		  this.setState({
			errors: nextProps.errors
		  });
		}
		else
		{
		  this.setState({
			errors: {}
		  });
		}
		console.log(nextProps,'nextProps');
		if(typeof nextProps.auth!='undefined'){
			if(typeof nextProps.auth.trade!='undefined'){
				if(typeof nextProps.auth.trade.data!='undefined'){
					console.log(nextProps.auth.trade.data,'nextProps.auth.trade.data.loginuserdata')
					if(typeof nextProps.auth.trade.data.loginuserdata!='undefined'){
						if(typeof nextProps.auth.trade.data.loginuserdata.referencecode!='undefined'){
							var loginuserdata = nextProps.auth.trade.data.loginuserdata;
							console.log('loginuserdata----------');
							console.log(loginuserdata);
							var link =refurl+"register?ref="+loginuserdata.referencecode;
							this.setState({
								user: loginuserdata,
								referencecode_link:link
							});
						}
					}

					if(typeof nextProps.auth.trade.data.referraldata!='undefined'){
						var referraldata = nextProps.auth.trade.data.referraldata;
						//console.log('referraldata----------');
						//console.log(referraldata);
						this.setState({
							referraldata: referraldata
						});
					}
				}
			}
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.getUser();
		this.getsettingData();
		this.getbonusData();
		this.getbalanceData();
		this.getcmsData();
	};



	getUser() {
		this.setState({user: this.props.auth.user});

		let userid = this.props.auth.user.id;
		var dynobj = {}
		dynobj.find = {};
		dynobj.find._id = userid;
		dynobj.table = {};
		dynobj.table.name = 'users';
		dynobj.return = {};
		dynobj.return.name = 'loginuserdata';
		this.props.getTableDataDynamic(dynobj);


		var dynobj = {}
		dynobj.find = {};
		dynobj.find.referaluserid= userid;
		dynobj.table = {};
		dynobj.table.name = 'users';
		dynobj.return = {};
		dynobj.return.name = 'referraldata';
		this.props.getTableDataDynamic(dynobj);
	}
	 getcmsData() {
          axios
          .get(url+"cryptoapi/getcms/referral")
           .then(res => {

             this.setState({referralcontent:res.data.content});
            })
           .catch()
           //console.log(this.setState,'this.setState');
   }
	getsettingData() {
					axios
					.get(url+"cryptoapi/contact")
					 .then(res => {
						 this.setState({settings:res,social_link1:res.data.social_link1,social_link4:res.data.social_link4,social_link3:res.data.social_link3});
						})
					 .catch()
	 }
	 getbonusData() {
          axios
          .get(url+"cryptoapi/getbonusdetails")
           .then(res => {
            if(res.data.status)
            {
             this.setState({bonus:res.data.data.firstlevel});
            }
            })
           .catch()
   }
   getbalanceData() {
   	let userid = this.props.auth.user.id;
          axios
          .get(url+"cryptoapi/getbonusbalance/"+userid)
           .then(res => {
            if(res.data.status)
            {
             // this.setState({bonus_balance:res.data.data.tempcurrency});
						 this.setState({bonus_balance:0});

            }
            })
           .catch()
   }
    createMarkup = () => {
  return { __html: this.state.referralcontent };
  }



	renderTableData() {
		if(this.state.referraldata.length>0)
		{
			return this.state.referraldata.map((details, index) => {
				const {  email ,date} = details //destructuring
				return (
				   <tr>
					  <td>{email}</td>
					  <td>{date}</td>
				   </tr>
				)
			 }
			 )
		}
		else
		{
			return <tr>
					  <td colSpan={2} className="text-center">No records found</td>
					 
				   </tr>
		}
		
	 }

	 async onCopy() {
		this.setState({
		  copied: true
		});
	
		setTimeout(function () {
		  this.setState({
			copied: false
		  });
		}.bind(this), 1000)
	
	  }

  render() {
	const { referraldata } = this.state

    return (
			<div  className='bg_strip'>
				<TradeHeader />
				<div className="container-fluid">
				<section className="tradeMain pb-5">
				  <div className="row mt-5">
				    <div className="col-xl-8 col-12 mx-auto">
				      
				      <div className="d-flex flex-column align-items-center kyc-header pt-5 px-2">
				        <div className="tableHead tableHeadBlock">
				          <h2 className='mt-0'>Referral  Program</h2>
				        </div>
				        <div className="darkBoxSpace px-0 pt-3">
				          <form name="supportTicket" method="">
				            <div className="row mx-0 w-100">
				              <div className="col-md-12 col-sm-12">
				                <div className="form-group">
				                  <label>Referral Code</label>
				                  <input name="" className="form-control text-center" placeholder="" value={this.state.user.referencecode} type="text" id="myWalletAddress" />
				                </div>
				              </div>
				              <div className="col-md-12 col-sm-12">
				                <div className="form-group input-group referralLinkBox">
				                    <label>Referral Link</label>
				                    <input name="" className="form-control" placeholder="" value={this.state.referencecode_link} type="text" />
				                    <div className="input-group-append">
				                        {/* <input className="btn buttonType1 px-4" type="button" value="Copy Link" /> */}
										<CopyToClipboard text={this.state.referencecode_link}
          >
          <button type="button" onClick={this.onCopy} className="btn buttonType1 px-4">Copy Link</button>
        </CopyToClipboard>
				                    </div>
		

				                </div>
								{this.state.copied && 
		<p className="copytxt d-block text-right" style={{ 'color': '#49a4de' }}>Copied</p>
		 }
				              </div>
				              <div className="col-md-12">
				                <div className="referralSocial">
				                  <h6>Refer from our social media links</h6>
				                  <ul>
				                    <li><a href="https://www.facebook.com/bitbaazi"><img src={ReferIcon1} /></a></li>
				                    <li><a href="https://twitter.com/bitbaazi"><img src={ReferIcon2} /></a></li>
				                  </ul>
				                </div>
				              </div>
				              <div className="col-md-12 col-sm-12">
				                <div className="form-group">
				                  <label>Registered Referrals</label>
				                  <input type="text" name="" className="form-control" value={referraldata.length} />
				                </div>
				              </div>

				              {/*}<div className="col-md-4 col-sm-4">
				                <div className="form-group inputWithText">
				                      <label>Balance</label>
				                      <input name="" className="form-control" placeholder="" value={parseFloat(this.state.bonus_balance).toFixed(8)} type="text" />

				                  </div>
				              </div>*/}
				            <div className="col-md-12">
				                <div className="form-group">

				                </div>
				              </div>
				            </div>
				          </form>
				          <div className="tableHead">
				            <h3 className="">Referral  History</h3>
				          </div>
				          <div className="table-responsive">
				            <table id="assetsTable" className="table">
				              <thead>
				                  <tr className="wow flipInX" data-wow-delay=".5s;">
				                      <th>Mobile/Email</th>
				                      <th>Registration Time</th>

				                  </tr>
				              </thead>
				             <tbody>
							{this.renderTableData()}


				               {/* <tr><td colspan="4" height="150" className="text-center">- You have no created  payment codes -</td></tr> */}
				             </tbody>
				          </table>
				          </div>
				         {/* <div className="noteText">
				            <h3 className="ml-0">Referral Program</h3>
				            <div dangerouslySetInnerHTML={this.createMarkup()} className='editor'></div>
				          </div> */}
				        </div>
				      </div>
				    </div>
				  </div>
				</section>
				</div>
				<Footer />
			</div>
			);
    }
}

Referral.propTypes = {
	getTableDataDynamic: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
	mapStateToProps,
	{
		getTableDataDynamic
	}
)(withRouter(Referral));
