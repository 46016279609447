import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import AboutImg from "../images/aboutus.png";
import keys from "../actions/config";
import axios from "axios";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

const url = keys.baseUrl;

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: [],
      userselectcategoryid: "",
      subcategory: [],
      subcategory1: [],
      articledata: [],
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);

    this.getData();
  }

  getData() {
    axios
      .get(url + "api/helpcentrecategory")
      .then(category => {
      if(category.data[0]){
        //console.log("da in sub", category.data);
        this.setState({ category: category.data });
        this.setState({ userselectcategoryid: category.data[0]._id });
        }
      })
      .catch();

    axios
      .get(url + "api/sub-category")
      .then(subcategory => {
        //console.log("subcategory in subcategory", subcategory.data);
        this.setState({ subcategory: subcategory.data });
      })
      .catch();

    axios
      .post(url + "api/helpcentrearticle-data")
      .then(articles => {
        //console.log("Article data in ssssssss ", articles);
        if (articles.data != undefined) {
          this.setState({ articledata: articles.data });
        }
      })
      .catch();
  }
  categoryselect = event => {
    var userselectcategoryid1 = event.currentTarget.dataset.item;
    //console.log("userselectcategoryid", userselectcategoryid1);
    this.setState({ userselectcategoryid: userselectcategoryid1 });
  };

  render() {
    const {
      category,
      articledata,
      subcategory,
      userselectcategoryid
    } = this.state;
    return (
      <div className='bg_strip'>
        <Navbar />
        <section className="innerCMS">
          <div className="container">
            <div className="row pt-5">
              <div className="col-md-12 mx-auto">
                {/* <img src={AboutImg} className="img-fluid cmsTopImg mt-1" /> */}
                <div className="darkBox contentPage">
                  <div className="tableHead tableHeadBlock helpMainTab">
                    <nav>
                      <div
                        className="nav nav-tabs"
                        id="navLink-tab"
                        role="tablist"
                      >
                        {
                          (category.length>0)?
                          category.map((item, i) => {
                          var categoryname = item.categoryName;
                          return (
                            <a
                              href="#"
                              className="nav-item nav-link"
                              data-item={item._id}
                              onClick={this.categoryselect}
                            >
                              {categoryname}
                            </a>
                          );
                        }):''
                      }
                      </div>
                    </nav>
                  </div>

                  <div className="darkBoxSpace helpPage">
                    <div className="wow fadeIn">
                      {
                        subcategory.map((item, i) => {
                        if (
                          item.maincategoryId._id.toString() ==
                          userselectcategoryid.toString()
                        ) {
                          var subcategoryname = item.subcategoryName;
                          var subcategory_id = item._id;

                          return (
                            <div id={item._id}>
                              <div className="card">
                                <div
                                  className="card-header wow flipInX"
                                  id="headingOne"
                                >
                                  <h5 className="mb-0">
                                    <button
                                      className="btn btn-link"
                                      data-toggle="collapse"
                                      data-target={"#collapseOne" + item._id}
                                      aria-expanded="false"
                                      aria-controls={"collapseOne" + item._id}
                                    >
                                      <span className="question">
                                        {subcategoryname}
                                      </span>

                                      <i
                                        className="fa fa-plus"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </h5>
                                </div>

                                <div
                                  id={"collapseOne" + item._id}
                                  className="collapse"
                                  aria-labelledby="headingOne"
                                  data-parent={"#" + item._id}
                                >
                                  <div className="card-body">
                                    <ul className="helpList">
                                     {
                                      (articledata.length>0)?
                                      articledata.map((item_art, i_art) => {
                                        if (
                                          item_art.subcategoryId._id.toString() ==
                                          subcategory_id.toString()
                                        ) {
                                          var Articlename =
                                            item_art.Articlename;
                                          return (
                                            <li>
                                              <a
                                                href={
                                                  "/helpdetails/" + item_art._id
                                                }
                                              >
                                                {Articlename}
                                              </a>
                                            </li>
                                          );
                                        }
                                      })
                                      :''
                                    }
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default About;
