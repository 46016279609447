import React, { Component } from 'react'

import { Link } from 'react-scroll';

class Navbar extends Component {
  render() {
    return (<div>
             <nav className="stackNavbar">
              <ul>
                <li><Link to="Flexible" spy={true} smooth={true} duration={250} offset={-180}>Flexible</Link></li>
                <li><Link to="Fixed" spy={true} smooth={true} duration={250} offset={-180}>Fixed</Link></li>
              {/*   <li><Link to="DualInvestment" spy={true} smooth={true} duration={250} offset={0}>Dual Inverstments</Link></li>*/}
              </ul>
            </nav>
          </div>
);
  }
}

export default Navbar