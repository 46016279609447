import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { store } from "react-notifications-component";

import TradeHeader from "./TradeHeader";
import Footer from "./Footer";
import doubleArrow from "../images/doubleArrow.png";
import { withTranslation } from "react-i18next";

import {
  getAssetExchangeData,
  getCurrencyData,
  getAssetData,
  LivePricedata,
  convertnow,
  getAssetExchangeHistory
} from "../actions/authActions";

const menuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  }
}

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: "white",
    backgroundColor: "#000",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: '52px',
    padding: '0 6px',
    backgroundColor: "#03081f",
    borderColor: '#81c8f6',
  borderRadius: 10,
  borderStyle: 'solid',
  borderWidth: '1px'
   
  }),
  control: (provided, state) => ({
    ...provided,
    height: '52px',
    borderRadius:10,
    backgroundColor: "#03081f",
    border:'none'
   
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '52px',
    position: 'absolute',
    right: 0,
    top: 0,
    color:'#fff' 
  }),    
  singleValue: (provided, state) => ({
    ...provided,
    color: "#fff"
  })
}


class AssetsExchange extends Component {
  constructor(props) {
    super(props);

    this.state = {
      AssetsExchangedata: {},
      AssetHistory: [],
      pasthistory: [],
      inputamount: "",
      receivingamount: 0,
      multipleamount: "",
      LivePrice: 0,
      CurrencyTableData: [],
      CurrencyTableData_optionsfrom: [],
      CurrencyTableData_optionsto: [],
      selectedvalue: "",
      selectedNumber: "",
      AssetTableData: [],
      from: "",
      to: "",
      balanceamount: { balance: 0 },
      pairdetails: {
        pair: "",
        first_currency: "",
        second_currency: "",
        single_min_limit: "--",
        single_max_limit: "--",
        full_min_limit: "--",
        full_max_limit: "--",
        trade_fee: "--"
      }
    };
    // this.onNumbersChange = this.onNumbersChange.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onNumbersChangefromto = selectedOption => {
    var from_currency = this.state.CurrencyTableData_optionsfrom;
    var to_currency = this.state.CurrencyTableData_optionsto;
    var AssetTableData = this.state.AssetTableData;

    if (selectedOption.place == "from") {
      var cur_from = selectedOption.value;
      var cur_to = this.state.to;
      var index = AssetTableData.findIndex(
        x => x.currencySymbol.toLowerCase() === cur_from.toLowerCase()
      );
      if (index != -1) {
        this.setState({ balanceamount: AssetTableData[index] });
      } else {
        this.setState({ balanceamount: { balance: 0 } });
      }
    } else if (selectedOption.place == "to") {
      var cur_from = this.state.from;
      var cur_to = selectedOption.value;
    }

    from_currency.map((item, i) => {
      if (cur_to.toLowerCase() == from_currency[i].value.toLowerCase()) {
        from_currency[i].disabled = "yes";
      } else {
        from_currency[i].disabled = "no";
      }
    });

    to_currency.map((item, i) => {
      if (cur_from.toLowerCase() == to_currency[i].value.toLowerCase()) {
        to_currency[i].disabled = "yes";
      } else {
        to_currency[i].disabled = "no";
      }
    });
    this.setState({
      CurrencyTableData_optionsfrom: from_currency,
      CurrencyTableData_optionsto: to_currency,
      from: cur_from,
      to: cur_to
    });
    this.getpairdetail(selectedOption.place, selectedOption.value);
  };

  getapidetails(from, to) {
    let userid = this.props.auth.user.id;
    var dynobj = {};
    dynobj.find = {};
    dynobj.find.from = from;
    dynobj.find.to = to;
    dynobj.return = {};
    dynobj.return.name = "LivePriceData";
    this.props.LivePricedata(dynobj);
  }

  getpairdetail(fromorto, value) {
    var from = this.state.from;
    var to = this.state.to;

    if (fromorto == "from") {
      from = value;
    } else if (fromorto == "to") {
      to = value;
    }

    var pair = from + to;
    var AssetsExchangedata = this.state.AssetsExchangedata;
    var index = AssetsExchangedata.findIndex(
      x => x.pair.toLowerCase() === pair.toLowerCase()
    );

    if (index != -1) {
      this.setState({ pairdetails: AssetsExchangedata[index] });
      this.getapidetails(from, to);
    } else {
      var empty = {
        pair: "",
        first_currency: from,
        second_currency: to,
        single_min_limit: "--",
        single_max_limit: "--",
        full_min_limit: "--",
        full_max_limit: "--",
        trade_fee: "--"
      };
      this.setState({ pairdetails: empty, LivePrice: 0 });
    }
  }

  amountchange = e => {
    this.setState({ [e.target.name]: parseFloat(e.target.value) }, function() {
      var multipleamount =
        parseFloat(this.state.inputamount) * parseFloat(this.state.LivePrice);
      var finalamount =
        multipleamount - parseFloat(this.state.pairdetails.trade_fee);
      this.setState({
        receivingamount: finalamount
      });
    });
  };

  convertnow = e => {
    const {t} = this.props;
    e.preventDefault();
    //console.log("in the validation ", this.state.pasthistory.length);
    var History = this.state.pasthistory;
    var totaldaylimit = 0;
    for (var i = 0; i < History.length; i++) {
      totaldaylimit += parseFloat(History[i].input_amount);
    }
    //console.log("total", totaldaylimit);
    if (
      this.state.inputamount >= this.state.pairdetails.single_min_limit &&
      this.state.inputamount <= this.state.pairdetails.single_max_limit &&
      // totaldaylimit >= this.state.pairdetails.full_min_limit &&
      totaldaylimit <= this.state.pairdetails.full_max_limit
    ) {
      const newAssetexchange = {
        from_coin: this.state.from,
        to_coin: this.state.to,
        inputamount: this.state.inputamount,
        userid: this.props.auth.user.id,
        receivingamount: parseFloat(this.state.receivingamount).toFixed(8)
      };
      this.props.convertnow(newAssetexchange);
      this.setState({
        receivingamount: 0
    })} else {
      store.addNotification({
        title: t("ERROR"),
        message: t("INPUT_AMOUNT_IS_BELOW"),
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true
        }
      });
    }
  };

  handleChange = selectedOption => {
    this.setState({ first_currency: selectedOption.value });
    this.setState({ selectedvalue: selectedOption.value });
  };
  handleChange1 = selectedOption => {
    this.setState({ second_currency: selectedOption.value });
  };

  componentWillReceiveProps(nextProps) {
    const {t} = this.props;
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    } else {
      this.setState({
        errors: {}
      });
    }
    //console.log(nextProps, "in assetssssexchnage");

    if (typeof nextProps.auth != "undefined") {
      if (typeof nextProps.auth.trade != "undefined") {
        if (typeof nextProps.auth.trade.data != "undefined") {
          if (
            typeof nextProps.auth.trade.data.AssetsExchangedata != "undefined"
          ) {
            var AssetsExchangedata =
              nextProps.auth.trade.data.AssetsExchangedata;

            this.setState({
              AssetsExchangedata: AssetsExchangedata
            });
          }
        }

        if (typeof nextProps.auth.trade.data.CurrencyTableData != "undefined") {
          var CurrencyTableData = nextProps.auth.trade.data.CurrencyTableData;
          var CurrencyTableData_optionsfrom = [];
          var CurrencyTableData_optionsto = [];
          for (var i = 0; i < CurrencyTableData.length; i++) {
            var from_val = {
              place: "from",
              value: CurrencyTableData[i].currencySymbol,
              label: CurrencyTableData[i].currencyName
            };
            var to_val = {
              place: "to",
              value: CurrencyTableData[i].currencySymbol,
              label: CurrencyTableData[i].currencyName
            };
            CurrencyTableData_optionsfrom.push(from_val);
            CurrencyTableData_optionsto.push(to_val);
          }
          this.setState({
            CurrencyTableData: CurrencyTableData,
            CurrencyTableData_optionsfrom: CurrencyTableData_optionsfrom,
            CurrencyTableData_optionsto: CurrencyTableData_optionsto
          });
        }

        if (typeof nextProps.auth.trade.data.AssetTableData != "undefined") {
          var AssetTableData = nextProps.auth.trade.data.AssetTableData;
          this.setState({
            AssetTableData: AssetTableData
          });
        }

        if (typeof nextProps.auth.trade.data != "undefined") {
          var LivePricedata = nextProps.auth.trade.data;
          this.setState({
            LivePrice: LivePricedata.val
          });
        }

        if (typeof nextProps.auth.trade.data.AssetHistory != "undefined") {
          var data = nextProps.auth.trade.data;
          //console.log("Data of 24", data);
          var AssetHistory = nextProps.auth.trade.data.AssetHistory;
          this.setState({
            AssetHistory: AssetHistory
          });
        }

        if (typeof nextProps.auth.trade.data.pasthistory != "undefined") {
          var pasthistory = nextProps.auth.trade.data.pasthistory;
          this.setState({
            pasthistory: pasthistory
          });
        }

        if (typeof nextProps.auth.trade.data.convertnow != "undefined") {
          var convertnow = nextProps.auth.trade.data.convertnow;
          store.addNotification({
            title: t("SUCCESS"),
            message: t("YOUR_EXCHANGE_HAS_BEEN_COMPLETED"),
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1000,
              onScreen: true
            }
          });

          this.getData();
        }

        nextProps.auth.trade.data = "";
      }
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getData();
  }

  getuserbalance(userid) {
    var dynobj = {};
    dynobj.find = {};
    dynobj.find._id = userid;
    dynobj.table = {};
    dynobj.table.name = "AssetsExchange";
    dynobj.return = {};
    dynobj.return.name = "AssetsExchangedata";
    this.props.getAssetExchangeData(dynobj);
  }

  getAssetExchangeHistory(userid) {
    var dynobj = {};
    dynobj.find = {};
    dynobj.find.userId = userid;
    dynobj.table = {};
    dynobj.table.name = "AssetExchangeHistory";
    dynobj.return = {};
    dynobj.return.name = "AssetHistory";
    this.props.getAssetExchangeHistory(dynobj);
  }

  getData() {
    let userid = this.props.auth.user.id;
    this.getuserbalance(userid);
    this.getAssetExchangeHistory(userid);
    var dynobj = {};
    dynobj.find = {};
    // dynobj.find.referaluserid= userid;
    dynobj.table = {};
    dynobj.table.name = "CurrencyTable";
    dynobj.return = {};
    dynobj.return.name = "CurrencyTableData";
    this.props.getCurrencyData(dynobj);

    var dynobj = {};
    dynobj.find = {};
    dynobj.find.userId = userid;
    dynobj.table = {};
    dynobj.table.name = "Asset";
    dynobj.return = {};
    dynobj.return.name = "AssetTableData";
    this.props.getAssetData(dynobj);
  }

  renderTableData() {
    return this.state.AssetHistory.map((details, index) => {
      const {
        time,
        from_coin,
        to_coin,
        input_amount,
        transaction_id,
        receiving_amount
      } = details; //destructuring
      return (
        <tr>
          <td>{time}</td>
          <td>{from_coin}</td>
          <td>{to_coin}</td>
          <td>{input_amount}</td>
          <td>{transaction_id}</td>
          <td>{receiving_amount}</td>
        </tr>
      );
    });
  }

  render() {
    const {
      CurrencyTableData_optionsfrom,
      CurrencyTableData_optionsto,
      selectedvalue,
      selectedNumber
    } = this.state;
const{t} = this.props;
    return (
      <div className="bg_strip">
        <TradeHeader />
        <div className="container pt-5">
          <section className="tradeMain">
            <div className="row mx-0">
              <div className="col-md-12">
                <div className="darkBox assetsTable">
                  <div className="tableHead">
                    <h3>Assets Exchange</h3>
                  </div>
                  <div className="tab-content">
                    <div className="assetExchange mb-3">
                      <div className="row mx-0">
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                          <div className="assetEchangeForm assetEchangeForm_new mx-0">
                            <div className="row mx-0">
                              <div className="col-sm-5 mb-4 mb-sm-0">
                                <div className="form-group">
                                  <label>{t("From Coin")}</label>

                                  <Select
                                  className="border_blue_select_normal_sel"
                                     width='100%'
                                     menuColor='red'
                                     MenuProps={menuProps} isSearchable={false}
                                    options={CurrencyTableData_optionsfrom}
                                    onChange={this.onNumbersChangefromto}
                                    isOptionDisabled={option =>
                                      option.disabled === "yes"
                                    }
                                    styles={customStyles}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-2">
                                <div className="form-group">
                                  <label className="d-none d-sm-block invisible">
                                   
                                  </label>
                                  <div className="changeSelect">
                                    <img
                                      src={doubleArrow}
                                      className="img-fluid"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-5 mb-4 mb-sm-0">
                                <div className="form-group">
                                  <label>{t("TO_COIN")}</label>
                                  <Select
                                  className="border_blue_select_normal_sel"
                                    options={CurrencyTableData_optionsto}
                                    onChange={this.onNumbersChangefromto} isSearchable={false}
                                    styles={customStyles}
                                    width='100%'
                                    menuColor='red'
                                    MenuProps={menuProps}
                                    isOptionDisabled={option =>
                                      option.disabled === "yes"
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row mx-0">
                            <div className="col-sm-5 mb-4 mb-sm-0">
                            <div className="form-group inputWithText">
                              <label>
                              {t("AMOUNT")}{" "}
                                <a href="#" className="float-right rightLink blue_link_hover">
                                {t("ALL_AMOUNT")}
                                </a>
                              </label>
                              <input
                                name="inputamount"
                                className="form-group"
                                placeholder=""
                                type="text"
                                id="inputamount"
                                onChange={this.amountchange}
                              />
                              <div className="input-group-append-icon">
                                <small>
                                  {this.state.pairdetails.first_currency}
                                </small>
                              </div>
                            </div>
                              </div>
                            </div>
                          <div className="row mx-0">
                            <div className="col-12">
                            <div class="mb-4 text-white">
                            {t("SINGLE_LIMIT")} :{" "}
                              <span class="yellowText">
                                {this.state.pairdetails.single_min_limit}/
                                {this.state.pairdetails.single_max_limit}
                              </span>{" "}
                            </div>
                            <div class="mb-4 text-white">
                            {t("24_HOURS_LIMIT")} : {" "}
                              <span class="yellowText">
                                {this.state.pairdetails.full_min_limit}/
                                {this.state.pairdetails.full_max_limit}
                              </span>{" "}
                              |{t("TRADE_FEES")} : {""}
                              <span class="yellowText">
                                {this.state.pairdetails.trade_fee}{" "}
                                {this.state.pairdetails.first_currency}
                              </span>
                            </div>

                            <div className="form-group">
                              <h3 className="assetTitle">
                                <span>
                                {t("RECEIVING_AMOUNT")} {" "}
                                  <small> {t("INCLUDING_FEE")}</small>
                                </span>
                                {parseFloat(this.state.receivingamount).toFixed(
                                  8
                                )}{" "}
                                {this.state.pairdetails.second_currency}{" "}
                                {/* <small>
                                  = 0.00{" "}
                                  {this.state.balanceamount.currencySymbol}
                                </small> */}
                              </h3>
                            </div>
                            <div className="form-group clearfix">
                              <input
                                className="buttonType1 float-left"
                                type="button"
                                name=""
                                value="Convert Now"
                                onClick={this.convertnow}
                              />
                            </div>
                            </div>
                            </div>
                          </div>
                        </div>
                        
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 mt-4 mt-xl-0">
                          <div className="assetExchangeWalletBalance mx-md-3">
                            <p>
                              {this.state.balanceamount.currencySymbol} {t("WALLET_BALANCE")}
                            </p>
                            <h3>
                              {this.state.balanceamount.balance} {""}
                              {this.state.balanceamount.currencySymbol}
                              {/* <small>= 0.00 USD</small> */}
                            </h3>
                            <h6>
                              1 {this.state.balanceamount.currencySymbol} {""}={" "}
                              {this.state.LivePrice
                                ? parseFloat(this.state.LivePrice).toFixed(8)
                                : "0.00000000"}
                              {""}
                              {""}
                              {this.state.pairdetails.second_currency}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mx-0 pb-4">
                      <div className="col-12">
                      <div className="tableHead">
                      <h3>{t("EXCHANGE_HISTORY")}</h3>
                    </div>
                    <div className="table-responsive">
                      <table id="assetsTable" className="table mb-0">
                        <thead>
                          <tr className="wow flipInX" data-wow-delay=".5s;">
                            <th>{t("TIME")}</th>
                            <th>{t("FROM_COIN")}</th>
                            <th>{t("TO_COIN")}</th>
                            <th>{t("AMOUNT_INPUTED")}</th>
                            <th>{t("TRANSACTION_ID")}</th>
                            <th>{t("AMOUNT_RECEIVED")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.renderTableData()}

                          {/* <tr>
                            <td
                              colspan="6"
                              height="150"
                              className="text-center"
                            >
                              - No data Available -
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

AssetsExchange.propTypes = {
  getAssetExchangeData: PropTypes.object.isRequired,
  convertnow: PropTypes.object.isRequired,
  getAssetExchangeHistory: PropTypes.object.isRequired,
  getCurrencyData: PropTypes.object.isRequired,
  getAssetData: PropTypes.object.isRequired,
  LivePricedata: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(mapStateToProps, {
  getAssetExchangeData,
  getCurrencyData,
  getAssetData,
  LivePricedata,
  convertnow,
  getAssetExchangeHistory
})(withTranslation()(AssetsExchange),withRouter(AssetsExchange));
