// import package
import React from 'react';
import { useSelector } from 'react-redux';

// import lib
import { unrealizedPnLPercentage } from '../lib/bybit';

const UnrealizedProfitLoss = (props) => {
    const { positionDetail, marketPrice } = props;

    // redux-state

    return (
        <>
            {unrealizedPnLPercentage({
                'price': positionDetail.price,
                'quantity': positionDetail.positionFilled,
                'lastPrice': marketPrice,
                'leverage': positionDetail.leverage,
                'takerFee': positionDetail.taker_fees,
                'buyorsell': positionDetail.buyorsell
            })}
        </>
    )
}

export default UnrealizedProfitLoss