import React, { Component } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import ReactDOM from 'react-dom';
import { store } from "react-notifications-component";
import axios from "axios";
import {Link,withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as moment from "moment";
import { withTranslation } from 'react-i18next';
import blogthumb1 from "../images/blogthumb1.jpg"
import blogthumb2 from "../images/blogthumb2.jpg"
import blogthumb3 from "../images/blogthumb3.jpg"
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from "react-html-parser";

import keys from "../actions/config";
const url = keys.baseUrl;
const imageUrl=keys.imageUrl;

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: [],
      userselectcategoryid: "",
      subcategory: [],
      subcategory1: [],
      articledata: [],
      recentdata:[]
    };
  }
  componentDidMount() {
    this.getData();
  }

  getData() {
    axios
      .get(url + "api/category")
      .then(response => {
        console.log(response);
        if (!response.data || !response.data.length) {
          return;
        }

        this.setState({
          category: response.data,
          userselectcategoryid: response.data[0]._id
        });
      })
      .catch();

      axios
        .post(url + "api/recentblog-data-limit")
        .then(articles => {
          //console.log("Article data in ssssssss ", articles);
          if (articles.data != undefined) {
            this.setState({recentdata: articles.data });
          }
        })
        .catch();

    axios
      .post(url + "api/article-data-limit")
      .then(articles => {
        if (articles.data != undefined) {
          this.setState({ articledata: articles.data });
        }
      })
      .catch();
  }

  render() {
    const { user } = this.props.auth;
    const {
      category,
      articledata,
      recentdata,
      userselectcategoryid
    } = this.state;
    const {t} = this.props;
    return (
<div className='bg_strip'>
  <section className="blogMainContent">
    <section class="headerCrediantials">
          <Navbar />
        </section>
          <div className="container pt-2">
            <div className="row mt25">
              <div className="col-xl-9">
            {category.map((item, i) => {
              var categoryname = item.categoryName;
              return (

                <section className="categoryBlogList">
                  <div className="categoryTitle clearfix">
                    <h2 className="float-left">{categoryname}</h2>
                    <a href= {
                      "/BlogCategorys/" + item._id
                    } className="float-right">{t("MORE")} &#62;&#62;</a>
                  </div>

                  {articledata.map((itemarticle, i) => {

                    if(item._id==itemarticle.maincategoryId._id){
                      //console.log("itemarticlsss",itemarticle);
                      var fromcontent= itemarticle.content
                      var artcontent=fromcontent.split(" ").splice(0,10).join(" ");
                    return (

                  <article className="categoryListPostBox">
                      <div className="row">
                        <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-12 d-none d-sm-block">
                          <div className="blogListImgThumb">
                            <a href={
                              "/BlogDetaile/" + itemarticle._id
                            }
                          >
                          {itemarticle.article_image!=null?
                              <img src={imageUrl + "help_images/" +itemarticle.article_image } className="img-fluid blogthumbimage" />
                            :
                                <img src={blogthumb1} className="img-fluid blogthumbimage" />

                          }

                          </a>
                          </div>
                        </div>
                        <div className="col-xl-9 col-lg-8 col-md-8 col-sm-8 col-12">
                          <div className="postDescriptionSection">
                            <span className="dateText">  {moment(itemarticle.createdDate).format(
                                "DD-MM-YYYY  h:mm a "
                              )}</span>
                            <h4><a href={
                              "/BlogDetaile/" + itemarticle._id
                            }>{itemarticle.Articlename}</a></h4>
                            <p>
                                {ReactHtmlParser(artcontent)}</p>
                            <div className="blogListImgThumb d-block d-sm-none mb-3">
                            <a href={
                              "/BlogDetaile/" + itemarticle._id
                            }><img src={blogthumb1} className="img-fluid" /></a>
                          </div>
                          {/*}  <div className="postAction">
                              <ul>
                                <li><a href="#">Like <span className="likeIcon"></span></a></li>
                                <li>
                                    <div className="dropdown">
                                    <a href="javascript:void(0)" className="dropdown-toggle" data-toggle="dropdown"><span>25 Share</span> <span className="shareIcon"></span></a>
                                    <div className="dropdown-menu">
                                      <ul>
                                      <li><a href="#"><i class="fab fa-facebook-f"></i> Facebook</a></li>
                                      <li><a href="#"><i class="fab fa-twitter"></i> Twitter</a></li>
                                      <li><a href="#"><i class="fab fa-linkedin-in"></i> Linkedin</a></li>
                                      <li><a href="#"><i class="fab fa-instagram"></i> Instagram</a></li>
                                    </ul>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            */}
                          </div>
                        </div>
                      </div>
                  </article>
                )
              }
              })}
                </section>
              )}
            )}
              </div>


              <div className="col-xl-3">
                <div className="blogRightSideBar">
                {recentdata.length>0?(
                  <aside className="sideBarBox recentBlog">
                      <h3>{t("RECENT_BLOGS")}</h3>
                        {recentdata.map((itemrecentdata, i) => {
                          //console.log("itemrecentdata",itemrecentdata);
                          return(
                      <ul>
                      <li><a href={
                        "/BlogDetaile/" + itemrecentdata._id
                      }>{itemrecentdata.Articlename}</a><span>{t("CATEGORY")}:<a href={
                        "/BlogCategorys/" + itemrecentdata._id
                      } >{itemrecentdata.maincategoryId.categoryName}</a> | {moment(itemrecentdata.createdDate).format(
                          "DD-MM-YYYY  h:mm a "
                        )}</span></li>
                      </ul>
                    )})}


                  </aside>
                ):(
                ""
                )
              }


              {/*}    <aside className="sideBarBox">
                      <h3>Blog Tags</h3>
                      <ul className="tagCloud">
                        <li><a href="#" className="smallDark">Crypto </a></li>
                        <li><a href="#" className="bigDark">Bitcoin</a></li>
                        <li><a href="#" className="bigDark">Crypto Currency</a></li>
                        <li><a href="#" className="smallLight">Trading Plaform</a></li>
                        <li><a href="#" className="bigRed">Etherum</a></li>
                        <li><a href="#" className="smallDark">Exchnage</a></li>
                        <li><a href="#" className="smallDark">Social Trading</a></li>
                        <li><a href="#" className="bigDark">Staking</a></li>
                        <li><a href="#" className="bigDark">Masternode</a></li>
                        <li><a href="#" className="smallLight">Pre-Paid Card</a></li>
                        <li><a href="#" className="bigRed">Liquidity</a></li>
                        <li><a href="#" className="smallDark">Crowfunding</a></li>
                        <li><a href="#" className="smallDark">Technology</a></li>
                      </ul>
                  </aside>
                  */}
                </div>
              </div>
            </div>
          </div>
        </section>
  <Footer />
</div>
);
  }
}

Blog.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps
)(withTranslation()(Blog),withRouter(Blog));
