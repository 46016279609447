import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import { store } from "react-notifications-component";
import axios from "axios";
import {Link,withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
import { getStaking, stakingOrder } from "../actions/authActions";
import { connect } from "react-redux";
import keys from "../actions/config";
import Navbar from './Navbar'
import Footer from './Footer'
import StackNavbar from './StackNavbar'
import { withTranslation } from 'react-i18next';

const url = keys.baseUrl;
const adminurl = keys.imageUrl;
var    dateFormat   = require('dateformat');
var now = new Date();
class Stacking extends Component {
  constructor(props) {

    super(props);
  var stakeNowData = {};
    stakeNowData.currencyRef = {};
    stakeNowData.periodList = [];

  this.state = {
    pairdetails : [],
    getBalanceDetails:[],
      curBal:0,
      stakingRec: [],
      stakeNowShow:false,
      stakeNowData:stakeNowData,
      lockAmt:0,
      stakingAggreement:false, 
      btn_name:{},
      stacking_content:{},
      stacking_table_btn1:{},
      stacking_table_btn2:{},
      stacking_faq:'',
      changeday:'',
      flexed:[],
      errors:{}
  }
   this.periodChange = this.periodChange.bind(this);
}
  componentDidMount() {
    const {t} = this.props;
    let userid = this.props.auth.user.id;
    this.getBalance();
    this.getStaking();
    this.getStakingButton();
    this.getStakingFaq();

    //console.log('userid');
    //console.log(userid);
    //console.log('typeof this.props.auth.user.id');
    //console.log(typeof this.props.auth.user.id);
    let pairdata = {exchangetype:"Spot"}
    axios
        .post(url + "cryptoapi/pair-data", pairdata)
        .then((res) => {
          if (res.status) {
           this.setState({pairdetails:res.data.data})
          } else {
            
          }
          // this.props.history.push("/login");
        })
        .catch();
   let withdraw_id = this.props.match.params.id;
    if (typeof withdraw_id != "undefined" && withdraw_id != "") {
      let passdata = { withdrawid: withdraw_id };
      axios
        .post(url + "cryptoapi/withdrawrequest", passdata)
        .then((res) => {
          if (res.data.status) {
            store.addNotification({
              title: t("SUCCESS"),
              message: res.data.message,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: t("ERRORS"),
              message: res.data.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          }
          this.props.history.push("/login");
        })
        .catch();
    }
  }
componentWillReceiveProps(nextProps) {
  const {t} = this.props;
    console.log('componentWillReceiveProps : ');
    console.log('nextProps : ',nextProps);
    console.log('this.props : ',this.props);

    var target = '';
    var data = {};

    if (nextProps.auth.trade !== undefined){
      if (nextProps.auth.trade.data !== undefined){
        data = nextProps.auth.trade.data;
        if (nextProps.auth.trade.data.target !== undefined){
          target = nextProps.auth.trade.data.target;
        }
      }
    }

    if (target == "stakingList"){
      if (data.list !== undefined){
        var list = data.list;
        this.setState({stakingRec:list});
      }
    }

    if (target == "stakingOrder"){
      if (nextProps.auth.trade.data.retType !== undefined){

        var retType = nextProps.auth.trade.data.retType;
        var retMsg = nextProps.auth.trade.data.retMsg;

        if(retType=='success'){
          console.log("@@@@@@@@@@@@@")
           this.setState({lockAmt:0});
          store.addNotification({
            title: t("SUCCESS"),
            message: retMsg,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true
            }
          });
          setTimeout(
          () => this.getBalanceA(), 
           0
          );

          this.setState({stakeNowShow:false});
        } else if(retType=='danger'){
          store.addNotification({
            title: t("ERRORS"),
            message: retMsg,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
        }
      }
    }

  }
  periodChange(data,changeVal) {
    console.log('periodChange data changeVal : ',changeVal);
    console.log('periodChange data data : ',data);

    this.setState({changeday:changeVal.days})

    var id = data._id;

    var stakingRec = this.state.stakingRec;
    console.log('stakingRec',stakingRec);

    var idx = stakingRec.findIndex(a => a._id == id);
    console.log('idx',idx);

    if(idx > -1){

      if(typeof changeVal=='string'){
        if(changeVal=='flexible'){
          stakingRec[idx].defaultBtn = 'flexible';
          stakingRec[idx].defaultDay = 0;
          stakingRec[idx].defaultApy = stakingRec[idx].flexibleApy;
        } else if(changeVal=='days'){
          stakingRec[idx].defaultBtn = 'days';
          stakingRec[idx].defaultDay = stakingRec[idx].periodList[0].days;
          stakingRec[idx].defaultApy = stakingRec[idx].periodList[0].apy;
        }
      } else {
        stakingRec[idx].defaultBtn = 'days';
        stakingRec[idx].defaultDay = changeVal.days;
        stakingRec[idx].defaultApy = changeVal.apy;
      }
      console.log('stakingRec',stakingRec);

      var retJsonObj = {};
      retJsonObj.list = {};
      retJsonObj.list.flexible = [];
      retJsonObj.list.fixed = [];

      for (var i = 0; i < stakingRec.length; i++) {
        var curRep = stakingRec[i];
        var stakingName = curRep.currencyRef.currencySymbol;
        stakingRec[i].stakingName = stakingName;
        if(stakingRec[i].defaultBtn == 'flexible'){
          retJsonObj.list.flexible.push(stakingRec[i]);
        }
        if (stakingRec[i].periodList.length>0 ) {
          retJsonObj.list.fixed.push(stakingRec[i]);
        }
        if(stakingRec.length-1 == i){
          retJsonObj.list.stakingRec = stakingRec;
        }
      }
      

      this.setState({
        Flexible:retJsonObj.list.flexible,
        Fixed:retJsonObj.list.fixed,
        stakingRec:retJsonObj.list.stakingRec
      });

    }
  }
  getStaking(){
       axios
      .post(url+"api/staking-list")
      .then(res=>{
        console.log("staking-flexed",res.data)
        if (res.data.list) {
        
          this.setState({
            Flexible:res.data.list.flexible,
             Fixed:res.data.list.fixed,
             stakingRec:res.data.list.stakingRec
          });

        }
           
      })

  }
   getBalance() {
    console.log('getBalance : ');
    if(typeof this.props.auth.user.id != 'undefined'){
      let userid = this.props.auth.user.id;
      axios
      .get(url+"cryptoapi/getBalance/"+userid)
      .then(res => {
        console.log('getBalance - res.data : ',res.data);
        var getBalanceDetails = [];
        if(typeof res.data!='undefined'){
          if(typeof res.data.data!='undefined'){
            getBalanceDetails = res.data.data;
          }
        }
        this.setState({getBalanceDetails:res.data.data});
      });
    }
  }

  getBalanceA() {
    console.log('getBalance : ');
    if(typeof this.props.auth.user.id != 'undefined'){
      let userid = this.props.auth.user.id;
      axios
      .get(url+"cryptoapi/getBalance/"+userid)
      .then(res => {
        console.log('getBalance - res.data : ',res.data);
        var getBalanceDetails = [];
        if(typeof res.data!='undefined'){
          if(typeof res.data.data!='undefined'){
            getBalanceDetails = res.data.data;
          }
        }
        this.setState({getBalanceDetails:res.data.data});
      });
    }
  }
   getStakingButton(){
    
     axios
       .get(url+"cryptoapi/get_staking_btn")
        .then(res => {
           var btn_name = {};
           var stacking_content = {};
           var stacking_table_btn1 = {};
           var stacking_table_btn2 = {};

          var btn_namekey = res.data.findIndex(i => i.identifier == 'Main Staking Button');
          if(btn_namekey > -1){
            btn_name = res.data[btn_namekey];
          }
          var stacking_table_btn1key = res.data.findIndex(i => i.identifier == 'Staking Table Button 1');
          if(stacking_table_btn1key > -1){
            stacking_table_btn1 = res.data[stacking_table_btn1key];
          }

          var stacking_table_btn2key = res.data.findIndex(i => i.identifier == 'Staking Table Button 2');
          if(stacking_table_btn2key > -1){
            stacking_table_btn2 = res.data[stacking_table_btn2key];
          }

          var stacking_contentkey = res.data.findIndex(i => i.identifier == 'Main Staking Content');
          if(stacking_contentkey > -1){
            stacking_content = res.data[stacking_contentkey];
          }

          this.setState({btn_name: btn_name,
            stacking_content: stacking_content,
            stacking_table_btn1: stacking_table_btn1,
            stacking_table_btn2: stacking_table_btn2,
           });
           
        });
  }

  // Initial rendering
  getStakingFaq(){
    // var stacking_faq={};
    axios
       .get(url+"cryptoapi/get_staking_faq")
        .then(res => {

          this.setState({stacking_faq:res.data});
        });
  }
   onChangeTextInput = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  stakeNowOpen = (data) => {
    const {t} = this.props;
    console.log('data : ',data);

    if(typeof this.props.auth.user.id != 'undefined'){

      var index   = this.state.getBalanceDetails.findIndex(x => (x.currencySymbol) === data.stakingName);

      if(index > -1){
        var balance = this.state.getBalanceDetails[index].spotwallet;
        balance = balance.toFixed(8);
      } else {
        var balance = 0;
      }

      console.log('data.stakingName : ',data.stakingName);
      console.log('this.state.getBalanceDetails : ',this.state.getBalanceDetails);
      console.log('index : ',index);
      console.log('balance : ',balance);

      this.setState({
        lockAmt:0,
        stakingAggreement:false,
        stakeNowShow:true,
        stakeNowData:data,
        curBal:balance,
        errors:{}
      });
    } else {
      store.addNotification({
        title: t("ERRORS"),
        message: t("PLEASE_LOGIN"),
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }
  confirmPurchase = (target) => {
    const {t} = this.props;
    console.log('confirmPurchase : ');
    console.log('target : ',target);

    var userid = this.props.auth.user.id;

    var errors = this.state.errors;
    errors = {};
    errors.lockAmt = '';
    var lockAmt = this.state.lockAmt;
    var stakeNowData = this.state.stakeNowData;

    var updateState = {};

    if(lockAmt==''){
      lockAmt = 0;
      updateState.lockAmt = lockAmt;
    }

    lockAmt = parseFloat(lockAmt);

    var currencySymbol = stakeNowData.currencyRef.currencySymbol;
    var balance = 0;

    var index   = this.state.getBalanceDetails.findIndex(x => (x.currencySymbol) === currencySymbol);
    if(index == -1){
      store.addNotification({
        title: t("ERRORS"),
        message: t("CHECK_YOUR_STAKE_CURRENCY"),
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    } else if(index > -1){
      var balance = this.state.getBalanceDetails[index].spotwallet;

      var defaultBtn = stakeNowData.defaultBtn;
      var minimum = stakeNowData.minimum;
      var maximum = stakeNowData.maximum;

      console.log('typeof lockAmt : ',typeof lockAmt);
      console.log('lockAmt : ',lockAmt);
      console.log('balance : ',balance);
      console.log('this.state.getBalanceDetails[index] : ',this.state.getBalanceDetails[index]);

      if(typeof lockAmt =='string' || lockAmt <= 0){
        errors.lockAmt = 'Please enter a valid amount.';
      } else if(balance < lockAmt){
        errors.lockAmt = 'There is not enough asset in your balance.';
      }

      if(defaultBtn == 'days' && errors.lockAmt==''){
        if(minimum > lockAmt){
          errors.lockAmt = 'Not enough in Minimum Locked Amount.';
        } else if(maximum < lockAmt){
          errors.lockAmt = 'Too much in Maximum Locked Amount.';
        }
      }

          if(defaultBtn == 'flexible' && errors.lockAmt==''){
        if(minimum > lockAmt){
          errors.lockAmt = 'Not enough in Minimum Locked Amount.';
        } else if(maximum < lockAmt){
          errors.lockAmt = 'Too much in Maximum Locked Amount.';
        }
      }

      var stakingAggreement = this.state.stakingAggreement;

      if(stakingAggreement==false){
        errors.stakingAggreement = 'Aggreement required';
      } else {
        errors.stakingAggreement = '';
      }

      updateState.errors = errors;
      this.setState(updateState)

      if(typeof target!='undefined'){
        if(target=='purchase'){
          console.log('allow order checking : ');
          if(errors.lockAmt=='' && errors.stakingAggreement==''){
            console.log('allow order : ');
            var postData = {};
            postData.userid = userid;
            postData.lockAmt = lockAmt;
            postData.stakeNowData = stakeNowData;
            this.props.stakingOrder(postData);
          }
        }
      }
    }
  }
   maxAmt = (e) => {
    const {t} = this.props;
    var curBal = this.state.curBal;
    var stakeNowData = this.state.stakeNowData;

    var index   = this.state.getBalanceDetails.findIndex(x => (x.currencySymbol) === stakeNowData.stakingName);

    if(index > -1){
      var balance = this.state.getBalanceDetails[index].spotwallet;
    } else {
      var balance = 0;
    }

    var lockAmt = balance;

    var defaultBtn = stakeNowData.defaultBtn;
    var minimum = stakeNowData.minimum;
    var maximum = stakeNowData.maximum;

    console.log('typeof lockAmt : ',typeof lockAmt);
    console.log('typeof lockAmt : ',typeof lockAmt);
    console.log('lockAmt : ',lockAmt);
    console.log('balance : ',balance);
    console.log('minimum : ',minimum);
    console.log('maximum : ',maximum);
    console.log('this.state.getBalanceDetails[index] : ',this.state.getBalanceDetails[index]);

    var errors = {};
    errors.lockAmt = '';

    if(lockAmt == 0){
    } else if(typeof lockAmt =='string' || lockAmt <= 0){
      errors.lockAmt = 'Please enter a valid amount.';
    } else if(balance < lockAmt){
      errors.lockAmt = 'There is not enough asset in your balance.';
    }

    if(defaultBtn == 'days' && errors.lockAmt==''){
      if(minimum > lockAmt){
        errors.lockAmt = 'Not enough in Minimum Locked Amount.';
      } else if(maximum < lockAmt){
        errors.lockAmt = 'Too much in Maximum Locked Amount.';
      }
    }


    if(errors.lockAmt==''){
      this.setState({ 'lockAmt': curBal });
    } else {
      var retMsg = errors.lockAmt;
      store.addNotification({
        title: t("ERRORS"),
        message: retMsg,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true
        }
      });
    }
  };
    onChangeStakingAggreement = (e) => {
    var stakingAggreement = this.state.stakingAggreement;

    if(stakingAggreement==true){
      stakingAggreement = false;
    } else {
      stakingAggreement = true;
    }
    this.setState({ stakingAggreement: stakingAggreement });
  }

  render() {
    const { user } = this.props.auth;

    return (
      <div>
      
      <div class="modal" id="myModal" data-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body stackPopup">
              <button type="button" class="close" data-dismiss="modal">&times;</button>
              <h6>Earn</h6>
              <img src={adminurl+'currency/'+this.state.stakeNowData.currencyRef.currencyimage} className="img-fluid d-block mx-auto" />
              <h3>{this.state.stakeNowData.currencyRef.currencySymbol}</h3>
               <div class="labelGroup">
                <label>Flexible Amount</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="Earn">Balance <small>{this.state.curBal} {this.state.stakeNowData.currencyRef.currencySymbol}</small></span>
              </div>
              <div class="input-group-lock-amount">
                <input type="number" name="lockAmt" id="lockAmt"    onChange={this.onChangeTextInput}
                  value={this.state.lockAmt} placeholder="Amount" class="form-control"  />
                <span class="inputLabel">{this.state.stakeNowData.currencyRef.currencySymbol}</span>
                
                <button class="btn btnStackGradiant ml-3 my-0 mr-0" onClick={() => this.maxAmt()}>MAX</button>
              </div>
                 <span className="text-danger">{this.state.errors.lockAmt}</span>
              <ul>
      <li ><span>Earn &nbsp; {this.state.stakeNowData.currencyRef.currencySymbol}</span>
       {((this.state.lockAmt*this.state.stakeNowData.defaultApy)/100)*365}</li>

                <li class="yellowText"><span>Est. APY</span>{this.state.stakeNowData.defaultApy}%</li>
                <li><span>Start date</span>{dateFormat(now, 'dd-mm-yyyy h:MM:ss TT')}</li>
                <li><span>Minimum Locked Amount</span>{this.state.stakeNowData.minimum} {this.state.stakeNowData.currencyRef.currencySymbol}</li>
                <li><span>Maximum Locked Amount</span>{this.state.stakeNowData.maximum} {this.state.stakeNowData.currencyRef.currencySymbol}</li>
                {/* <li><span>Interest payout frequency</span>Daily</li>
                 
                <li><span>Interest start date</span>{dateFormat(now,'dd-mm-yyyy h:MM:ss TT')}</li>*/}
                <li><span>Redemption</span>{this.state.stakeNowData.redemptionPeriod}</li>
                <li><span>Compounding</span>Yes</li>
              </ul>
              <div class="checkbox">
                <label>
                  <input type="checkbox" value=""  onChange={this.onChangeStakingAggreement}/>
                  <span class="cr"><i class="cr-icon fa fa-check"></i></span> I accept and agree to the <a href="#">terms and conditions</a>
                </label>
              </div>
               <span className="text-danger">{this.state.errors.stakingAggreement}</span>
              <div class="text-center bigButton">
                <button class="btn btnStackGradiant btn-large btn-block py-3 my-3" onClick={() => this.confirmPurchase('purchase')}>Confirm Purchase</button>
              </div>
            </div>
          </div>
        </div>
      </div>
       <div class="modal" id="myModal1" data-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body stackPopup">
              <button type="button" class="close" data-dismiss="modal">&times;</button>
              <h6>Earn</h6>
              <img src={adminurl+'currency/'+this.state.stakeNowData.currencyRef.currencyimage} className="img-fluid d-block mx-auto" />
              <h3>{this.state.stakeNowData.currencyRef.currencySymbol}</h3>
                  <div class="labelGroup">
                <label>Fixed Amount</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="Earn">Balance <small>{this.state.curBal} {this.state.stakeNowData.currencyRef.currencySymbol}</small></span>
              </div>
              <div class="input-group-lock-amount">
                <input type="number" name="lockAmt" id="lockAmt"    onChange={this.onChangeTextInput}
                  value={this.state.lockAmt} placeholder="Amount" class="form-control"  />
                <span class="inputLabel">{this.state.stakeNowData.currencyRef.currencySymbol}</span>
                 
                <button class="btn btnStackGradiant ml-3 my-0 mr-0" onClick={() => this.maxAmt()}>MAX</button>
              </div>
                <span className="text-danger">{this.state.errors.lockAmt}</span>
              <ul>
<li className="Earn"><span>Earn {this.state.stakeNowData.currencyRef.currencySymbol}</span>
   {((this.state.lockAmt*this.state.stakeNowData.defaultApy)/100)*(this.state.stakeNowData.defaultDay)}</li>

                <li class="yellowText"><span>Est. APY</span>{this.state.stakeNowData.defaultApy}%</li>
                <li><span>Minimum Locked Amount</span>{this.state.stakeNowData.minimum} {this.state.stakeNowData.currencyRef.currencySymbol}</li>
                <li><span>Maximum Locked Amount</span>{this.state.stakeNowData.maximum} {this.state.stakeNowData.currencyRef.currencySymbol}</li>
                <li><span>Redemption</span>{this.state.stakeNowData.redemptionPeriod}</li>
               {/*  <li ><span>Estimated Annual Yield</span>{this.state.stakeNowData.defaultApy}%</li>*/}
    {
              (this.state.stakeNowData.defaultBtn=='days')?
                <li>
                  <span>Duration</span>
                  <div className="btnGroup">
                    {this.state.stakeNowData.periodList.map((pl) => {
                      return <button class={this.state.stakeNowData.defaultDay==pl.days?'btn daysRoundBtn active':'btn daysRoundBtn button'} onClick={() => this.periodChange(this.state.stakeNowData,pl)}>{pl.days}</button>
                    })}
                  </div>
                </li>
              :
                ''
              }   
              </ul>
              <div class="checkbox">
                <label>
                  <input type="checkbox" value=""  onChange={this.onChangeStakingAggreement}/>
                  <span class="cr"><i class="cr-icon fa fa-check"></i></span> I accept and agree to the <a href="#">terms and conditions</a>
                </label>
              </div>
               <span className="text-danger">{this.state.errors.stakingAggreement}</span>
              <div class="text-center bigButton">
                <button class="btn btnStackGradiant btn-large btn-block py-3 my-3" onClick={() => this.confirmPurchase('purchase')}>Confirm Purchase</button>
              </div>
            </div>
          </div>
        </div>
      </div>

     <header className="stackingList">
      <div className="container">
        <Navbar />
      </div>  
    </header>
    <section className="stakeList">
      
        <div className="stackNav">
          <div className="container">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-10">
              <StackNavbar />
            </div>
          </div>
        </div>
      </div>
        <div className="stackListMain"  name="Flexible">
          <div className="container">
          <div className="row">
            <div className="col-lg-2">
              <div className="stackTitle">
                <h2>Flexible<small>18 Crypto</small></h2>
              </div>
            </div>
           
            <div className="col-lg-10">
              <div className="stackListGroup">
             
                <div className="row">
               {this.state.Flexible && this.state.Flexible.map((data,i)=>{
                console.log("data####3",data)
                   return(
                  <div className="col-md-4">

                    <div className="stakeHomeBox">
                      <img src={adminurl+'currency/'+data.currencyRef.currencyimage} className="img-fluid" />
                      <h3><span>Est. APY</span> {data.defaultApy}%</h3>
                      <button className="btn btnLandingJoin px-4">Earn Now</button>

                      <div className="stakeHomeBoxHover">
                      <img src={adminurl+'currency/'+data.currencyRef.currencyimage} className="img-fluid" />
                      <h3><span>Est. APY</span> {data.defaultApy}%</h3>
                      <div className="input-group">
                      {/*  <label>Invest</label>
                        <input type="text" name="" value="100" className="form-control" />
                        <label>{data.stakingName}</label>*/}
                      </div>
                      <div className="input-group stackDays">
                        <label>Can redembed in</label>
                        <input type="text" name="" value={data.redemptionPeriod} className="form-control" />
                        <label>days</label>
                      </div>

                      <button className="btn btnLandingJoin px-4" data-toggle="modal" data-target="#myModal"  onClick={() => this.stakeNowOpen(data)}><small>Earn Now</small> </button>
                    </div>

                    </div>
                
                  </div>
                   )
                })
              }
               

                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="stackListMain pt-2" name="Fixed">
          <div className="container">
          <div className="row">
           
            <div className="col-lg-2">
              <div className="stackTitle">
                <h2>Fixed<small>7 Crypto</small></h2>
              </div>
            </div>  
             <div className="col-lg-10">
              <div className="stackListGroup">
                <div className="row">
            {this.state.Fixed && this.state.Fixed.map((data1,i)=>{

              return(
                 <div className="col-md-4">
                    <div className="stakeHomeBox">
                      <img src={adminurl+'currency/'+data1.currencyRef.currencyimage} className="img-fluid" />
                      <h3><span>Est. APY</span> {data1.defaultApy}%</h3>
                      <h6>Days</h6>
                         {
              (data1.periodList.length > 0)?
                      <div className="daysRoundBtnGroup">
                       {data1.periodList.map((pl,j) => {
                        return(
                        <button  class={data1.defaultDay==pl.days?'btn daysRoundBtn active':'btn daysRoundBtn'} onClick={() => this.periodChange(data1,pl)}>{pl.days}</button>
                        )
                       })
                     }
                      </div>
                      :
                ''
              }
                      <button className="btn btnLandingJoin px-4">Earn Now</button>
                      <div className="stakeHomeBoxHover">
                      <img src={adminurl+'currency/'+data1.currencyRef.currencyimage} className="img-fluid" />
                      <h3><span>Est. APY</span> {data1.defaultApy}%</h3>
                      <div className="input-group">
                        {/* <label>Invest</label>
                        <input type="text"  type="number" name="lockAmt" id="lockAmt" value={this.state.lockAmt} className="form-control" onChange={this.onChangeTextInput}/>
                        <label>{data1.currencyRef.currencySymbol}</label>*/}
                      </div>
               { (data1.periodList.length > 0)?
                      <div className="form-group stackDays">
                        <label>In</label>
                        <div className="daysRoundBtnGroup mb-0">
                          {data1.periodList.map((pl,j) => {
                        return(
                        <button  class={data1.defaultDay==pl.days?'btn daysRoundBtn active':'btn daysRoundBtn'} onClick={() => this.periodChange(data1,pl)}>{pl.days}</button>
                        )
                       })
                     }
                        </div>
                        <label>Days</label>
                      </div>
                  :
                ''
              }
                      <button className="btn btnLandingJoin px-4" data-toggle="modal" data-target="#myModal1"  onClick={() => this.stakeNowOpen(data1)}><small>Earn Now</small> </button>
                    </div>
                    </div>
                  </div>
                    )
            })
          }
                   </div>
                    </div>
                  </div>
          </div>
        </div>
        </div>
     
    </section>
    <Footer />
      </div>
);
  }
}

Stacking.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
      getStaking   : PropTypes.func.isRequired,
  stakingOrder : PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
      {
    getStaking,
    stakingOrder
  }
)(withTranslation()(Stacking),withRouter(Stacking));
