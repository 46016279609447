import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import TradeHeader from './TradeHeader'
import Footer from './Footer'
import PaymentCodeImg from "../images/add-token.png"

class PaymentCode extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
  render() {
    return (
			<div className='bg_strip'>
				<TradeHeader />
				<div className="container-fluid">
				<section className="tradeMain payment_padding_sec">
				  <div className="row">
				    <div className="col-xl-8 col-lg-10 col-md-12 mx-auto">
				      {/* <img src={PaymentCodeImg} className="img-fluid cmsTopImg" /> */}
				      <div className="darkBox supportTicket">
				        <div className="tableHead tableHeadBlock borderBottomRemove">
				          <h2>Payment Code</h2>
				          <p>In the given Section you are enabled to create your own unique deposit codes.<br />Please select available currency and its amount. The given sum will be written off from your wallet while creating the code.</p>
				        </div>
				        <div className="darkBoxSpace pt-1 px-2 px-xl-4">
				          <form name="supportTicket" method="">
				            <div className="row mx-0 align-items-center">
				              <div className="col-md-12 mb-3">
				                <div className="form-group">
				                  <input type="submit" name="" value="Activate Payment Code" className="btn buttonType1 py-3 px-3" />
				                </div>
				              </div>
				              <div className="col-md-4 col-sm-6">
				                <div className="form-group clearfix">
				                  <label>Select Cryptocoin</label>
				                  <select className="niceStyleSelect">
				                    <option value="">BTC - Bitcoin</option>
				                    <option value="">ETH - Ethereum</option>
				                    <option value="">XRP - Ripple</option>
				                    <option value="">LTC - Litecoin</option>
				                    <option value="">BCH - Bitcoin Cash</option>
				                  </select>
				                </div>
				              </div>
				              <div className="col-md-4 col-sm-6">
				                <div className="form-group inputWithText">
				                      <label>Balance</label>
				                      <input name="" className="form-control" placeholder="" value="1.25456987" type="text" disabled="" />
				                      <div className="input-group-append-icon"><small>BTC</small></div>
				                  </div>
				              </div>
				              <div className="col-md-4 col-sm-12">
				                <div className="form-group">
				                  <label className="d-none d-md-block invisible">Label Space</label>
				                  <input type="button" className="btn buttonType1 px-4 mx-0" value="Create New Code" />
				                </div>
				              </div>
				            </div>
				          </form>
				        </div>
				        <div className="table-responsive">
				            <table id="assetsTable" className="table">
				              <thead>
				                  <tr className="wow flipInX" data-wow-delay=".5s;">
				                      <th>Code</th>
				                      <th>Amount</th>
				                      <th>Status</th>
				                  </tr>
				              </thead>
				             <tbody>
				               <tr><td colspan="3" height="150" className="text-center">- You have no created  payment codes -</td></tr>
				             </tbody>
				          </table>
				          </div>
				      </div>
				    </div>
				  </div>
				</section>
				</div>
				<Footer />
			</div>
			);
    }
}

export default PaymentCode