import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import {Store} from 'redux'
import {
  DYNAMIC,
  GET_ERRORS,
  USER_REGISTER,
  SET_CURRENT_USER,
  GET_USER,
  FORGOT,
  USER_LOADING,
  USER_FORGOT,
  USER_UPDATE,
  OTP_REGISTER,
  CONTACT,
  SUPPORT,
  SUPPORT_REPLY,
  TRADE,
  ORDER_HISTORY,
  TRADE_HISTORY,DYN,
  CONTACT_ADD,
  LAUNCHPADDATA_ADD,
  TWO_FA_UPDATE,
  APPLY_TOKEN,
  Lauchpad_ADD,
  LANGUAGE_CHANGER
} from "./types";
import keys from "./config";
import store from "../store";
const url = keys.baseUrl;

export const languageChanger = (data) => dispatch => {
  dispatch({
    type: LANGUAGE_CHANGER,
    payload: data
  })
}

export const registerUser = (userData, history) => dispatch => {
    axios
        .post(url+"cryptoapi/user-add", userData)
        .then(res => dispatch({
            type: USER_REGISTER,
            payload: res,
        })
        )
        .catch(err =>{
          console.log("============",err);
            dispatch({
                type: GET_ERRORS,
                payload: err?.response?.data
            })
          }
        );
};
export const addLaunchpadContact = (userData, history) => dispatch => {
  console.log("userData",userData)
    axios
        .post(url+"api/Launchpad_Contactus", userData)
        .then(res =>
            dispatch({
                type: CONTACT_ADD,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err?.response?.data
        })
    );
};
export const applyToken = (userData, history) => dispatch => {
  console.log("applyToken",userData)
    axios
        .post(url+"api/applyToken", userData)
        .then(res =>
            dispatch({
                type: APPLY_TOKEN,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err?.response?.data
        })
    );
};
export const Launchpadbuy = (currencyData) => dispatch => {
    console.log("userData!!!!!!",currencyData)
    axios
        .post(url + "api/launchpadbuy", currencyData)
        .then(res =>
            dispatch({
                type: Lauchpad_ADD,
                payload: res,
            })
        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err?.response?.data
            })
        );
};
  export const stakinggethistory = (userdata) => dispatch => {
    console.log("stakinggethistory",userdata)
    axios
    .post(url+"api/stakinggethistory",userdata)
    .then(res => dispatch({
      type: TRADE,
      payload: res,
    })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err?.response?.data
      })
    );
   };
  export const stackingClose = (userdata) => dispatch => {
    axios
    .post(url+"api/stackingClose",userdata)
    .then(res => dispatch({
      type: TRADE,
      payload: res,
    })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err?.response?.data
      })
    );
   };
export const getStaking = () => dispatch => {
  var dynobj = {};
  axios
  .post(url+"api/staking",dynobj)
  .then(res => dispatch({
    type: TRADE,
    payload: res,
  }))
  .catch(err =>
    dispatch({
      type: GET_ERRORS,
      payload: err?.response?.data
    })
  );
};
export const stakingOrder = (dynobj) => dispatch => {
  axios
  .post(url+"api/stakingOrder",dynobj)
  .then(res => dispatch({
    type: TRADE,
    payload: res,
  }))
  .catch(err =>
    dispatch({
      type: GET_ERRORS,
      payload: err?.response?.data
    })
  );
};
export const convertnow = (newAssetexchange) => dispatch => {
  axios
      .post(url+"cryptoapi/convertnow", newAssetexchange)
      .then(res =>
          dispatch({
              type: TRADE,
              payload: res,
          })
      ).catch(err =>
      dispatch({
          type: GET_ERRORS,
          payload: err?.response?.data
      })
  );
};



export const getAssetExchangeHistory = (AssetExchangeHistory) => dispatch => {
  axios
      .post(url+"cryptoapi/asset-exchange-history", AssetExchangeHistory)
      .then(res =>
          dispatch({
              type: TRADE,
              payload: res,
          })
      ).catch(err =>
      dispatch({
          type: GET_ERRORS,
          payload: err?.response?.data
      })
  );
};

export const kycadd = kycData => dispatch => {
 console.log("i worked",kycData)

  axios
    .post(url + "cryptoapi/kycupdate", kycData)
    .then(res =>
      dispatch({
        type: USER_UPDATE,
        payload: res,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err?.response?.data
      })
    );
};



export const myAssets1 = (userData, history) => dispatch => {
    axios
        .post(url+"cryptoapi/order-history", userData)
        .then(res => dispatch({
            type: ORDER_HISTORY,
            payload: res,
        })
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err?.response?.data
            })
        );
};
export const myAssets2 = (userData, history) => dispatch => {
    axios
        .post(url+"cryptoapi/trade-history", userData)
        .then(res => dispatch({
            type: TRADE_HISTORY,
            payload: res,
        })
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err?.response?.data
            })
        );
};

export const mobregisterUser = (userData, history) => dispatch => {
    axios
        .post(url+"cryptoapi/mobuser-add", userData)
        .then(res => dispatch({
            type: USER_REGISTER,
            payload: res,
        })
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err?.response?.data
            })
        );
};

export const otptoUser = (otpData, history) => dispatch => {
    axios
        .post(url+"cryptoapi/otp-submit", otpData)
        .then(res => dispatch({
            type: USER_REGISTER,
            payload: res,
        })
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err?.response?.data
            })
        );
};


export const loginUser = userData => dispatch => {
    axios
        .post(url+"cryptoapi/logincheck", userData)
        .then(res => {
            const { token } = res.data;
            localStorage.setItem("jwtToken", token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(decoded));
        })
        .catch(err =>{
          console.log("err_login_user",err);
          dispatch({
            type: GET_ERRORS,
            payload: err?.response?.data
        })
        }  
            
        );
};

export const tfachecking = userData => dispatch => {
    axios
        .post(url+"cryptoapi/tfachecking", userData)
        .then(res => {
            const { token } = res.data;
            localStorage.setItem("jwtToken", token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(decoded));
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err?.response?.data
            })
        );
};

export const confirmaionchecking = userData => dispatch => {
    axios
        .post(url+"cryptoapi/confirmaionchecking", userData)
        .then(res => {
            const { token } = res.data;
            localStorage.setItem("jwtToken", token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(decoded));
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err?.response?.data
            })
        );
};

export const mobloginUser = userData => dispatch => {
    axios
        .post(url+"cryptoapi/moblogincheck", userData)
        .then(res => {
            const { token } = res.data;
            localStorage.setItem("jwtToken", token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(decoded));
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err?.response?.data
            })
        );
};

export const forgotUser = (forgotData,history) => dispatch => {
  axios
      .post(url+"cryptoapi/forgot", forgotData)
      .then(res => dispatch({
          type: USER_FORGOT,
          payload: res,
      })
      )
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err?.response?.data
          })
      );
 };

 export const mobforgotUser = (forgotData,history) => dispatch => {
  axios
      .post(url+"cryptoapi/forgotformob", forgotData)
      .then(res => dispatch({
          type: USER_FORGOT,
          payload: res,
      })
      )
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err?.response?.data
          })
      );
 };

 export const resetUser = (resetData) => dispatch => {
   axios
       .post(url+"cryptoapi/reset", resetData)
       .then(res => dispatch({
           type: USER_FORGOT,
           payload: res,
       })
       )
       .catch(err =>
           dispatch({
               type: GET_ERRORS,
               payload: err?.response?.data
           })
       );
  };

  export const mobresetPassword = (resetData) => dispatch => {
   axios
       .post(url+"cryptoapi/mobresetPassword", resetData)
       .then(res => dispatch({
           type: USER_FORGOT,
           payload: res,
       })
       )
       .catch(err =>
           dispatch({
               type: GET_ERRORS,
               payload: err?.response?.data
           })
       );
  };

  export const profileUser = (profileData) => dispatch => {
    axios
        .post(url+"cryptoapi/userupdate", profileData)
        .then(res => 

          {
            console.log("USER_UPDATEUSER_UPDATE",res);
            dispatch({
              type: USER_UPDATE,
              payload: res,
          })
          }
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err?.response?.data
            })
        );
   };

  export const createApiKey = (apiData) => dispatch => {
    axios
        .post(url+"cryptoapi/CreateApiKey", apiData)
        .then(res => dispatch({
            type: USER_UPDATE,
            payload: res,
        })
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err?.response?.data
            })
        );
   };

  export const deleteApiKey = (apiData) => dispatch => {
    axios
        .post(url+"cryptoapi/deleteApiKey", apiData)
        .then(res => dispatch({
            type: USER_UPDATE,
            payload: res,
        })
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err?.response?.data
            })
        );
   };

   export const tfaFormsubmit = (otpData, history) => dispatch => {
    axios
        .post(url+"cryptoapi/tfa-enable", otpData)
        .then(res =>
          {
            dispatch({
              type: TWO_FA_UPDATE,
              payload: res.data,
          })
          dispatch({
            type: GET_ERRORS,
            payload: ''
        })
          
          }
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err?.response?.data
            }),
           
        );
};

export const contact = (contactdata) => dispatch => {
   // //console.log(contactdata,'contactdata');
    axios
        .post(url+"cryptoapi/contact", contactdata)
        .then(res => dispatch({
            type: CONTACT,
            payload: res,
        })

        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err?.response?.data
            })
        );
   };

  export const getPertual = (contactdata) => dispatch => {
 
    axios
      .post(url+"cryptoapi/perpetual-data", contactdata)
      .then(res =>{ 
        console.log(res,"getPertual")
        dispatch({
          type: TRADE,
          payload: res,
      })}

      )
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err?.response?.data
          })
      );
   };

   export const getspotPertual = (contactdata) => dispatch => {
    // console.log(contactdata,'contactdata-------');
    axios
      .post(url+"cryptoapi/spotpair-data", contactdata)
      .then(res => dispatch({
          type: TRADE,
          payload: res,
      })

      )
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err?.response?.data
          })
      );
   };

  export const orderhistory = (userdata) => dispatch => {
    // //console.log(contactdata,'contactdata');
    axios
      .post(url+"cryptoapi/order-history",userdata)
      .then(res => dispatch({
          type: TRADE,
          payload: res,
      })

      )
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err?.response?.data
          })
      );
   };

   export const withdrawalhistory = (userdata) => dispatch => {
    // //console.log(contactdata,'contactdata');
    axios
      .post(url+"cryptoapi/withdrawalhistory",userdata)
      .then(res => dispatch({
          type: TRADE,
          payload: res,
      })

      )
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err?.response?.data
          })
      );
   };

  export const cancelwithdraw = (withdrawdata) => dispatch => {
    // //console.log(contactdata,'contactdata');
    axios
      .post(url+"cryptoapi/cancelwithdraw",withdrawdata)
      .then(res => dispatch({
          type: TRADE,
          payload: res,
      })

      )
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err?.response?.data
          })
      );
   };

   export const assethistory = (userdata) => dispatch => {
    // //console.log(contactdata,'contactdata');
    axios
      .post(url+"cryptoapi/assethistory",userdata)
      .then(res => dispatch({
          type: TRADE,
          payload: res,
      })

      )
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err?.response?.data
          })
      );
   };

    export const bonushistory = (userdata) => dispatch => {
    // //console.log(contactdata,'contactdata');
    axios
      .post(url+"cryptoapi/bonus-history",userdata)
      .then(res => dispatch({
          type: TRADE,
          payload: res,
      })

      )
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err?.response?.data
          })
      );
   };


  export const Searchdata = (searchdata) => dispatch => {
    // //console.log(contactdata,'contactdata');
    axios
      .post(url+"cryptoapi/searchorder-history",searchdata)
      .then(res => dispatch({
          type: TRADE,
          payload: res,
      })

      )
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err?.response?.data
          })
      );
   };
     export const Searchwithdata = (searchdata) => dispatch => {
    // //console.log(contactdata,'contactdata');
    axios
      .post(url+"cryptoapi/searchwithdraw-history",searchdata)
      .then(res => dispatch({
          type: TRADE,
          payload: res,
      })

      )
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err?.response?.data
          })
      );
   };

  export const tradehistory = (userdata) => dispatch => {
    // //console.log(contactdata,'contactdata');
    axios
      .post(url+"cryptoapi/trade-history",userdata)
      .then(res => dispatch({
          type: TRADE,
          payload: res,
      })

      )
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err?.response?.data
          })
      );
   };

  export const closedposhistory = (userdata) => dispatch => {
    // //console.log(contactdata,'contactdata');
    axios
      .post(url+"cryptoapi/closedposhistory",userdata)
      .then(res => dispatch({
          type: TRADE,
          payload: res,
      })

      )
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err?.response?.data
          })
      );
   };

   export const position_details = (inputdata) => dispatch => {
    // //console.log(contactdata,'contactdata');
    axios
      .post(url+"cryptoapi/position_details",inputdata)
      .then(res => dispatch({
          type: TRADE,
          payload: res,
      })

      )
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err?.response?.data
          })
      );
   };


  export const tradeSearchdata = (searchdata) => dispatch => {
    // //console.log(contactdata,'contactdata');
    axios
      .post(url+"cryptoapi/searchtrade-history",searchdata)
      .then(res => dispatch({
          type: TRADE,
          payload: res,
      })

      )
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err?.response?.data
          })
      );
   };



     export const Depositsearchdata = (searchdata)  => {
       // //console.log(contactdata,'contactdata');
       axios
         .post(url+"cryptoapi/Depositsearchdata-history",searchdata)
         .then(res =>{
          return {
            status: 'success',
            loading: false,
            message: res.data.data,
            // userToken: respData.data.userToken,
            }
         }
         )
         // .catch(err =>
         // //     dispatch({
         // //         type: GET_ERRORS,
         // //         payload: err?.response?.data
         // //     })
         // );
      };

  export const pnlSearchdata = (searchdata) => dispatch => {
    // //console.log(contactdata,'contactdata');
    axios
      .post(url+"cryptoapi/pnlSearchdata",searchdata)
      .then(res => dispatch({
          type: TRADE,
          payload: res,
      })

      )
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err?.response?.data
          })
      );
   };

   export const getPricevalue = (pairdata) => dispatch => {
    axios
      .post(url+"cryptoapi/getPricevalue", pairdata)
      .then(res => dispatch({
          type: TRADE,
          payload: res,
      })

      )
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err?.response?.data
          })
      );
   };

      export const getspotPricevalue = (pairdata) => dispatch => {
    axios
      .post(url+"cryptoapi/getspotPricevalue", pairdata)
      .then(res => dispatch({
          type: TRADE,
          payload: res,
      })

      )
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err?.response?.data
          })
      );
   };
   export const support = (supportdata) => dispatch => {
    axios
        .post(url+"cryptoapi/support", supportdata)
        .then(res => dispatch({
            type: SUPPORT,
             payload: res,
        }),
        dispatch({
          type: GET_ERRORS,
          payload: ""
        })
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err?.response?.data
            })
        );


   };

   export const closeTicket = (userData, history) => dispatch => {
    axios
      .post(url + "cryptoapi/closeTicket", userData)
      .then(res => dispatch({
        type: SUPPORT,
        payload: res,
      }),
      
      )
      .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload: err?.response?.data
        })
      );
  };
   export const supportreply = (supportreplydata) => dispatch => {
    //console.log(supportreplydata,'supportreplydata');
    axios
        .post(url+"cryptoapi/support_reply_user", supportreplydata)
        .then(res => dispatch({
            type: SUPPORT_REPLY,
            payload: res,
        }),
        dispatch({
          type: GET_ERRORS,
          payload: ""
        })
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err?.response?.data
            })
        );


   };

export const triggerstop = (orderdata) => dispatch => {
  // //console.log(contactdata,'contactdata');
  axios
    .post(url+"cryptoapi/triggerstop", orderdata)
    .then(res => dispatch({
      type: TRADE,
      payload: res,
    }))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err?.response?.data
      })
    );
};

export const orderPlacing = (orderdata) => dispatch => {
  // //console.log(contactdata,'contactdata');
  axios
    .post(url+"cryptoapi/orderPlacing", orderdata)
    .then(res => dispatch({
      type: TRADE,
      payload: res,
    }))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err?.response?.data
      })
    );
};

export const spotorderPlacing = (orderdata) => dispatch => {
  // console.log('spotorderPlacing : orderdata : ', orderdata);
  axios
    .post(url+"cryptoapi/spotorderPlacing", orderdata)
    .then(res => dispatch({
      type: TRADE,
      payload: res,
    }))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err?.response?.data
      })
    );
};

export const getTradeData = (pass_data) => dispatch => {
  axios
    .post(url+"cryptoapi/getTradeData", pass_data)
    .then(res => dispatch({
      type: TRADE,
      payload: res,
    }))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err?.response?.data
      })
    );
};

export const getspotTradeData = (pass_data) => dispatch => {
  axios
    .post(url+"cryptoapi/getspotTradeData", pass_data)
    .then(res => dispatch({
      type: TRADE,
      payload: res,
    }))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: (err && err.response && err.response.data)?err.response.data:''
      })
    );
};

export const cancelTrade = (pass_data) => dispatch => {
  axios
    .post(url+"cryptoapi/cancelTrade", pass_data)
    .then(res => dispatch({
      type: TRADE,
      payload: res,
    }))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err?.response?.data
      })
    );
};

export const spotcancelTrade = (pass_data) => dispatch => {
  axios
    .post(url+"cryptoapi/spotcancelTrade", pass_data)
    .then(res => dispatch({
      type: TRADE,
      payload: res,
    }))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err?.response?.data
      })
    );
};

export const getuserTradeData = (userdetails) => dispatch => {
  axios
    .post(url+"cryptoapi/getuserTradeData", userdetails)
    .then(res => {console.log(res,"getuserTradeData");dispatch({
      type: TRADE,
      payload: res,
    })})
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err?.response?.data
      })
    );
};

export const getspotuserTradeData = (userdetails) => dispatch => {
  axios
    .post(url+"cryptoapi/getspotuserTradeData", userdetails)
    .then(res => dispatch({
      type: TRADE,
      payload: res,
    }))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err?.response?.data
      })
    );
};

export const changeopenpositions = (leveragedata) => dispatch => {
  axios
    .post(url+"cryptoapi/changeopenpositions", leveragedata)
    .then(res => dispatch({
      type: TRADE,
      payload: res,
    }))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err?.response?.data
      })
    );
};

export const getCurrencyData = (dynobj) => dispatch => {
    axios
      .post(url+"cryptoapi/getCurrencyData", dynobj)
      .then(res => dispatch({
        type: TRADE,
        payload: res,
      }))
      .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload: err?.response?.data
        })
      );
  };


export const getAssetData = (dynobj) => dispatch => {
    axios
      .post(url+"cryptoapi/getAssetData", dynobj)
      .then(res => dispatch({
        type: TRADE,
        payload: res,
      }))
      .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload: err?.response?.data
        })
      );
  };


export const LivePricedata = (dynobj) => dispatch => {
  axios
  .post(url+"cryptoapi/LivePricedata", dynobj)
  .then(res => dispatch({
    type: TRADE,
    payload: res,
  }))
  .catch(err =>
    dispatch({
      type: GET_ERRORS,
      payload: err?.response?.data
    })
  );
};

export const getAssetExchangeData = (dynobj) => dispatch => {
  axios
  .post(url+"cryptoapi/getAssetExchangeData", dynobj)
  .then(res => dispatch({
    type: TRADE,
    payload: res,
  }))
  .catch(err =>
    dispatch({
      type: GET_ERRORS,
      payload: err?.response?.data
    })
  );
};

export const getTableDataDynamic = (dynobj) => dispatch => {
  axios
  .post(url+"cryptoapi/getTableDataDynamic", dynobj)
  .then(res => dispatch({
    type: TRADE,
    payload: res,
  }))
  .catch(err =>
    dispatch({
      type: GET_ERRORS,
      payload: err?.response?.data
    })
  );
};

export const updateTableDataDynamic = (dynobj) => dispatch => {
  axios
  .post(url + "cryptoapi/updateTableDataDynamic", dynobj)
  .then(res => dispatch({
    type: TRADE,
    payload: res,
  }))
  .catch(err =>
    dispatch({
      type: GET_ERRORS,
      payload: err?.response?.data
    })
  );
};

export const postCallDynamic = (dynobj) => dispatch => {
  axios
  .post(url + dynobj.url, dynobj.payload)
  .then(res => dispatch({
    type: TRADE,
    payload: res,
  }))
  .catch(err =>
    dispatch({
      type: GET_ERRORS,
      payload: err?.response?.data
    })
  );
};

export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};
export const getUserData = async (decoded) => {
  // this function get user and this function from app.js file
  const id = decoded.id;
  getUserKycVerify(decoded);
  console.log('hello')
  const userData = await axios.get(url+"cryptoapi/getSingleUser/"+id)
  store.dispatch({
    type: GET_USER,
    payload: userData.data.userData
  })
};

export const getUserKycInfo = async (decoded) =>{
  const id = decoded.id;
  const userData = await axios.get(url+"api/getkycsession/"+id);
  return userData.data.data;
}

// getkycverification

export const getUserKycVerify = async (decoded) =>{
  const id = decoded.id;
  const userData = await axios.get(url+"api/getkycverification/"+id);
  return userData.data.data;
}

export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};

export const logoutUser = (data) => async dispatch => {
  console.log(data,"logout") 
  const logout =  await axios.post(url+"cryptoapi/logout",data)
  console.log('logoutdetaulks',logout)
  localStorage.removeItem("jwtToken");
  setAuthToken(false);
  dispatch(setCurrentUser({}));
  window.location.href = "/login";
};

export const displayingDigits = (value,float)=>{
  let res = parseFloat(Math.abs(value)).toFixed(float)
  if(res > 0){
      return res
  }
  else{
      res = parseFloat(value).toFixed(8)
      return res
  }
  
}