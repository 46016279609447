import React, { Component } from 'react'
import BannerX from "../images/xicon.png"
import CaptchaImg from "../images/captchaImg.jpg"
import {Link,withRouter} from 'react-router-dom';
import Navbar from './Navbar'
import Footer from './Footer'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import queryString from 'query-string'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import ReCAPTCHA from "react-google-recaptcha";
import { store } from 'react-notifications-component';
import { registerUser,mobregisterUser,otptoUser } from "../actions/authActions";
import { withTranslation } from 'react-i18next';
import classnames from "classnames";
import keys from "../actions/config";
const url = keys.baseUrl;
const Recaptchakey = keys.Recaptchakey;
const recaptchaRef = React.createRef();

const grecaptchaObject = window.grecaptcha
class Register extends Component {
	constructor(props) {
			super(props);
			this.state = {
				email: "",
				password: "",
				password2: "",
				referalcode: "",
				errors: {},
				phone: "",
				accept: "",
				phone: "",
				mobilepassword: "",
				mobilereferalcode: "",
				recaptcha: "",
				mobrecaptcha: "",
				mobileaccept: "",
				otpver: "",
				readOnly: false,
				disable: false,
				viewpassword:false,
				viewconfirmpassword:false
			};
	}

	componentDidMount() {
		
		window.scrollTo(0, 0);

		if (this.props.auth.isAuthenticated) {
			this.props.history.push("/settings");
		}

		const values = queryString.parse(this.props.location.search)
		if(typeof values.ref!= "undefined"){
			this.setState({referalcode:values.ref,readOnly:true})
		}
	}

	componentWillReceiveProps(nextProps) {
		const {t} = this.props;
			if (nextProps.errors) {
				this.setState({
						errors: nextProps.errors,
						disable:false,
				});
			}
			if (nextProps.auth.newuser !== undefined &&
					nextProps.auth.newuser.data !== undefined &&
					nextProps.auth.newuser.data.message !== undefined) {
				store.addNotification({
				  title: t("REGISTRATION_SUCCESS"),
				  message: t("ACTIVATION_MAIL_SENT"),
				  type: "success",
				  insert: "top",
				  container: "top-right",
				  animationIn: ["animated", "fadeIn"],
				  animationOut: ["animated", "fadeOut"],
				  dismiss: {
				    duration: 5000,
				    onScreen: true
				  }
				});
				this.setState({
						email: "",
						password: "",
						referalcode: "",
						errors: {},
						accept : "",
						disable:false,
						otpver : (this.state.phone != '')?'true':''
				});
				if(nextProps.auth.newuser.data.message=='OTP verification successfully completed, You can login now')
				{
					this.props.history.push("/login");
					nextProps.auth.newuser = "";
				}
				else
				{
					this.props.history.push("/login");
					nextProps.auth.newuser = "";
				}
			}

	}

	onChange = e => {
		this.setState({ [e.target.id]: e.target.value });
	};

	captchaChange = e => {
		this.setState({ recaptcha : e });
	};

	captchaChange1 = e => {
		this.setState({ mobrecaptcha : e });
	};

	onChangeCheckbox = e => {
		this.setState({ [e.target.id]: e.target.checked });
	};


	onSubmit = e => {
		this.setState({disable:true})
			e.preventDefault();
			const newUser = {
				email: this.state.email,
				password: this.state.password,
				password2: this.state.password2,
				referalcode: this.state.referalcode,
				recaptcha: this.state.recaptcha,
				accept: this.state.accept.toString()
			};
			this.props.registerUser(newUser, this.props.history);
	};

	mobileSubmit = e => {
			e.preventDefault();
			const newUser = {
					phone: this.state.phone,
					mobilepassword: this.state.mobilepassword,
					mobilereferalcode: this.state.mobilereferalcode,
					mobrecaptcha: this.state.mobrecaptcha,
					mobileaccept: this.state.mobileaccept.toString()
			};
			this.props.mobregisterUser(newUser, this.props.history);
	};

	otpSubmit = e => {
			e.preventDefault();
			const newUser = {
					otpcode: this.state.otpcode,
					phone: this.state.phone,
			};
			this.props.otptoUser(newUser, this.props.history);
	};


	render() {

        const {t} = this.props;
		const { errors } = this.state;
		return(
			<div className='bg_strip loginsec'>

				<section class="headerCrediantials login_banner">
					<Navbar />
					<div className="container">
				    <div className="row">
				      <div className="col-md-7 col-lg-5 m-auto">
				        <div className="formBox mb-5 mt-5" data-aos="fade-up" data-aos-duration="2000">
				         <h2>{t("REGISTER")}</h2>
				        <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
				         <div className="tab-pane fade show active" id="nav-emailLogin" role="tabpanel" aria-labelledby="nav-emailLogin-tab">
				          <form className="stoForm landingForm" noValidate onSubmit={this.onSubmit} >
				            <div className="form-group fadeIn second">
				            <label>{t("EMAIL_PLACEHOLDER")}</label>
				            <input
				                onChange={this.onChange}
				                value={this.state.email}
				                error={errors.email}
				                id="email"
				                type="email"
				                className={classnames("form-control", {
				                    invalid: errors.email
				                })}
				            />
				            <span className="text-danger">{errors.email}</span>
				          </div>
				          <div className="form-group fadeIn third">
				             <label>{t("PASSWORD")}</label>
							 <div class="input-group input_grp_pwd">
				             <input
				                 onChange={this.onChange}
				                 value={this.state.password}
				                 error={errors.password}
				                 id="password"
				                 type={this.state.viewpassword ? "text":"password"}
				                 className={classnames("form-control", {
				                     invalid: errors.password
				                 })}
				             />
							  <div class="input-group-append input_grp_pwd_append" onClick={()=>{this.setState({viewpassword:!this.state.viewpassword})}}>
											<span class="input-group-text">
												{this.state.viewpassword?
												<i class="fa fa-eye"></i>
												:
												<i class="fa fa-eye-slash"></i>
												}
												</span>
											</div>
							 </div>
							 
				             <span className="text-danger">{errors.password}</span>
				          </div>
				           <div className="form-group fadeIn third">
				             <label>{t("CONFIRM_PASSWORD")}</label>
							 <div class="input-group input_grp_pwd">
				             <input
				                 onChange={this.onChange}
				                 value={this.state.password2}
				                 error={errors.password2}
				                 id="password2"
				                 type={this.state.viewconfirmpassword ? "text":"password"}
				                 className={classnames("form-control", {
				                     invalid: errors.password2
				                 })}
				             />
							  <div class="input-group-append input_grp_pwd_append" onClick={()=>{this.setState({viewconfirmpassword:!this.state.viewconfirmpassword})}}>
											<span class="input-group-text">
												{this.state.viewconfirmpassword?
												<i class="fa fa-eye"></i>
												:
												<i class="fa fa-eye-slash"></i>
												}
												</span>
											</div>
							 </div>
				             <span className="text-danger">{errors.password2}</span>
				          </div>
				          {/* <div className="form-group fadeIn third">
				             <label>Referral Code (Optional)</label>
				             <input
				                onChange={this.onChange}
				                value={this.state.referalcode}
								
				                error={errors.referralCode}
				                id="referalcode"
				                type="text"
				                readOnly={this.state.readOnly}
				                className={classnames("form-control", {
				                    invalid: errors.referralCode
				                })}
				            />
				            <span className="text-danger">{errors.referralCode}</span>
				          </div> */}

				          <div className="form-group clearfix fadeIn third">
				            <div className="float-left"><div className="checkbox">
				            <label>
				            <input
				               onChange={this.onChangeCheckbox}
				               value={this.state.accept}
				               error={errors.accept}
				               id="accept"
				               type="checkbox"
				               className={classnames("form-control", {
				                   invalid: errors.accept
				               })}
				             />
				                <span className="cr"><i className="cr-icon fa fa-check"></i></span>
				                <span>{t("I_AGREE")} <a href="/Terms" target="_blank" className="regTerms">{t("TERMS_CONDITION")}</a></span>
				            </label>
				            <span className="text-danger">{errors.accept}</span>
				        </div>
							</div>
				    </div>
	          <div className="form-group">
	          <div className="form-group clearfix fadeIn third ml-1 recaptchaMain">
				      <ReCAPTCHA
					      ref={(r) => this.recaptcha = r}
					      sitekey={Recaptchakey}
					      grecaptcha={grecaptchaObject}
					      onChange={this.captchaChange}
					      />
	      		<span className="text-danger">{errors.recaptcha}</span>
				      	</div>
				          </div>
				            <div className="form-group my-4">
				            <input type="submit" className="btn-block fadeIn fourth" disabled={this.state.disable} value={t("REGISTER")} />
				          </div>
				          </form>
				          <div id="formFooter" className="fadeIn fourth" >
				            <h6 className="text-center" style={{ whiteSpace: 'pre-line' }}>{t("ALREADY_REGISTERED_USER")}&nbsp;&nbsp;<Link to="/login" className="underlineHover">{t("LOGIN_HERE")}</Link></h6>
				          </div>
				          </div>
				          <div className="tab-pane fade" id="nav-mobileLogin" role="tabpanel" aria-labelledby="nav-mobileLogin-tab">
				         { this.state.otpver==''?
				            <form className="stoForm landingForm" noValidate onSubmit={this.mobileSubmit}>
				      <div className="form-group input-group mobNumber">
				        <label>{t("MOBILE_NO")}</label>
				        <PhoneInput
					        placeholder="Enter phone number"
					        country={'IN'}
					        value={this.state.phone}
					        onChange={phone => this.setState({ phone })}
					        />
				        <span className="text-danger">{errors.phone}</span>
				      </div>
				          <div className="form-group fadeIn third">
				             <label>{t("PASSWORD")}</label>
				        <input
				          onChange={this.onChange}
				          value={this.state.mobilepassword}
				          error={errors.mobilepassword}
				          id="mobilepassword"
				          type="password"
				          className={classnames("form-control", {
				          invalid: errors.mobilepassword
				        })}
				        />
				        <span className="text-danger">{errors.mobilepassword}</span>
				          </div>
				          <div className="form-group fadeIn third">
				             <label>{t("REF_CODE_OPTION")}</label>
				            <input
						          onChange={this.onChange}
						          value={this.state.mobilereferalcode}
						          error={errors.mobilereferalcode}
						          id="mobilereferalcode"
						          type="text"
						          className="form-control"
						          />
				          </div>
				          <div className="form-group clearfix fadeIn third">
				            <div className="float-left"><div className="checkbox">
				            <label>
				                <input
				               onChange={this.onChangeCheckbox}
				               value={this.state.mobileaccept}
				               error={errors.mobileaccept}
				               id="mobileaccept"
				               type="checkbox"
				               className={classnames("form-control", {
				                   invalid: errors.mobileaccept
				               })}
				             />
				                <span className="cr"><i className="cr-icon fa fa-check"></i></span>
				                <span>{t("I_AGREE")} <a href="/Terms" target="_blank" className="regTerms">{t("TERMS_CONDITION")}</a></span>
				            </label>
				        <span className="text-danger">{errors.mobileaccept}</span>
				        </div>
								</div>
				          </div>
				          <div className="form-group clearfix fadeIn third ml-1 recaptchaMain">
							      <ReCAPTCHA
							      ref={(r) => this.recaptcha = r}
							      sitekey={Recaptchakey}
							      grecaptcha={grecaptchaObject}
							      onChange={this.captchaChange1}
							      />
				      <span className="text-danger">{errors.mobrecaptcha}</span>
				      </div>
				      <div className="form-group my-4">
				        <input type="submit" className="btn-block fadeIn fourth" value="Register" />
				      </div>
				    </form> :

	          <form className="stoForm landingForm" noValidate onSubmit={this.otpSubmit}>
	             <div className="form-group fadeIn third">
	             <p>{t("PLEASE_ENTER_THE_VERIFICATION_CODE")} {this.state.phone}</p>
	             <label>{t("SMS_VERIFICATION_CODE")}</label>
	            <input
			          onChange={this.onChange}
			          value={this.state.otpcode}
			          error={errors.otpcode}
			          id="otpcode"
			          type="text"
			          className="form-control"
			          />
		          </div>
		          <div className="form-group my-4">
		            <input type="submit" className="btn-block fadeIn fourth" value="Submit" />
		          </div>
		          <div className="form-group">
		          <h6 className="text-center">
		          <p style={{textAlign:'center'}}>{t("NO_SMS_RECEIVED")}<a onClick={this.mobileSubmit} className="underlineHover" style={{color:'#d6b032 !important'}}>{t("RESEND_OTP")}</a></p>
		          </h6>
		          </div>
		          </form>
				    }
				          <div id="formFooter" className="fadeIn fourth">
				            <h6 className="text-center" style={{ whiteSpace: 'pre-line' }}>{t("ALREADY_REGISTERED_USER")}&nbsp;&nbsp;<Link to="/login" className="underlineHover">{t("LOGIN_HERE")}</Link></h6>
				          </div>
				          </div>
				        </div>
				        </div>
				      </div>
				    </div>
				  </div>
				</section>
				<Footer />
			</div>
		);
	}

}



Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  mobregisterUser: PropTypes.func.isRequired,
  otptoUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { registerUser, mobregisterUser, otptoUser }
)(withTranslation()(Register),withRouter(Register));
