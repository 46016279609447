export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const DYNAMIC = "DYNAMIC";
export const USER_ADD = "USER_ADD";
export const USER_REGISTER = "USER_REGISTER";
export const OTP_REGISTER = "OTP_REGISTER";
export const USER_UPDATE = "USER_UPDATE";
export const TWO_FA_UPDATE = "TWO_FA_UPDATE";
export const GET_USER = "GET_USER";
export const USER_FORGOT = "USER_FORGOT";
export const CONTACT = "CONTACT";
export const SUPPORT = "SUPPORT";
export const SUPPORT_REPLY = "SUPPORT_REPLY";
export const TRADE = "TRADE";
export const ORDER_HISTORY = "ORDER_HISTORY";
export const TRADE_HISTORY = "TRADE_HISTORY";
export const DYN = "DYN";
export const CONTACT_ADD="CONTACT_ADD";
export const Lauchpad_ADD="Lauchpad_ADD";
export const APPLY_TOKEN="APPLY_TOKEN";
export const THEME_CHANGER="THEME_CHANGER";
export const LANGUAGE_CHANGER="LANGUAGE_CHANGER";
