import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import Navbar from './Navbar'
import Footer from './Footer'
import axios from "axios";
import keys from "../actions/config";
import { withTranslation } from 'react-i18next';
const url = keys.baseUrl;
class AffiliateProgram extends Component {

  
   
	 
	render() {
		const { t } = this.props; 
		return (<div>
			<section class="headerCrediantials homeBanner">
      <Navbar />
      </section>
			<section className="innerCMS">
			
			  <div className="container">
			    <div className="row">
			      <div className="col-md-10 mx-auto">
			        <div className="darkBox contentPage">
			          <div className="tableHead tableHeadBlock">
			            <h2>{t("REASON_AFFILIATE_PROGRAM")}</h2>
			          </div>
			          <div className="darkBoxSpace">
			            <h4>{t("ATTRACTIVE_COMMISSION_CRYPTOCURRENCIES")}</h4>
                  <p>{t("YOU_WILL_REFFERRAL_COMMISSION")}</p>
                  <h4>{t("DEDICATED_SUPPORT")}</h4>
                  <p>{t("OUR_AFFILIATE_TEAM")}</p>
                  <h4>{t("REAL_TIME_REPORTS")}</h4>
                  <p>{t("GET_ONLINE")}</p>
                  <h4>{t("HIGH_IMPACT_PROMO_MATERAILS")}</h4>
                  <p>{t("OUR_AFFILIATE_PROGRAM")}</p>
                  <p>{t("BELL_EXCHANGES_AFFILIATE_PROGRAM")}</p>
                  <p>{t("THIS_AFFILIATE_PROGRAM")}</p>
                  <p>{t("REGISTER_NOW_AND_GET")}</p>
                  <p><b>{t("BY_SIGNING_AFFILIATE_PROGRAM")}</b></p>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			   
			</section>
			<Footer />
		</div>
		);
	}
}

export default (withTranslation()(AffiliateProgram),AffiliateProgram)