import React, { Component } from 'react'
import { a } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Navbar from './Navbar'
import Footer from './Footer'
import Navtradebar from './Navtradebar'
import axios from "axios";
import { Link, withRouter } from 'react-router-dom';
import keys from "../actions/config";
import LogoNewFixHead from "../images/logo.png";
import LogoLightTheme from "../images/logo.png";
import $ from 'jquery';
import 'bootstrap';
import { withTranslation } from 'react-i18next';
import config from '../actions/config';

import { logoutUser } from "../actions/authActions";
import { store } from 'react-notifications-component';

import { toast } from 'react-toastify';

toast.configure();
const url = keys.baseUrl;

class CopyTradingSetting extends Component {

  constructor(props) {
    super(props);

    this.updateSettingType = this.updateSettingType.bind(this);
    this.updateForm = this.updateForm.bind(this);
    this.validateSettingForm = this.validateSettingForm.bind(this);
    this.unFollow = this.unFollow.bind(this);
    this.finalSubmit = this.finalSubmit.bind(this);
    this.increment = this.increment.bind(this);
    this.decrement = this.decrement.bind(this);
    this.removeError = this.removeError.bind(this);

    this.state = {
      subject:"",
      content: "",
      privacy : {},
      cmsData:{},
      trader: {},
      pairs: [],
      followersDetails: [],
      forms: [],
      errors: [],
      formObject: {},
      settingType: 1,
      settingId: '',
      followerSettings: {copytradeType:1},
      mySettings: {}
    };
  }

  useQuery() {
    const { search } = this.props.location;
    const { t } = this.props;
    return new URLSearchParams(search);
  }

  onLogoutClick = e => {
    const {t} = this.props;
    e.preventDefault();
    store.addNotification({
      title: t("LOGOUT_SUCCESS"),
      message: t("COME_JOIN"),
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
    let data = {userId:this.props.auth.user.id}
    this.props.logoutUser(data);
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.gettingCmsData();
    this.getTraderInfo();
    await this.getPairs();
    this.getSettingData();
  }

  gettingCmsData = async () => {
    const data = { identifier: "Privacy Policy" };
    const result = await axios.post(url + "cryptoapi/getSingleCmsData", data);
    const cmsData = result.data.result;
    this.setState({
      cmsData: cmsData
    });
  }

  getTraderInfo() {
    let id = this.useQuery().get('id');
    axios.get(url+'cryptoapi/getSingleUser/'+id).then(response => {
      this.setState({
        trader: response.data.userData[0],
        followersDetails: response.data.followers
      });
    }).catch(e => {
      console.log(e);
    });
  }

  getSettingData() {

    let data = {
      traderId: this.useQuery().get('id'),
      followerId: this.props.auth.user.id
    };

    axios.post(url+'cryptoapi/getFollowerSetting',data).then(response => {
      if(Object.keys(response.data.result).length) {

        let pairs = this.state.pairs;
        let setting = response.data.result;
        let forms = setting.detail.map(setting => setting.pairId.tiker_root);
        this.setState({
          mySettings: setting,
          settingId: response.data.result._id,
          forms: forms,
          settingType: setting.followMode
        });

        var followerSettings = {};
        if(setting.followMode==1) {
          followerSettings['copytradeType'] = setting.detail[0].copyTradeAmountType;
          followerSettings['copytradeAmount'] = setting.detail[0].copyTradeAmountValue;
          followerSettings['stopLossRatio'] = setting.detail[0].stopLossRatio;
          followerSettings['takeProfitRatio'] = setting.detail[0].takeProfitRatio;
          followerSettings['maximumPosition'] = setting.detail[0].maximumFollow;
        } else {
          for(let i=0; i<pairs.length; i++) {
            let pairId = pairs[i]._id;
            let pair = pairs[i].tiker_root;
            var index = setting.detail.findIndex(e => e.pairId && e.pairId._id && e.pairId._id.toString() == pairId.toString());
            if(index == -1) {
              followerSettings[`copytradeType_${pair}`] = '';
              followerSettings[`copytradeAmount_${pair}`] = '';
              followerSettings[`stopLossRatio_${pair}`] = '';
              followerSettings[`takeProfitRatio_${pair}`] = '';
              followerSettings[`maximumPosition_${pair}`] = '';
            }
            else {
              followerSettings[`copytradeType_${pair}`] = setting.detail[index].copyTradeAmountType;
              followerSettings[`copytradeAmount_${pair}`] = setting.detail[index].copyTradeAmountValue;
              followerSettings[`stopLossRatio_${pair}`] = setting.detail[index].stopLossRatio;
              followerSettings[`takeProfitRatio_${pair}`] = setting.detail[index].takeProfitRatio;
              followerSettings[`maximumPosition_${pair}`] = setting.detail[index].maximumFollow;
            }
          }
        }
        this.setState({followerSettings: followerSettings});
      }

    }).catch(e => {
      console.log(e);
    });
  }

  getPairs() {
    axios.post(url+'cryptoapi/perpetual-data').then(response => {
      let forms = response.data.data.map(pair => pair.tiker_root);
      var followerSettings = {copytradeType:1};
      for (let i = 0; i < forms.length; i++) {
        const element = forms[i];
        followerSettings['copytradeType_'+element] = 1;
      }

      this.setState({
        pairs: response.data.data,
        forms: forms,
        followerSettings: followerSettings
      });
    }).catch(e => {
      console.log(e);
    });
  }

  updateSettingType(e) {
    var settingType = this.state.settingType;
    if(settingType != parseInt(e.target.value)) {
      var jsonState = {settingType: parseInt(e.target.value)};

      var forms = this.state.forms;
      var followerSettings = this.state.followerSettings;

      for (let i = 0; i < forms.length; i++) {
        const element = forms[i];
        if(typeof followerSettings['copytradeType_'+element] == 'undefined' || followerSettings['copytradeType_'+element] == '') {
          followerSettings['copytradeType_'+element] = 1;
        }
      }
      jsonState.followerSettings = followerSettings;
      this.setState(jsonState);
    }

  }

  updateForm(e) {
    let value = e.target.value;
    let forms = this.state.forms;
    let index = forms.findIndex(form => form===value);
    if(index!==-1) {
      if(forms.length > 1) {
        forms.splice(index,1);
      }
    } else {
      let index = this.state.pairs.findIndex(pair => pair.tiker_root===value);
      forms.push(this.state.pairs[index].tiker_root);
    }

    this.setState({forms: forms});
  }

  validPositive(e) {
  	if(e.target.validity.valid || (e.target.value='')) {
  		e.preventDefault();
    }
  }

  increment(e) {
  	let els = document.getElementsByName(e.target.name);
  	els[0].value++;
    this.removeError(e);
  }

  decrement(e) {
  	let els = document.getElementsByName(e.target.name);
  	if(els[0].value>1) {
  		els[0].value--;
      this.removeError(e);
    }
  }

  removeError(e) {
    let name = e.target.name;
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        [name]: ''
      }
    }));

    let els = document.getElementsByName(e.target.name);
    let value = els[0].value;
    if(e.target.name.includes('copytradeType') && !els[0].checked)
      value = els[1].value;

    this.setState(prevState => ({
      followerSettings: {
        ...prevState.followerSettings,
        [name]: value
      }
    }));
  }

  async validateSettingForm(e) {
    e.preventDefault();

    var form = new FormData(e.target);
    let formObject = Object.fromEntries(form.entries());

    let data = {
    	forms: this.state.forms,
    	type: this.state.settingType,
  		form: formObject
  	};

    let validation = await axios.post(url+'cryptoapi/validateCopyTradeForm',data);

    if(!validation.data.status) {
      this.setState({errors: validation.data.errors});
    } else {
      this.setState({formObject: formObject});
      $('#confirm-copy-trade').modal('show');
    }
  }

  async unFollow() {
    let data = {
      traderId: this.useQuery().get('id'),
      followerId: this.props.auth.user.id,
      by: 'follower'
    };
    console.log("unFollow", data)
    axios.post(url + 'api/v1/copytrade/follow/cancel', data).then(response => {
      if (response.data.toast) {
        toast(response.data.toast.message, { position: toast.POSITION.TOP_CENTER });
        setTimeout(function () {
          window.location.reload(false)
        }, 1000);

      }
    });
  }

  finalSubmit() {
  	const { forms, settingType, formObject, pairs, settingId } = this.state;

    console.log('---- finalSubmit formObject : ', settingType, formObject);

  	var details = [];

  	for(let i=0; i<forms.length; i++) {
  		let index = pairs.findIndex(pair => pair.tiker_root==forms[i]);

  		let detail = {};
  		detail.allOrSingle = settingType;
  		detail.pairId = pairs[index]._id;
  		detail.leverageMode = 1;
  		detail.copyTradeAmountType = settingType==1 ? formObject[`copytradeType`]:formObject[`copytradeType_${forms[i]}`];
  		detail.copyTradeAmountValue = settingType==1 ? formObject[`copytradeAmount`]:formObject[`copytradeAmount_${forms[i]}`];
  		detail.stopLossRatio = settingType==1 ? formObject[`stopLossRatio`]:formObject[`stopLossRatio_${forms[i]}`];
  		detail.takeProfitRatio = settingType==1 ? formObject[`takeProfitRatio`]:formObject[`takeProfitRatio_${forms[i]}`];
  		detail.maximumFollow = settingType==1 ? formObject[`maximumPosition`]:formObject[`maximumPosition_${forms[i]}`];

      console.log('---- detail : ', detail)

      detail.copyTradeAmountType = detail.copyTradeAmountType!="" ? parseFloat(detail.copyTradeAmountType) : 0;
      detail.copyTradeAmountValue = detail.copyTradeAmountValue!="" ? parseFloat(detail.copyTradeAmountValue) : 0;
      detail.stopLossRatio = detail.stopLossRatio!="" ? parseFloat(detail.stopLossRatio) : 0;
      detail.takeProfitRatio = detail.takeProfitRatio!="" ? parseFloat(detail.takeProfitRatio) : 0;
      detail.maximumFollow = detail.maximumFollow!="" ? parseFloat(detail.maximumFollow) : 0;

  		details.push(detail);
  	}

  	let data = {
      id: settingId,
  		traderId: this.useQuery().get('id'),
  		followerId: this.props.auth.user.id,
  		followMode: settingType,
  		details: details
  	};

  	axios.post(url+'cryptoapi/saveCopyTradeFollowers',data).then(response => {
      toast(response.data.successMessage, {position: toast.POSITION.TOP_CENTER});
      $('#confirm-copy-trade').modal('hide');
      setTimeout(function(){
        window.location.reload(false)
     }, 1000);
    }).catch(e => {
      console.log(e);
    });
  }

  renderPrivacyPolicyText() {
    return (
      <div>
      </div>
    )
  }

  renderSettingForm () {
    
    const { settingType,  forms, errors, followerSettings } = this.state;
    // const { t } = this.props;
    if(settingType==1) {
      const { t } = this.props;
      return <div className="copy_setting_box">
          <div className="copy_unify_box">
            <div className="copy_unify_label">{t("LEVERAGE_MODE")}</div>
            <div className="copy_unify_type_box">
              <div className="custom-control custom-radio custom-control-inline">
                <input type="radio" id="customRadioInline3" name="future" checked value="1" className="custom-control-input"/>
                <label className="custom-control-label" for="customRadioInline3">{t("FUTURE_SETTING")}</label>
              </div>
              {/* <div className="custom-control custom-radio custom-control-inline">
                <input type="radio" id="customRadioInline4" name="customRadioInline3" className="custom-control-input"/>
                <label className="custom-control-label" for="customRadioInline4">Follow the trader</label>
              </div> */}
              {/* <div className="custom-control custom-radio custom-control-inline">
                <input type="radio" id="customRadioInline5" name="customRadioInline3" className="custom-control-input"/>
                <label className="custom-control-label" for="customRadioInline5">Customized</label>
              </div> */}
            </div>
            {/* <div className="copy_input_lever_box">
              <div className="copy_input_lever">
                <span className="input-lever-text">Long position</span>
                <input min='1' type="number" onInput={this.validPositive} className="lever_input form-control"/>
                <span className="input_lever_x green_txt">X</span>
              </div>
              <div className="copy_input_lever">
                <span className="input-lever-text">Short position</span>
                <input maxLength="3" type="text" className="lever_input form-control"/>
                <span className="input_lever_x red_txt">X</span>
              </div>
            </div> */}
          </div>

          <div className="copy_unify_box align_end_copy_set">
            <div className="copy_unify_label">{t("COPY_TRADING_AMOUNT")}</div>
            <div className="copy_unify_type_box">
              <div className="custom-control custom-radio custom-control-inline">
                <input type="radio" id="customRadioInline6" name="copytradeType" onChange={this.removeError} value={1} className="custom-control-input" checked={followerSettings && followerSettings.copytradeType==1 ? true:false} defaultChecked/>
                <label className="custom-control-label" for="customRadioInline6">{t("FIXED_AMOUNT")}</label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input type="radio" id="customRadioInline7" name="copytradeType" onChange={this.removeError} value={2} className="custom-control-input" checked={followerSettings && followerSettings.copytradeType==2 ? true:false}/>
                <label className="custom-control-label" for="customRadioInline7">{t("FIXED_RATIO")}</label>
              </div>                      
            </div>  
            <div className="copy_input_lever_box">
              <div className="copy_input_lever ml-0">
                <input min='1' type="number" onInput={this.validPositive} onChange={this.removeError} name="copytradeAmount" className="lever_input form-control" value={followerSettings ? followerSettings.copytradeAmount:''}/>
                { followerSettings.copytradeType == 1 && <span className="input-lever-text">{t("USDT")}</span> }
                { followerSettings.copytradeType == 2 && <span className="input-lever-text">{t("TIME")}</span> }
                <button type="button" className="copy_inp_cal" name="copytradeAmount" onClick={this.decrement}>-</button>
                <button type="button" className="copy_inp_cal" name="copytradeAmount" onClick={this.increment}>+</button>
              </div>
              <span className="text-danger">{errors.copytradeAmount}</span>
            </div>
          </div>

          <div className="copy_unify_box">
            <div className="copy_unify_label mar_top_align_setng">{t("ADVANCED_SETTING")}</div> 
            <div className="copy_input_lever_box">
              <div className="copy_input_lever ml-0">
                <span className="input-lever-text">{t("STOP_LOSS_RATIO_OPTIONAL")}</span>
                <input min='1' type="number" onInput={this.validPositive} onChange={this.removeError} name="stopLossRatio" className="lever_input form-control" value={followerSettings ? followerSettings.stopLossRatio:''}/>
                <span className="input-lever-text">%</span>
                <button type="button" className="copy_inp_cal" name="stopLossRatio" onClick={this.decrement}>-</button>
                <button type="button" className="copy_inp_cal" name="stopLossRatio" onClick={this.increment}>+</button>
              </div>
            </div>                     
            <div className="copy_input_lever_box">
              <div className="copy_input_lever ml-0">
                <span className="input-lever-text mar_top_align_setng">{t("TAKE_PROFIT_RATIO_OPTIONAL")}</span>
                <input min='1' type="number" onInput={this.validPositive} onChange={this.removeError} name="takeProfitRatio" className="lever_input form-control" value={followerSettings ? followerSettings.takeProfitRatio:''}/>
                <span className="input-lever-text">%</span>
                <button type="button" className="copy_inp_cal" name="takeProfitRatio" onClick={this.decrement}>-</button>
                <button type="button" className="copy_inp_cal" name="takeProfitRatio" onClick={this.increment}>+</button>
              </div>
            </div>
            <div className="copy_input_lever_box">
              <div className="copy_input_lever ml-0">
                <span className="input-lever-text">{t("MAXIMUM_POSITION")}</span>
                <input min='1' type="number" onInput={this.validPositive} onChange={this.removeError} name="maximumPosition" className="lever_input form-control" value={followerSettings ? followerSettings.maximumPosition:''}/>
                <span className="input-lever-text">{t("CONT")}</span>
                <button type="button" className="copy_inp_cal" name="maximumPosition" onClick={this.decrement}>-</button>
                <button type="button" className="copy_inp_cal" name="maximumPosition" onClick={this.increment}>+</button>
              </div>
              <span className="text-danger">{errors.maximumPosition}</span>
            </div>
          </div>
        </div>;
    } else {
      const { t } = this.props;
      return <div className="copy_setting_box">
        {
          forms && forms.map(form => (
            <div className="">
              <div class="pair-title"><span>{ form }</span></div>
              <div className="copy_unify_box">
                <div className="copy_unify_label">{t("LEVERAGE_MODE")}</div>
                <div className="copy_unify_type_box">
                  <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline3" name={"future_"+form} defaultChecked value="1" className="custom-control-input"/>
                    <label className="custom-control-label" for="customRadioInline3">{t("FUTURE_SETTING")}</label>
                  </div>
                  {/* <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline4" name={"future_"+form} value="2" className="custom-control-input"/>
                    <label className="custom-control-label" for="customRadioInline4">Follow the trader</label>
                  </div> */}
                  {/* <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline5" name="customRadioInline3" className="custom-control-input"/>
                    <label className="custom-control-label" for="customRadioInline5">Customized</label>
                  </div> */}
                </div>
                {/* <div className="copy_input_lever_box">
                  <div className="copy_input_lever">
                    <span className="input-lever-text">Long position</span>
                    <input maxLength="3" type="text" className="lever_input form-control"/>
                    <span className="input_lever_x green_txt">X</span>
                  </div>
                  <div className="copy_input_lever">
                    <span className="input-lever-text">Short position</span>
                    <input min='1' type="number" onInput={this.validPositive} className="lever_input form-control"/>
                    <span className="input_lever_x red_txt">X</span>
                  </div>
                </div> */}
              </div>

              <div className="copy_unify_box">
                <div className="copy_unify_label">{t("COPY_TRADING_AMOUNT")}</div>
                <div className="copy_unify_type_box">
                  <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" id={`copytradeType_${form}_1`} name={"copytradeType_"+form} value={1} className="custom-control-input" checked={followerSettings && followerSettings[`copytradeType_${form}`]==1 ? true:false} onChange={this.removeError} defaultChecked/>
                    <label className="custom-control-label" for={`copytradeType_${form}_1`}>{t("FIXED_AMOUNT")}</label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" id={`copytradeType_${form}_2`} name={"copytradeType_"+form} value={2} className="custom-control-input" checked={followerSettings && followerSettings[`copytradeType_${form}`]==2 ? true:false} onChange={this.removeError}/>
                    <label className="custom-control-label" for={`copytradeType_${form}_2`}>{t("FIXED_RATIO")}</label>
                  </div>
                </div>  
                <div className="copy_input_lever_box">
                  <div className="copy_input_lever ml-0">
                    <input min='1' type="number" onChange={this.removeError} onInput={this.validPositive} name={`copytradeAmount_${form}`} className="lever_input form-control" value={followerSettings ? followerSettings[`copytradeAmount_${form}`]:''}/>
                    { followerSettings[`copytradeType_${form}`] == 1 && <span className="input-lever-text">{t("USDT")}</span> }
                    { followerSettings[`copytradeType_${form}`] == 2 && <span className="input-lever-text">{t("TIME")}</span> }
                    <button type="button" className="copy_inp_cal" name={`copytradeAmount_${form}`} onClick={this.decrement}>-</button>
                    <button type="button" className="copy_inp_cal" name={`copytradeAmount_${form}`} onClick={this.increment}>+</button>
                  </div>
                  <span className="text-danger">{errors[`copytradeAmount_${form}`]}</span>
                </div>
              </div>

              <div className="copy_unify_box">
                <div className="copy_unify_label">{t("ADVANCED_SETTING")}</div> 
                <div className="copy_input_lever_box">
                  <div className="copy_input_lever ml-0">
                    <span className="input-lever-text">{t("STOP_LOSS_RATIO")}</span>
                    <input min='1' type="number" onInput={this.validPositive} name={`stopLossRatio_${form}`} className="lever_input form-control" value={followerSettings ? followerSettings[`stopLossRatio_${form}`]:''} onChange={this.removeError}/>
                    <span className="input-lever-text">%</span>
                    <button type="button" className="copy_inp_cal" name={`stopLossRatio_${form}`} onClick={this.decrement}>-</button>
                    <button type="button" className="copy_inp_cal" name={`stopLossRatio_${form}`} onClick={this.increment}>+</button>
                  </div>
                </div>
                <div className="copy_input_lever_box">
                  <div className="copy_input_lever ml-0">
                    <span className="input-lever-text">{t("TAKE_PROFIT_RATIO_OPTIONAL")}</span>
                    <input min='1' type="number" onInput={this.validPositive} name={`takeProfitRatio_${form}`} className="lever_input form-control" value={followerSettings ? followerSettings[`takeProfitRatio_${form}`]:''} onChange={this.removeError}/>
                    <span className="input-lever-text">%</span>
                    <button type="button" className="copy_inp_cal" name={`takeProfitRatio_${form}`} onClick={this.decrement}>-</button>
                    <button type="button" className="copy_inp_cal" name={`takeProfitRatio_${form}`} onClick={this.increment}>+</button>
                  </div>
                </div>
                <div className="copy_input_lever_box">
                  <div className="copy_input_lever ml-0">
                    <span className="input-lever-text">{t("MAXIMUM_POSITION")}</span>
                    <input min='1' type="number" onChange={this.removeError} onInput={this.validPositive} name={`maximumPosition_${form}`} className="lever_input form-control" value={followerSettings ? followerSettings[`maximumPosition_${form}`]:''}/>
                    <span className="input-lever-text">{t("CONT")}</span>
                    <button type="button" className="copy_inp_cal" name={`maximumPosition_${form}`} onClick={this.decrement}>-</button>
                    <button type="button" className="copy_inp_cal" name={`maximumPosition_${form}`} onClick={this.increment}>+</button>
                  </div>
                  <span className="text-danger">{errors[`maximumPosition_${form}`]}</span>
                </div>
              </div>
            </div>
            )
          )
        }
        </div>;
    }
  }

	render() {
   
    const { user } = this.props.auth;
    const {
      trader,
      settingType,
      pairs,
      forms,
      formObject,
      followersDetails,
      followerSettings,
      mySettings
    } = this.state;
    const { t } = this.props;
    console.log(t,'trader')
		return (<div className='bg_strip pt-5'>
			<section class="container-fluid px-0 pt-5">
      <Navtradebar />
	    </section>
			<section className="privacy-policy-container px-0 pt-0">
      <div className="inner_pageheader container-fluid px-0">
          <div className="inner_content_wrapper">
            <div className="container">
              <div className="whitebox">
                <div className="follow_trade_head">
                  <Link to="/copy-trading"><i className="bi bi-arrow-left"></i> <span>{t("FOLLOW_THE_TRADER")}</span></Link>
                  <p>{t("PROFIT_SHARE_RATIO")}</p>
                </div>
                <div className="copy_trading_panel_top mt-3">
                  <div>
                  {(trader?.profile ==null || trader?.profile ==undefined || trader?.profile == '') ? 
                                   (<img src={require("../images/user.png")} alt="User" className="" />) :
                                   (<img src={`${config.imageUrl}profile_images/${trader?.profile}`} alt="User" className="" />)
                                   }
                    {/* <img src={require("../images/user.png")} alt="User" className="" /> */}
                    <div className="copy_trading_panel_userdetail">
                      <h2>
                        { trader.name && trader.name.substring(0, 10) }
                        { !trader.name && trader.email!=null ? trader.email.split('@')[0].substring(0, 10):'' }
                      </h2>
                      {trader && trader.followersDetails && trader.traderDetails && 
                        <h5>{t("FOLLOWERS")} {followersDetails.length}/{trader.traderDetails.maxFollow} people</h5>
                      }                      
                    </div>
                  </div>
                  { (mySettings._id && mySettings.followStatus == 1) && <button className="follow_btn" data-toggle="modal" data-target="#unfollow-modal">{t("UNFOLLOW")}</button> }                 
                </div>
                <div className="trader_contract_box">
                  <div className="d-flex align-items-center">
                    <span className="contract_label">{t("FUTURES")}</span>
                    <div className="contract_pair_box">
                      <span>
                      {pairs && pairs.map(pair => (
                        
                        <button className={forms.findIndex(form => form===pair.tiker_root)!=-1 ? "contract_pair active":"contract_pair"} value={pair.tiker_root} onClick={this.updateForm}>{ pair.tiker_root }</button>
                      ))}
                    
                      {/* <button className="contract_pair active">BTC/USDT</button> */}
                      {/* <button className="contract_pair">ETH/USDT</button> */}
                      {/* <button className="contract_pair">XRP/USDT</button> */}
                      {/* <button className="contract_pair">LTC/USDT</button> */}
                      </span>
                    </div>
                  </div>
                  {/* <button className="follow_btn" data-toggle="modal" data-target="#transfer-modal">Transfer</button> */}
                </div>
                <p className='notes_blue_blk'>{t("ONLY_SELECTED_PAIRS")}</p>

                <div className="trader_contract_box justify-content-start mt-4">
                  <span className="contract_label">{t("FOLLOW_MODE")}</span>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline1" name="customRadioInline1" className="custom-control-input" value="1" checked={settingType===1} onChange={this.updateSettingType}/>
                    <label className="custom-control-label" for="customRadioInline1">{t("UNIFIED_SETTING")}</label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline2" name="customRadioInline1" className="custom-control-input" value="2" checked={settingType===2} onChange={this.updateSettingType}/>
                    <label className="custom-control-label" for="customRadioInline2">{t("RESPECTIVE_SETTING")}</label>
                  </div>
                </div>  
                <form onSubmit={this.validateSettingForm}>
                  {this.renderSettingForm()}
                  <div className="text-center mt-4">
                    <button className="inner_primary_btn">{t("NEXT")}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
			</section>
			<Footer />

      {/* Unfollow Modal */}    
      <div className="modal fade primary-modal" id="unfollow-modal" data-backdrop="static" tabindex="-1" aria-labelledby="unfollow-modalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="unfollow-modalLabel">{t("UNFOLLOW")}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text_white_moda">
              <p>{t("FOLLOWING_THIS_TRADER")}</p>
              <div className="mt-3 mb-3 d-flex align-items-center justify-content-end">
                <button type="button" className="inner_primary_btn mr-3 ml-0" data-dismiss="modal">{t("THINK_AGAIN")}</button>
                <button type="button" className="inner_primary_btn ml-0" onClick={this.unFollow}>{t("CONFIRM")}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Transfer Modal */}    
      <div className="modal fade primary-modal" id="transfer-modal" data-backdrop="static" tabindex="-1" aria-labelledby="transfer-modalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="transfer-modalLabel">{t("TRANSFER")}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form className="primary_form">
                <div className="form-group">
                  <label for="exampleFormControlSelect1">{t("COIN")}</label>
                  <select className="custom-select">
                    <option>{t("USDT")}</option>
                    <option>{t("BTC")}</option>
                    <option>{t("ETH")}</option>
                    <option>{t("LTC")}</option>
                    <option>{t("BCH")}</option>
                  </select>
                </div>
                <div className="form-group">
                  <div className="d-flex align-items-center justify-content-between">
                    <label for="formGroupExampleInput">{t("TRANSFER_AMOUNT")}</label>
                    <small>{t("BALANCE")}: <b>1.23569878 {t("USDT")}</b></small>
                  </div>
                  <div className="input_grp">
                    <input type="text" className="primary_inp_inner" id="formGroupExampleInput"/>
                    <span className="input_prepend yellow_txt pointer">{t("MAX")}</span>
                  </div>
                </div>
              </form>
              <div className="mt-3 mb-3 text-right">
                <button type="button" className="inner_primary_btn">{t("CONFIRM")}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Confirm Copy Trade Information Modal */}    
      <div className="modal fade primary-modal" id="confirm-copy-trade" data-backdrop="static" tabindex="-1" aria-labelledby="confirm-copy-tradeLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="confirm-copy-tradeLabel">{t("CONFIRM_COPY_TRADE_INFORMATION")}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
            	{
                    forms && forms.map(form => (
	              	<div className="setting_overview">
		                <div className="dialog_pair">{ form }</div>
		                <div>
		                  <span>{t("LEVERAGE_MODE")}</span>
		                  <span>{t("FUTURE_SETTING")}</span>
		                </div>
		                <div>
		                  <span>{t("FIXED")} { settingType==1 ? (formObject.copytradeType=='1' ? 'Cont':'Ratio'):(formObject[`copytradeType_${form}`]=='1' ? 'Cont':'Ratio') }</span>
		                  <span>{ settingType==1 ? formObject.copytradeAmount:formObject[`copytradeAmount_${form}`] }{ settingType==1 ? (formObject.copytradeType=='1' ? ' cont':'%'):(formObject[`copytradeType_${form}`]=='1' ? ' cont':'%') }</span>
		                </div>
		                <div>
		                  <span>{t("STOP_LOSS_RATIO")}</span>
		                  <span>{ settingType==1 ? formObject.stopLossRatio:formObject[`stopLossRatio_${form}`] }%</span>
		                </div>
		                <div>
		                  <span>{t("TAKE_PROFIT_RATIO")}</span>
		                  <span>{ settingType==1 ? formObject.takeProfitRatio:formObject[`takeProfitRatio_${form}`] }%</span>
		                </div>
		                <div>
		                  <span>{t("MAXIMUM_POSITION")}</span>
		                  <span>{ settingType==1 ? formObject.maximumPosition:formObject[`maximumPosition_${form}`] } cont</span>
		                </div>
	              	</div>)
	                )
                }
              <div className="text-center mt-3 mb-3">                
                <button type="button" className="banner_btn" onClick={this.finalSubmit}>{t("CONFIRM")}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

		</div>
		);
	}
}

CopyTradingSetting.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(  mapStateToProps,  {
  logoutUser
}  )(withTranslation()(CopyTradingSetting),withRouter(CopyTradingSetting));
