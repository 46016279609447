import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import Navbar from './Navbar'
import Footer from './Footer'
import BonusImg from "../images/bonus.png"
import speakerImg from "../images/speakerIcon.png"
import axios from "axios";
import { withTranslation } from 'react-i18next';
import keys from "../actions/config";
const url = keys.baseUrl;

class Bonus extends Component {
	constructor(props) {
			 super(props);
			 this.state = {
					 email:"",
           bonus:0,
					 contact_person:"",
					 sitename: "",
					 site_description:"",
					 favorite_image:"",
					 sitelogo: "",
					 phone_number:"",
					 mobile_number:"",
					 address: "",
					 tax_amount:"",
					 social_link1:"",
					 social_link2:"",
					 social_link3:"",
					 social_link4:"",
					 social_link5:"",
					 copyright_text: "",
					 working_day:"",
					 working_hours:"",
					 company_info_link:"",
					 license_info_link:"",
           fbcontent:"",
           telegramcontent:"",
           twittercontent:"",
           bonuscontent:"",
					 errors: {},
					 settings : {},
			 };


			 }

			 componentDidMount() {
    window.scrollTo(0, 0);
        
				 //console.log('call here');
					 this.getData()
           this.getbonusData()
           this.getcmsData()
           this.getcmsData1()
           this.getcmsData2()
           this.getcmsData3()
           this.getcmsData4()
			 };

	 getData() {
					axios
					.get(url+"cryptoapi/contact")
					 .then(res => {
						 this.setState({settings:res,social_link1:res.data.social_link1,social_link4:res.data.social_link4,social_link3:res.data.social_link3});
						})
					 .catch()
					 //console.log(this.setState,'this.setState');
	 }
   getbonusData() {
          axios
          .get(url+"cryptoapi/getbonusdetails")
           .then(res => {
           
            if(res.data.status)
            {
             this.setState({bonus:res.data.data.promo_bonus});
            }
            })
           .catch()
           //console.log(this.setState,'this.setState');
   }
   getcmsData() {
          axios
          .get(url+"cryptoapi/getcms/facebook")
           .then(res => {
            
             this.setState({fbcontent:res.data.content});
            })
           .catch()
           //console.log(this.setState,'this.setState');
   }
   getcmsData1() {
          axios
          .get(url+"cryptoapi/getcms/twittercontent")
           .then(res => {
            
             this.setState({twittercontent:res.data.content});
            })
           .catch()
           //console.log(this.setState,'this.setState');
   }
   getcmsData2() {
          axios
          .get(url+"cryptoapi/getcms/telegramcontent")
           .then(res => {
            
             this.setState({telegramcontent:res.data.content});
            })
           .catch()
           //console.log(this.setState,'this.setState');
   }
   getcmsData3() {
          axios
          .get(url+"cryptoapi/getcms/bonuscontent")
           .then(res => {
            
             this.setState({bonuscontent:res.data.content});
            })
           .catch()
           //console.log(this.setState,'this.setState');
   }
    getcmsData4() {
          axios
          .get(url+"cryptoapi/getcms/depbonuscontent")
           .then(res => {
            
             this.setState({depbonuscontent:res.data.content});
            })
           .catch()
           //console.log(this.setState,'this.setState');
   }
  createMarkup = () => {
  return { __html: this.state.fbcontent };
  }

  createMarkup1 = () => {
  return { __html: this.state.twittercontent };
  }

  createMarkup2 = () => {
  return { __html: this.state.telegramcontent };
  }
   createMarkup3 = () => {
  return { __html: this.state.bonuscontent };
  }
  createMarkup4 = () => {
  return { __html: this.state.depbonuscontent };
  }

	render() {
    const {t} = this.props;
		return (<div className='bg_strip'>
			<Navbar />
    <div className="modal fade primary-modal" id="questionnaire" tabindex="-1" role="dialog" aria-labelledby="questionnaire" aria-hidden="true" data-backdrop="static">
  <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div className="modal-content questionnaireContent">

    <div className="modal-header">
      <div>
									<h4 className='modal-title h4'>{t("QUESTIONNAIRE")}</h4>
                  <h2><small>$</small>5</h2>
                  </div>
									<button type="button" className="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
      <div className="modal-body">
     
      
        <div className="popUpSpace">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>{t("CRYPTO_DERIVATES_TRADING")}<sup>*</sup></label>
              <div className="answerList">
                <span className="radio">
                  <label>
                    <input type="radio" name="question1" value="" checked="" />
                      <span className="cr"><i className="cr-icon fa fa-circle"></i></span>
                      {t("LAST_3_MONTH")}
                  </label>
                </span>
                <span className="radio">
                  <label>
                    <input type="radio" name="question1" value="" />
                      <span className="cr"><i className="cr-icon fa fa-circle"></i></span>
                      {t("LAST_6_MONTH")}
                  </label>
                </span>
                <span className="radio">
                  <label>
                    <input type="radio" name="question1" value="" checked="" />
                      <span className="cr"><i className="cr-icon fa fa-circle"></i></span>
                      {t("MORE_THAN_1_YEAR")}
                  </label>
                </span>
                <span className="radio">
                  <label>
                    <input type="radio" name="question1" value="" />
                      <span className="cr"><i className="cr-icon fa fa-circle"></i></span>
                      {t("FEWS_DAYS")}
                  </label>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label>{t("CRYPTO_DERIVATIVES")}<sup>*</sup></label>
              <div className="answerList">
                <span className="radio">
                  <label>
                    <input type="radio" name="question2" value="" checked="" />
                      <span className="cr"><i className="cr-icon fa fa-circle"></i></span>
                      {t("LONG_TERM")}
                  </label>
                </span>
                <span className="radio">
                  <label>
                    <input type="radio" name="question2" value="" />
                      <span className="cr"><i className="cr-icon fa fa-circle"></i></span>
                      {t("SHORT_TERM")}
                  </label>
                </span>
                <span className="radio">
                  <label>
                    <input type="radio" name="question2" value="" checked="" />
                      <span className="cr"><i className="cr-icon fa fa-circle"></i></span>
                      {t("SWING_TRADING")}
                  </label>
                </span>
                <span className="radio">
                  <label>
                    <input type="radio" name="question2" value="" />
                      <span className="cr"><i className="cr-icon fa fa-circle"></i></span>
                      {t("INTRADAY")}
                  </label>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-12">
                <div className="form-group">
                  <input type="submit" name="" value="Submit" className="btn buttonType1" />
                </div>
              </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</div>
			<section className="innerCMS">
  <div className="container">
    <div className="row">
      <div className="col-md-10 mx-auto">
        {/* <img src={BonusImg} className="img-fluid cmsTopImg mt-1" /> */}
        <div className="darkBox bonusRewards mt-5">
          <div className="tableHead tableHeadBlock borderBottomRemove">
            <h2>{t("BONUS_REWARDS")}</h2>
            <p>{t("RISK_FREE_TRADING")}</p>
          </div>

          <div className="darkBoxSpace">
             <p><div dangerouslySetInnerHTML={this.createMarkup3()} className='editor'></div></p>
            <div className="bonusBox">
             <h2 className="mb-4">{this.state.bonus} <small>USD</small></h2>
            <div className="row mb-4">
              <div className="col-md-4 col-sm-4 mb-4 mb-sm-0">
                  <h4>{t("FACEBOOK")}</h4>
                  <p><div dangerouslySetInnerHTML={this.createMarkup()} className='editor'></div></p>
                  <p><a href={this.state.social_link4} className="bonusfaceIcon"><i className="fab fa-facebook-f"></i></a></p>
              </div>
              <div className="col-md-4 col-sm-4 mb-4 mb-sm-0">
              <div className="borLeftRight">
                  <h4>{t("TWITTER")}</h4>
                  <p><div dangerouslySetInnerHTML={this.createMarkup1()} className='editor'></div></p>
                   <p><a href={this.state.social_link1} className="bonustwitterIcon"><i className="fab fa-twitter"></i></a></p>
                   </div>
              </div>
              <div className="col-md-4 col-sm-4">
                  <h4>{t("TELEGRAM")}</h4>
                   <p><div dangerouslySetInnerHTML={this.createMarkup2()} className='editor'></div></p>
                    <p><a href={this.state.social_link3} className="bonusteleIcon"><i className="fab fa-telegram-plane"></i></a></p>
              </div>
            </div>
             <p className="btnClaim text-center"><a href="https://docs.google.com/forms/d/e/1FAIpQLSfGqJeBfMFFykJfn_YXO8OhM4NuWmEbLd_LBmDVaiQNeb-gtQ/viewform?vc=0&c=0&w=1" className="btn buttonType1">{t("CLAIM_NOW")}</a></p>
          </div>
           <div className="row mt-2 ml-2">
           <p><div dangerouslySetInnerHTML={this.createMarkup4()} className='editor'></div></p>
           </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
			<Footer />
		</div>
		);
	}
}

export default (withTranslation()(Bonus),Bonus)
