import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import keys from "../actions/config";
import axios from "axios";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Navtradebar from "./Navtradebar";
import { withTranslation } from 'react-i18next';
const url = keys.baseUrl;

class Fee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      fbcontent: "",
      currencydetails: [],
      subcategory1: [],
      articledata: [],
      spotdata: [],
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);

    this.getData();
  }

  getData() {
    axios
      .post(url + "cryptoapi/perpetual-data")
      .then(category => {
        //console.log("da in sub", category);
        var perparray = category.data.data;
        var indexarr = [0, 1, 2, 3, 4];
        var outputarr = indexarr.map(i => perparray[i]);
        this.setState({ records: perparray });
      })
      .catch();
    axios
      .post(url + "cryptoapi/spot-data")
      .then((category) => {
        this.setState({ spotdata: category.data.data });
        // //console.log("spot",this.state.spotdata)
      })
      .catch();

    axios
      .get(url + "cryptoapi/currencydetails")
      .then(currencydetails => {
        //console.log("(currencydetails.data", currencydetails.data);
        if (currencydetails.data.status) {
          var perparray = currencydetails.data.data;
          var indexarr = [2, 0, 4, 1, 3];
          var outputarr = indexarr.map(i => perparray[i]);
          this.setState({ currencydetails: perparray });
        }
      })
      .catch();

    axios
      .get(url + "cryptoapi/getcms/feepage")
      .then(res => {

        this.setState({ fbcontent: res.data.content });
      })
      .catch()
    //console.log(this.setState, 'this.setState');
  }
  categoryselect = event => {
    var userselectcategoryid1 = event.currentTarget.dataset.item;
    //console.log("userselectcategoryid", userselectcategoryid1);
    this.setState({ userselectcategoryid: userselectcategoryid1 });
  };
  createMarkup = () => {
    return { __html: this.state.fbcontent };
  }

  render() {
    const {
      category,
      articledata,
      subcategory,
      userselectcategoryid
    } = this.state;
    const { t } = this.props;
    return (
      <div>
        <section class="headerCrediantials homeBanner">
        <Navtradebar />
        </section>
        <section className="innerCMS fees_cms">
          <div className="container">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="darkBox contentPage">
                  <div className="tableHead tableHeadBlock">
                    <h2>{t("BELL EXCHANGE FEE SCHEDULE")}</h2>
                  </div>
                  <div className="darkBoxSpace lokalFee">
                    <p><div dangerouslySetInnerHTML={this.createMarkup()} className='editor'></div></p>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default (withTranslation()(Fee),Fee);
