import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import Navbar from './Navbar'
import Footer from './Footer'
import LeverageImg from "../images/leverage.png"
import LeverageChartImgPng from "../images/leverageBarChart.png"
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import io from "socket.io-client";
import { withTranslation } from 'react-i18next';


import axios from "axios";
import keys from "../actions/config";
const url = keys.baseUrl;
// Rc Slider - https://react-component.github.io/slider/
const marks = {
  1: '1x',
  25: '25x',
  50: '50x',
  75: '75x',
  100: '100x',
  125: '125x',
  150: {
    style: {
      color: '#00b5eb',
    },
    label: <strong>150x</strong>,
  },
};
class Leverage extends Component {

   constructor(props) {
        super(props);
        this.state = {
            subject:"",
            identifier:"",
            content: "",
            errors: {},
            Leverage : {},
            leveragevalue: 150,
            quantity: '',
            open_price: '',
            close_price: '',
            leverage: 0,
            initial_margin: 0,
            profitnloss: 0,
            profitnlossper: 0,
            order_cost: 0,
            btcprice: 0,
            order_value: 0,
            taker_fee: 0,
            buyorsell: "Buy",
            ROE: 0,
            records:[]
        };
        this.socket = io(keys.socketUrl,{secure: true,transports:['polling'],jsonp:false,rejectUnauthorized:false}); //live

        // this.socket = io(keys.socketUrl,{transports:['polling']});

        this.socket.on('PRICEDETAILS', function(data){
            priceappenddata(data);
        });
          const priceappenddata = data => {

            if(data != null){
              if(data.tiker_root=='BTCUSD')
              {
                  var floating = (this.state.chartpair=='XRPUSD')?4:2;
                  this.setState({markprice:parseFloat(data.markprice).toFixed(floating),last_price:data.last});
                  if(this.state.prevoiusprice!=0 && this.state.prevoiusprice < data.markprice )
                  {
                      this.setState({spotpricecolor:"greenText"});
                  }
                  else
                  {
                      this.setState({spotpricecolor:"pinkText"});
                  }
                  this.setState({prevoiusprice:this.state.markprice,floating:floating});
              }
              }
          }
        }

      handleChangeComplete = value => {
          this.setState({
          leveragevalue: value
      });
      }

      handleShow1 = (param) =>{
    var type     = param.target.innerHTML;
    this.setState({buyorsell:type})
  }

      onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
      }

      calculation = () => {
        //console.log(this.state.quantity);
        //console.log(this.state.open_price);
        //console.log(this.state.close_price);
        //console.log(this.state.buyorsell);
        if(this.state.quantity!='' && this.state.open_price!='' && this.state.close_price!='')
        {

          var orderprice = this.state.open_price;

          var order_value1 = parseFloat(this.state.quantity*orderprice).toFixed(8);

          var order_value = parseFloat(order_value1/orderprice).toFixed(8);

          var initial_margin = parseFloat(order_value1)/this.state.leveragevalue;

          var fee = parseFloat(order_value1)*this.state.taker_fee/100;

          var margininbtc = parseFloat(initial_margin)/parseFloat(orderprice);
          var feeinbtc = parseFloat(fee)/parseFloat(orderprice);

          var order_cost = parseFloat(margininbtc)+parseFloat(feeinbtc);

          var profitnlossusd = (parseFloat(this.state.close_price)) - (parseFloat(this.state.open_price));
          var profitnlossusd = parseFloat(profitnlossusd)*parseFloat(this.state.quantity);
          var profitnloss    = parseFloat(profitnlossusd)/parseFloat(this.state.open_price);
          var open_value     = parseFloat(this.state.quantity)/parseFloat(this.state.open_price);
          var profitnlossper = (parseFloat(profitnloss)*100);
          var roe            = profitnlossper*10;
          if(this.state.buyorsell=='Buy')
          {
              this.setState({order_cost:parseFloat(order_cost).toFixed(8),order_value:parseFloat(order_value).toFixed(8),initial_margin:parseFloat(margininbtc).toFixed(8),profitnloss:parseFloat(profitnloss).toFixed(8),profitnlossper:parseFloat(profitnlossper).toFixed(2),ROE:parseFloat(roe).toFixed(2)});
          }
          else
          {
            this.setState({initial_margin:parseFloat(margininbtc).toFixed(8),profitnloss:parseFloat(profitnloss*-1).toFixed(8),profitnlossper:parseFloat(profitnlossper*-1).toFixed(2),ROE:parseFloat(roe*-1).toFixed(2)});
          }
        }
      }


        componentDidMount() {
            this.getData()
        };
    createMarkup = () => {
          return { __html: this.state.Leverage.data.content };
        }

    getData() {
           axios
           .get(url+"cryptoapi/leverage")
            .then(res => {
              this.setState({Leverage:res});
             })
            .catch()

             axios
           .post(url+"cryptoapi/perpetual-data")
            .then(res => {
              var btcindex = res.data.data.findIndex(x => (x.tiker_root) === 'BTCUSD');
              this.setState({records:res.data.data,btcprice:res.data.data[btcindex].markprice,taker_fee:res.data.data[btcindex].taker_fees});
             })
            .catch()
    }




	render() {
    const {t} = this.props;
		return (<div>
			<section class="headerCrediantials homeBanner">
      <Navbar />
      </section>

			<section className="innerCMS">
  <div className="container">
    <div className="row">
      <div className="col-md-10 mx-auto">
        <img src={LeverageImg} className="img-fluid cmsTopImg mb-1" />
        <div className="darkBox contentPage leverageContent">
          <div className="tableHead tableHeadBlock">
            <h2>{t("LEVERAGE_TRADING")}</h2>
            <h6>{t("LEVERAGE_ENABLES")}</h6>
          </div>
          <div className="darkBoxSpace">
            {this.state.Leverage.data?
            <div>
                <p><div dangerouslySetInnerHTML={this.createMarkup()} className='editor'></div></p>
            </div>
            :''}
            <h3 className="text-center text-white mb-4">{t("LEVERAGE_CALCULATOR")}</h3>

            <div className="row">
              <div className="col-md-6">
                <div className="CalculaterPart">
                <div className="mt-4 mb-5">
               {/* <Slider min={1} max={150} marks={marks} included={false} onChange={this.handleChangeComplete} value={this.state.leveragevalue} />*/}

                </div>
                <h6 className="text-center text-white mb-4">{t("LEVERAGE")} : <span> {this.state.leveragevalue} </span></h6>

                   <div className="form-group inputWithText">
                      <label></label>
                     <div class="btn-group leverageBuySell"><button class="btn btnGroupBorderBtn py-2 active" onClick={this.handleShow1.bind(this)}>{t("BUY")}</button><button class="btn btnGroupBorderBtn py-2" onClick={this.handleShow1.bind(this)}>{t("SELL")}</button></div>
                  </div>
                  <div className="form-group inputWithText">
                      <label>{t("ENTYRY_PRICE")}</label>
                      <input name="open_price" id="open_price" onChange={this.onChange} className="form-control" value={this.state.open_price} type="text" />
                      <div className="input-group-append-icon"><small></small></div>
                  </div>
                  <div className="form-group inputWithText">
                      <label>{t("EXIT_PRICE")}</label>
                      <input name="close_price" id="close_price" onChange={this.onChange} className="form-control" value={this.state.close_price} type="text" />
                      <div className="input-group-append-icon"><small></small></div>
                  </div>
                  <div className="form-group inputWithText">
                      <label>{t("QUANTITY")}</label>
                      <input name="quantity" id="quantity" className="form-control" onChange={this.onChange} value={this.state.quantity} type="text" />
                      <div className="input-group-append-icon"><small></small></div>
                  </div>
                  <h6 className="text-center text-white mb-4">{t("SPOT_PRICE")}: <span className={this.state.spotpricecolor}>$ {this.state.markprice} </span></h6>
                  <div className="form-group">
                    <p className="text-center"><input type="submit" className="inputcenter btn buttonType2" onClick={this.calculation} value="Calculate" /></p>
                  </div>
                </div>
              </div>
               <div className="col-md-6">
                 <div className="calcResultBox">
                   <h4>{t("RESULT")}</h4>
                   <p> <p>{t("LEVERAGE_CALCULATOR_ALLOWS")}</p></p>
                   <ul className="calcResultList">
                     <li>
                       <p>{t("ORDER_VALUE")}</p>
                       <p><span>{this.state.order_value}</span> - {t("BTC")}</p>
                     </li>
                     <li>
                       <p>{t("ORDER_COST")}</p>
                       <p><span>{this.state.order_cost}</span> - {t("BTC")}</p>
                     </li>
                     <li>
                       <p>{t("INITIAL_MARGIN")}</p>
                       <p><span>{this.state.initial_margin}</span> - {t("BTC")}</p>
                     </li>
                     <li>
                       <p>{t("PNL")}</p>
                       <p><span>{this.state.profitnloss}</span>- {t("BTC")}</p>
                     </li>
                     <li>
                       <p>{t("PNL")} %</p>
                       <p><span>{this.state.profitnlossper}</span>- %</p>
                     </li>
                     <li>
                       <p>{t("PNL")}</p>
                       <p><span>{this.state.ROE}</span>- %</p>
                     </li>
                   </ul>
                 </div>
               </div>
            </div>
            <p className="text-center mt-4">
             <Link to={"/trade/BTC-USD"} className="btn buttonType2">{t("START_TRADING")}</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
			<Footer />
		</div>
		);
	}
}

export default (withTranslation()(Leverage),Leverage)
