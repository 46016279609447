import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import Navbar from './Navbar'
import Footer from './Footer'
import axios from "axios";
import keys from "../actions/config";
const url = keys.baseUrl;
class PresidentMessage extends Component {

  
	componentDidMount() {
		window.scrollTo(0, 0);
  }
    
	render() {
		return (<div className='bg_strip'>
			<section class="headerCrediantials pt-5">
      <Navbar />
      </section>
			<section className="innerCMS">
			
			  <div className="container">
			    <div className="row">
			      <div className="col-md-10 mx-auto">
			        <div className="darkBox contentPage">
			          <div className="tableHead tableHeadBlock">
			            <h2>President Message</h2>
			          </div>
			          <div className="darkBoxSpace px-3 px-md-4">
						<p>Bell Exchange Platform: it is the ultimate venue where successful digital asset traders meet new investors, in a transparent, safe and efficient peer-2-peer investment environment and of course I am glad to announce that we are working towards the Islamic Syariah Compliance Exchanger, backed by our Japan partners expertise in Blockchain technology.</p>
						<p>The real meaning of Bell Exchange itself in Japan is moving forward - do our best to become the top. Our philosophy - tetsukagu - do our best, not just for our business, but also our customer. Bell Exchange welcome investors from around the world to board into our platforms, allow you to compare the performance of hundreds of successful cryptocurrency traders and mirror their trades automatically to their account. While traders benefit from success fees generated by profitable trading - investors will also enjoy peace of mind knowing that their funds are following a model of an industry professional in the comfort of their own.</p>
						<p>Our Bell Exchange platform represents a unique proposition for the blockchain space in Southeast Asia, by removing or reducing entry barriers to the crypto market that may otherwise prevent consumers from adopting blockchain. From a Bell Exchange point of view, opening a fiat exchange improves its users' experience by enabling them to seamlessly convert across several fiat currencies and then make transactions directly from the exchange account using the new currency. Even more significantly, fiat to fiat exchanges generally offer users interbank exchange rates as against retail exchange rates, which means that users get more for their money.</p>
						<p>Best Regards,<br />Bell Exchange Team.</p>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			   
			</section>
			<Footer />
		</div>
		);
	}
}

export default PresidentMessage