import React, { Component } from 'react'
import { a } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Navbar from './Navbar'
import Footer from './Footer'
import Navtradebar from './Navtradebar'
import axios from "axios";
import { Link, withRouter } from 'react-router-dom';
import keys from "../actions/config";
import LogoNewFixHead from "../images/logo.png"
import LogoLightTheme from "../images/logo.png"
import { withTranslation } from 'react-i18next';
// Datatable
import DataTable from 'react-data-table-component';

import { unrealizedPnLPercentage } from '../lib/bybit';

import * as moment from "moment";

import io from "socket.io-client";
//import { getUserData } from '../actions/authActions';
import { logoutUser } from "../actions/authActions";
import { store } from 'react-notifications-component';

import { toast } from 'react-toastify';
toast.configure();

const url = keys.baseUrl;
// const {t} = this.props;
// Copy Trade Table
const table_data = [
  {position: <div><span class="badge badge-danger">Short10X</span> BTC/USDT</div>, openprice: '51711.88 USDT ', closeprice: '51252.50 USDT', opentime: '09-06 02:28:35', closetime: "09-06 17:35:28", "plratio": <span className="green_txt">+8.88%</span>, "ordernumber": '516726906915232'},
  {position: <div><span class="badge badge-danger">Short10X</span> BTC/USDT</div>, openprice: '51711.88 USDT ', closeprice: '51252.50 USDT', opentime: '09-06 02:28:35', closetime: "09-06 17:35:28", "plratio": <span className="green_txt">+8.88%</span>, "ordernumber": '516726906915232'},
  {position: <div><span class="badge badge-danger">Short10X</span> BTC/USDT</div>, openprice: '51711.88 USDT ', closeprice: '51252.50 USDT', opentime: '09-06 02:28:35', closetime: "09-06 17:35:28", "plratio": <span className="green_txt">+8.88%</span>, "ordernumber": '516726906915232'},
  {position: <div><span class="badge badge-danger">Short10X</span> BTC/USDT</div>, openprice: '51711.88 USDT ', closeprice: '51252.50 USDT', opentime: '09-06 02:28:35', closetime: "09-06 17:35:28", "plratio": <span className="green_txt">+8.88%</span>, "ordernumber": '516726906915232'},
  {position: <div><span class="badge badge-danger">Short10X</span> BTC/USDT</div>, openprice: '51711.88 USDT ', closeprice: '51252.50 USDT', opentime: '09-06 02:28:35', closetime: "09-06 17:35:28", "plratio": <span className="green_txt">+8.88%</span>, "ordernumber": '516726906915232'},
  {position: <div><span class="badge badge-danger">Short10X</span> BTC/USDT</div>, openprice: '51711.88 USDT ', closeprice: '51252.50 USDT', opentime: '09-06 02:28:35', closetime: "09-06 17:35:28", "plratio": <span className="green_txt">+8.88%</span>, "ordernumber": '516726906915232'},
];
                                                                   
const table_columns = [
  {
    name: 'Position',
    selector: 'position',
  },
  {
    name: 'Open Price',
    selector: 'openprice',
  },
  {
    name: 'Close Price',
    selector: 'closeprice',
  },
  {
    name: 'Open time',
    selector: 'opentime',
  },
  {
    name: 'Close time',
    selector: 'closetime',
  },
  {
    name: 'P/L Ratio(%)',
    selector: 'plratio',
  },
  {
    name: 'Order number',
    selector: 'ordernumber',
  },
];

class CopyTradingMyAccount extends Component {

  constructor(props) {
    super(props);
console.log("props",props)
    this.getTraderHistory = this.getTraderHistory.bind(this);
    this.autoUnFollow = this.autoUnFollow.bind(this);
    this.copyTradeNav = this.copyTradeNav.bind(this);

    var userId = '';
    if(this.props.auth && this.props.auth.user && this.props.auth.user.id) {
      userId = this.props.auth.user.id;
    }

    this.state = {
      subject:"",
      content: "",
      autoUnFollow:0,
      errors: {},
      privacy : {},
      trader: {},
      myProfile: false,
      userDetail: {},
      followerList: [],
      followDetail: {},
      userId: userId,
      userDetail:{},
      traderId: '',
      tdata_history:[],
      tdata_followerList: [],
      tcol_followerList: [
        {
          name: 'Followers',
          selector: 'followers',
        },
        {
          name: 'Total vol(USDT)',
          selector: 'totalvol',
        },
        {
          name: 'Profit(USDT)',
          selector: 'profit',
        }
      ],

      tcol_copyTradeDetail: [
        {name: 'Position',selector: 'position'},
        {name: 'Open Price',selector: 'openPrice'},
        {name: 'Current Price',selector: 'currentPrice'},
        {name: 'Open Time',selector: 'openTime'},
        {name: 'P/L Ratio(%)',selector: 'pandl'},
        {name: 'Trader',selector: 'traderDet'},
        {name: 'Order Number',selector: 'orderNumber'},
      ],
      tcol_copyTradeSummary: [
        {name: 'Position',selector: 'position'},
        {name: 'Open Price',selector: 'openPrice'},
        {name: 'Current Price',selector: 'currentPrice'},
        {name: 'P/L Ratio(%)',selector: 'pandl'},
      ],
      tcol_tradeHistory: [
        {name: 'Position',selector: 'position'},
        {name: 'Open Price',selector: 'openPrice'},
        {name: 'Close Price',selector: 'closePrice'},
        {name: 'Open Time',selector: 'opentime'},
        {name: 'Close Time',selector: 'closetime'},
        {name: 'P/L Ratio(%)',selector: 'pandl'},
        {name: 'Trader',selector: 'traderDet'},
        {name: 'Order Number',selector: 'orderNumber'}
      ],
      tcol_myTrader: [
        {name: 'Trader',selector: 'trader'},
        {name: 'Copy trade capital',selector: 'copytradecapital'},
        {name: 'Profit',selector: 'profit'},
        {name: 'Operation',selector: 'operation'},
      ],

      tdata_copyTradeDetail: [],
      tdata_copyTradeSummary: [],
      tdata_tradeHistory: [],

      tdata_copyTradeTarget: 'copyTradeDetail',
      tdata_myTrader: [],

      markpriceAll: {},
      positionDetailAll: [],

      myTraderList: [],
      traderHistory: [],
    };

    this.socket = io(keys.socketUrl);

    this.socket.on('perpetualMarketPrice', (result) => {
      indexPriceWS(result)
    })

    const indexPriceWS = (result) => {
      // console.log('indexPriceWS : ', result);
      if (result && result.first_currency && result.second_currency && result.data && result.data.markprice) {
        var markpriceAll = this.state.markpriceAll;
        markpriceAll[result.first_currency+result.second_currency] = parseFloat(result.data.markprice);
        this.setState({ markpriceAll: markpriceAll });
        this.resetCopyTradeList();
      }
    }
  }

  onLogoutClick = e => {
    const {t} = this.props;
    e.preventDefault();
    store.addNotification({
      title: t("LOGOUT_SUCCESS"),
      message: t("COME_JOIN"),
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
    let data = {userId:this.props.auth.user.id}
    this.props.logoutUser(data);
  };
  async getUserdata() {
    var userId = this.props.auth.user.id;
    if (userId) {
      var data = {
        id: this.props.auth.user.id,
      }
      axios.post(url + 'api/v1/copytrade/trader/getUserdata', data).then(resp => {
        console.log(resp,"resp")
        this.setState({ userDetail: resp.data.userdata ,autoUnFollow:resp.data.autoUnFolHrs})
      }).catch((err) => {
        console.log(err, 'err')
      })
    }
  }
  async componentDidMount () {
    window.scrollTo(0, 0);
    // await this.getTraderDetail();
    // const userid={
    //   id:this.props.auth.user.id
    // }
    //  await getUserData(userid);
    await this.getUserdata()
    await this.getFollowerDetail();
    await this.getCopyTrade();
    this.getTraderHistory();
  };

  useQuery() {
    const { search } = this.props.location;
    return new URLSearchParams(search);
  }

  renderPrivacyPolicyText() {
    return (
      <div>
      </div>
    )
  }

  copyTradeNav(target, e) {
    this.setState({tdata_copyTradeTarget: target});
    this.getCopyTrade(target);
  }

  getFollowerDetail() {
    var userId = this.props.auth.user.id;
    if(userId) {
      var data = {
        userId: this.props.auth.user.id,
      }
      axios.post(url+'api/v1/copytrade/trader/getFollowerDetail', data).then(resp => {
        var result = resp.data;
        console.log(result,'resultttttttttttttttt')
        var jsonState = {
          myTraderList: [],
          traderHistory: [],
          tdata_myTrader: []
        };
        if(result.traderHistory) {
          jsonState.traderHistory = result.traderHistory;
        }
        if(result.myTraderList) {
          jsonState.myTraderList = result.myTraderList;

          var tdata_myTrader = [];

          for (let i = 0; i < jsonState.myTraderList.length; i++) {
            const item = jsonState.myTraderList[i];
            var newObj = {
              trader: '',
              copytradecapital: '',
              profit: '',
              operation: ''
            };
            var trader = item.myTraderDetail && item.myTraderDetail.name != ''?
              item.myTraderDetail.name
            :
              (item.myTraderDetail.email.split('@')[0].length<8)?item.myTraderDetail.email.split('@')[0]:item.myTraderDetail.email.split('@')[0].substring(0, 7)+'...';
            newObj.trader = trader;
            newObj.copytradecapital = (item.orderCostInUsdt).toFixed(4);
            newObj.profit = (item.pAndLInUsdt).toFixed(4);
            newObj.operation = <Link to={'copy-trader-info?id='+item.myTraderDetail._id} className="navbar-brand innerLogo">Edit</Link>;
            tdata_myTrader.push(newObj);
          }

          jsonState.tdata_myTrader = tdata_myTrader;
        }
        if(Object.keys(jsonState).length > 0) {
          this.setState(jsonState);
        }
      }).catch(e => {
        console.log(e);
      });
    }
  }

  getTraderHistory() {
    var userId = this.props.auth.user.id;
    if(userId) {
      var data = {
        userId: userId,
        target: 'followerProfile'
      }
      axios.post(url+'api/v1/copytrade/trader/history', data).then(resp => {
        var result = resp.data;
        var jsonState = {tdata_tradeHistory:[]};

        if(result.list) {
          var history = result.list;
          for (let i = 0; i < history.length; i++) {
            const element = history[i];
            var newObj = {
              position: '',
              openPrice: '',
              closePrice: '',
              opentime: '',
              closetime: '',
              pandl: '',
              traderDet: '',
              orderNumber: ''
            };

            var buyorsell = (element.closing_direction == 'Closed long') ? 'buy' : 'sell';
            var leverage = element.leverage;
            var pairName = element.pairName;

            newObj.position = <div> <div class={buyorsell=='buy'?'greenText':'pinkText'}>{buyorsell=='buy' ? 'Long' : 'Short'}</div> {leverage}X {pairName} Isolated</div>;
            newObj.openPrice = element.entry_price;
            newObj.closePrice = element.exit_price;
            newObj.opentime = (element.orderId && element.orderId.filled && element.orderId.filled[0] && element.orderId.filled[0].created_at) ? moment(element.orderId.filled[0].created_at).format("DD-MM-YYYY  h:mm a ") : '';;
            newObj.closetime = moment(element.createdDate).format("DD-MM-YYYY  h:mm a ");
            newObj.pandl = element.pAndL_per;
            newObj.orderNumber = (element.orderId && element.orderId._id) ? element.orderId._id : '';

            if(
              element.orderId
              && element.orderId.traderId
              && element.orderId.traderId._id
          ) {
              var traderDet = element.orderId.traderId.name ? element.orderId.traderId.name : element.orderId.traderId.email.split('@')[0].substring(0, 10);
              newObj.traderDet = <Link to={'copy-trader-info?id='+element.orderId.traderId._id} >{traderDet}</Link>;
          }

            jsonState.tdata_tradeHistory.push(newObj);

          }
        }
        this.setState(jsonState);
      });
    }
  }

  async getCopyTrade(target = this.state.tdata_copyTradeTarget) {
    var {
      userId,
      traderId,
    } = this.state;

    if(userId) {
      var data = {
        traderId: traderId,
        userId: userId,
        target: target
      }
      axios.post(url+'api/v1/copytrade/trader/getCopyTradeList', data).then(resp => {
        var result = resp.data;

        var jsonState = {getCopyTradeList : []};
        if(result.success == true && result.results) {
          if(result.positionDetailAll) {
            jsonState.positionDetailAll = result.positionDetailAll;
          }
          jsonState.getCopyTradeList = result.results;
        }
        this.setState(jsonState);
        this.resetCopyTradeList(jsonState.getCopyTradeList);
      }).catch((err)=>{
        console.log(err,"error")
      })
    }
  }

  resetCopyTradeList(getCopyTradeList = this.state.getCopyTradeList) {
    var {
      userId,
      myProfile,
      traderId,
      tdata_copyTradeTarget,
      markpriceAll,
      positionDetailAll,
    } = this.state;
    var newResult = [];
    if(getCopyTradeList) {
      for (let i = 0; i < getCopyTradeList.length; i++) {
        const element = getCopyTradeList[i];

        var newObj = {};
        if(tdata_copyTradeTarget == 'copyTradeSummary') {
          newObj = {
            position: '',
            openPrice: '',
            currentPrice: '',
            pandl: '',
          };

          var openPrice = element.openPrice;

          var {
            buyorsell,
            leverage,
            pairName
          } = element._id;
        }
        else {
          newObj = {
            position: '',
            openPrice: '',
            currentPrice: '',
            openTime: '',
            pandl: '',
            traderDet: '-',
            orderNumber: '',
          };

          var {
            buyorsell,
            openPrice,
            leverage,
            pairName
          } = element;

          if(
            element.myTraderDetail && element.myTraderDetail[0]
            && element.myTraderDetail[0]._id && element.myTraderDetail[0]._id[0]
            && element.myTraderDetail[0].name && element.myTraderDetail[0].email
            && (element.myTraderDetail[0].name[0] || element.myTraderDetail[0].email[0])
          ) {
            var traderDet = element.myTraderDetail[0].name[0] ? element.myTraderDetail[0].name[0] : element.myTraderDetail[0].email[0].split('@')[0].substring(0, 10);
            newObj.traderDet = <Link to={'copy-trader-info?id='+element.myTraderDetail[0]._id} >{traderDet}</Link>;
          }

          newObj.openTime = moment(element.openTime).format("DD-MM-YYYY  h:mm a ");
          newObj.orderNumber = element._id;
        }

        newObj.position = <div> <div class={buyorsell=='buy'?'greenText':'pinkText'}>{buyorsell=='buy' ? 'Long' : 'Short'}</div> {leverage}X {pairName} Isolated</div>;
        newObj.openPrice = openPrice;

        if(markpriceAll && markpriceAll[pairName]) {
          newObj.currentPrice = markpriceAll[pairName];

          var index = positionDetailAll.findIndex(el => el.tiker_root == pairName);
          var positionDetail = (index > -1) ? positionDetailAll[index] : {};

          if(positionDetail.taker_fees) {
            console.log(newObj,'newObjnewObjnewObj')
            var det = {
              'price': newObj && newObj.openPrice != undefined ? newObj.openPrice : 0,
              'quantity': 1,
              'lastPrice': newObj.currentPrice,
              'leverage': leverage,
              'takerFee': positionDetail.taker_fees,
              'buyorsell': (buyorsell == 'sell') ? 'buy' : 'sell'
            };
            var pandl = unrealizedPnLPercentage(det).toFixed(2);
            if(pandl >= 0) {
              newObj.pandl = <div class='greenText'>{pandl}</div>;
            } else {
              newObj.pandl = <div class='pinkText'>{pandl}</div>;
            }
          }
        }
        newResult.push(newObj);
      }
    }

    if(tdata_copyTradeTarget == 'copyTradeSummary') {
      var jsonState = {tdata_copyTradeSummary: newResult};
      this.setState(jsonState);
    }
    else if(tdata_copyTradeTarget == 'copyTradeDetail') {
      var jsonState = {tdata_copyTradeDetail: newResult};
      this.setState(jsonState);
    }
  }

  autoUnFollow() {
    var userId = '';
    if(this.props.auth && this.props.auth.user && this.props.auth.user.id) {
      userId = this.props.auth.user.id;
    }
    if(userId == '') {
      toast('Please login to continue', {position: toast.POSITION.TOP_CENTER});
    }
    else {
      var data = {userId: userId}
      axios.post(url+'api/v1/copytrade/trader/unfollow', data).then(resp => {
        if(resp.data.toast) {
          toast(resp.data.toast.message, {position: toast.POSITION.TOP_CENTER});
          setInterval(() => {
            window.location.reload();
          }, 400);
        }
        // if(typeof resp.data.traderFollowMode != 'undefined') {
        //   this.props.auth.getUser.traderFollowMode = resp.data.traderFollowMode;
        // }
      })
    }
  }

	render() {
    const { user, getUser } = this.props.auth;
console.log('getuser',getUser,getUser && getUser.length > 0)
    const {
      tcol_copyTradeDetail,
      tcol_copyTradeSummary,
      tcol_tradeHistory,
      tcol_myTrader,
      userDetail,
      tdata_copyTradeDetail,
      tdata_copyTradeSummary,
      tdata_tradeHistory,
      tdata_myTrader,
autoUnFollow,
      tdata_copyTradeTarget,

      myTraderList,

      traderHistory,

    } = this.state;

    const paginationRowsPerPageOptions = [5, 10, 15, 20];
const {t} = this.props;
		return (<div className='bg_strip'>
			<section class="container-fluid px-0">
      <Navtradebar />
	    </section>
			<section className="privacy-policy-container px-0 pt-0">
      <div className="inner_pageheader container-fluid px-0">
          <div className="inner_heading_wrapper pt-5">
            <div className="container text-center">
              <div className="row">
                <div className="col-md-12">
                  <div class="holder">
                    <img src={require("../images/user.png")} alt="logo" id="imgPreview" className="img-fluid"/>
                  </div>
                  <div className="profile_edit_panel">
                    <div className="profile_edit_icon">
                      <i className="fas fa-pencil-alt"></i>
                    </div>
                    <input type="file" name="photograph" id="photo" required="true" />
                  </div>
                  {/* <h2>ran*********@gmail.com</h2> */}
                  <h2>
                    {
                      userDetail && userDetail?.name=='' ?
                        (userDetail.email.split('@')[0].length<8)?userDetail.email.split('@')[0]:userDetail.email.split('@')[0].substring(0, 7)+'...'
                      :
                      userDetail && userDetail?.name
                    }
                  </h2>
                  {console.log('getUser[0]?.traderFollowMode',userDetail && userDetail?.traderFollowMode,getUser)}
                  <div className="custom-control custom-switch">
                    <input type="checkbox" className="custom-control-input" id="customSwitch1" checked={userDetail && userDetail?.traderFollowMode ? true : false} />
                    <label className="custom-control-label text-white" for="customSwitch1" onClick={() => this.autoUnFollow()}>{t("AUTOMATICALLY_UNFOLLOW")}</label>
                  </div>
                  <p className='mt-4'>{t("WHEN_TRADER_DOES_NOT_OPEN")} {autoUnFollow && autoUnFollow} {t("CONSECUTIVE_HOURS")}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="inner_content_wrapper">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="whitebox">
                    <div className="copy_trade_user_data">
                      <h3 className='text-white'>{t("COPY_TRADE_DATA")}</h3>
                      <div>
                        <h3 className='text-white'>{traderHistory && traderHistory.orderCostInUsdt ? (traderHistory.orderCostInUsdt).toFixed(4) : 0}</h3>
                        <p>{t("COPY_TRADE_USD")}</p>
                      </div>
                      <div>
                        <h3 className="text-white">{traderHistory && traderHistory.pAndLInUsdt ? (traderHistory.pAndLInUsdt).toFixed(4) : 0}</h3>
                        <p>{t("COPY_TRADE_NET_PROFIT")}</p>
                      </div>
                    </div>
                  </div>

                  <div className="whitebox py-0 px-0">
                    <ul class="nav nav-tabs primary_tab tab_bottom_border" id="myTab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <a class="nav-link active" id="pills-ranking-tab" data-toggle="pill" href="#pills-ranking" role="tab" aria-controls="pills-ranking" aria-selected="true">{t("COPY_TRADE")}</a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a class="nav-link" id="pills-return-tab" data-toggle="pill" href="#pills-return" role="tab" aria-controls="pills-return" aria-selected="false">{t("HISTORY")}</a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a class="nav-link" id="pills-transaction-tab" data-toggle="pill" href="#pills-transaction" role="tab" aria-controls="pills-transaction" aria-selected="false">{t("MY_TRADER")}</a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a class="nav-link" id="pills-followers-tab" data-toggle="pill" href="#pills-followers" role="tab" aria-controls="pills-followers" aria-selected="false">{t("FOLLOW_INFORMATION")}</a>
                      </li>
                    </ul> 
                    <div class="tab-content px-3 pb-3" id="pills-tabContent">
                      <div class="tab-pane fade show active" id="pills-ranking" role="tabpanel" aria-labelledby="pills-ranking-tab">
                        <ul class="nav nav-tabs primary_tab inner_tab_style_blue" id="myTab" role="tablist">
                          <li class="nav-item" role="presentation">
                            <a class={tdata_copyTradeTarget == 'copyTradeDetail'?'nav-link active':'nav-link'} onClick={() => this.copyTradeNav('copyTradeDetail')}>{t("DETAILS")}</a>
                          </li>
                          <li class="nav-item" role="presentation">
                            <a class={tdata_copyTradeTarget == 'copyTradeSummary'?'nav-link active':'nav-link'} onClick={() => this.copyTradeNav('copyTradeSummary')}>{t("SUMMARY")}</a>
                          </li>
                        </ul>
                        {console.log(tdata_copyTradeDetail,'tdata_copyTradeDetail',tdata_copyTradeSummary)}
                        {tdata_copyTradeTarget == 'copyTradeDetail'?
                          <DataTable className="history_table" columns={tcol_copyTradeDetail} data={tdata_copyTradeDetail} pagination={true} paginationPerPage="5" paginationRowsPerPageOptions={paginationRowsPerPageOptions} />
                        :
                          <DataTable className="history_table" columns={tcol_copyTradeSummary} data={tdata_copyTradeSummary} pagination={true} paginationPerPage="5" paginationRowsPerPageOptions={paginationRowsPerPageOptions} />
                        }
                      </div>
                      <div class="tab-pane fade" id="pills-return" role="tabpanel" aria-labelledby="pills-return-tab">
                        <DataTable className="history_table" columns={tcol_tradeHistory} data={tdata_tradeHistory} pagination={true} paginationPerPage="5" paginationRowsPerPageOptions={paginationRowsPerPageOptions} />
                      </div>
                      <div class="tab-pane fade" id="pills-transaction" role="tabpanel" aria-labelledby="pills-transaction-tab">
                        <DataTable className="history_table" columns={tcol_myTrader} data={tdata_myTrader} pagination={true} paginationPerPage="5" paginationRowsPerPageOptions={paginationRowsPerPageOptions} />
                      </div>
                      <div class="tab-pane fade" id="pills-followers" role="tabpanel" aria-labelledby="pills-followers-tab">
                        {
                          myTraderList.map((item, i) => {
                            const { t } = this.props;
                            return (
                              <div className="setting_overview mt-3">
                                <div className="dialog_pair">{t("TRADER")}: 
                                  {
                                  item.myTraderDetail && item.myTraderDetail.name != ''?
                                    item.myTraderDetail.name
                                  :
                                    (item.myTraderDetail.email.split('@')[0].length<8)?item.myTraderDetail.email.split('@')[0]:item.myTraderDetail.email.split('@')[0].substring(0, 7)+'...'
                                  }
                                  <div>
                                    <span><b>{t("FUTURES")}</b></span>
                                    <span><b>{t("MAX_AMOUNT_BALANCE")}</b></span>
                                  </div>
                                  {
                                    item.detail.map((itemA, ii) => {
                                      return(<div>
                                        <span>{item.perpetualAs[ii].tiker_root}</span>
                                        <span>
                                          {itemA.maximumFollow}/{ ii != 0 ? itemA.maximumFollow : (item.orderCostInUsdt).toFixed(4)}
                                        </span>
                                      </div>)
                                    })
                                  }
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
			</section>
			<Footer />
		</div>
		);
	}
}

CopyTradingMyAccount.propTypes = {
  //getUserData: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(  mapStateToProps,  {
  logoutUser,
  //getUserData
}  )(withTranslation()(CopyTradingMyAccount),withRouter(CopyTradingMyAccount));