import React, { Component } from 'react'
import {Link,withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TradeHeader from './TradeHeader'
import Footer from './Footer'
import DepositeQR from "../images/depositeQR.jpg"
import Select from "react-select";
import BtcIcon from "../images/btcIcon.png"
import EthIcon from "../images/ethIcon.png"
import XrpIcon from "../images/xrpIcon.png"
import LtcIcon from "../images/ltcIcon.png"
import BchIcon from "../images/bchIcon.png"
import {Modal,Button} from 'react-bootstrap/';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import classnames from "classnames";
import { store } from 'react-notifications-component';
import axios from "axios";
import keys from "../actions/config";
import coinAddressValidator from 'coin-address-validator';
import { withTranslation } from 'react-i18next';

const url = keys.baseUrl;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "red" : "blue",
    padding: 20,
    display: state.isDisabled || state.isSelected ? "none" : "block"
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  }
};

class ManageAddress extends Component {
	constructor() {
			super();
			this.state = {
				addAddress :  false,
				tagshow :  false,
				currencyData :  [],
				getAddressDetails :  [],
				cryptoAddress : '',
				currencyName : '',
				name : '',
				tagid : '',
				errors          : {},
			}
		}

    styles = {
      option: (provided, state) => ({
        ...provided,
        color: "white",
        backgroundColor: "#000",
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        height: '52px',
        padding: '0 6px',
        backgroundColor: "#03081f",
        borderColor: '#81c8f6',
      borderRadius: 10,
      borderStyle: 'solid',
      borderWidth: '1px'
       
      }),
      control: (provided, state) => ({
        ...provided,
        height: '52px',
        borderRadius:10,
        backgroundColor: "#03081f",
        border:'none'
       
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '52px',
        position: 'absolute',
        right: 0,
        top: 0,
        color:'#fff' 
      }),    
      singleValue: (provided, state) => ({
        ...provided,
        color: "#fff"
      })
    };
		componentDidMount() {
      window.scrollTo(0, 0);
				this.getAddress();
				this.getCurrencyData();
		}

		getAddress() {
			let userid = this.props.auth.user.id;
				axios
						.get(url+"cryptoapi/getaddress/"+userid)
						.then(res => {
              this.setState({getAddressDetails:res.data.data});
						});
			}
      getCurrencyData() {
        axios
            .get(url+"cryptoapi/getcurrency")
            .then(res => {
              var currencyselect = [];
              var getAllCurrency = res.data.data;
              //console.log(getAllCurrency);
              for (var i = 0; i < getAllCurrency.length; i++) {
                // if(getAllCurrency[i].currencySymbol=='BTC' || getAllCurrency[i].currencySymbol=='ETH' || getAllCurrency[i].currencySymbol=='USDT')
                // {
                 var from_val = {
                   value: getAllCurrency[i].currencySymbol,
                   label: getAllCurrency[i].currencyName
                 };
                 currencyselect.push(from_val);

                // }
                }
              this.setState({currencyData:currencyselect});
            });
       }
       
	    handleDeleteaddress = (details) => {
        const {t} = this.props;

          var id = details._id;
          var passVal = {id:id}
          axios
              .post(url+"cryptoapi/deleteaddress",passVal)
              .then(res => {
                //console.log(res);
                store.addNotification({
                  title: t("SUCCESS"),
                  message: t("DELETED_SUCCESSFULLY"),
                  type: "success",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animated", "fadeIn"],
                  animationOut: ["animated", "fadeOut"],
                  dismiss: {
                    duration: 1500,
                    onScreen: true
                  }
                });

                 this.getAddress();
              })

      }

	    addAddressSubmit = () => {
        const {t} = this.props;
        var message = "";      
      if(this.state.currencyName == ""){
        message = t("CURRENCY_NAME_SHOULD_NOT_EMPTY");
      }
      if(this.state.cryptoAddress == ""){
        message = t("ADDRESS_SHOULD_NOT_EMPTY");
      }
      if(this.state.name == ""){
        message = t("NAME_SHOULD_NOT_EMPTY");
      }
      if(message != ""){
        store.addNotification({
          title: t("ERRORS"),
          message: message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true,
            pauseOnHover: true
          }
        });
        return false;
      }
      // var isCurrencyAddress  = coinAddressValidator.validate(this.state.cryptoAddress, this.state.currencyName, 'prod');
      // if(!isCurrencyAddress){
      //   store.addNotification({
      //     title: "Error!",
      //     message: "Please Enter Correct "+this.state.currencyName+" Address",
      //     type: "danger",
      //     insert: "top",
      //     container: "top-right",
      //     animationIn: ["animated", "fadeIn"],
      //     animationOut: ["animated", "fadeOut"],
      //     dismiss: {
      //       duration: 1500,
      //       onScreen: true,
      //       pauseOnHover: true
      //     }
      //   });
      //   return false;
      // }

      let userid = this.props.auth.user.id;
      var passData = {};
      passData.userId = userid;
      passData.address = this.state.cryptoAddress;
      passData.currency = this.state.currencyName;
      passData.name = this.state.name;
      passData.tagid = this.state.tagid;
      console.log("passData",passData);
      axios
					.post(url+"cryptoapi/addAddress",passData)
					.then(res => {
            console.log('res.status.status',res.data,res.data.status)
            if(res.data.status){ 
              store.addNotification({
                title: t("SUCCESS"),
                message: res.data.message,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 1500,
                  onScreen: true
                }
              });
              this.setState({addAddress:false})
              this.getAddress();
            }else{
              store.addNotification({
                title: t("ERRORS"),
                message: res.data.message,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 1500,
                  onScreen: true
                }
              });
            }
					});
			}
      openaddAddress = () => {
        this.setState({currencyName: "",
        cryptoAddress: "",
        name: "",
        addAddress:true
      });
      }

      handleCloseAddress = () => {
        this.setState({addAddress:false});
      }

			onChange = e => {
        if(e.target.id == "name"){
				 	if (/[^a-zA-Z]/.test(e.target.value))
                return 
        }
        this.setState({ [e.target.id]: e.target.value });
			};
			onChangeSelect = selectedOption => {
          var tagshow = selectedOption.value=='XRP'?true:false;
				 	this.setState({ currencyName: selectedOption.value,tagshow:tagshow });
			};

      renderAddressData() {
        const {t} = this.props;
        // console.log(this.state.getAddressDetails.length,"this.state.getAddressDetails");
        if(this.state.getAddressDetails.length > 0 ){
          return this.state.getAddressDetails.map((details, index) => {
            return (
             <tr className="wow flipInX" data-wow-delay=".1s;">
              {console.log('details.currency',details.currency,details.address,details.name,details.tagid)}
                   <td>{details.currency} </td>
                   <td>{details.address}</td>
                   <td>{details.name} </td>
                   <td>{details.tagid ? details.tagid : "-"} </td>
                   <td>
                     <div className="assetDownMain"  >
                       <div onClick={()=>{this.handleDeleteaddress(details)}} title="Delete" className="text-center"><i class="far fa-trash-alt"></i></div>
                     </div>
                   </td>
               </tr>
                
              )
             })
        }
        else{
          return (<tr><td colSpan={5} className="text-center">{t("NO_RECORDS_FOUND")}</td></tr>)
        }
      
      }

  render() {
    const {t} = this.props;
		const { errors } = this.state;
    return (
			<div className='bg_strip'>
        
			<Modal className="primary-modal" show={this.state.addAddress} onHide={this.handleCloseAddress}  aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
				<Modal.Header closeButton>
				<Modal.Title>{t("ADD_ADDRESS")}</Modal.Title>
				</Modal.Header>
				<Modal.Body className='pb-0'>
				<div className="popUpSpace">
        <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label>{t("CURRENCY")}</label>
            <Select isSearchable={false}
              width="100%"
              menuColor="red"
              options={this.state.currencyData}
              onChange={this.onChangeSelect}
              styles={this.styles} className="border_blue_select"
            />
          </div>
        </div>
           <div className="col-md-12">
             <div className="form-group">
               <label>{t("ADDRESS")}</label>
               <input
                  onChange={this.onChange}
                  value={this.state.cryptoAddress}
                  id="cryptoAddress"
                  type="text"
                  className={classnames("form-control")}/>
             </div>
           </div>
           {
             (this.state.tagshow)?
             <div className="col-md-12">
             <div className="form-group">
             <label>{t("TAG_OPTIONAL")}</label>
             <input
             onChange={this.onChange}
             value={this.state.tagid}
             id="tagid"
             type="text"
             className={classnames("form-control")}/>
             </div>
             </div>: ''
           }
           <div className="col-md-12">
             <div className="form-group">
               <label>{t("NAME")}</label>
               <input
                  onChange={this.onChange}
                  value={this.state.name}
                  id="name"
                  type="text"
                  className={classnames("form-control")}/>
             </div>
           </div>
          </div>
      	</div>
				</Modal.Body>
				<Modal.Footer className='pt-0 pb-5'>
					<Button variant="secondary btnDefaultNewBlue" onClick={this.handleCloseAddress}>
					{t("CANCEL")}	
					</Button>
					<Button onClick={this.addAddressSubmit} variant="primary btnDefaultNew" >
					{t("CONFIRM")}	
					</Button>
				</Modal.Footer>
			</Modal>
				<TradeHeader />
				<div className="container">
    		<section className="tradeMain pt-5">
			  <div className="row pt-5">
			    <div className="col-md-12">
			      <div className="tradeLimitMarket assetsTable tradeLimitMarket_h">
			        <div className="tab-content">
			          <div className="assetTableTop mx-0 mb-5">
                 <h3 className='assetTitle mb-0'>{t("MANAGE_ADDRESS")}</h3>
                  <button className="btnDefaultNew" onClick={()=>{this.openaddAddress()}} variant="primary">{t("ADD")}</button>
                </div>
			           <div className="table-responsive table_radius">
               <table id="assetsTable" className="table">
                      <thead>
                          <tr className="wow flipInX" data-wow-delay=".5s;">
                              <th>{t("COIN")}</th>
                              <th>{t("WALLET_ADDRESS")}</th>
                              <th>{t("NAME")}</th>
                              <th>{t("MEMO_TAG")}</th>
                              <th className="text-center">{t("ACTION")}</th>
                          </tr>
                      </thead>
                      <tbody>
										   	{this.renderAddressData()}
                      </tbody>
                  </table>
			          </div>

			        </div>
			      </div>
			    </div>
			  </div>
			</section>
			</div>
    	<Footer />
			</div>
			);
    }
}

ManageAddress.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
)(withTranslation()(ManageAddress),withRouter(ManageAddress));
