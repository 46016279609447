import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import { store } from "react-notifications-component";
import axios from "axios";
import {Link,withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import keys from "../actions/config";
import Navbar from './Navbar'
import Footer from './Footer'
import { withTranslation } from 'react-i18next';

const url = keys.baseUrl;

class Landing extends Component {
  constructor(props) {

    super(props);

  this.state = {
    pairdetails : [],
    result:"",
    totalinv:""
  }
}
  componentDidMount() {
    const {t} = this.props;
    let userid = this.props.auth.user.id;
    this.getData();
    //console.log('userid');
    //console.log(userid);
    //console.log('typeof this.props.auth.user.id');
    //console.log(typeof this.props.auth.user.id);
    let pairdata = {exchangetype:"Spot"}
    axios
        .post(url + "cryptoapi/pair-data", pairdata)
        .then((res) => {
          if (res.status) {
           this.setState({pairdetails:res.data.data})
          } else {
            
          }
          // this.props.history.push("/login");
        })
        .catch();
   let withdraw_id = this.props.match.params.id;
    if (typeof withdraw_id != "undefined" && withdraw_id != "") {
      let passdata = { withdrawid: withdraw_id };
      axios
        .post(url + "cryptoapi/withdrawrequest", passdata)
        .then((res) => {
          if (res.data.status) {
            store.addNotification({
              title: test("SUCCESS"),
              message: res.data.message,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: t("ERRORS"),
              message: res.data.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          }
          this.props.history.push("/login");
        })
        .catch();
    }
  }
getData(){
  axios
  .get(url +"cryptoapi/get-latest-stakingcount")
   .then((res) => {
    if(res &&  res.data){
      this.setState({result:res.data.result})
      this.setState({totalinv:res.data.totalinv})
    }
   })
}
  render() {
    const { user } = this.props.auth;
    return (
      <div>
     <header className="stackingBanner">
  <div className="container">
  <Navbar />
  <div id="carouselExampleIndicators" className="carousel slide carousel-fade py-5" data-ride="carousel">
    <div className="carousel-inner" role="listbox">
      <div className="carousel-item active">
        <div className="stackingBannerContent">
          <h2>Earn Crypto While Sleeping</h2>
          <h4>Stake top crypto from our Easy Investing Platform</h4>
          <img src={require("../images/abtimg2.png")} alt="" className="img-fluid d-block mx-auto my-4" />
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="input-group">
                <input type="text" className="form-control" placeholder="Enter Your Email Address" />
                <div className="input-group-append">
                  <button className="btn btnYellow">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>  
</header>
<section className="bitAbout stakeMoreReturn">
  <div className="container">
    <div className="row">
      <div className="col-md-5">
        <div className="abtContent wow fadeInUp">
          <h3><span>More Stake</span> More Return</h3>
          <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
          <div className="stakeHomeBox">
            <img src={require("../images/usdtbig.png")} className="img-fluid" />
            <h3>Top APY <span>12.8%</span></h3>
            <button className="btn btnLandingJoin px-4">Stake Now</button>
            <p><a href="#">More</a></p>
          </div>
        </div>
      </div>
      <div className="col-md-7">
        <div className="abtImg wow flipInX">
          <img src={require("../images/abtimg1.png")} className="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</section>
<section className="stakePlans">
  <div className="container">
    <h2 className="stakeInvestorTitle wow fadeInUp pt-0 mb-0" data-wow-delay="1s"><span>Bitbazzi</span>Staking Plans</h2>
    <div className="row">
      <div className="col-lg-4 col-md-6 flexColumn" style={{marginRight: 'auto'}}>
        <div className="whyCard wow flipInY">
          <div className="whySpace">
            <h3>Flexible</h3>
            <ul>
              <li><i className="fas fa-square-full"></i> More than 18 cryptos</li>
              <li><i className="fas fa-square-full"></i> Auto transfer</li>
              <li><i className="fas fa-square-full"></i> Auto renewal</li>
              <li><i className="fas fa-square-full"></i> Easy Redeem</li>
            </ul>
            <Link to="/StakingList"><button className="btn btnLandingJoin px-4">Stake Now</button></Link>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 flexColumn">
        <div className="whyCard wow flipInY">
          <div className="whySpace">
            <h3>Fixed</h3>
            <ul>
              <li><i className="fas fa-square-full"></i> More than 18 cryptos</li>
              <li><i className="fas fa-square-full"></i> 15,30,60,90 Days</li>
              <li><i className="fas fa-square-full"></i> Auto renewal</li>
              <li><i className="fas fa-square-full"></i> High Return</li>
            </ul>
            <Link to="/StakingList"><button className="btn btnLandingJoin px-4">Stake Now</button></Link>
          </div>
        </div>
      </div>
      {/*<div className="col-lg-4 col-md-6 flexColumn">
        <div className="whyCard wow flipInY">
          <div className="whySpace">
            <h3>Dual Investments</h3>
            <ul>
              <li><i className="fas fa-square-full"></i> More than 18 cryptos</li>
              <li><i className="fas fa-square-full"></i> Flexi Subscription</li>
              <li><i className="fas fa-square-full"></i> Auto renewal</li>
              <li><i className="fas fa-square-full"></i> Easy Return</li>
            </ul>
            <Link to="/StakingList"><button className="btn btnLandingJoin px-4">Stake Now</button></Link>
          </div>
        </div>
      </div>*/}
    </div>
  </div>
</section>
<section className="countableStake">
  <div className="container">
    <div className="row">
      <div className="col-md-3">
        <div className="countCard wow flipInY">
          <h2>{0}<small>Staking Crypto</small></h2>{/* 24hours */}
        </div>
      </div>
      <div className="col-md-3">
        <div className="countCard wow flipInY">
        <h2>{0}<small>Interest Earning Per Day</small></h2> {/* per day */}
        </div>
      </div>
      <div className="col-md-3">
        <div className="countCard wow flipInY">
        <h2>{this.state.totalinv}<small>Investment Per Day</small></h2> {/* per day */}
        </div>
      </div>
      <div className="col-md-3">
        <div className="countCard wow flipInY">
        <h2>{this.state.result}<small>Satisfied Investors</small></h2> 
        </div>
      </div>
    </div>
  </div>
</section>
<section className="coinTable stakeInvesMain py-5">
  <div className="container">
    <div className="coinTableMain wow fadeInUp" data-wow-delay=".5s">
    <div className="row">
                <div className="col-md-12 ">
                   <h2 className="stakeInvestorTitle"><span>Top 10</span>Staking Investors</h2>
                  <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-pair1" role="tabpanel" aria-labelledby="nav-pair1-tab">
                      <div className="table-responsive">
                        <table className="table stakeInverstmentTable">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Investor ID</th>
                              <th>Plan</th>
                              <th>Investment</th>
                              <th>24H Earnings</th>
                            </tr>
                          </thead>
                          <tbody>
                           <tr>
                             <td>{0}</td>
                             <td>{0}</td>
                             <td>{0}</td>
                             <td><img src={require("../images/usdtmidd.png")} className="img-fluid" /> {0}</td>  
                            {/*  <td><img src={require("../images/usdtmidd.png")} className="img-fluid" /> {0}</td>*/}
                             <td>${0}</td>
                           </tr>
                            
                          </tbody>                       
                        </table>
                      </div>
                    </div>

                  </div>
                
                </div>
              </div>
            </div>
  </div>
</section>
<section className="faqStake">
  <div className="container">

                    <h2 className="stakeInvestorTitle pt-0">FAQ</h2>

                  <div className="faqCont">
          <div className="homeAccordian wow fadeIn">

              <div id="accordion">
                                    <div className="card">
                                      <div className="card-header wow flipInX" id="headingOne">
                                        <h5 className="mb-0">
                                        <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"><span className="question">1. What is Locked Staking？</span> <i className="fa fa-plus" aria-hidden="true"></i></button>
                                        </h5>
                                      </div>
                                      <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                        <div className="card-body">
                                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="card">
                                      <div className="card-header wow flipInX" id="headingTwo">
                                        <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"><span className="question">2. What’s the difference between Flexible Staking and Locked Staking？</span> <i className="fa fa-plus" aria-hidden="true"></i></button>
                                        </h5>
                                      </div>
                                      <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                        <div className="card-body">
                                          <p>Urna condimentum mattis pellentesque id nibh tortor aliquet. Urna condimentum mattis pellentesque id nibh tortor aliquet. Urna condimentum mattis pellentesque id nibh tortor aliquet. Urna condimentum mattis pellentesque id nibh tortor aliquet.</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="card">
                                      <div className="card-header wow flipInX" id="headingThree">
                                        <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"><span className="question">3. What will happen when I'm holding Locked Staking?</span> <i className="fa fa-plus" aria-hidden="true"></i></button>
                                        </h5>
                                      </div>
                                      <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                        <div className="card-body">
                                          <p>Urna condimentum mattis pellentesque id nibh tortor aliquet. Urna condimentum mattis pellentesque id nibh tortor aliquet. Urna condimentum mattis pellentesque id nibh tortor aliquet. Urna condimentum mattis pellentesque id nibh tortor aliquet. Urna condimentum mattis pellentesque id nibh tortor aliquet.. Urna condimentum mattis pellentesque id nibh tortor aliquet. Urna condimentum mattis pellentesque id nibh tortor aliquet. Urna condimentum mattis pellentesque id nibh tortor aliquet.</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="card">
                                      <div className="card-header wow flipInX" id="headingFour">
                                        <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour"><span className="question">4. What will happen when you're holding Locked Staking?</span> <i className="fa fa-plus" aria-hidden="true"></i></button>
                                        </h5>
                                      </div>
                                      <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                        <div className="card-body">
                                          <p>Urna condimentum mattis pellentesque id nibh tortor aliquet. Urna condimentum mattis pellentesque id nibh tortor aliquet. Urna condimentum mattis pellentesque id nibh tortor aliquet. Urna condimentum mattis pellentesque id nibh tortor aliquet.</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="card">
                                      <div className="card-header wow flipInX" id="headingFive">
                                        <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive"><span className="question">5. What will happen in a redeem period?</span> <i className="fa fa-plus" aria-hidden="true"></i></button>
                                        </h5>
                                      </div>
                                      <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                        <div className="card-body">
                                          <p>Urna condimentum mattis pellentesque id nibh tortor aliquet. Urna condimentum mattis pellentesque id nibh tortor aliquet. Urna condimentum mattis pellentesque id nibh tortor aliquet. Urna condimentum mattis pellentesque id nibh tortor aliquet. Urna condimentum mattis pellentesque id nibh tortor aliquet.. Urna condimentum mattis pellentesque id nibh tortor aliquet. Urna condimentum mattis pellentesque id nibh tortor aliquet. Urna condimentum mattis pellentesque id nibh tortor aliquet.</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>


            </div>
        </div>
      </div>
</section>
    <Footer />
      </div>
);
  }
}

Landing.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps
)(withTranslation()(Landing),withRouter(Landing));
