import React, { Component, useTransition } from 'react'
import axios from "axios";
import { Form } from 'react-bootstrap'
import { connect } from "react-redux";
import keys from "../actions/config";
import { Link, withRouter } from 'react-router-dom';
import logo from '../images/footerlogo.svg'
import Settings from './Settings';
import { store } from 'react-notifications-component';
import { withTranslation } from 'react-i18next';
const url = keys.baseUrl;


class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: {},
      validation: true,
      cmsData: [],
      lang: ""
    };
  }

  componentDidMount() {
    this.getData()
    this.getallCmsData()
    const localLanguage = localStorage.getItem('lang')
    this.setState({lang: localLanguage})
    console.log('aksfkgsdfdsvjdvd', this.state.validation)
  };
  componentWillReceiveProps(nextProps){
    console.log("auth_auth_auth",nextProps);
    this.setState({lang: nextProps.auth.language})
  }

  createMarkup = () => {
    return { __html: this.state.licence.data.content };
  }
  getData() {
    axios
      .get(url + "cryptoapi/contact")
      .then(res => {
        console.log("res", res)
        this.setState({ settings: res.data });
      })
      .catch()
  }
  getData1() {
    axios
      .get(url + "cryptoapi/licence")
      .then(res => {
        this.setState({ licence: res });
      })
      .catch()
  }


  Emailvalidataion = () => {
    let error = {}

    if (this.state.email != "" && this.state.email != null && this.state.email != "undefined" && this.state.email != undefined) {
      let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zA-Z\-0-9]+\.)+[A-Za-zA-Z]{2,}))$/;
      let validation = emailRegex.test(this.state.email);
      return validation;
    }
    else {
      return error.value = "Email is required!"
    }


  }


  onChange = (e) => {
    if (e.target.id == 'email') {
      this.setState({ email: e.target.value })
    }
  }


  Subscribe = async () => {
    const {t} = this.props;
    let error = {};
    console.log('errorsgal', error)

    let valid = await this.Emailvalidataion()
    console.log('skbfhasbsdgsdsj',valid)
    if (valid) {
      error.value = valid;
      this.setState({ emailerr: true })
    }
    if (!valid) {
      this.setState({ validation: valid })
      this.setState({ emailerr: false })

    }

    if (valid && this.state.email != "" && this.state.email != null && this.state.email != "undefined" && this.state.email != undefined) {
      let data = {
        email: this.state.email
      }
      this.setState({emailerr : false})
      console.log('ah sbjkgasu', data)

      await axios.post(url + "api/subscribe", data)
        .then((res) => {
          console.log('reqqwfasddfadfasf', res.data.status)
          if (res.data.status == 'success') {
            store.addNotification({
              title: t("SUCCESS"),
              message: t("SUBSCRIBED_SUCCESSFULLY"),
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 1500,
                onScreen: true
              }
            })
          }
          else {
            store.addNotification({
              title: t("ERRORS"),
              message: "Email Id Already Exist!",
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 1500,
                onScreen: true
              }
            })
          }
        })
    }

  }

  getallCmsData = async () => {
    const result = await axios.get(url + "cryptoapi/getallCmsData");
    // setCmsData(result.data.result)
    this.setState({cmsData: result.data.result})
    console.log("result_result_result", result.data.result);
  }
  cmsFilter = (data) => {
    const filter = this.state.cmsData?.find((el) => el.identifier == data);
    console.log("data_data_data_cmsFilter", filter?.spanishText);
    if (filter) {
      let checkLang = localStorage.getItem('lang') == "en" ? filter.content : filter.spanishText
      return checkLang
    }
  }
  cmsFilterImage = data => {
    console.log("cmsFilterImage_cmsFilterImage", data);
    const filter = this.state.cmsData?.find((el) => el.identifier == data);
    console.log("cmsFilterImage_cmsFilterImage", filter.bannerImage);

    if (filter) {
      return filter.bannerImage
    }
  }

  render() {
    const { user } = this.props.auth;
    const { t } = this.props;
    console.log("useruseruser", user)

    return (
      <footer className="mainFooter">

        <div class="container container-theme">
          <div className='row'>
            <div className='col-xl-4 col-lg-6 mb-4'>
              <div>
                <img src={logo} className="footerlogo" />
                <p className='text-light'>{this.cmsFilter("landing_banner_23")} </p>
                <div className='mt-lg-5'>

                  <ul>
                    <li className='text-light'><p className='mb-0 f-14'><span className='fa fa-globe mr-2'></span> {t("LOREM_IPSUM")},123456</p></li>
                    <li className='text-light'><p className='mb-0 f-14'><span className='fa fa-envelope mr-2'></span> {t("HELPLOREM")}</p></li>
                    <li className='text-light'><p className='mb-0 f-14'><span className='fa fa-phone mr-2'></span>+91 9685741230</p></li>
                  </ul>

                </div>
              </div>
            </div>
            <div className='col-xl-2 col-sm-6 mb-4'>
              <ul>
                <li><h4>{this.cmsFilter("landing_banner_24")}</h4></li>
                <li><a href="/about">{this.cmsFilter("landing_banner_25")}</a></li>
                {/* <li><a href="#">Referral</a></li> */}
                <li><a href="/terms">{this.cmsFilter("landing_banner_26")}</a></li>
                 <li><a href="/faq">{this.cmsFilter("landing_banner_27")}</a></li>

                {/* <li><a href="#">API</a></li>
                <li><a href="#">Coin Info</a></li> */}
              </ul>
            </div>
            <div className='col-xl-2 col-sm-6 mb-4'>
              <ul>
                <li><h4>{this.cmsFilter("landing_banner_28")}</h4></li>
                <li><a href="/trade">{this.cmsFilter("landing_banner_29")}</a></li>
                {/* <li><a href="/fee">Fee & Condition</a></li> */}
                {/* <li><a href="#">Security</a></li> */}
                <li><Link to="/settings">{this.cmsFilter("landing_banner_30")}</Link></li>
                <li><Link to="/Contact_us">{this.cmsFilter("landing_banner_33")}</Link></li>
               
            {/* <li><a href="#">Bonus</a></li> */}
              </ul>
            </div>
            <div className='col-xl-4 col-lg-6 mb-4'>
              <div>
                <h4 className='mb-3 text-light'>{this.cmsFilter("landing_banner_31")}</h4>
                <div className='d-sm-flex gap-2'>
                  <div className="inputgrp d-flex">
                    <input type='email' id="email" className="newletter_inp" placeholder={t("EMAIL_PLACEHOLDER")} onChange={this.onChange}></input>
                    <button className=""><span className='fa fa-envelope'></span></button>
                  </div>
                  <button className="appnd_btn mt-sm-0 mt-2" onClick={this.Subscribe}>{t("SUBSCRIBE")}</button>
                </div>
                {/* {console.log('hskdfasdkfasdfas', this.state.emailerr)} */}
                {this.state.emailerr ? <Form.Text className='text-danger'>{t("EMAIL_REQUIRED")}</Form.Text> : (this.state.validation ? "" : <Form.Text className='text-danger'>{t("EMAIL_INVALID")}</Form.Text>)}
                <div className="social mt-3">
                  <ul className="socialIcons d-flex">
                    <li><a href={this.state.settings.social_link3} target="_blank"><i class="fab fa-telegram-plane"></i></a></li>
                    <li><a href={this.state.settings.social_link1} target="_blank"><i class="fab fa-twitter"></i></a></li>
                    <li><a href={this.state.settings.social_link5} target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    <li><a href={this.state.settings.social_link2} target="_blank"><i class="fab fa-reddit-alien"></i></a></li>
                    <li><a href={this.state.settings.social_link4} target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr className='whitehr' />
          <div className='text-center'>
            <p className='text-light mb-0'>{this.cmsFilter("landing_banner_32")} &copy; 2023</p>
          </div>

        </div>
      </footer>
    )
  };
}
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps
)(withTranslation()(Footer),withRouter(Footer));

