import React, { Component } from 'react'
import TradeHeader from './TradeHeader'
import Footer from './Footer'
import DepositeQR from "../images/depositeQR.jpg"
import BtcIcon from "../images/btcIcon.png"
import EthIcon from "../images/ethIcon.png"
import XrpIcon from "../images/xrpIcon.png"
import LtcIcon from "../images/ltcIcon.png"
import BchIcon from "../images/bchIcon.png"
import { store } from 'react-notifications-component';
import keys from "../actions/config";
import axios from "axios";
import DatePicker from "react-datepicker";
import { tradehistory,pnlSearchdata, tradeSearchdata,stakinggethistory,stackingClose  } from "../actions/authActions";
import { Link, withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CSVLink, CSVDownload } from "react-csv";
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import { Scrollbars } from 'react-custom-scrollbars';
const url = keys.baseUrl;

const menuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  }
}

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: "white",
    backgroundColor: "#000",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: '52px',
    padding: '0 6px',
    backgroundColor: "#03081f",
    borderColor: '#81c8f6',
    borderRadius: 10,
    borderStyle: 'solid',
    borderWidth: '1px'
  }),
  control: (provided, state) => ({
    ...provided,
    height: '52px',
    borderRadius:10,
    backgroundColor: "#03081f",
    border:'none'
   
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '52px',
    position: 'absolute',
    right: 0,
    top: 0,
    color:'#fff' 
  }),    
  singleValue: (provided, state) => ({
    ...provided,
    color: "#fff"
  })
}

const options1 = [
  { value: 'All', label: 'All' },
  { value: 'Buy', label: 'Buy' },
  { value: 'Sell', label: 'Sell' },
];

const options = [{ value: 'All', label: 'All' },];

class StakingHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      curSettledList : [],
      tradehistory: [],
      records: [],
      stakinggethistory : [],
      csvData: [],
      contract: { value: 'All', label: 'All' },
      type: { value: 'All', label: 'All' },
      startDate: '',
      endDate: '',
      // exchangetype : 'Derivatives',
      exchangetype: 'Spot',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getData()
    this.getCurrencyData()
  };
    getCurrencyData() {
      axios
      .get(url+"cryptoapi/getcurrency")
      .then(res => {
        var currencyselect = [];
        var getAllCurrency = res.data.data;
        console.log(getAllCurrency);
        for (var i = 0; i < getAllCurrency.length; i++) {
          // if(getAllCurrency[i].currencySymbol=='BTC' || getAllCurrency[i].currencySymbol=='ETH' || getAllCurrency[i].currencySymbol=='USDT')
          // {
            var from_val = {
              _id: getAllCurrency[i]._id,
              value: getAllCurrency[i].currencySymbol,
              label: getAllCurrency[i].currencyName
            };
            currencyselect.push(from_val);

          // }
          }
          currencyselect.push(options)
        this.setState({currencyData:currencyselect});
      });
     }
 componentWillReceiveProps(nextProps) {

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
    console.log('nextProps.auth : ',nextProps.auth);

    var target = '';
    var data = {};

    if (nextProps.auth.trade !== undefined){
      if (nextProps.auth.trade.data !== undefined){
        data = nextProps.auth.trade.data;
        if (nextProps.auth.trade.data.target !== undefined){
          target = nextProps.auth.trade.data.target;
        }
      }
    }

    console.log('target : ',target);
    
    if (target == "stakinggethistory"){
      if (data.record !== undefined){
        var record = data.record;
        this.setState({stakinggethistory:record});
      }
    }

    if (target == "stackingClose"){

      var retMsg = data.retMsg;

      var retType = data.retType;

      if(retType=='danger'){
        store.addNotification({
          title: "Error!",
          message: retMsg,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      } else if(retType=='success'){
        store.addNotification({
          title: "Success!",
          message: retMsg,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        var stakinggethistoryBodyData = {"userid":this.props.auth.user.id};
        this.props.stakinggethistory(stakinggethistoryBodyData);
      }
    }

  }

  getData() {
    var stakinggethistoryBodyData = {"userid":this.props.auth.user.id};
    this.props.stakinggethistory(stakinggethistoryBodyData);
  }

  changecontract = contract => {
    this.setState({ contract });
  };

  changetype = type => {
    this.setState({ type });
  };
  exchangetype = e => {
    var type = e.target.innerHTML;
    this.setState({ exchangetype: type, records: [] }
      , () => {
        this.getData();
      });
  };
 receivedAmtDet = (item) => {
    console.log('item : ',item);
    var curSettledList = item.settledList;
    if(curSettledList.length > 0){
      this.setState({curSettledList:curSettledList,stakeNowShow:true});
    } else {
      var retMsg = 'No record found.';
      store.addNotification({
        title: "Error!",
        message: retMsg,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true
        }
      });
    }
  }

  buildarray = (historydata) => {
    var csvdata = [];

    var titledata = ["Contracts", "Filled Type", "Filled Qty", "Exec price", "Value", "Fee Rate", "Fees Paid", "Order Price", "Unfilled Qty", "Type", "Transaction ID", "Order Time"];
    csvdata.push(titledata);
    if (historydata.length > 0) {
      historydata.map((item, i) => {
        var quantity = item.quantity ? item.quantity : 0;
        var price = item.price ? item.price : 0;
        var orderValue = item.orderValue ? item.orderValue : 0;
        var orderType = item.buyorsell ? item.buyorsell : 0;
        var _id = item._id ? item._id : 0;
        var classs = item.buyorsell == 'buy' ? 'greenText' : 'pinkText';
        var filledAmount = item.filledAmount ? item.filledAmount : 0;
        var Remaining = parseFloat(quantity) - parseFloat(filledAmount);
        var newarr = [];
        if (typeof item.filled != 'undefined') {
          item.filled.map((itemdata, i) => {
            //console.log(itemdata, 'itemdatasss');
            var created_at = itemdata.created_at ? itemdata.created_at : '';
            var Price = itemdata.Price ? itemdata.Price : 0;
            var pairname = itemdata.pairname ? itemdata.pairname : 0;
            var data1 = new Date(created_at);
            let date = data1.getFullYear() + '-' + (data1.getMonth() + 1) + '-' + data1.getDate() + ' ' + data1.getHours() + ':' + data1.getMinutes() + ':' + data1.getSeconds();
            newarr.push(pairname);
            newarr.push("Trade");
            newarr.push(filledAmount);
            newarr.push(Price);
            newarr.push(orderValue);
            newarr.push(orderValue);
            newarr.push(orderValue);
            newarr.push(orderValue);
            newarr.push(Remaining);
            newarr.push(orderType);
            newarr.push(_id);
            newarr.push(date);
            csvdata.push(newarr);
          })
        }
      })
    }
    this.setState({ csvData: csvdata })
  }

  Searchdata = () => {
    var postdetails = {
      "currencyRef"  : this.state.contract._id,
      "startDate" : this.state.startDate,
      "endDate"   : this.state.endDate,
      "userid"    : this.props.auth.user.id
    }
    this.props.stakinggethistory(postdetails);
  }

  pairdatachanges = data => {
    axios
      .post(url + "cryptoapi/pair-data", { exchangetype: this.state.exchangetype })
      .then(res => {
        if (res.data.status) {
          options.splice(1);
          // //console.log(res.data.data,'ressssss');
          this.setState({ records: res.data.data });
          res.data.data.map((item, i) => {
            if (item.first_currency != '' && item.second_currency != '') {
              var one = { "value": item.first_currency + item.second_currency, "label": item.first_currency + item.second_currency }
              options.push(one);
            }
          });
        }
      })
      .catch();

  }

  handleChange = date => {
    this.setState({
      startDate: date
    });
  };


  handleChange1 = date => {
    this.setState({
      endDate: date
    });
  };

 stackingCloseFunc = (a) => {
    console.log('stackingClose : ',a);
    var postdetails = a;
    this.props.stackingClose(postdetails);
  }
  render() {
    const { tradehistory, contract, type } = this.state



    return (
      <div className='bg_strip'>
        <div className="modal fade" id="deposite" tabindex="-1" role="dialog" aria-labelledby="deposite" aria-hidden="true" data-backdrop="static">
          <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <div className="modal-content depositeContent">
            <div className="modal-header">
									<h4 className='modal-title h4'>BTC Deposit</h4>
									<button type="button" className="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
              <div className="modal-body">               
                <div className="popUpSpace">
                  <div className="depositeQr">
                    <img src={DepositeQR} className="img-fluid" />
                  </div>
                  <div className="walletKey">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group inputWithText">
                          <input name="" className="form-control" placeholder="" value="3CMCRgEm8HVz3DrWaCCid3vAANE42jcEv9" type="text" />
                          <button className="input-group-append-icon"><i className="far fa-copy"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="noteCont">
                    <h6>Notes:</h6>
                    <p>1. Do not deposit any non- BTC assets to the above address. Otherwise, the assets will lost permanently.</p>
                    <p>2. Please provide the correct Trading wallet address to ensure a deposit.</p>
                    <p>3. Your deposit will be reflected inside your account after receiving 1 confirmation on the blockchain.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="Withdraw" tabindex="-1" role="dialog" aria-labelledby="Withdraw" aria-hidden="true" data-backdrop="static">
          <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <div className="modal-content WithdrawContent">
            <div className="modal-header">
									<h4 className='modal-title h4'>BTC Withdraw</h4>
									<button type="button" className="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
              <div className="modal-body">
                
                <div className="popUpSpace withdrawPopupForm">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Receiver’s Wallet Address <a href="#" className="float-right">Add Wallet</a></label>
                        <input type="text" name="" className="form-control" value="3CMCRgEm8HVz3DrWaCCid3vAANE42jcEv9" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>BTC Amount <span className="float-right">Balance: <small>10.23569878 BTC</small> | <a href="#">Send All</a></span></label>
                        <input type="text" name="" className="form-control" value="0.00000000" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Final Amount <span className="float-right">+ Fee: 0.00005 BTC</span></label>
                        <input type="text" name="" className="form-control" value="0.00000000" disabled="" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Enter 2FA Authentication Code</label>
                        <input type="text" name="" className="form-control" value="123456" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input type="submit" name="" value="Withdraw" className="btn buttonType1" />
                      </div>
                    </div>
                  </div>
                  <div className="noteCont">
                    <h6>Notes:</h6>
                    <p>1. Minimum withdrawal:0.002BTC.</p>
                    <p>2. Your withdraw will be reflected inside your account after receiving 1 confirmation  on the blockchain.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TradeHeader pairdatachange={this.pairdatachange} />
        <div>
          <section className='container pt-5'>
            <div className="row mx-0 pt-5">
              <div className="col-md-12 px-0">
                <div className="darkBox assetsTable border-top-one py-2">
                  {/* <nav>
                    <div className="nav nav-tabs" id="navLink-tab" role="tablist">
                      <a href="/closedPandL" className="nav-item nav-link ">Closed P&L</a>

                      <Link to="/AssetsHistory" className="nav-item nav-link active">Deposit History</Link>
                      <Link to="/OrderHistory" className="nav-item nav-link">Order History</Link>
                      <Link to="/tradeHistory" className="nav-item nav-link">Trade History</Link>
                      <Link to="/withdrawalHistory" className="nav-item nav-link">Withdrawal History</Link>
                    </div>
                  </nav> */}
                  <h3 className="assetTitle p-3 nav-tabs">Trade History</h3>
                    <nav>
                    <div className="nav nav-tabs" id="navLink-tab" role="tablist">
                      <a href="/stakingHistory" className="nav-item nav-link">Derivatives</a>
                      <a href="/stakingHistory" className="nav-item nav-link">Spot</a>
                
                      <a href="/stakingHistory" className="nav-item nav-link active">Staking History</a>
                    </div>
                  </nav>
                
                  <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-USD" role="tabpanel" aria-labelledby="nav-USD-tab">
                      <div className="tab-content">
                        <div className="assetHistoryTableTop clearfix">
                          <div className="row">
                            <div className="col-lg-12 col-xl-10 col-md-12 col-sm-12">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-6">
                                  <div className="form-group clearfix">
                                    <label>Coin</label>

                                    <Select
                                   className="border_blue_select border_blue_select_bg_blue"
                                   width='100%'
                                   menuColor='red'
                                   MenuProps={menuProps}
                                   options={this.state.currencyData}
                                   value={contract}
                                   onChange={this.changecontract} isSearchable={false}
                                   />
                                  </div>
                                </div>
                                {/*<div className="col-lg-2 col-md-4 col-sm-6">
                                  <div className="form-group clearfix">
                                    <label>Type</label>
                                    <Select
                                      styles={customStyles}
                                      width='100%'
                                      menuColor='red'
                                      options={options1}
                                      value={type}
                                      onChange={this.changetype}
                                    />
                                  </div>
                                </div>*/}
                                <div className="col-lg-3 col-md-3 col-sm-6">
                                  <div className="form-group">
                                    <label>Start Date</label>
                                      <DatePicker
                                    selected={this.state.startDate}
                                    onChange={this.handleChange}
                                    showYearDropdown
                                    showMonthDropdown
                                />
                                  </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6">
                                  <div className="form-group">
                                    <label>End Date</label>
                                  <DatePicker
                                  selected={this.state.endDate}
                                  onChange={this.handleChange1}
                                  showYearDropdown
                                  showMonthDropdown
                              />
                                  </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 flex_cen_mob_btn flex_cen_mob_btn_md">
                                  <div className="form-group">
                                    <label className="d-none d-md-block invisible">s</label>
                            <input type="button" name="" value="Search" onClick={this.Searchdata} className="buttonType1" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-4">
                              <div className="downLoadBTN float-right">
                                <div className="form-group">
                                  <label className="d-none d-sm-block invisible">s</label>
 {(this.state.csvData.length>0)?
                                <CSVLink data={this.state.csvData} className="btn transYellowButton">Download.csv</CSVLink> : ''
                              }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="tab-content">
                  <div className="table-responsive">
                  <Scrollbars style={{ width: '100%', height: 400 }} renderTrackVertical={props => <div className="track-vertical"/>}>
                    <table id="assetsTable" className="table">
                      <thead>
                          <tr className="wow flipInX" data-wow-delay=".5s;">
                            <th>Date</th>
                            <th>Currency Name</th>
                            <th>Type</th>
                            <th>Duration</th>
                            <th>Remaining days</th>
                            <th>Amount</th>
                            <th>APY</th>
                            <th>Received</th>
                            <th>Redemption Period</th>
                            <th>Status</th>
                          </tr>
                      </thead>
                        {this.state.stakinggethistory.length>0?(
                        <tbody>
                        {
                          this.state.stakinggethistory.map((item,i)=>{
                            var type = '';
                            var duration = '-';
                            if(item.type=='days'){
                              type = 'Fixed';
                              duration = item.duration;
                            } else {
                              type = 'Flexible';
                              duration = 365;
                            }

                            var status = item.status;
                            var remainingDays = (item.SettleRemainingCount || item.nextSettleDayCount) ? item.SettleRemainingCount + item.nextSettleDayCount :0;
                            var receivedAmt = 0;
                            var newDate = new Date(item.createdDate);
                            var date = newDate.getFullYear() + '-' + (newDate.getMonth() +1) + '-' + newDate.getDate();

                            if(item.settledList.length==0){
                            } else {
                              for (let a = 0; a < item.settledList.length; a++) {
                                var curElement = item.settledList[a];
                                receivedAmt = parseFloat(receivedAmt)+parseFloat(curElement.amount);
                              }
                            }

                            receivedAmt = receivedAmt.toFixed(4);

                            var redemptionPeriod = item.redemptionPeriod;

                            return <tr>
                              <td>{date}</td>
                              <td>{item.currencyRef.currencySymbol}</td>
                              <td>{type}</td>
                              <td>{duration}</td>
                              <td>{remainingDays}</td>
                              <td>{item.amount}</td>
                              <td>{item.apy}</td>
                              <td>
                                {
                                (receivedAmt!=0)?(
                                <p style={{color:'#ff9e00',cursor:'pointer'}} onClick={() => this.receivedAmtDet(item)}>
                                  {receivedAmt}
                                </p>):'0'}
                              </td>
                              <td>{redemptionPeriod}</td>
                              <td>
                                {(type=='Locked' && status==1)?<p>Process</p>:""}
                                {(type=='Locked' && status==3)?<p>Completed</p>:""}
                                {
                                  (type=='Flexible' && status==1)?<p>
                                    <button className="btn transYellowButton" type="button" onClick={() => this.stackingCloseFunc(item)}>Cancel</button>
                                  </p>
                                  :""
                                }
                                {(type=='Flexible' && status==2)?<p>Cancelled</p>:""}
                                {(type=='Flexible' && status==3)?<p>Completed</p>:""}
                              </td>
                            </tr>
                          })
                        }
                        </tbody>
                        ):(<tbody>
                          <tr><td colspan="10" className="text-center">No Records</td></tr></tbody>)}
                        </table>
                        </Scrollbars>
                    </div>
                  </div>
                    </div>
                    <div className="tab-pane fade" id="nav-INR" role="tabpanel" aria-labelledby="nav-INR-tab">
                      <div className="tab-content">
                        <div className="assetHistoryTableTop clearfix">
                          <div className="row">
                            <div className="col-lg-10 col-md-9 col-sm-8">
                              <div className="row">
                                <div className="col-lg-2 col-md-4 col-sm-6">
                                  <div className="form-group clearfix">
                                    <label>Pairs</label>

                                    <Select
                                      styles={customStyles}
                                      width='100%'
                                      menuColor='red'
                                      options={options}
                                      value={contract}
                                      onChange={this.changecontract} isSearchable={false}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-2 col-md-4 col-sm-6">
                                  <div className="form-group clearfix">
                                    <label>Type</label>
                                    <Select
                                      styles={customStyles}
                                      width='100%'
                                      menuColor='red'
                                      options={options1}
                                      value={type}
                                      onChange={this.changetype} isSearchable={false}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-2 col-md-4 col-sm-6">
                                  <div className="form-group">
                                    <label>Start Date</label>
                                    <DatePicker
                                      selected={this.state.startDate}
                                      onChange={this.handleChange}
                                      showYearDropdown
                                      showMonthDropdown
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-2 col-md-4 col-sm-6">
                                  <div className="form-group">
                                    <label>End Date</label>
                                    <DatePicker
                                      selected={this.state.endDate}
                                      onChange={this.handleChange1}
                                      showYearDropdown
                                      showMonthDropdown
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-2 col-md-4 col-sm-6">
                                  <div className="form-group">
                                    <label className="d-none d-md-block invisible">s</label>
                                    <input type="button" name="" value="Search" onClick={this.Searchdata} className="buttonType1" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-3 col-sm-4">
                              <div className="downLoadBTN float-right">
                                <div className="form-group">
                                  <label className="d-none d-sm-block invisible">s</label>

                                  {(this.state.csvData.length > 0) ?
                                    <CSVLink data={this.state.csvData} className="btn transYellowButton">Download.csv</CSVLink> : ''
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="tab-content">
                        <div className="table-responsive">
                          {this.state.tradehistory ?
                            <Scrollbars style={{ width: '100%', height: 400 }} renderTrackVertical={props => <div className="track-vertical" />}>
                              <table id="assetsTable" className="table table-striped rembordor">
                                <thead>
                                  <tr>
                                    <th>Contracts</th>
                                    <th>Filled Type</th>
                                    <th>Filled Qty</th>
                                    <th>Exec price</th>
                                    <th>Value</th>
                                    <th>Fee Rate</th>
                                    <th>Fees Paid</th>
                                    <th>Order Price</th>
                                    <th>Unfilled Qty</th>
                                    <th>Type</th>
                                    <th>Transaction ID</th>
                                    <th>Time</th>
                                  </tr>
                                </thead>

                                {
                                  tradehistory.map((item, i) => {
                                    //console.log(item, 'itemsss');
                                    var quantity = item.quantity ? item.quantity : 0;
                                    var price = item.price ? item.price : 0;
                                    var orderValue = item.orderValue ? item.orderValue : 0;
                                    var orderType = item.buyorsell ? item.buyorsell : 0;
                                    var taker_fees = item.taker_fees ? item.taker_fees : 0;

                                    var _id = item._id ? item._id : 0;
                                    var classs = item.buyorsell == 'buy' ? 'greenText' : 'pinkText';
                                    var filledAmount = item.filledAmount ? item.filledAmount : 0;
                                    var Remaining = parseFloat(quantity) - parseFloat(filledAmount);
                                    return (<tbody>
                                      { (typeof item.filled != 'undefined') ? item.filled.map((itemdata, i) => {
                                        //console.log(itemdata, 'itemdatasss');
                                        var created_at = itemdata.created_at ? itemdata.created_at : '';
                                        var Fees = itemdata.Fees ? itemdata.Fees : 0;
                                        var Price = itemdata.Price ? itemdata.Price : 0;
                                        var pairname = itemdata.pairname ? itemdata.pairname : 0;
                                        var data1 = new Date(created_at);
                                        let date = data1.getFullYear() + '-' + (data1.getMonth() + 1) + '-' + data1.getDate() + ' ' + data1.getHours() + ':' + data1.getMinutes() + ':' + data1.getSeconds();
                                        return (<tr>
                                          <td className="text-center">{pairname}</td>
                                          <td className="text-center">Trade</td>
                                          <td className="text-center">{parseFloat(filledAmount).toFixed(8)}</td>
                                          <td className="text-center">{parseFloat(Price).toFixed(2)}</td>
                                          <td className="text-center">{parseFloat(orderValue).toFixed(8)}</td>
                                          <td className="text-center">{parseFloat(taker_fees).toFixed(8)}</td>
                                          <td className="text-center">{parseFloat(Fees).toFixed(8)}</td>
                                          <td className="text-center">{parseFloat(Price).toFixed(2)}</td>
                                          <td className="text-center">{parseFloat(Remaining).toFixed(8)}</td>
                                          <td className="text-center">{orderType}</td>
                                          <td className="text-center">{_id}</td>
                                          <td className="text-center">{date}</td>
                                        </tr>
                                        );
                                      }) : ''
                                      }
                                    </tbody>);

                                  })
                                }

                              </table></Scrollbars> : ''}
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

StakingHistory.propTypes = {
  tradeSearchdata: PropTypes.func.isRequired,
  tradehistory: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  stakinggethistory : PropTypes.object.isRequired,
  stackingClose : PropTypes.object.isRequired,
   pnlSearchdata : PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(

  mapStateToProps,
  {
    tradehistory,
    tradeSearchdata,
    stakinggethistory,
    stackingClose,
    pnlSearchdata,
  }
)(withRouter(StakingHistory));
