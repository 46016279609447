module.exports = {
  cryptoPass: "InktBitjfnWUtYpbD",

  // Bell-Exchange Local
  // refurl: "http://localhost:3000/",
  // imageUrl: "http://localhost:3528/",
  // baseUrl: "http://localhost:3528/",
  // socketUrl: "http://localhost:3528/",
  // Recaptchakey: "6LdpeoQUAAAAAHwFEDfpcA-W5-leSH8548lZWWeb",

  // Bell-Exchange Demo
  // refurl: "https://bellexchange.maticz.in/",
  // imageUrl: "https://bellexchange.maticz.com/api/",
  // baseUrl: "https://bellexchange.maticz.com/api/",
  // socketUrl: "https://bellexchange.maticz.com/api/",
  // Recaptchakey: "6LcAn-YkAAAAANnuNOMZ4fIeUbPle0lGZAwu2PCR",
  //   6LcAn-YkAAAAANnuNOMZ4fIeUbPle0lGZAwu2PCR

    // Bellxchange live
  refurl: "https://bell.exchange/",
  imageUrl: "https://api.bell.exchange/",
  baseUrl: "https://api.bell.exchange/",
  socketUrl: "https://api.bell.exchange/",
  Recaptchakey: "6LcHP7slAAAAAIUkd-9cdXP7PgysgGEyYMX_pbtg",

};