import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Navbar from './Navbar'
import Footer from './Footer'
import axios from "axios";
import ReactHtmlParser from 'react-html-parser';
import keys from "../actions/config";
import Navtradebar from './Navtradebar';
const url = keys.baseUrl;
class About extends Component {

  constructor(props) {
    super(props);
    this.state = {
      subject: "",
      identifier: "",
      content: "",
      errors: {},
      About_us: {},
      cmsData: []

    };

    //console.log(this.state,'state');
  }

  //     componentDidMount() {
  //         this.getData()
  //     };
  // createMarkup = () => {
  //       return { __html: this.state.About_us.data.content };
  //     }

  // getData() {
  //        axios
  //        .get(url+"cryptoapi/about_us")
  //         .then(res => {
  //           this.setState({About_us:res});
  //          })
  //         .catch()
  //         //console.log(this.setState,'this.setState');
  // }
  componentDidMount() {
		window.scrollTo(0, 0);

    this.gettingCmsData();

  }

  gettingCmsData = async () => {


    const result = await axios.get(url + "cryptoapi/getallCmsData");

    const cmsData = result.data.result;

    this.setState({
      cmsData: cmsData
    })

  }



  render() {
    const { cmsData } = this.state;

    return (<div className='bg_strip'>
      <section class="headerCrediantials homeBanner">
      <Navtradebar />
      </section>
      <section className='container privacy-policy-container'>

        {
          cmsData && cmsData.map((data, i) => {

            return (
              <>
                {
                  //  this condition is used for getting a particular cms data  //
                  data.subject == "About_us" &&
                  (
                    <div className="pt-5">
                      {
                        data && data.content &&
                        ReactHtmlParser(
                          data.content
                        )}
                    </div>
                  )
                }
                
                {
                //  this condition is used for getting a particular cms data  //

                  data.subject == "About_us_1" &&
                  (
                    <div className="py-5">

                      {
                        data && data.content &&
                        ReactHtmlParser(
                          data.content
                        )}

                    </div>
                  )

                }

              </>
            )

          })
        }
      </section>
      <Footer />
    </div>
    );
  }
}

export default About
