import React, { Component } from 'react'
import BannerX from "../images/xicon.png"
import {Link} from 'react-router-dom';
import Navbar from './Navbar'
import Footer from './Footer'
import { toast } from 'react-toastify';
import { store } from 'react-notifications-component';
import $ from 'jquery';
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { contact } from "../actions/authActions";
import classnames from "classnames";
import keys from "../actions/config";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import Navtradebar from './Navtradebar';
import { withTranslation } from 'react-i18next';
const url = keys.baseUrl;


const Recaptchakey = keys.Recaptchakey;
const recaptchaRef = React.createRef();
const grecaptchaObject = window.grecaptcha
class Contact extends Component {

  constructor(props) {
        super(props);
        this.state = {
            email            : "",
            email            : "",
            contact_person   : "",
            sitename         : "",
            site_description : "",
            favorite_image   : "",
            sitelogo         : "",
            phone_number     : "",
            mobile_number    : "",
            address          : "",
            tax_amount       : "",
            social_link1     : "",
            social_link2     : "",
            social_link3     : "",
            social_link4     : "",
            social_link5     : "",
            copyright_text   : "",
            working_day      : "",
            working_hours    : "",
            recaptcha        : "",
            name             : "",
            message          : "",
            settings         : {},
            errors           : {},
        };
    }
componentDidMount() {
  window.scrollTo(0, 0);
            this.getData()


        };

    componentWillReceiveProps(nextProps) {
      console.log("nextPRops.errors",nextProps.auth.contact_us.data.messag)
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }

        if (nextProps.auth !== undefined
            && nextProps.auth.contact_us !== undefined
            && nextProps.auth.contact_us.data !== undefined
            && nextProps.auth.contact_us.data.message !== undefined) {
           store.addNotification({
            title: "Success!",
            message: nextProps.auth.contact_us.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });

          this.setState({
            email:"",
            message:"",
            name:"",
            errors: {}
          })
            nextProps.auth.contact_us = undefined;

        }
    }

    captchaChange = e => {
      this.setState({ recaptcha : e });
  };

getData() {
           axios
           .get(url+"cryptoapi/contact")
            .then(res => {
            //console.log(res,'res_contact');
              this.setState({settings:res});
             })
            .catch()
            //console.log(this.setState,'this.setState');
    }
    onChange = e => {
        if(e.target.id == "name"){
            if (/[^a-zA-Z ]/.test(e.target.value))
                return 
        }
        this.setState({ [e.target.id]: e.target.value });
    };

    onSubmit = e => {
        e.preventDefault();
        const Newcontact = {
            email: this.state.email,
            name: this.state.name,
            message: this.state.message,
            recaptcha: this.state.recaptcha,
        };
        //console.log(Newcontact);
        this.props.contact(Newcontact);
    };

	render() {
      const { errors } = this.state;
      const {t} = this.props;
      
		return (<div className="homebg indx bg_strip contactus">
			<section class="headerCrediantials">
      {/* <Navbar /> */}
      <Navtradebar />
      </section>
			<section className="loginBanner">
 <div className="container">
    <div className="row justify-content-center">
    {/*}  <div className="col-md-6 pt-5">
         {this.state.settings.data?
        <div className="lgoinBannerText wow fadeInUp" data-wow-delay=".25s">
          <h3>Exchange Top Cryptos with</h3>
          <h2><span>150</span> <img src={BannerX} /> <small>Leverage</small></h2>
         <p>{this.state.settings.data.sitename}<br />
             Register Code  : {this.state.settings.data.reg_code}<br />
             Address: {this.state.settings.data.address}<br />
             Contact : {this.state.settings.data.phone_number}<br />
             Email :<a href={"mailto:" + this.state.settings.data.email}>{this.state.settings.data.email}</a></p>
             <p>{this.state.settings.data.copyright_text}</p>
        </div>
        :''}
      </div>*/}
      {/* <div className="col-md-5 col-lg-6 pt-5 center_div_align">
        <div className="lgoinBannerText wow fadeInUp" data-wow-delay=".25s">
          <h3>Exchange Top Cryptos with</h3>
          <h2><span>150</span> <img src={BannerX} /> <small>Leverage</small></h2>
          <ul>
            <li><i className="fas fa-check"></i> Advanced Trade Orders</li>
            <li><i className="fas fa-check"></i> More Leverage</li>
            <li><i className="fas fa-check"></i> Safe And Secured Wallets</li>
            <li><i className="fas fa-check"></i> Technical Expertise</li>
          </ul>
        </div>
      </div> */}
      <div className="col-md-7 col-lg-10">
        <div className="formBox contact wow flipInY mt-3 mt-md-5 mb-5 mb-md-0" data-wow-delay=".15s;">
         <h2>{t("CONTACT_US")}</h2>
      
        <form className="stoForm form_contct" noValidate onSubmit={this.onSubmit}>
          <div className='row'>
            <div className='col-md-6'>
               <div className="form-group fadeIn second">
            <label>{t("NAME")}</label>
            <input
                onChange={this.onChange}
                value={this.state.name}
                error={errors.name}
                id="name"
                type="text"
                className={classnames("form-control", {
                    invalid: errors.name
                })}
            />
            <span className="text-danger">{errors.name}</span>
          </div>
            </div>
            <div className='col-md-6'>
              	<div className="form-group fadeIn second">
            <label>{t("EMAIL_PLACEHOLDER")}</label>
            <input
                onChange={this.onChange}
                value={this.state.email}
                error={errors.email}
                id="email"
                type="email"
                className={classnames("form-control", {
                    invalid: errors.email
                })}
            />
            <span className="text-danger">{errors.email}</span>
          </div>
            </div>
            <div className='col-md-12'>
              <div className="form-group fadeIn third">
                  <label>{t("MESSAGE")}</label>
                  <textarea
                      onChange={this.onChange}
                      value={this.state.message}
                      error={errors.message}
                      id="message"
                      type="text" 
                      row={4}
                      className={classnames("form-control", {
                          invalid: errors.message
                      })}
                  />
                  <span className="text-danger">{errors.message}</span>
              </div>
            </div>
            <div className='col-md-6'>
               <div className="form-group clearfix fadeIn third ml-1 recaptchaMain">

                        <ReCAPTCHA
                    ref={(r) => this.recaptcha = r}
                    sitekey={Recaptchakey}
                    grecaptcha={grecaptchaObject}
                    onChange={this.captchaChange}
                    />
                <span className="text-danger">{errors.recaptcha}</span>
              </div>
              </div>
          </div>
           
            
		          

          
            <div className="form-group mt-4 mb-0">
            <input type="submit" className="themebtn " value="Submit" />
          </div>
            </form>

        
        </div>
      </div>
    </div>
  </div>
</section>
<Footer />
		</div>
		);
	}
}


Contact.propTypes = {
    contact: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { contact }
)(withTranslation()(Contact),withRouter(Contact));
