import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import TradeHeader from './TradeHeader'
import Footer from './Footer'
import SupportTicketImg from "../images/supportTicket.png";
import $ from 'jquery';
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { store } from 'react-notifications-component';
import { supportreply } from "../actions/authActions";
import { connect } from "react-redux";
import classnames from "classnames";
import profileimg from "../images/profile-pic.png";
import userprofile from "../images/user.jpg";
import keys from "../actions/config";
import ReactTimeAgo from 'react-time-ago';
import { TimeAgo } from "@n1ru4l/react-time-ago";
import frenchStrings from 'react-timeago/lib/language-strings/fr'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'

import axios from "axios";
const url = keys.baseUrl;

class SupportReply extends Component {
    constructor() {
        super();
        this.state = {
            _id:"",
            query_image:"",
            subject:"",
            description: "",
            attachment:"",
            reply:[],
            message_query:"",
            errors: {},
            created_date:"",
            
        };
    }
    componentDidMount() {
      window.scrollTo(0, 0);
            this.getData()        
        };
    
 onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };
    getData() {
      const id = this.props.match.params.id
      ;

           axios
           .post(url+"cryptoapi/supportreply/"+id)
            .then(res => { 
            console.log(res.data,'res_contactidddsss');  
              this.setState(res.data);
             })
            .catch()
            //console.log(this.setState,'this.setState');
    }
    handleChange = (event) => {
      let imageFormat = /\.(pdf|PDF|docx|DOCX|png|PNG)$/;
      let errors = {};
      var MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
      console.log("MAX_FILE_SIZE", event.target.files[0].size , "-----", MAX_FILE_SIZE);
       if (event.target.files[0].size > MAX_FILE_SIZE) {
        console.log("first condition")
        errors["query_image"] = "*Image is Above 5MB";
        this.setState({
                errors: errors
            });
            return
      }
      if (!imageFormat.test(event.target.files[0].name)) {
        console.log("second condition")
        errors["query_image"] = "*Images should be allowed only in pdf/docx/jpg.";
        this.setState({
                errors: errors
            });
      }else{
        console.log("third condition")
      this.setState({
      //  profileurl: URL.createObjectURL(event.target.files[0]),
        query_image: event.target.files[0]
      })
    }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        else{
          this.setState({
            errors: {}
        });
        }

        
        if (nextProps.auth !== undefined
            && nextProps.auth.supportreply !== undefined
            && nextProps.auth.supportreply.data !== undefined
            && nextProps.auth.supportreply.data.message !== undefined) {
           store.addNotification({
            title: "Success!",
            message: nextProps.auth.supportreply.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
          this.setState({
            message_query:"",
            query_image:""

          })
          this.getData()
            nextProps.auth.supportreply = undefined;
              //console.log(this.state,'thissssssssssssssssssssssssstate');
            
        }
    }
 
    onSubmit = e => {
      e.preventDefault();
     const Newsupportreply = {
       _id: this.state._id,
       message_query: this.state.message_query,
       query_image: this.state.query_image,
       created_date: this.state.created_date,
     };
     const data = new FormData();
     data.append('_id', this.state._id);
     data.append('message_query', this.state.message_query);
     data.append('file', this.state.query_image);
     data.append('created_date', this.state.created_date);
     //console.log(Newsupportreply,'rxdtdgj€gjsatdn6yc7sautdfysufj');
     this.props.supportreply(data);
     //console.log(data,'data');
   };

  render() {
        const { errors } = this.state;
        const { user } = this.props.auth;
        //console.log(user);
    return (
			<div className='bg_strip'>
				<TradeHeader />
				<div className="container-fluid pt-5">
                <section className="tradeMain">
                 <div className="container">
                  <div className="tableHead tableHeadBlock font_20">
                          <h1>Support reply<a href="/support" className="float-right blue_text_back"><i className="fa fa-angle-left" aria-hidden="true"></i> Back</a></h1>
                        </div>
                   <div className="whiteBgShadow px-0 px-md-4">
      <div className="row mx-0">
        <div className="col-md-12">
          <div className="whtBoxCont">
            <h2 className="innerTitleType3 clearfix text-white">{this.state.subject}</h2>
            <ul className="mainResponses" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0">
              <li>
                <div className="row mx-0">
                  <div className="col-md-12">
                    <div className="responseUserImg">
                      {/* <img src={userprofile} className="img-fluid" /> */}
                      <p>Support Ticket created <TimeAgo className="d-inline" date={new Date(this.state.created_date)}>{({ value }) => <p>{value}</p>}</TimeAgo></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                 <div> 
                    <div className="tableHeadz tableHeadBlock">
                      {/* <p>Hello,</p> */}
                      <p>{this.state.description}</p>
                      {this.state.attachment!==null?
                       <div className="col-md-6 px-0 pb-3">
                        <a href={keys.baseUrl + 'support_images/' + this.state.attachment} target="blank" download>Download</a>
                     </div>:''}
                    </div>
                    </div>
                      <ul>
                  <li>    
                   {this.state.reply.map((array, i) => {
                      return ( 
                <div className="row py-3 border_row_blue_rep mx-auto">
                  <div className="col-md-12">
                    <div className="responseUserImg">
                    {/* {array.replytype=='user'?
                    <img src={userprofile} className="img-fluid" />:
                 <img src={profileimg} className="img-fluid" />} */}
                  {array.replytype=='user'?
                      <p>{user.name}</p>:
                       <p>{array.replytype}</p>}
                      <p><TimeAgo date={new Date(array.replydate)}>{({ value }) => <p>{value}</p>}</TimeAgo></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="responseText">
                      {/* <p> hello,</p> */}
                      <p>{array.message_query}</p>
                      {array.query_image !==null?
                        <a href={keys.baseUrl + 'support_images/' + array.query_image} target="blank" download>Download</a>
                        :''
                     }
                      
                     
                    </div>
                  </div>
                </div>)
                       })}
               
               {
                this.state.ticketStatus!="closed" &&
                  <>
                 
                  <h6 className='pt-3'>Reply to admin</h6>
                   <div className="col-md-12 px-0">
                    <div className="responseText">
                   <form name="supportreply" onSubmit={this.onSubmit}>
                       <div className="form-group">
                       <textarea name="message_query"
                            onChange={this.onChange}
                            value={this.state.message_query}
                            error={errors.message_query}
                            id="message_query"
                            type="text"
                            className={classnames("form-control", {
                                invalid: errors.message_query
                            })}
                        />
                          <span className="text-danger">{errors.message_query}</span>
                      </div>
                       <div className="col-md-6 px-0">
                                <div className="form-group">
                                   <label>Attachments if any</label>
                                   <label className="custom-file form-control custom_file_posit_c" id="customFile">
                                      <input type="file"  onChange={this.handleChange} name="file1" className="custom-file-input" id="exampleInputFile" aria-describedby="fileHelp" />
                                      <span className="custom-file-control form-control-file"></span>
                                  </label>
                                  <span style={{color:"red"}}>{errors.query_image}</span>
                                </div>
                              </div>
                    <div className="form-group mt-5">
                                  <input type="submit" name="" value="Submit" className="btn buttonType1" />
                                </div>
                    </form>
                  </div>
                </div>
                  </>
               }
                
                </li>
                </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
                  </div>
                </section>
                </div>
				<Footer />
			</div>
			);
    }
}

SupportReply.propTypes = {
    supportreply: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { supportreply }
)(withRouter(SupportReply));
