import React, { Component } from 'react'
import TradeHeader from './TradeHeader'
import Footer from './Footer'
import DepositeQR from "../images/depositeQR.jpg"
import BtcIcon from "../images/btcIcon.png"
import EthIcon from "../images/ethIcon.png"
import XrpIcon from "../images/xrpIcon.png"
import LtcIcon from "../images/ltcIcon.png"
import BchIcon from "../images/bchIcon.png"
import { store } from 'react-notifications-component';
import keys from "../actions/config";
import Select from 'react-select';
import axios from "axios";
import DatePicker from "react-datepicker";
import { orderhistory, Searchdata } from "../actions/authActions";
import { Link, withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CSVLink, CSVDownload } from "react-csv";
import { Scrollbars } from 'react-custom-scrollbars';
import { withTranslation } from 'react-i18next';

import "react-datepicker/dist/react-datepicker.css";
const url = keys.baseUrl;


const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    color: state.isSelected ? 'red' : 'blue',
    padding: 20,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}
// const {t} = this.props;
const options1 = [
  { value: 'All', label: 'All' },
  { value: 'Buy', label: 'Buy' },
  { value: 'Sell', label: 'Sell' },
];

const options = [{ value: 'All', label: 'All' },];
console.log('optionsdetails',options)
class OrderHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      historydetails: [],
      records: [],
      csvData: [],
      contract: { value: 'All', label: 'All' },
      type: { value: 'All', label: 'All' },
      // exchangetype:'Derivatives',
      exchangetype: 'Spot',
      startDate: '',
      endDate: '',
    };
  }
  styles = {
    option: (provided, state) => ({
      ...provided,
      color: "white",
      backgroundColor: "#000",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '52px',
      padding: '0 6px',
      backgroundColor: "#03081f",
      borderColor: '#81c8f6',
    borderRadius: 10,
    borderStyle: 'solid',
    borderWidth: '1px'
     
    }),
    control: (provided, state) => ({
      ...provided,
      height: '52px',
      borderRadius:10,
      backgroundColor: "#03081f",
      border:'none'
     
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '52px',
      position: 'absolute',
      right: 0,
      top: 0,
      color:'#fff' 
    }),    
    singleValue: (provided, state) => ({
      ...provided,
      color: "#fff"
    })
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getData()
  };
  componentWillReceiveProps(nextProps) {



    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }

    if (nextProps.auth !== undefined
      && nextProps.auth.trade !== undefined
      && nextProps.auth.trade.data !== undefined
      && nextProps.auth.trade.data.data !== undefined
      && nextProps.auth.trade.data.type !== undefined) {
      //console.log("historydetails", nextProps.auth.trade.data);
      this.setState({ historydetails: nextProps.auth.trade.data.data });
      console.log("nextProps",nextProps.auth.trade.data.data)
      this.buildarray(nextProps.auth.trade.data.data);
    }
  }
  changecontract = contract => {
    console.log('contractcontract',contract)
    this.setState({ contract });
  };

  changetype = type => {
    this.setState({ type });
  };
  exchangetype = e => {
    console.log('e.target.innerHtml',e.target.innerHTML)
    var type = e.target.innerHTML;
    this.setState({ exchangetype: type, records: [] }
      , () => {
        this.getData();
      });
  };

  getData() {
    var input = { "userid": this.props.auth.user.id, "exchangetype": this.state.exchangetype }
    this.props.orderhistory(input);
    this.pairdatachanges();
  }

  buildarray = (historydata) => {

    var csvdata = [];
    var titledata = ["Contracts", "Qty", "Filled remaining", "Exec price", "Trigger price", "Order price", "Order value", "Trading fees", "Type", "Status", "Order#", "Order Time"];
    //console.log(csvdata, 'test');
    csvdata.push(titledata);
    //console.log('if');
    if (historydata.length > 0) {
      historydata.map((item, i) => {
        var pairName = item.pairName ? item.pairName : 0;
        var quantity = item.quantity ? item.quantity : 0;
        var price = item.quantity ? item.price : 0;
        var buyorsell = item.buyorsell ? item.buyorsell : 0;
        var filledAmount = item.filledAmount ? item.filledAmount : 0;
        var orderValue = item.orderValue ? item.orderValue : 0;
        var orderType = item.orderType ? item.orderType : 0;
        var orderDate = item.orderDate ? item.orderDate : 0;
        var _id = item._id ? item._id : 0;
        var status1 = item.status;
        var e_price = (typeof item.filled != 'undefined' && item.filled.length > 0) ? item.filled[0].Price : 0;
        var tradingfees = (typeof item.filled != 'undefined' && item.filled.length > 0) ? item.filled[0].Fees : 0;
        var Remaining = parseFloat(quantity) - parseFloat(filledAmount);
        var data1 = new Date(orderDate);
        let date12 = data1.getFullYear() + '-' + (data1.getMonth() + 1) + '-' + data1.getDate() + ' ' + data1.getHours() + ':' + data1.getMinutes() + ':' + data1.getSeconds();

        var status = (status1 == 0) ? "New" : (status1 == 1) ? "Completed" : (status1 == 2) ? "Partial" : (status1 == 3) ? "Cancel" : ''
        var newarr = [];
        newarr.push(pairName);
        newarr.push(quantity);
        newarr.push(filledAmount + '/' + Remaining);
        newarr.push(e_price);
        newarr.push('-/-');
        newarr.push(price);
        newarr.push(orderValue);
        newarr.push(parseFloat(tradingfees).toFixed(8));
        newarr.push(buyorsell);
        newarr.push(status);
        newarr.push(_id);
        newarr.push(date12);

        
        csvdata.push(newarr);
      });

    }
    this.setState({ csvData: csvdata })

  }

  Searchdata = () => {
    var typeexchange = this.state.exchangetype == "Derivative" ? "Futures" : "Spot"
    var postdetails = {
      "contract": this.state.contract.value,
      "type": this.state.type.value,
      "startDate": this.state.startDate,
      "endDate": this.state.endDate,
      "userid": this.props.auth.user.id,
      "exchangetype": typeexchange
    }
    this.props.Searchdata(postdetails);
  }

  pairdatachanges = data => {
    axios
      .post(url + "cryptoapi/pair-data", { exchangetype: this.state.exchangetype })
      .then(res => {
        if (res.data.status) {
          options.splice(1);
          // //console.log(res.data.data,'ressssss');
          this.setState({ records: res.data.data });
          res.data.data.map((item, i) => {
            if (item.first_currency != '' && item.second_currency != '') {
              var one = { "value": item.first_currency + item.second_currency, "label": item.first_currency + item.second_currency }
              options.push(one);
            }
          });
        }
      })
      .catch();

  }

  handleChange = date => {
    console.log("keydwon2")
    this.setState({
      startDate: date,
      endDate: ''
    });
  };


  handleChange1 = date => {
    this.setState({
      endDate: date
    });
  };


  render() {
    const {t} = this.props;
    const { historydetails, timeinforcetype, contract, type } = this.state
    return (
      <div  className='bg_strip'>
        <div className="modal fade primary-modal" id="deposite" tabindex="-1" role="dialog" aria-labelledby="deposite" aria-hidden="true" data-backdrop="static">
          <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <div className="modal-content depositeContent">
            <div className="modal-header">
									<h4 className='modal-title h4'>{t("BTC_DEPOSIT")}</h4>
									<button type="button" className="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
              <div className="modal-body">
              
                <div className="popUpSpace">
                  <div className="depositeQr">
                    <img src={DepositeQR} className="img-fluid" />
                  </div>
                  <div className="walletKey">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group inputWithText">
                          <input name="" className="form-control" placeholder="" value="3CMCRgEm8HVz3DrWaCCid3vAANE42jcEv9" type="text" />
                          <button className="input-group-append-icon"><i className="far fa-copy"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="noteCont">
                    <h6>{t("NOTES")}:</h6>
                    <p>1. {t("NOTES1")}</p>
                    <p>2. {t("NOTES2")}</p>
                    <p>3. {t("NOTES3")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade primary-modal" id="Withdraw" tabindex="-1" role="dialog" aria-labelledby="Withdraw" aria-hidden="true" data-backdrop="static">
          <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <div className="modal-content WithdrawContent">
            <div className="modal-header">
									<h4 className='modal-title h4'>{t("BTC_WITHDRAW")}</h4>
									<button type="button" className="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
              <div className="modal-body">
              
                <div className="popUpSpace withdrawPopupForm">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>{t("RECEIVER_WALLET_ADDRESS")} <a href="#" className="float-right">{t("ADD_WALLET")}</a></label>
                        <input type="text" name="" className="form-control" value="3CMCRgEm8HVz3DrWaCCid3vAANE42jcEv9" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>{t("BTC_AMOUNT")}<span className="float-right">{t("BALANCE")}: <small>10.23569878 {t("BTC")}</small> | <a href="#">{t("SEND_ALL")}</a></span></label>
                        <input type="text" name="" className="form-control" value="0.00000000" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>{t("FINAL_AMOUNT")} <span className="float-right">+ {t("FEES")}: 0.00005 {t("BTC")}</span></label>
                        <input type="text" name="" className="form-control" value="0.00000000" disabled="" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>{t("ENTER_AUTHENTICATION_CODE")}</label>
                        <input type="text" name="" className="form-control" value="123456" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input type="submit" name="" value="Withdraw" className="btn buttonType1 ml-0 ml-xl-auto" />
                      </div>
                    </div>
                  </div>
                  <div className="noteCont">
                    <h6>{t("NOTES")}:</h6>
                    <p>1. {t("MINUMUM_WITHDRAW")}:0.002BTC.</p>
                    <p>2. {t("INSIDE_YOUR_ACCOUNT")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TradeHeader pairdatachange={this.pairdatachange} />
        <div>
          <section className=''>
            <div className="row mx-0 myasset">
              <div className="col-md-12 px-0">
                <div className="assetsTable">
                  {/* <nav>
                    <div className="nav nav-tabs" id="navLink-tab" role="tablist">
                      <a href="/closedPandL" className="nav-item nav-link ">Closed P&L</a>
                      <Link to="/AssetsHistory" className="nav-item nav-link active">Deposit History</Link>
                      <Link to="/OrderHistory" className="nav-item nav-link">Order History</Link>
                      <Link to="/tradeHistory" className="nav-item nav-link">Trade History</Link>
                      <Link to="/withdrawalHistory" className="nav-item nav-link">Withdrawal History</Link>
                    </div>
                  </nav> */}
                 
                  <div className="tab-content card-body" id="nav-tabContent">
                  <h3 className="assetTitle">{t("ORDER_HISTORY")}</h3>
                  <nav>
                    <div className="nav nav-tabs paddingTopTen nav_border_new" id="nav-tab" role="tablist">
                      <a
                        className={this.state.exchangetype == 'Derivatives' ? "nav-item nav-link active ml-0 pl-0" : "nav-item nav-link ml-0 pl-0"}
                        id="nav-USD-tab" data-toggle="tab" href="#nav-USD" role="tab" aria-controls="nav-USD" aria-selected="false" onClick={this.exchangetype}>{t("DERIVATIVES")}</a>
                      <a
                        className={this.state.exchangetype == 'Spot' ? "nav-item nav-link active" : "nav-item nav-link"}
                        id="nav-INR-tab" data-toggle="tab" href="#nav-INR" role="tab" aria-controls="nav-INR" aria-selected="false" onClick={this.exchangetype}>{t("SPOT")}</a>
                    </div>
                  </nav>
                    <div className="tab-pane fade show active" id="nav-USD" role="tabpanel" aria-labelledby="nav-USD-tab">
                      <div className="tab-content">
                        <div className="assetHistoryTableTop clearfix mx-0">
                          <div className="row">
                            <div className="col-lg-12 col-xl-12 col-md-11 col-sm-12">
                              <div className="row">
                                <div className="col-lg-3 col-xl-2 col-md-4 col-sm-6">
                                  <div className="form-group clearfix">
                                    <label>{t("PAIRS")}</label>

                                    <Select styles={this.styles} className="border_blue_select"
                                      width='100%'
                                      menuColor='red'
                                      options={options}
                                      value={contract} isSearchable={false}
                                      onChange={this.changecontract}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-3 col-xl-2 col-md-4 col-sm-6">
                                  <div className="form-group clearfix">
                                    {/* <label>{t("PAIR_TYPE")}</label> */}
                                    { <label>{t("TYPE")}</label>}
                                    {/*<label>{t("TYPE")}</label> */}
                                    <Select styles={this.styles} className="border_blue_select"
                                      width='100%'
                                      menuColor='red'
                                      options={options1}
                                      value={type} isSearchable={false}
                                      onChange={this.changetype}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-3 col-xl-2 col-md-4 col-sm-6">
                                  <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DatePicker
                                      selected={this.state.startDate}
                                      onChange={this.handleChange}
                                      showYearDropdown
                                      showMonthDropdown
                                      // minDate={new Date()}
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-3 col-xl-2 col-md-4 col-sm-6">
                                  <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DatePicker
                                      selected={this.state.endDate}
                                      onChange={this.handleChange1}
                                      showYearDropdown
                                      showMonthDropdown
                                      minDate={new Date(new Date(this.state.startDate).setDate(new Date(this.state.startDate).getDate() + 1))}
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-3 col-xl-2 col-md-4 col-sm-6">
                                  <div className="form-group">
                                    <label className="d-none d-md-block invisible">s</label>
                                    <input type="button" name="" value={t("SEARCH")} onClick={this.Searchdata} className=" themebtn" />
                                  </div>
                                </div>
                              
                           
                            <div className="col-lg-3 col-xl-2 col-md-3 col-sm-4">
                              <div className="downLoadBTN float-xl-right">
                                <div className="form-group">
                                  <label className="d-none d-md-block invisible">s</label>

                                  {(this.state.csvData.length > 0) ?
                                    <CSVLink data={this.state.csvData} className="btn transYellowButton themebtn">{t("CSV")}</CSVLink> : ''
                                  }
                                </div>
                              </div>
                            </div>
                            </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="tab-content pb-4">

                        <div className="table-responsive table_radius walletBalanceTable_td_cen">
                          {this.state.historydetails ?
                          
                              <table id="assetsTable" className="table">
                                <thead>
                                  <tr>
                                    <th>{t("CONTRACTS")}</th>
                                    <th>{t("QTY")}</th>
                                    <th>{t("FILLED_REMAINING")}</th>
                                    <th>{t("EXEC_PRICE")}</th>
                                    <th>{t("TRIGGER_PRICE")}</th>
                                    <th>{t("ORDER_PRICE")}</th>
                                    <th>{t("ORDER_VALUE")}</th>
                                    <th>{t("TRADING_FESS")}</th>
                                    <th>{t("TYPE")}</th>
                                    <th>{t("STATUS")}</th>
                                    <th>{t("ORDER")}#</th>
                                    <th>{t("ORDER_TIME")}</th>
                                  </tr>
                                </thead>
                                {this.state.historydetails.length > 0 ? (
                                <tbody>
                                  {
                                    historydetails.map((item, i) => {
                                      var pairName = item.pairName ? item.pairName : '';
                                      var quantity = item.quantity ? item.quantity : 0;
                                      var price = item.quantity ? item.price : 0;
                                      var buyorsell = item.buyorsell ? item.buyorsell : 0;
                                      var filledAmount = item.filledAmount ? item.filledAmount : 0;
                                      var orderValue = item.orderValue ? item.orderValue : 0;
                                      var orderType = item.orderType ? item.orderType : 0;
                                      var orderDate = item.orderDate ? item.orderDate : 0;
                                      var classs = item.buyorsell == 'buy' ? 'greenText' : 'pinkText';
                                      var _id = item._id ? item._id : 0;
                                      var status1 = item.status;
                                      var e_price = (typeof item.filled != 'undefined' && item.filled.length > 0) ? item.filled[0].Price : 0;
                                      var tradingfees = (typeof item.filled != 'undefined' && item.filled.length > 0) ? item.filled[0].Fees : 0;
                                      var Remaining = parseFloat(quantity) - parseFloat(filledAmount);
                                      var data1 = new Date(orderDate);
                                      let date12 = data1.getFullYear() + '-' + (data1.getMonth() + 1) + '-' + data1.getDate() + ' ' + data1.getHours() + ':' + data1.getMinutes() + ':' + data1.getSeconds();
                                      return <tr>
                                        <td>{pairName}</td>
                                        <td>{parseFloat(quantity).toFixed(8)}</td>
                                        <td className="text-center">{parseFloat(filledAmount).toFixed(8) + '/' + parseFloat(Remaining).toFixed(8)}</td>
                                        <td>{parseFloat(e_price).toFixed(2)}</td>
                                        <td className="text-center">{'-/-'}</td>
                                        <td>{parseFloat(price).toFixed(2)}</td>
                                        <td>{parseFloat(orderValue).toFixed(8)}</td>
                                        <td>{parseFloat(tradingfees).toFixed(8)}</td>
                                        <td>{buyorsell}</td>
                                        {(status1 == 0) ?
                                          <td className="text-center">{t("NEW")}</td>
                                          : ''}
                                        {(status1 == 1) ?
                                          <td className="text-center">{t("COMPLETED")}</td>
                                          : ''}
                                        {(status1 == 2) ?
                                          <td className="text-center">{t("PARTIAL")}</td>
                                          : ''}
                                        {(status1 == 3) ?
                                          <td className="text-center">{t("CANCEL")}</td>
                                          : ''}
                                        <td>{_id}</td>
                                        <td>{date12}</td>
                                      </tr>
                                    })
                                  }
                                </tbody>
                                ):(<tbody>
                                  <tr ><td colspan="12" className="text-center">{t("NO_RECORDS")}</td></tr></tbody>)}
                              </table>: ''}
                        </div>

                      </div>
                    </div>
                    <div className="tab-pane fade" id="nav-INR" role="tabpanel" aria-labelledby="nav-INR-tab">
                      <div className="tab-content">
                        <div className="assetHistoryTableTop clearfix mx-0">
                          <div className="row">
                            <div className="col-lg-12 col-xl-12 col-md-11 col-sm-12">
                              <div className="row">
                                <div className="col-lg-3 col-xl-2 col-md-4 col-sm-6">
                                  <div className="form-group clearfix">
                                    <label>{t("PAIRS")}</label>

                                    <Select
                                     styles={this.styles} className="border_blue_select"
                                      width='100%'
                                      menuColor='red'
                                      options={options} isSearchable={false}
                                      value={contract}
                                      onChange={this.changecontract}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-3 col-xl-2 col-md-4 col-sm-6">
                                  <div className="form-group clearfix">
                                    <label>{t("TYPE")}</label>
                                    <Select
                                    styles={this.styles} className="border_blue_select"
                                      width='100%'
                                      menuColor='red'
                                      options={options1}
                                      value={type} isSearchable={false}
                                      onChange={this.changetype}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-3 col-xl-2 col-md-4 col-sm-6">
                                  <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DatePicker
                                      selected={this.state.startDate}
                                      onChange={this.handleChange}
                                      showYearDropdown
                                      showMonthDropdown
                                      // minDate={new Date()}
                                      onKeyDown={(e) => {
                                        console.log("keydown")
                                        e.preventDefault();
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-3 col-xl-2 col-md-4 col-sm-6">
                                  <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DatePicker
                                      selected={this.state.endDate}
                                      onChange={this.handleChange1}
                                      showYearDropdown
                                      showMonthDropdown
                                      minDate={new Date(new Date(this.state.startDate).setDate(new Date(this.state.startDate).getDate() + 1))}
                                      onKeyDown={(e) => {
                                        console.log("keydown")
                                        e.preventDefault();
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-3 col-xl-2 col-md-4 col-sm-6">
                                  <div className="form-group">
                                    <label className="d-none d-md-block invisible">s</label>
                                    <input type="button" name="" value="Search" onClick={this.Searchdata} className="buttonType1 ml-0 ml-xl-auto" />
                                  </div>
                                </div>
                                <div className="col-lg-3 col-xl-2 col-md-3 col-sm-4">
                              <div className="downLoadBTN float-xl-right">
                                <div className="form-group">
                                  <label className="d-none d-md-block invisible">s</label>

                                  {(this.state.csvData.length > 0) ?
                                    <CSVLink data={this.state.csvData} className="btn transYellowButton">{t("")}Download.csv</CSVLink> : ''
                                  }
                                </div>
                              </div>
                            </div>
                              </div>
                            </div>
                           
                          </div>
                        </div>

                      </div>
                      <div className="tab-content">

                        <div className="table-responsive table_radius walletBalanceTable_td_cen">
                          {this.state.historydetails ?
                         
                              <table id="assetsTable" className="table">
                                <thead>
                                  <tr>
                                    <th>{t("CONTRACTES")}</th>
                                    <th>{t("QTY")}</th>
                                    <th>{t("FILLED_REMAINING")}</th>
                                    <th>{t("EXEC_PRICE")}</th>
                                    <th>{t("TRIGGER_PRICE")}</th>
                                    <th>{t("ORDER_PRICE")}</th>
                                    <th>{t("ORDER_VALUE")}</th>
                                    <th>{t("TRADING_FESS")}</th>
                                    <th>{t("TYPE")}</th>
                                    <th>{t("STATUS")}</th>
                                    <th>{t("ORDER")}#</th>
                                    <th>{t("ORDER_TIME")}</th>
                                    {/* <th>Order Time</th> */}
                                  </tr>
                                </thead>
                                {this.state.historydetails.length > 0 ? (
                                <tbody>
                                  {
                                    historydetails.map((item, i) => {

                                      var pairName = item.pairName ? item.pairName : '';
                                      var quantity = item.quantity ? item.quantity : 0;
                                      var price = item.quantity ? item.price : 0;
                                      var buyorsell = item.buyorsell ? item.buyorsell : 0;
                                      var filledAmount = item.filledAmount ? item.filledAmount : 0;
                                      var orderValue = item.orderValue ? item.orderValue : 0;
                                      var orderType = item.orderType ? item.orderType : 0;
                                      var orderDate = item.orderDate ? item.orderDate : 0;
                                      var classs = item.buyorsell == 'buy' ? 'greenText' : 'pinkText';
                                      var _id = item._id ? item._id : 0;
                                      var status1 = item.status;
                                      var e_price = (typeof item.filled != 'undefined' && item.filled.length > 0) ? item.filled[0].Price : 0;
                                      var tradingfees = (typeof item.filled != 'undefined' && item.filled.length > 0) ? item.filled[0].Fees : 0;
                                      var Remaining = parseFloat(quantity) - parseFloat(filledAmount);
                                      var data1 = new Date(orderDate);
                                      let date12 = data1.getFullYear() + '-' + (data1.getMonth() + 1) + '-' + data1.getDate() + ' ' + data1.getHours() + ':' + data1.getMinutes() + ':' + data1.getSeconds();
                                      return <tr>
                                        <td>{pairName}</td>
                                        <td>{parseFloat(quantity).toFixed(8)}</td>
                                        <td className="text-center">{parseFloat(filledAmount).toFixed(8) + '/' + parseFloat(Remaining).toFixed(8)}</td>
                                        <td>{parseFloat(e_price).toFixed(2)}</td>
                                        <td className="text-center">{'-/-'}</td>
                                        <td>{parseFloat(price).toFixed(2)}</td>
                                        <td>{parseFloat(orderValue).toFixed(8)}</td>
                                        <td>{parseFloat(tradingfees).toFixed(8)}</td>
                                        <td>{buyorsell}</td>
                                        {(status1 == 0) ?
                                          <td className="text-center">{t("NEW")}</td>
                                          : ''}
                                        {(status1 == 1) ?
                                          <td className="text-center">{t("COMPLETED")}</td>
                                          : ''}
                                        {(status1 == 2) ?
                                          <td className="text-center">{t("PARTIAL")}</td>
                                          : ''}
                                        {(status1 == 3) ?
                                          <td className="text-center">{t("CANCEL")}</td>
                                          : ''}
                                        <td>{_id}</td>
                                        <td>{date12}</td>
                                        <td>{date12}</td>
                                      </tr>
                                    })
                                  }
                                </tbody>): (<tbody>
    <tr ><td colspan="12" className="text-center">{t("NO_RECORDS")}</td></tr></tbody>)}
                              </table> : ''}
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

OrderHistory.propTypes = {
  Searchdata: PropTypes.func.isRequired,
  orderhistory: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(

  mapStateToProps,
  {
    orderhistory,
    Searchdata,
  }
)(withTranslation()(OrderHistory),withRouter(OrderHistory));
