export default {
    "REGISTER_TITLE_1": "Register your account easily and strart trading.",
    "REGISTER": "Register",
    "ALREADY_HAVE_ACCOUNT": "Already have an account?",
    "ACCEPT_TERMS_MESSAGE": "Please accept the Terms & Conditions of Use",
    "RECOVERY_PASSWORD_TITLE": "Type in your registered password and we will send you a password recovery email.",
    "EMAIL_NOT_EXISTS": "User account not found",
    // "SIGN_IN_BUTTON": "Sign In",
    "EMAIL_PLACEHOLDER": "Email Address",
    "EMAIL_CHANGE_PLACEHOLDER": "Change Email Address",
    "ENTER_PASSWORD": "Enter Password",
    "KEEP_SIGN_COMPUTER": "Keep me signed in on this computer",
    "DON'T_HAVE_ACCOUNT": "Don't have an account",
    "EMAIL_REQUIRED": "Email field is required",
    "EMAIL_INVALID": "Invalid Email Address",
    "INVALID_PASSWORD": "invalid Password",
    "ONLY_NUMERIC": "Only allow numeric value",
    "INVALID_OTP": "Invalid OTP",
    "FORGOT_PASSWORD": "Forgot password",
    // "SIGN_IN": "Sign up for Free",
    "PASSWORD_REQUIRED": "Password field is required",
    "PASSWORD_INCORRECT": "Password incorrect",
    "CHECK_OPTION": "Please check option",
    "HAVE_BACKUP": " I have backup the code",
    "PASSWORD_MIN_MAX": "minimum 6 and maximum 18",
    "CONFIRM_PASSWORD_MISMATCH": "Passwords must match",
    "REGEX_PASSWORD": "Password should contain atleast one uppercase, atleast one lowercase, atleast one number, atleast one special character",
    "PASSWORD_CHANGE_SUCCESS": "Password Reset Successfully",
    "PASSSWORD_RESET": "Password Reset",
    "CURRENT_PASSWORD_REQUIRED": "Current Password field is required",
    "CONFIRM_PASSWORD_REQUIRED": "Confirm password field is required",
    "CONFIRM_PASSWORD": "Confirm Password",
    "UPDATE_PASSWORD": "Update Login Password",
    "LOGIN": "Login",
    "ORDER_HISTORY": "Order History",
    "DEFAULT_FIAT_CURRENCY": "Default Fiat Currency",
    "PAGE_AFTER_LOGIN": "Default Page After Login",
    "DEFAULT_LANGUAGE": "My Default Language",
    "REF_CODE_OPTION": "Referral Code (Optional)",
    "CURRENT_PASSWORD": "Current Password",
    "NEW_PASSWORD": "New Password",
    "CONFIRM_PASSWORD": "Confirm New Password",
    "REFERRAL_CODE": "Reference Code",
    "I_AGREE": " I accept and agree to the",
    "REFERRAL": "Referral",
    "YOUR_REFERRAL_ID": "Your Referral ID",
    "INVITE_FRIENDS": "Invite Friends",
    "YOUR_TOTAL_REFERRAL": "Your Total Referral Income ",
    "TERMS_AND_CONDITION": "Terms & Conditions",
    "AND": "and",
    "PRIVACY": "Privacy Policy",
    "PASSWORD": "Password",
    "OTP": "OTP",
    "DEFINITION": "Definitions",
    "TERMS": "terms",
    "OUR_TERMS": "Our Terms",
    "TERMS_CONTENT_1": "These Aurex Terms of Use is entered into between you (hereinafter referred to as “you” or “your”) and Aurex operators (as defined below). By accessing, downloading, using or clicking on “I agree” to accept any Aurex Services (as defined below) provided by Aurex (as defined below), you agree that you have read, understood and accepted all of the terms and conditions stipulated in these Terms of Use (hereinafter referred to as “these Terms”) as well as our Privacy Policy at   https://www.Aurex.com/en/privacy. In addition, when using some features of the Services, you may be subject to specific additional terms and conditions applicable to those features.",
    "TERMS_CONTENT_2": "By accessing, using or attempting to use Aurex Services in any capacity, you acknowledge that you accept and agree to be bound by these Terms. If you do not agree, do not access Aurex or utilize Aurex services.",
    "TERMS_CONTENT_3": "Aurex refers to an ecosystem comprising Aurex websites (whose domain names include but are not limited to  https://www.Aurex.com), mobile applications, clients, applets and other applications that are developed to offer Aurex Services, and includes independently-operated platforms, websites and clients.",
    "TERMS_CONTENT_4": "Aurex Operators refer to all parties that run Aurex, including but not limited to legal persons, unincorporated organizations and teams that provide Aurex Services and are responsible for such services. For convenience, unless otherwise stated, references to “Aurex” and “we” in these Terms specifically mean Aurex ",
    "TERMS_CONTENT_5": " Aurex Services refer to various services provided to you by Aurex that are based on Internet and/or blockchain technologies and offered via Aurex websites, mobile applications, clients and other forms (including new ones enabled by future technological development). Aurex Services include but are not limited to such Aurex ecosystem components as Digital Asset Trading Platforms, the financing sector, Aurex Labs, Aurex Academy, Aurex Charity, Aurex Info, Aurex Launchpad, Aurex Research, Aurex Chain, Aurex X, Aurex Fiat Gateway, existing services offered by Trust Wallet and novel services to be provided by Aurex",
    "SEND_CODE": " Send Code",
    "RE_SEND_CODE": " Resend Code",
    "VERIFY_RECAPTCHA": "Verify recaptcha",
    "MOBILE_NO": "Mobile Number",
    "ENTER_MOBILE_NO": "Enter mobile number",
    "VERIFY_CODE": "Verification Code",
    "REQUIRED": "Required",
    "PRICE_ONLY_NUMERIC": "Price only numeric value",
    "FIRST_NAME_ALPHABET": "FirstName Must be filled out with Alphabets",
    "LAST_NAME_ALPHABET": "LastName Must be filled out with Alphabets",
    "BLOCKNO_NAME_ALPHABET": "BlockNo Must be filled out with Alphabets",
    "STATE_NAME_ALPHABET": "State Must be filled out with Alphabets",
    "CITY_NAME_ALPHABET": "City Must be filled out with Alphabets",
    "POSTAL_CODE_MUST_NUMBER": "PostalCode Must be filled out with Numbers",
    "INVALID_EMAIL": "Invalid email",
    "FILLED": "filled",
    "ONLY_NUMERIC": "Only allowed numeric values",
    "ONLY_NUMERIC_POSITIVE": "Only allowed positive numeric values",
    "QUANTITY_REQUIRED": "Quantity field is required",
    "MAX_LIMIT_REQUIRED": "Max limit field is required",
    "MAX_LIMIT_4": "Max limit 4",
    "INVALID_CODE": "Invalid Code",
    "TERMS_REQUIRED": "Terms field is required",
    "PERSONAL_DETAILS": "Personal Details",
    "FULL_NAME": "Full Name",
    "RESIDENTIAL_ADDRESS": "Residential Address",
    "BUILDING_BLOCK": "Building / Block Name",
    "ADDRESS": "Address",
    "STATE_PROVISION": "State / Province",
    "CITY": "City",
    "POSTAL_CODE": "Postal Code",
    "COUNTRY": "Country",
    "EDIT_PROFILE": "Edit my Profile",
    "SUBMIT": "Submit",
    "FIRST_NAME": "First Name",
    "LAST_NAME": "Last Name",
    "NAME": "Name",
    "PROFILE_EDIT_SUCCESS": "Thank you, your profile has been updated!",
    "SOMETHING_WRONG": "Something went wrong",
    "NO_DATA": "There is no data",
    "NO_ORDER": "There is no order",
    "ORDER_CANCEL": "Your Order cancelled successfully",
    "ORDER_ALREADY_COMPLETED": "Your Order already completed",
    "ORDER_ALREADY_CANCEL": "Your Order already cancelled",
    "REGISTER_BANK_ACCOUNT": "Here you can register your bank account within the system which will allow you to withdraw funds from coin goldx",
    "ADD_BANK_ACCOUNT": "Add Bank Account",
    "ADD_BANK": "Add Bank",
    "READ": "Read",
    "MY_BANK_ACCOUNT": "My Bank Accounts",
    "DEFAULT": "Default",
    "BANK_ACCOUNT": "Bank Account",
    "CHANGE": "change",
    "BANK_NAME": "Bank Name",
    "ACCOUNT_NO": "Account No",
    "ACCOUNT_NUMBER": "Account Number",
    "ACCOUNT_HOLDER_NAME": "Account Holder Name",
    "IBAN_SWIF": "IBAN/Swift Code",
    "IBAN_CODE": "IFSC Code",
    "BANK": "Bank",
    "STATUS": "Status",
    "CREATED_ON": "Create on",
    "TWO_FA": "2 Factor Authentication",
    "EDIT": "Edit",
    "DELETE": "Delete",
    "TWO_FA_MSG": "You have to enable 2FA in order to withdraw fiat funds.",
    "BANK_EDIT_SUCCESS": "Bank details updated successfully",
    "BANK_ADD_SUCCESS": "Bank details added successfully",
    "BANK_DELETE_SUCCESS": "Bank details deleted successfully",
    "BANK_SET_PRIMARY_SUCCESS": "Default bank details updated successfully",
    "UPDATE": "Update",
    "NOTES": "Notes",
    "MIN_DEPOSIT_LIMIT": "Minimum deposit limit",
    "DEPOSIT_TIME": "Deposit time will take 4-24 hours due to bank network",
    "CHAT_ONLY_TRADE": "Chat only trade details info",
    "WITHDRAW_FIAT_DEPOSIT": "Withdraw Fiat Deposit",
    "WITHDRAW_FIAT": "Withdraw Fiat",
    "AVOID_WORNG_WORDS": "Avoid unnecessary words",
    "TRANSFER_PAYMENT_BUTTON": " Click Transfer Payment buttoncomplete the payment to the seller",
    "NEED_SUPPORT": "Need Support?",
    "CREAT_SUPPORT_TICKET": "Create Support Ticket",
    "SUPPORT_DETAILS": "Support Details",
    "2FA_VERIFID": "2FA Code Verification",
    "PASSWORD_TITLE": "For security measures, your password should contain:",
    "PASSWORD_DESCRIPTION1": "a minimum of 6 characters long",
    "PASSWORD_DESCRIPTION2": "at least one UPPERCASE letter (A, B, X, Y...)",
    "PASSWORD_DESCRIPTION3": "at least one digit (0,1,2,9...)",
    "PASSWORD_DESCRIPTION4": "at least one special character (! @ # $ ^ *...)",
    "PASSWORD_DESCRIPTION5": "a maximum of 18 characters long",
    "PASSWORD_DESCRIPTION6": "at least one LOWERCASE letter (a,b,x,y...)",
    "TWO_FA_DESCRIPTION1": `Enable Two Factor Authentication as an additional security measure, to protect your funds and be able to withdraw. Please use Google Authenticator
    which you can`,
    "TWO_FA_DESCRIPTION2": `Please download the google authenticator by clicking "Download here" and scan the QR code. You will be receiving a 6 digit code, which need to enter in 2FA box.`,
    "TWO_FA_DESCRIPTION3": "Right down the 32 digit code in a white paper for your security if you forgot the 2FA code to withdraw funds",
    "DOWNLOAD_HERE": "Download Here",
    "HOW_ENABLE": "How to Enable",
    "ENABLE": "Enable 2FA",
    "DISABLE": "Disable 2FA",
    "ENTER_TWO_FA_CODE": "Enter 6 Digit 2FA Code",
    "TWO_FA_CODE": "Your 32 Digit Security Code",
    "SCAN_QR_CODE": "Scan the QR Code",
    "DISABLED": "Disabled",
    "ENABLED": "Enabled",
    "TWO_FA_ENABLE_SUCCESS": "2FA enabled successfully",
    "TWO_FA_DISABLE_SUCCESS": "2FA disabled successfully",
    "EDIT_SETTING_SUCCESS": "Settings updated successfully",
    "IDENTITY_DOCUMENT": "Identity Document",
    "IDENTIFICATION_DOCUMENT": "Identification Document",
    "SELECTED_ID_NUMBER": "Enter the selected document ID number",
    "FRONT_SIDE": "Front Side",
    "PICTURE_SCAN": "Picture or scan showing the 4 corners",
    "MAX_1MB_IMG": "Max 1MB in jpg/png/pdf",
    "BACK_SIDE": "Back Side",
    "SIDE": "Side",
    "EXECTED_PRICE": "Executed Price",
    "EXECUTED": "Executed",
    "ADA": "ADA",
    "SELFIE_SELECTED_ID": "Selfie with Selected ID",
    "IDENTITY_DOCUMENT_TITLE1": "Here you can verify your identity by uploading one of the approved identity documents",
    "IDENTITY_DOCUMENT_DESCRIPTION1": "All Images should be clearly visible and text also clearly visible",
    "IDENTITY_DOCUMENT_DESCRIPTION2": "If not readable text, unclear face, blur image should be disqualified and the status will be NOT verified.",
    "IDENTITY_DOCUMENT_DESCRIPTION3": "The selfie or picture with  your selected ID must show clear face holding the selected ID. Please see an example",
    "IDENTITY_DOCUMENT_DESCRIPTION4": "If you want to change your identity document please contact our",
    "IDENTITY_HINT1": "Max. 9MB size, jpg, png, pdf allowed",
    "IDENTITY_HINT2": "Max 9MB in jpg/png/pdf",
    "HERE": "Here",
    "IDENTITY_DOCUMENT_VERIFIED": "Your ID document has been uploaded correctly",
    "SUPPORT_TEAM": "support team",
    "TICKET_FOR": "Ticket For",
    "MSG_TO_SUPPORT_TEAM": "Message to Support Team",
    "ADDRESS_DOCUMENT_TITLE1": "Proof of Residential Address",
    "ADDRESS_DOCUMENT_TITLE2": "Address Proof Document",
    "ADDRESS_DOCUMENT_TITLE3": "Upload the document",
    "ADDRESS_DOCUMENT_DESCRIPTION1": "The document must clearly indicate your full name and full address.",
    "ADDRESS_DOCUMENT_DESCRIPTION2": "Document should NOT be older than 3 months",
    "ADDRESS_DOCUMENT_DESCRIPTION3": "Approved utility bill electricity bill, gas bill, landline phone, internet bill. Mobile phone bill NOT accepted. ",
    "ADDRESS_DOCUMENT_DESCRIPTION4": "Utility Bill (Approved utility bills: electricity bill, gas bill, landline phone, internet bill). Mobile phone not accepted.",
    "ADDRESS_DOCUMENT_DESCRIPTION5": "If you want to change your Address document please contact our",
    "ADDRESS_DOCUMENT_VERIFIED": "Your proof of address has been submitted",
    "DRIVING_LICENSE": "Driving License",
    "UTILITY_BILL": "Utility Bill",
    "BANK_STATEMENT": "Bank statement",
    "CREDIT_CARD_STATEMENT": "Credit card statement",
    "MUNICIPALITY_REGISTRATION": "Municipality Registration",
    "GOVERNMENT_ISSUED_ID": "Government issued ID",
    "VOTER_ID": "Voter ID",
    "CITIZEN_SHIP": "Citizen ship",
    "PAN_CARD": "Pan Card Image",
    "PASSPORT": "Passport",
    "PANCARD": "Pancard",
    "GAS_BILL": "Gas Bill",
    //"CITIZEN_SHIP": "Aadhar Card",
    "TOO_LARGE": "Too large",
    "INVALID_IMAGE": "Please select valid format",
    "IDENTITY_DOC_UPLOAD_SUCCESS": "Identity document updated successfully",
    "ADDRESS_DOC_UPLOAD_SUCCESS": "Residential document updated successfully",
    "NOT_APPLY": "Not Apply",
    "VERIFIED": "Verified",
    "NOT_VERIFIED": "Not Verified",
    "REJECTED": "Rejected",
    "BASIC": "Basic",
    "ADVANCED": "Advanced",
    "PRO": "Pro",
    "BASIC_PENDING": "Basic Pending",
    "BASIC_SUBMITTED": "Basic Submitted",
    "SUBMITTED": "Submitted",
    "BASIC_VERIFIED": "Basic Verified",
    "ADVANCED_Pending": "Advanced Pending",
    "PRO_PENDING": "Pro Pending",
    "ADVANCED_Pending": "Advanced Pending",
    "PRO_PENDING": "Pro Pending",
    "BASIC_USER": "Basic User",
    "ADVANCED_USER": "Advanced User",
    "PRO_USER": "Pro User",
    "TYPE_BASIC_DESCRIPTION1": "Deposit crypto up to 10,00 € in value",
    "TYPE_BASIC_DESCRIPTION2": "Trade crypto and fiat markets",
    "TYPE_BASIC_DESCRIPTION3": "Withdraw crypto and fiat up to 10,00  € in value for maximum $2500",
    "ID_ADDRESS_PROOF": "ID & Address Proof",
    "DEFAULT_BANK_ACCOUNT_DETAIL": "Bank Account Proof (only default withdraw bank)",
    "USER_ID": "User ID",
    "MEMBER_SINCE": "Member Since",
    "VERIFICATION_TYPE": "Verification Type",
    "EMAIL_PHONE": "Email Address & Phone",
    "VERIFY_NOW": "Verify Now",
    "PENDING": "Pending",
    "PHONE": "Phone",
    "TRANS_WALLET_AMOUNT": "Transfer Wallet Amount",
    "WITHDRAW_ACCOUNT": "Withdraw Account",
    "FIAT_WITHDRAW_DESCRIPTION1": "Minimal Withdraw",
    "FIAT_WITHDRAW_DESCRIPTION2": "Withdraw will take 24-48 hrs due to bank network / holidays",
    "FINAL_WITHDRAW_AMOUNT": "Final Withdraw Amount",
    "FINAL_WITHDRAW_AMOUNT_WITH_FEE": "Withdraw Amount with Fee",
    "WALLET_BALANCE": "Wallet Balance ",
    "ENTER2FA_CODE": "Enter 2FA Code",
    "WALLET_TRANSFER": "Wallet Transfer",
    "MIN_WITHDRAW_LIMIT": "Minimum withdraw limit ",
    "WITHDRAW_TIME_TAKE": "Withdraw time will take 4-24 hours due to bank network",
    "WITHDRAW_TIME": "Your Withdraw Valid only for 2 Minutes",
    "WITHDRAW_PROCESS": "Submit Your Withdraw, your Received mail can approve (or) cancel",
    "WITHDRAW_PROCESS2": "after 2 minutes your withdraw not completed automatically cancelled and then your amount will be updated",
    "WITHDRAW_AMOUNT": "Withdraw Amount",
    "FUND_TRANSFER": "Fund Transfer",
    "RECEIVER_EMAIL": "Receiver Email",
    "WITHDRAW_ADDRESS": "Withdraw Address",
    "ALLOW_NUMERIC": "Only allowed numeric",
    "QUANTITY_ONLY_NUMERIC": "Quantity only numeric value",
    "SIDE_REQUIRED": "Side field is REQUIRED",
    "INVALID_SIDE": "Invalid side",
    "MIN_LIMIT_REQUIRED": "Min limit field is REQUIRED",
    "MAX_LIMIT_REQUIRED": "Max limit field is REQUIRED",
    "SELECT_DATE": "Please Select date",
    "GREATER_THEN_CURRENT": "Please Select date Greater than Current date",
    "LESS_LIMIT_PURCHASE": "From limit price should be less than to limit price",
    "ACCEPT_TERMS": "Please accept the terms and policy",
    "BALANCE": "Balance",
    "DISTANCE": "Distance",
    "INSUFFICIENT_BALANCE": "Insufficient balance",
    "KYC_SUBMIT_ALERT": "Please submit your kyc details",
    "EMAIL_SUMBIT": "Pleasen submit your email details",
    "INVALID_BANK_ACCOUNT": "Invalid bank account",
    "VERIFICATION_LINK": "Verification link sent to email address",
    "INVALID_TOKEN": "Invalid Token",
    "EXPIRY_TOKEN": "Expiry token",
    "DEPOSIT_QR_CODE": "To deposit use this QR",
    "COIN_ADDRESS": "Your Coin Address",
    "COPY_ADDRESS": "Copy Address",
    "COIN_DEPOSIT_DESCRIPTION1": "Send only LTC to this deposit address.",
    "COIN_DEPOSIT_DESCRIPTION2": "Minimal Deposit: 0.001 LTC",
    "COIN_DEPOSIT_DESCRIPTION3": "Sending coin or token other than LTC to this address may result in the loss of your deposit.",
    "WITHDRAW_TOO_LOW": "Final amount too low",
    "FINAL_AMOUNT": "Final Amount",
    "FIAT_DEPOSIT_DESCRIPTION1": "Minimal Deposit: €10",
    "FIAT_DEPOSIT_DESCRIPTION2": "Must upload proof of deposit in pdf / jpg and enter reference number / ID",
    "ADMIN_ACCOUNT": "Admin Account",
    "DEPOSIT": "Deposit",
    "DEPOSIT_AMOUNT": "Deposit Amount",
    "UPLOAD_PROOF": "Upload Proof",
    "STAKE_AMOUNT": "Stake Amount",
    "T_HAVE_READ": "I have read the ",
    "DEPOSIT_REQUEST_SUCCESS": "Deposit request sent successfully",
    "DEPOSIT_TOO_LOW": "Deposit amount too low",
    "SELECT_CURRENCY": "Select Currency",
    "INVALID_WALLET_TYPE": "Invalid wallet type",
    "WALLET_MIS_MATCH": "From wallet and to wallet should be different",
    "TO_WALLET": "To Wallet",
    "FROM_WALLET": "From Wallet",
    "WALLET_TRANSFER_SUCCESS": "Wallet conversion process completed successfully",
    "OPEN_ORDER": "Open Orders",
    "CANCEL": "Cancel",
    "ORDERS": "Orders",
    "SUBSCRIBE_ETH": "Subscribe ETH",
    "LOT_AMOUNT": "Lot amount",
    "AVAILABLE_LOTS": "Available 10 Lots",
    "STAKE_LIMIT": "Stake Limit",
    "MINIMUM_LOT": "Minimum 1 Lot",
    "LOT_SIZE": "Lot size",
    "INTEREST_PER_LOT": "Interest per lot",
    "INDIVIDUAL_MAX": "Individual max",
    "VALUE_DATE": "Value date",
    "INTEREST_RATE": "Interest Rate",
    "INT_DURATION_TIME": "Interest Distribution Date",
    "REDEMPTION_DATE": "Redemption date",
    "EXPECT_INTEREST": "Expected interest",
    "HAVE_READ_AND_AGREE": "    I have read and I agree to the",
    "USER": "User",
    "CURRENT_SUBSCRIPTION": "Current Subscription",
    "TYPE": "Type",
    "RELEASE": "Release",
    "ENTER_CODE": "Enter code",
    "PRICE": "Price",
    "LATEST_PRICE": "Latest Price",
    "FLEXIBLE": "Flexible",
    "LOCKED": "Locked",
    "SUBSCRIBE": "Subscribe",
    "STOP_LIMIT": "Stop-limit",
    "STOP_MARKET": "Stop-market",
    "TRAILING_STOP": "Trailing-stop",
    "DEFAULT_THEME": "Default Theme for Trading",
    "LIGHT_THEME": "Light Theme",
    "DARK_THEME": "Dark Theme",
    "GENERAL_SETTINGS": "General Settings",
    "GENERAL": "General",
    "HIGH_YIELD": "High Yield",
    "CRYPTOS": "Cryptos",
    "VACATION": "Vacation",
    "DISABLE_ALL_ADVERTISMENT_SELL": "Disable all your advertisment for sell temprarily",
    "DISABLE_ALL_ADVERTISMENT_BUY": "Disable all your advertisment for buy temprarily",
    "YOUR_BALANCE": "Your Balance",
    "ESTIMATED_VALUE": "Estimated Value:",
    "BUSD": "BUSD",
    "BNB": "BNB",
    "TRX": "TRX",
    "XRP": "XRP",
    "OTHERS": "Other",
    "TOTAL_BALANCE": "Total Account Balance",
    "TRANSFER_FUND": "Transfer funds",
    "AMOUNT": "Amount",
    "WELCOME_TO": "Welcome to",
    "BANNER_DESCRIPTION_1": `We put the power in your hands to `,
    "BANNER_DESCRIPTION_2": "buy, sell and trade digital currency.",
    "GET_STARTED": "Get Started",
    "EXPLORE_MARKETS": "Explore Our Markets",
    "EXPLORE_MARKETS_DESCRIPTION_1": "The World's Leading Aurex Currency Exchange",
    "EXPLORE_MARKETS_DESCRIPTION_2": "Trade Bitcoin, ETH, and hundreds of other Aurex Currencies in minutes.",
    "BUY": "Buy",
    "DASHBOARD": "Dashboard",
    "MARKET": "Market",
    "SPOT_ORDERS": "Orders",
    "ADMIN_ANNOUNCEMENT": "Admin Announcement",
    "LAST_LOGIN": "Last login",
    "ADMIN": "Admin",
    "ACCOUNT": "Account",
    "KYC_VERIFICATION": "KYC Verification",
    "EMAIL": "Email",
    "MOBILE_PHONE": "Mobile Phone",
    "2FA_TOOL_TIP1": "In order to withdraw funds,\nplease enable 2 Factor Authentication",
    "ENABLE_NOW": "Enable Now",
    "DISABLE_NOW": "Disable Now",
    "LOGIN_PASSWORD": "Login Password",
    "ALERT": "Alert",
    "WHEN_ENABLE_DISABLE_2FA": "When enable / disable 2FA",
    "WHEN_LOGIN_PWD_CHG": "When login password change",
    "MARKTING_PROMOTION_EMAIL": "Markting and promotion email",
    "WALLET_BAL": "Wallet Balance",
    "AMOUNT_TO_STAKE": "Interested Amount to Stake",
    "SUBSCRIPTION_AMOUNT": "Subscription amount",
    "SUBSCRIPTION_DATE": "Subscription Date",
    "WITHDRAW": "Withdraw",
    "TRANSFER": "Transfer",
    "BTCUSDT": "BTCUSDT",
    "FIAT_HISTORY": "Fiat History",
    "CRYPTO_HISTORY": "Cryptocurrency History",
    "P2P_HISTORY": "P2P Order History",
    "ADV_FIAT_DEPOSIT": "Advanced Fiat Deposit upto",
    "ADV_FIAT_WITHDRAW": " Advanced Fiat Withdraw upto",
    "ALL_ADVANCE_FEATURE": "All Advanced Features",
    "PLUS": "PLUS",
    "FIAT_DEPOSIT": "Fiat Deposit",
    "DEPOSIT_BANK_INFO": "Deposit Bank Info",
    "SPOT_BALANCE": "Spot Balance",
    "ACCOUNT_FEATURES": "Full accounts features",
    "UNLIMITED_FIAT_DEP_WITH": "UNLIMITED Fiat Desposit and Withdraw",
    "DERIVATIVE_BAL": "Derivative Balance",
    "P2P_BAL": "P2P Balance",
    "STATISTICS": "Statistics",
    "TOTAL_SPOT_TRADE": "Total Spot Trade Completed",
    "TOTAL_DERIVATIVE_TRADE": "Total Derivatives Placed",
    "P2P_TRADE": "P2P Trade",
    "OPEN": "Open",
    "SPOT_TRADE": "Spot Trade",
    "DERIVATIVE_TRADE": "Derivative Trade",
    "2FA_PASS_UPDATE": "Two Factor & Password Update",
    "ESCROW_STATUS": "Escrow Status",
    "COMPLETED": "Completed",
    "RECENT_TRANSACTION": "Recent Transactions",
    "LOGIN_HISTORY": "Login History",
    "TRADE_HISTORY": "Trade History",
    "NOTIFICATION_HISTORY": "Notification History",
    "HISTORY": "History",
    "DATE": "Date",
    "TYPE": "Type",
    "SYMBOL": "Symbol",
    "TRANSACTION_REF": "Transaction Ref.",
    "NO_RECORD": "No Record",
    "POST_AD": "Post Ad",
    "MINUTES": "Minutes",
    "PLEASE_MAKE_PAYMENT": "Please make a payment within 30:00 mins, otherwise, the order will be cancelled automatically.",
    "TRADE_LIMIT": "Trade Limit",
    "TRANSFER_PAYMENT": "Transfered Payment",
    "RELEASE_ASSET": "Release Asset",
    "DISPUTE_TRADE": "Dispute Trade",
    "GENERAL": "General",
    "RLY_TO_ADMIN": "Reply to admin",
    "CLOSE_THE_TICKET": "Satisfied, Close this ticket",
    "WANT_PAY": "I want to pay",
    "WANT_SELL": "I want to sell",
    "CANCEL_ORDER": "Cancel Order",
    "WILL RECEIVE": "I will receive",
    "CALNEL_AD": "  Are you sure want to cancel the Ad?",
    "IP_ADDRESS": "IP Address",
    "LOCATION": "Location",
    "DEVICE": "Device",
    "FIAT_CRYPTO": "Fiat/Crypto",
    "TOTAL": "Total",
    "FIAT_WALLET": "Fiat Wallet",
    "CRYPTO_WALLET": "Crypto Wallet",
    "SPOT": "SPOT",
    "DERIVATIVE": "DERIVATIVE",
    "P2P": "P2P",
    "TRIGGER_CONDITION": "Trigger Conditions",
    "SELL_BITCOIN": "Sell Bitcoin",
    "BUY_BITCOIN": "Buy Bitcoin",
    "BITCOIN": "Bitcoin",
    "ETHEREUM": "Ethereum",
    "TOTAL_STAKING": "Total Staking",
    "RIPPLE": "Ripple",
    "LITE_COION": "Litecoin",
    "TETHER_COIN": "TetherCoin",
    "FIND_COIN": "Find Coin",
    "WALLET": "Wallet",
    "FUND": "Fund",
    "HOME": "Home",
    "FEES": "Fees",
    "FAQ": "FAQ",
    "TERMS_CONDITION": "Terms & Conditions",
    "TRANSFER_CONFIRM": "Transfer Confirm",
    "STAKING_DELAILS": "Staking Details",
    "DAY_APY": "Day APY",
    "YESTERDAY_APY": "Yesterday's Flexbile APY",
    "FLEXIBLE_INTERESTED": "Flexible Interest Per Thousand",
    "PRIVACY_POLICY": "Privacy Policy",
    "REG_EMAIL_MOBILE": "Your Registered Email Address/ Mobile Number",
    "DOWNLOAD_APP": "Download app from",
    "MARKETS": "Market",
    "SPOT_MARKET": "Spot Market",
    "DERIVATIVE_MARKET": "Derivative Market",
    "POLICY": "policy",
    "SUBMIT_POST": "Submit Post",
    "POST_ID": "Post ID",
    "POSTED_ON": "Posted on",
    "MARKET": "Market",
    "SUPPORT": "Support",
    "CONTACT_US": "Contact us",
    "CONTACT_PARAGRAPH_1": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    "SUPPORT_TICKET": "Support Ticket",
    "NEWS_UPDATE_TITLE": "Get the latest news and updates",
    "COPY_RIGHT": "©Copy right ©{{YEAR}} All rights reserved",
    "CONNECT_WITH_US": "Connect with us",
    "CONTACT": "Contact",
    "PROFILE": "Profile",
    "PERSONAL": "Personal",
    "MY_ACCOUNT": "My account",
    "LOGOUT": "Logout",
    "KYC": "KYC",
    "CONTACT_DETAILS": "Contact Details",
    "BANK_ACCOUNT_DETAIL": "Bank Account Details",
    "PROFILE_DESCRIPTION1": "Your personal details should be introduced strictly <b>as indicated in your official identification documents and proof of address</b> your address should be as indicated in the proof of address that is required for verifying the account. If you have any doubts please write us at <a>{{SUPPORT_MAIL}}</a>",
    "PROFILE_IMAGE": "Profile Image",
    "FILE_FORMAT1": "jpg/jpeg/png max {{SIZE}}",
    "CHANGE_EMAIL": "Change Email",
    "PHONE_NUMBER": "Phone Number",
    "SEND_OTP": "Send OTP",
    "RESEND_OTP": "Resend OTP",
    "ENTER_OTP": "Enter OTP",
    "SEC": "Sec",
    "UPDATE_PHONE_NO": "Update Phone Number",
    "ADD_ANOTHER_ACCOUNT": "Add Another Account",
    "WHY_COIN": "Why Coin",
    "PARAGRAPH_1": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    "GOLD": "Gold",
    "SPOT_TRADE": "Spot Trading",
    "PARAGRAPH_2": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    "START_TRADE": "Start Spot Trading",
    "DERIVATIVE_TRADE": "Derivative Trading",
    "PARAGRAPH_3": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    "START_DERIVATIVE": "Start Derivative Trading",
    "LIGHT": "Light",
    "MODE": "Mode",
    "DARK": "Dark",
    "COIN": "Coin",
    "FEATURE": "Features",
    "FREE": "Free",
    "INSTANT": "Instant",
    "TRADING": "Trading",
    "FIAT_EASILY": "Fiat Easily",
    "PROMPT": "Prompt",
    "KYC_VERIFY": "KYC Verify",
    "SECURE_PLATFORM": "Our Secured Platform",
    "2FA_PROTECT": "2FA Protected",
    "PARAGRAPH_4": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    "PARAGRAPH_5": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    "PARAGRAPH_6": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    "256-BIT": "256-bit Encryption",
    "SECURE_WALLET": "Secured Wallet",
    "JOIN_REFERRAL_HEADING": "Join our referral Program",
    "EARN_MONEY": "Start earning money by inviting friends",
    "JOIN_REFERRAL": "Join Our Referral ",
    "GET_STARTED_HEADING": "Get started in a few minutes",
    "SIGN_UP": "Sign up",
    "ACCOUNT_ACCESS": "Instant 2 min account access",
    "VERIFY": "Verify",
    "PASSWORD_ADDRESS": "with Passport & Address proof",
    "TRADE": "Trade",
    "PAIR_PROFIT": "with Multi pair & book profit",
    "TRADE_WITH": "Trade With Mobile App",
    "FOOTER_P": "Compatible with multiple devices, start trading with safety and convenience.",
    "P2P_TRADING": "P2P Trading",
    "PARAGRAPH_7": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.",
    "I_WANT_TO": "I want to ",
    "ACCOUNT_FROZEN": " Malicious clicks will lead to account frozen",
    "SELL": "Sell",
    "BUY": "Buy",
    "PAY_WITH_TIME_LIMIT": "Pay within the time limit, otherwise the order cancelled automatically 1",
    "PAY_TRADE_TERMS": "Only pay as per trade terms",
    "CONFIRM_PAYMENT": "Confirm Payment",
    "PAYMENT_MADE_SELLER": "Please confirm that payment has been made to the seller.",
    "SELECT_CRYPTO": "Select Crypto",
    "AMOUNT": "Amount",
    "PAYMENT_TYPE": "Payment Type",
    "START_p2p": "Start P2P",
    "EMAIL": "Email",
    "NOT_VERIFIED": "Not Verified",
    "ID_DOCUMENT": "ID Document:",
    "ADDRESS_PROOF": "Address Proof:",
    "2FA_HEADING": "2 Step Authentication",
    "DIGITAL_CODE": "Enter 2FA Six Digit Code",
    "PASTE_CODE": "Paste Code",
    "CONFIRM": "Confirm",
    "SECURITY_SETTINGS": "Security Settings",
    "POST_NEW_AD": "Post New Ad",
    "P2P_STATISTICS": "P2P Statistics",
    "TOTAL_TRADES": "Total Trades",
    "FIRST_TRADE": "First Trade",
    "TRADES": "trades",
    "COMPLETION": "completion",
    "AVL": "Avl",
    "CREATED_TIME": "Created Time",
    "ORDER_ID": "Order ID",
    "ATTACHMENT": "Attachment",
    "MORE_ABOUT_SUPPORT": "More About Support",
    "LOGIN_ISSUE": "Login Issue",
    "DEPOSIT_ISSUE": "Deposit Issue",
    "WITHDRAW_ISSUE": "Withdraw Issue",
    "ADMIN_RLY": "Admin reply on",
    "CONFIRM_MY_POST": "Confirm My Post",
    "FILTER_BY": "Filter by",
    "REPLAY": "Reply",
    "BACK": "Back",
    "YOUR_API_KEY": "Your API Keys",
    "ID": "Id",
    "CREATED": "Created",
    "PERMISSION": "Permissions",
    "ACTION": "Action",
    "STAKE_NOW": "  Stake Now",
    "LOADING": "Loading...",
    "INVALID_URL": "Invalid Url",
    "TRANSFER_BITCOIN": "Transfer Bitcoin",
    "TICKET_ID": "Ticket ID",
    "POST_HISTORY": "Post History",
    "REF_KEY_LATER": "Optional name for this key. This will help you reference this key later.",
    "IP_RESTRICTION": "IP access restrictions:",
    "SECURITY_REASONS": "For security reasons, please do not reveal your key(s) to non-trusted third parties.We recommend that users add their IP addresses to each of the API key. A maximum of four (4) IP addresses can be added to each key. To add multiple IP addresses, enter them seperately with commas in between. e.g.192.168.1.1,192.168.1.2,192.168.1.3",
    "CREATE_API_KEY": "  Create API Key",
    "WRITE_SECRET_KEY": "Please write down the secret key.",
    "SOMEWHERE_SAFE": "Write it down somewhere safe! The secret key will no longer be available once you leave this page.",
    "SECRET": "Secret",
    "USD": "USD",
    "EURO": "Euro",
    "ADD_ANOTHER_ACCOUNT": "    Add Another Account",
    "CRYPTO": "Crypto",
    "LAST_PRICE": "Last Price",
    "FIAT": "Fiat",
    "YOUR_FULL_NAME": "Your Full Name",
    "SUBJECT": "Subject",
    "MESSAGE": "Message",
    "CONTRACTS": "Contracts",
    "FILLED_TOTAL": "Filled/Total",
    "FILLED_PRICE": "Filled Price",
    "FILLED": "Filled",
    "TRADE_HISTORY": "Trade History",
    "ORDER_PRICE": "Order Price",
    "TRADE_TYPE": "Trade Type",
    "ORDER_TYPE": "Order Type",
    "ORDER_NO": "Order No.",
    "Download_PDF": "Download PDF",
    "CANCEL_POST": "Cancel My Post",
    "ORDER_TIME": "Order Time",
    "NO_DATA": "No data Available ",
    "SIZE": "Size",
    "TAKER_PRICE": "Taker Profit",
    "STOP_LOSS": "Stop Loss",
    "GTC": "GTC",
    "IOC": "IOC",
    "FOK": "FOK",
    "LONG": "Long",
    "SHORT": "Short",
    "VALUE": "Value",
    "COST": "Cost",
    "TP_SL": "TP/SL",
    "24H_CHANGE": "24h Change",
    "24_HIGH": "24h High",
    "24_LOW": "24h Low",
    "24H_VOLUME": "24h Volume",
    "QTY": "Qty",
    "ORDER_BOOK": "Order Book",
    "PLACE_ORDER": "Place Order",
    "LIMIT": "Limit",
    "PERPETUAL": "Perpetual",
    "BTC_PAIRS": "BTC Pairs",
    "ETH_PAIRS": "ETH Pairs",
    "PAIR": "Pair",
    "MARKET_CLOSE": "Market Close",
    "CLOSE_QTY": "Close Qty USD",
    "5CONTRACT_CLOSE": "5 Contracts(s) Will be closed at last Traded price, and your expected profit will be ",
    "INCLUSIVE": "inclusive of est.colsing fees",
    "FEES": "Fee",
    "FEE_CONTENT": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    "ENTYRY_PRICE": "Entry Price",
    "IDENTITY_VERIFICATION": "Identity Verification",
    "LIQ_PRICE": "Liq. Price",
    "POSITION_MARGIN": "Position Margin",
    "UNREALIZED": "Unrealized P&L (%)",
    "DAILY_REALIZED": "Daily Realized P&L",
    "TRAILING_STOP": "Trailing Stop",
    "CLOSED_BY": "Close By",
    "RECENT_TRADE": "Recent Trades",
    "TIME": "Time",
    "STOP_PRICE": "Stop Price",
    "SPOT_PRICE": "Spot Price",
    "LAST_PRICE": "Last Price",
    "LIMIT_PRICE": "Limit Price",
    "POSITION": "Position",
    "EDIT_MY_POST": "Edit My Post",
    "CRYPTO_CURRENCY": "Cryptocurrency",
    "SELECT_COIN": "Select coin",
    "QUANTITY": "Quantity",
    "PRICE_CURRENCY": "Price Currency",
    "CURRENCY_MARKET_PRICE": "Current Market Price",
    "PRICE_LIMIT_MARKET": "Price Limit From ",
    "SHOW_MY_POST": "Show My Post Till",
    "PERFERRED_PAYMENTS": "Preferred Payments",
    "PAYMENT_TO_MADE": "Payment to be made",
    "PRICE_LIMIT_FROM": "Price Limit From",
    "PRICE_LIMIT_TO": "Price Limit To",
    "SELECT_PAYMENT": "Select Payment",
    "DURATION_DAYS": "Duration (Days)",
    "I_AGREE_ESCROW": "I agree to Escrow my crypto funds and accept  the",
    "DURATION": "Duration",
    "ALL": "All",
    "ETH": "ETH",
    "APY": "APY",
    "XRB": "XRB",
    "SPOT_WALLET": "Spot Wallet",
    "DERIVATIVE_WALLET": "Derivative Wallet",
    "P2P_WALLET": "P2P Wallet",
    "FORM": "From",
    "QUANTITY": "Quantity",
    "FLEX_STAKING": "Flexible  Staking",
    "LOCKED_STAKING": "Locked Staking",
    "MIN_SUBSCRIPTION": "Minimum subscription",
    "SUBSCRIPTION": "subscription",
    "MAX_SUBSCRIPTION": "Maximum subscription",
    "STAKING_HISTORY": "Staking History",
    "REDEMPTION_PERIOD": "Redemption Period",
    "REDEMPTION": "Redemption",
    "READ_AND_AGREE": "I have read and I agree to the",
    "STAKING_TERMS": "Staking Terms",
    "INTEREST": "Interest",
    "STAKING": "Staking",
    "BTC/USD": "BTC/USD",
    "DIGITAL_ASSETS": "Leading Digital Assets ",
    "ONE_PLATFORM": "Under One Platform",
    "MATKET_PRICE": "Market Price",
    "TRADE": "Trade",
    "SPOT_ORDERS": "Spot Orders",
    "DERIVATIVE_ORDERS": "Derivative Orders",
    "P2P_ORDERS": "P2P Orders",
    "STAKING_ORDERS": "Staking Orders",
    "MANA": "MANA",
    "BTC": "BTC",
    "DAYS": "Days",
    "TOTAL_VOLUME": "Total Volume",
    "TOTAL_TRADE_COMPLETE": "Total Trade Complete",
    "ENTER_AMOUNT": "Enter Amount",
    "VIEW_CHAT": "View/Chat",
    "PAY_BY": "Pay By",
    "TOTAL": "Total",
    "ALL_PAYMENT": "All Payment",
    "AVAILABLE": "Available",
    "SELLER_PAYMENT_TYPE": "Seller’s Payment Type",
    "PAYMENT_TIME_LIMIT": "Payment Time Limit",
    "TYPE": "Type",
    "RECENT_POST": "Recent Post",
    "TO": "To",
    "DEPOSIT_CURRENCY": "Deposit Currency",
    "YOUR": "Your",
    "WALLET_ADDRESS": "Wallet Address",
    "SCAN_QR_CODE": "Scan QR Code",
    "MEMO_TAG": "Memo Tag",
    "TOKEN_PURCHASE_EXPIRED": "Token purchase expired",
    "MINIMUM_QUANTITY": "Amount is greater than minimun amount",
    "TOKEN_PURCHASE_SUCCESS": "Token purchase success",
    "INSUFFICIENT_BALANCE": "Insufficient Balance",
    "NOT_AVAILABLE_BALANCE": "Not available Balance",
    "SOMETHING_WRONG": "Something wrong",
    "INVALID_COIN": "Invalid coin",
    "PRICE_REQUIRED": "price is required",
    "24H_CHANGE": "24H Change",
    "24H_HIGH": "24H High",
    "24H_TURNOVE": "24H Turnove",
    "CURRENT_PRICE": "Current Price",
    "TRIGGER_PRICE": "Trigger Price",
    "LAST": "Last",
    "INDEX": "Index",
    "MARK": "Mark",
    "ORDER_VALUE": "Order Value",
    "ORDER_COST": "Order Cost",
    "SEND_ORDER": "Send Order",
    "MARGIN_IMPACT": "Margin impact",
    "LEVERAGE": "leverage",
    "RISK_LIMIT": "Risk Limit",
    "CLOSED_P&L": "Closed P&L",
    "FILLED_ORDER": "Filled Order",
    "ASSET_OVERVIEW": "Asset Overview",
    "CLOSING_DIRECTION": "Closing Direction",
    "EXIT_PRICE": "Exit Price",
    "CLOSED_P&L(EXCL FEE)": "Closed P&L(Excl Fee)",
    "EXIT_TYPE": "Exit Type",
    "CLOSE_POSITION_TIME": "Close Position Time",
    "LOADMORE": "Load More",
    "FILLED_REMAINING": "Filled/Remaining",
    "PRICE_DISTANCE": "Price (Distance)",
    "EXEC_PRICE": "Exec Price",
    "NEW": "New",
    "PARTIAL": "Partial",
    "CONDITIONAL": "Conditional",
    "EQUITY": "Equity",
    "AVAILABLE_BALANCE": "Available Balance",
    "ORDER_MARGIN": "Order Margin",
    "TOTAL_REALIZED_P&L": "Total Realized P&L",
    "UNREALIZED_P&L": "Unrealized P&L",
    "ORDER_DETAILS": "Order Details",
    "TAKE_PROFIT_USD": "Take Profit USD",
    "STOP_LOSS_USD": "Stop Loss USD",
    "POSITION_LEVERAGE": "Position Leverage",
    "TOTAL_POSITION_QTY_AFTER_EXECUTION": "Total Position Qty after execution",
    "TAKE_PROFIT": "Take Profit",
    "STOP_LOSS": "Stop Loss",
    "MARK_PRICE": "Mark Price",
    "ESTIMATED_LIQ_PRICE": "Estimated Liq. Price",
    "MARKPRICE_TO_LIQPRICE_DISTANCE": "Mark Price to Liq. Price distance",
    "TIME_IN_FORCE": "Time in Force",
    "NUMBER_OF_POSITION_CLOSED": "Number of Positions Closed",
    "CLOSE_PRICE": "Close Price ",
    "TRAILING_STOP_USD": "Trailing Stop USD",
    "IF_OPTIMAL_LAST_TRADED_PRICE_RETRACES_BY": "If optimal Last Traded Price retraces by",
    "USD_IT_WILL_TRIGGER_STOP_LOSS_WITH_MARKET_ORDER": "USD, it will trigger Stop-Loss with Market Order",
    "ARE_YOU_CONFIRM_TO_CANCEL_THIS_ORDER": "Are you confirm to cancel this order?",
    "ORDER": "Order",
    "APPLY_TO_BECOME_A_TRADER": "Apply to become a trader",
    "CONDITIONS": "Conditions",
    "CUMULATIVE_DEPOSIT": "Cumulative deposit ≥",
    "USDT": "USDT",
    "OK": "ok",
    "REASON_AFFILIATE_PROGRAM": "Reasons to join Bell Exchnage ’s Affiliate Program",
    "ATTRACTIVE_COMMISSION_CRYPTOCURRENCIES": "Attractive commisions in cryptocurrencies",
    "YOU_WILL_REFFERRAL_COMMISSION": "You will earn 10% referral commission for each sale (net sale amount, excluding VAT and shipping). Monthly payouts in cryptocurrencies.",
    "DEDICATED_SUPPORT": "Dedicated Support",
    "OUR_AFFILIATE_TEAM": "Our affiliate team is here to proactively help you develop your campaign to success and to provide you with the resources and answers you need.",
    "REAL_TIME_REPORTS": "Real Time Reports",
    "GET_ONLINE": "Get 24/7 online access your affiliate ​account to manage your campaigns. Tracking option to help you optimize traffic sources and conversions.",
    "HIGH_IMPACT_PROMO_MATERAILS": "High Impact Promo Materials",
    "OUR_AFFILIATE_PROGRAM": "Our affiliate program grants you access to banner creatives, links, videos. If you need any additional assets, feel free to ask!",
    "BELL_EXCHANGES_AFFILIATE_PROGRAM": "Bell Exchnage’s affiliate program is among the best crypto currencies affiliate programs. Publish your affiliate link and get rewarded in Crypto coins for your referrals.",
    "THIS_AFFILIATE_PROGRAM": "This affiliate program is meant for bloggers, YouTubers, crypto companies… or anybody who has a network and a voice in the crypto world and aim at educating around the importance of security.",
    "REGISTER_NOW_AND_GET": "Register now and get an affiliate link for your website, channel, newsletter… and earn a 10% commission in Crypto currencies on all your referrals!",
    "BY_SIGNING_AFFILIATE_PROGRAM": "By signing up to our Affiliate Program, you accept the Terms of Use and agree that we may process your personal data in accordance with our Privacy Policy and Cookie Policy.",
    "TOKEN_LAUNCH_PRICE": "Token Launch Price",
    "Whitepaper": "Whitepaper",
    "Token Launch Coin": "Token Launch Coin",    
    "RISK_DISCLOSURE_STATEMENT": "Declaración de divulgación de riesgost",
    "DELIVER_OUR_SERVICE": "We use the information collected to deliver our services and verify user identity. We use the IP address and unique identifiers stored in your device’s cookies to help us authenticate your identity and provide our service. Given our legal obligations and system requirements, we cannot provide you with services without data like identification, contact information and transaction-related information.",
    "TO_PROTECT_OUR_USERS": "To protect our users",
    "PROTECT_OUR_PLANS": "We use the information collected to protect our platform, users’ accounts and archives. We use IP addresses and cookie data to protect against automated abuse such as spam, phishing and Distributed Denial of Service (DDoS) attacks. We analyse trading activity with the goal of detecting suspicious behavior early to prevent potential fraud and loss of funds to bad actors.",
    "LEGAL_AND_REGULATORY": "To comply with legal and regulatory requirements",
    "PRIVACY_AND_SECURITY": "Respect for the privacy and security of data you store with Bell Exchnage informs our approach to complying with regulations, governmental requests and user-generated inquiries. We will not disclose or provide any personal information to third party sources without review from our legal case team and/or prior consent from the user.",
    "TO_MEASURE_SITE_PERFORMANCE": "To measure site performance",
    "MEASURE_AND_ANALYSE": "We actively measure and analyse data to understand how our services are used. This review activity is conducted by our operations team to continually improve our platform’s performance and to resolve issues with the user experience.",
    "TO_COMMUNICATE_WITH_YOU": "To communicate with you",
    "PERSONAL_INFORMATION_COLLECTED": "We use personal information collected, like an email address to interact with users directly when providing customer support on a ticket or to keep you informed on log ins, transations, and security. Without processing your personal information for confirming each communication, we will not be able to respond to your submitted requests, questions and inquiries. All direct communications are kept confidential and reviewed internally for accuracy.",
    "ENFORCE_OUR_TERMS": "To enforce our Terms of Use and other agreements",
    "CUSTOMER": "It is very important for us and our customers that we continually review, investigate and prevent any potentially prohibited or illegal activities that violate our Terms of Service. For the benefit of our entire user base, we carefully enforce our agreements with third parties and actively investigate violations of our posted Terms of Use. Bell Exchnage reserves the right to terminate the provision of service to any user found engaging in activities that violate our Terms of Use.",
    "PROTECT_USER_DATA": "HOW DOES Bell Exchnage PROTECT USER DATA",
    "SECURITY_BELLEXCHANGE": "Bell Exchnage has implemented a number of security measures to ensure that your information is not lost, abused, or altered. Our data security measures include, but are not limited to: PCI Scanning, Secured Sockets Layered encryption technology, pseudonymisation, internal data access restrictions, and strict physical access controls to buildings & files. Please note that it is impossible to guarantee 100% secure transmission of data over the Internet nor method of electronic storage. As such, we request that you understand the responsibility to independently take safety precautions to protect your own personal information.",
    "FROM_COIN": "From Coin",
    "TO_COIN": "To Coin",
    "AMOUNT": "Amount",
    "ALL_AMOUNT": "All Amount",
    "SINGLE_LIMIT": "Single Limit",
    "24_HOURS_LIMIT": "24 Hours Limit",
    "TRADE_FEES": "Trade Fees",
    "RECEIVING_AMOUNT": "Receiving Amount",
    "INCLUDING_FEE": "including Fee 0.5%",
    "WALLET_BALANCE": "Wallet Balance",
    "EXCHANGE_HISTORY": "Exchange History",
    "AMOUNT_INPUTED": "Amount Inputed",
    "TRANSACTION_ID": "Transaction ID",
    "AMOUNT_RECEIVED": "Amount Recieved",
    "BTC_DEPOSIT": "BTC Deposit",
    "BTC_WITHDRAW": "BTC Withdraw",
    "NOTES1": "Do not deposit any non- BTC assets to the above address. Otherwise, the assets will lost permanently.",
    "NOTES2": "Please provide the correct Trading wallet address to ensure a deposit.",
    "NOTES3": "Your deposit will be reflected inside your account after receiving 1 confirmation on the blockchain.",
    "RECEIVER_WALLET_ADDRESS": "Receiver’s Wallet Address",
    "ADD_WALLET": "Add Wallet",
    "BTC_AMOUNT": "BTC Amount",
    "SEND_ALL": "Send All",
    "FINAL_AMOUNT": "Final Amount",
    "ENTER_AUTHENTICATION_CODE": "Enter 2FA Authentication Code",
    "MINUMUM_WITHDRAW": "Minimum withdrawal",
    "INSIDE_YOUR_ACCOUNT": "Your withdraw will be reflected inside your account after receiving 1 confirmation  on the blockchain.",
    "DEPOSIT_HISTORY": "Deposit History",
    "START_DATE": "Start Date",
    "END_DATE": "End Date",
    "CSV": "Download.csv",
    "NO_RECORDS": "No Records",
    "BEGINNERS_GUIDE": "Beginner's Guide",
    "ACCOUNT_REGISTRATION": "Account Registration",
    "REGISTER_REAL_ACCOUNT": "Register a Real Account",
    "REGISTER_TESNET_ACCOUNT": "Register a Testnet Account",
    "FORGOT_PASSWORD_RETRIEVE_PASSWORD": "Forgot Password/How to Retrieve Password",
    "ACCOUNT_AND_SECURITY": "Account and Security",
    "BYBIT_INSTALL": "How to install the Bybit APP on your Smartphone?",
    "ACCOUNT_OVERVIEW": "Account Overview",
    "ACCOUNT_DETAILS": "Account Details",
    "SECURITY": "Security",
    "SET_CHANGE_EMAIL": "Set/Change Email",
    "SET_CHANGE_GOOGLE_AITHENTICATION": "Set/Change Google Authentication",
    "SET_CHANGE_MOBILE_PHONE": "Set/Change Mobile Phone",
    "API_MANAGEMENT": "API Management",
    "ADD_NEW_API_KEY": "Add New API Key",
    "SETTINGS": "Settings",
    "DEPOSIT_AND_WITHDRAWAL": "Deposit and Withdrawal",
    "BYBIT_DEPOSIT_OR_WITHDRAW": "How to Make a Deposit or Withdrawal on Bybit?",
    "WITHDRAWAL_REQUEST": "How to Submit a Withdrawal Request?",
    "ADD_YOUR_WALLET_ADDRESS": "How To Add Your Wallet Address?",
    "PLACING_ORDER": "Placing an order",
    "DIFFERENT_RERERENCE_PRICE": "How to Set a Different Reference Price to Trigger Take Profit/Stop Loss?",
    "ORDER_PLACING_OVERVIEW": "Order Placing Overview",
    "MARKET_ORDER": "Market Order",
    "LIMIT_ORDER": "Limit Order",
    "CONDITIONAL_ORDER": "Conditional Order",
    "PARTIAL_CLOSE": "How to Partial Close Your Open Position?",
    "TAKE_PROFIT_STOP_LOSS": "Set Take Profit/Stop Loss",
    "ACTIVE_ORDER": "Active Order",
    "POSITION_CLOSING": "Position Closing",
    "ADJUST_TAKE_PROFIT": "Set or Adjust Take Profit/Stop Loss",
    "ADJUST_TRAILING_STOP": "Set or Adjust Trailing Stop",
    "MORE": "More",
    "RECENT_BLOGS": "Recent Blogs",
    "CATEGORY": "Category",
    "BLOG": "Blog",
    "CATEGORIES": "Categories",
    "QUESTIONNAIRE": "Questionnaire",
    "CRYPTO_DERIVATES_TRADING": "1. When did you start cryptocurrency derivatives trading?",
    "LAST_3_MONTH": "Last 3 months",
    "LAST_6_MONTH": "Last 6 months",
    "MORE_THAN_1_YEAR": "More than 1 year",
    "FEWS_DAYS": "Few Days",
    "CRYPTO_DERIVATIVES": "2. What’s your main purpose for trading cryptocurrency derivatives?",
    "LONG_TERM": "Long term",
    "SHORT_TERM": "Short term",
    "SWING_TRADING": "Swing trading",
    "INTRADAY": "Intraday",
    "BONUS_REWARDS": "Bonus Rewards",
    "RISK_FREE_TRADING": "Risk free trading on bonus",
    "FACEBOOK": "Facebook",
    "TWITTER": "Twitter",
    "TELEGRAM": "Telegram",
    "CLAIM_NOW": "Claim Now",
    "BONUS": "Bonus",
    "DEPOSIT_AMOUNT": "Deposit amount",
    "REFERREDUSER": "Referreduser",
    "CLOSE_TIME": "Close Time",
    "TOTAL_REALIZED_PNL": "Total Realized PNL",
    "UNREALIZED_P&l_MARK_PRICE": "Unrealized P&L(Mark Price)",
    "UNREALIZED_P&L_LTP": "Unrealized P&L(LTP)",
    "COOKIES_POLICY": "Cookies Policy",
    "AUTOMATICALLY_UNFOLLOW": "Automatically Unfollow",
    "WHEN_TRADER_DOES_NOT_OPEN": "When trader does not open positions for",
    "CONSECUTIVE_HOURS": "consecutive hours, trade-copying will be automatically canceled",
    "COPY_TRADE_USDT": "Copy trade capital(USDT)",
    "COPY_TRADE_DATA": "Copy trade data",
    "COPY_TRADE_NET_PROFIT": "Copy trade net profit(USDT)",
    "COPY_TRADE": "Copy Trade",
    "MY_TRADER": "My Trader",
    "FOLLOW_INFORMATION": "Follow Information",
    "DETAILS": "Details",
    "SUMMARY": "Summary",
    "TRADER": "Trader",
    "FUTURES": "Futures",
    "MAX_AMOUNT_BALANCE": "Max. / Balance amount(USDT)",
    "EARNING_MONEY_NOW": "Earning money now",
    "CURRENTLY_FOLLOWING": "Currently following",
    "PEOPLE": "people",
    "FOLLOW": "Follow",
    "TRADER_DETAILS": "Trader details",
    "PL_RATIO": "P/L Ratio",
    "TRADING_ORDER": "Trading Order",
    "ACCUM_FOLLOWERS": "Accum Followers",
    "PROFIT_USDT": "Profit(USDT)",
    "GAIN": "Gain",
    "LOSS": "Loss",
    "COPY_TRADE_ORDER": "Copy Trade Order",
    "FOLLOWERS": "Followers",
    "FULL": "Full",
    "COPY_TRADE": "Copy trade",
    "LEVERAGE_MODE": "Leverage Mode",
    "FUTURE_SETTING": "Future setting",
    "COPY_TRADING_AMOUNT": "Copy trade amount",
    "FIXED_AMOUNT": "Fixed Amount",
    "FIXED_RATIO": "Fixed Ratio",
    "STOP_LOSS_RATIO_OPTIONAL": "Stop loss ratio (optional)",
    "TAKE_PROFIT_RATIO_OPTIONAL": "Take profit ratio (optional)",
    "MAXIMUM_POSITION": "Maximum Position",
    "CONT": "cont",
    "ADVANCED_SETTING": "Advanced Setting",
    "STOP_LOSS_RATIO": "Stop loss ratio",
    "FOLLOW_THE_TRADER": "Follow the trader",
    "PROFIT_SHARE_RATIO": "Profit Share Ratio",
    "UNFOLLOW": "Unfollow",
    "ONLY_SELECTED_PAIRS": "Notes : Only the selected pairs will be copy",
    "UNIFIED_SETTING": "Unified Setting",
    "RESPECTIVE_SETTING": "Respective Setting",
    "NEXT": "Next",
    "FOLLOWING_THIS_TRADER": "If you cancel following this trader, the orders that have been placed to follow will not be closed immediately, and will still follow the trader’s closing actions. You can also close the position manually",
    "THINK_AGAIN": "Think again",
    "LTC": "LTC",
    "BCH": "BCH",
    "TRANSFER_AMOUNT": "Transfer Amount",
    "MAX": "Max",
    "CONFIRM_COPY_TRADE_INFORMATION": "Confirm Copy Trade Information",
    "FOLLOW_MODE": "Follow Mode",
    "FIXED": "Fixed",
    "TAKE_PROFIT_RATIO": "Take profit ratio",
    "CRYPTO_COPY_TRADING": "Crypto Copy Trading",
    "TRADER_LIST": "Trader list",
    "HOURLY_DATA_UPDATE": "Hourly data update",
    "BECOME_TRADER": "Become Trader",
    "TRADER_PROFILE": "Trader Profile",
    "RANKING": "Ranking",
    "TOTAL_PL": "Total P/L",
    "CUMULATIVE_RETURN": "Cumulative Return",
    "ACCUM_TRANSACTIONS": "Accum Transactions",
    "TOTAL_PL_RATIO": "Total P/L Ratio",
    "WIN_RATE": "Win Rate",
    "TOTAL_PL_USDT": "Total P/L (USDT)",
    "ACCUM_PL_RATIO": "Accum P/L Ratio",
    "THERE_IS_NO_TRADERS": "There is no traders",
    "VIEW_MORE": "View more",
    "FAQ": "FAQ",
    "BELL EXCHANGE FEE SCHEDULE": "BELL EXCHANGE FEE SCHEDULE",
    "BUY_OR_SELL_POPULAR_DIGITAL": "Buy and sell popular digital currencies, keep track of them in the one place. Invest in cryptocurrency monthly.",
    "LOREM_IPSUM": "Lorem ipsum",
    "HELPLOREM": "help@lorem.com",
    "RELATED_LINKS": "Related Links",
    "ABOUT_US": "About Us",
    "SIGNUP_TO_OUR_NEWSLETTER": "Sign up to our newsletter",
    "COPY_RIGHT": "Copyright",
    "GUIDES": "Guides",
    "RISK": "Risk",
    "ANTI_SPAM_POLICY": "Anti Spam Policy",
    "SUBSCRIBE_FOR_LATEST_UPDATES": "Subscripe for Latest Updates",
    "FOLLOW_US": "Follow Us",
    "LOCAL_COIN_ALL_RIGTHS_RESERVED": "2020 © Lokal Coin All Rights Reserved",
    "DISCORD": "Discord",
    "TEAM": "Team",
    "AML": "AML",
    "CFT": "CFT",
    "KYC_REGISTRATION": "KYC Registration",
    "REGISTER_ON": "Registered on",
    "NOT_REGISTER_USER": "Not a registered user?",
    "REGISTER_NOW": "Register Now!",
    "LOST_YOUR_TOKEN_PLEASE": "If you have lost your 2FA token, please",
    "LOST_2FA_DEVICE": "with the subject Lost 2FA Device. Trading support will help you recover your account.",
    "OTP_CODE": "OTP Code",
    "NO_SMS_RECEIVED": "No SMS reaceived?",
    "RESEND_OTP": "Resend otp",
    "HELLO_HAFIZ": "Hello Hafiz",
    "NOT_YET_REGISTERED": "Not Yet Registered",
    "PROOF_IDENTITY": "Proof of Identity",
    "DOCUMENT_TYPE": "Document Type",
    "ID_NUMBER": "ID Number",
    "UPLOAD_NEW_IMAGE": "Please upload a new image.",
    "PLEASE_UPLOAD_CLEAR": "Please upload a clear, readable copy of any of the following",
    "MAX_3MB_IMG": "Images must be  /.jpg/.png/.jpeg max 3MB",
    "SELECT_DOCUMENT_TYPE": "Select Document Type",
    "PROOF_OF_ADDRESS": "Proof of Address",
    "FRONT_SIDE_NAME_AND_ADDRESS": "Front Side (Name and Address)",
    "SELF_PHOTO": "Self Photo",
    "WAITING_FOR_APPROVAL": "Waiting For Approval",
    "NOTE": "Note",
    "PLEASE_UPLOAD_CLEAR_PHOTO_YOURSELF": "Please upload a clear photo of yourself",
    "WORLD_CLASS_TRADING": "World Class Trading Platform",
    "LOREM_PARAGRAPH": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rerum a corporis veniam necessitatibus dolores. Quibusdam ea, possimus aliquid impedit dolorum dicta pariatur odit. Mollitia repellendus saepe commodi iusto porro quia.",
    "ETHBTC": "ETHBTC",
    "TOP_CRYPTO_ASSETS": "Top Crypto Assets",
    "LOREM_PARAGRAPH2": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rerum a corporis veniam necessitatibus dolores. Quibusdam ea, possimus aliquid impedit dolorum dicta pariatur odit. Mollitia repellendus saepe commodi i",
    "SIMPLE KYC REQUIRED": "SIMPLE KYC REQUIRED",
    "START_EARNING_TODAY": "Start earning today",
    "EXECUTED_ORDERSPE_SECOND": "Executed orderspe second",
    "INTEGRATED_LIQUIDITY_PROVIDERS": "Integrated liquidity providers",
    "AVERAGE_ORDER_EXECUTION_SPEED": "Average order execution speed",
    "CLASS_TRADING_PLATFORM": "Our world class trading platform",
    "LOREM_PARAGRAPH3": "rem ipsum, dolor sit amet consectetur adipisicing elit. Rerum a corporis veniam necessitatibus dolores. Quibusda",
    "EASY_ACCESS": "Easy access",
    "QUICK_BALANCE_CHECK": "Quick balance check",
    "PROFIT_BOOKING": "Profit Booking",
    "MULTI_WINDOW_PLATFORM": "Multi Window Platform",
    "LOREM_IPSUM": "Lorem ipsum",
    "FAST_AND_STABLE": "Fast and Stable",
    "LOREUM_PARAGRAPH4": "dolor sit amet consectetur adipisicing elit. Rerum a corporis veniam necessitatibus dolore",
    "DYNAMIC_CRYPTOCURRENCY": "Dynamic Cryptocurrency trading that puts you in the driver's seat",
    "TRADING_WITH": "Trading With",
    "BELL_EXCHANGE": "Bell Exchnage",
    "LOREM_PARAGRAPH5": "Execute trades in microseconds without compromising reliability or security. Our latency trading platform has all the tools you need to make the most of market opportunities, whenever and where",
    "OPEN_FREE_ACCOUNT": "Open a Free Account",
    "BEST_IN_CLASS": "Best-in-Class Trading Platform",
    "LOREM_OUR_FAST": "Our fast, safe and reliable trading platform is suitable for both novice and professional traders",
    "LOW_TRADING_FEE": "Low Trading Fee",
    "ENJOY_TIGHT": "Enjoy tight spreads and low commission rates while trading BTC, ETH, LTC, XRP and BCH with 1:100 leverage",
    "FAST_AND_STABLE": "Fast and Stable",
    "MULTIPLE_CHART_TYPES": "Multiple chart types, variety of indicators and drawing tools as well as ability to trade directly from the chart",
    "RELIABILITY": "Reliability",
    "BLOCKCHAIN_PLATFORM": "Blockchain Platform",
    "PROJECT_INTRODUCTION": "Project Introduction",
    "INDUSTRY": "Industry",
    "WEBSITE": "Website",
    "TOKEN_DETAILS": "Token Details",
    "TOKEN_SALE_START_DATE": "Token Sale Start Date",
    "TOKEN_LAUNCH_PRICE": "Token Launch Price",
    "MINIMUM_PURCHASE_AMOUNT": "Minimim Purchase Amount",
    "ACCEPTED_CURRENCIES": "Accepted Currencies",
    "DISCOUNT": "Discount",
    "TAKE_AVAILABLE_TO_SALE": "Token Available to Sale",
    "TOKEN_MAX_SUPPLY": "Token Max Supply",
    "DOCUMENTS": "Documents",
    "I_AM_NOT_CITIZEN": "I am not a citizen and/or resident of FATF blacklist countries and/or countries not recognized by the Estonia/EU.",
    "COMPLETE_KYC": "Complete KYC",
    "MY_TRADES": "My Trades",
    "TOTAL_AMOUNT": "Total Amount",
    "SOCIAL_MEDIA": "Social Media",
    "BRIJESH": "Brijesh Gadiali",
    "CEO": "CEO",
    "WHITE_PAPER": "Whitepaper",
    "PRESENTATION": "Presentation",
    "EXECUTIVE_SUMMARY": "Executive Summary",
    "YOU_PAY": "You pay",
    "BELL EXCHANGE LAUNCH PROGRAM": "BELL EXCHANGE LAUNCH PROGRAM",
    "TOKEN_LAUNCH_PLATFORM": "A token launch platform for transformative projects",
    "BUY_TOKEN": "Buy Token",
    "HOW_WE_SUPPORT": "How We Support",
    "TOKEN_LAUNCH": "Token Launch",
    "LONG_ESTABLISHED": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using.",
    "SET_UP": "Setup",
    "PLATFORM_INTEGRATION": "Platform Integration",
    "LAUNCH": "Launch",
    "PROJECT_DEVELOPMENT": "Project Development",
    "SOCIAL_MEDIA_SETUP": "Social Media setup",
    "FILLING_INFORMATION_ABOUT_PROJECT": "Filling information about the project",
    "ESTABLISHMENT1": "Establishment of fully developed online gaming platform ready to go live",
    "ESTABLISHMENT2": "Establishment of fully developed online casino ready to go live",
    "ESTABLISHMENT3": "Sourcing and obtaining of necessary licenses",
    "ESTABLISHMENT4": "Developing charity and PR programmes for all continents",
    "ESTABLISHMENT5": "Establishing portals and social media channels",
    "ESTABLISHMENT6": "Invitation of an exclusive group of founding shareholders to the company",
    "ESTABLISHMENT7": "Building insurance solutions with established companies for the portal",
    "ESTABLISHMENT8": "Establishing global payment solutions",
    "SOCIAL_MEDIA_SETUP_FULFILLMENT": "Social Media setup and fulfillment",
    "ESTABLISHED_LAYOUT": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    "EXTERNAL_PROMOTION": "External promotion in Social Media",
    "LAUNCHPAD_STAGE": "Launchpad stage",
    "CHECK_LAUNCHED_TOKENS": "CHECK LAUNCHED TOKENS",
    "BELL_EXCHANGE_LAUNCH": "Bell Exchange Launch",
    "APPLY_FOR": "Apply for",
    "PROGRAM": "Program",
    "AADHYA": "Aadhya",
    "LISTING_MANAGER": "Listing Manager",
    "GET_IN_TOUCH_WITH_US": "Get in touch with us",
    "YOUR_TELEGRAM_NAME": "Your Telegram Name",
    "YOUR_PROJECT_NAME": "Your Project Name",
    "YOUR_EMAIL_ADDRESS": "Your Email Address",
    "FREE_CONSULTATION": "Free Consultation",
    "HOURS": "Hours",
    "MINS": "Mins",
    "SECS": "Secs",
    "LAUNCHPAD_DETAILS": "Launchpad Details",
    "TOKEN_NAME": "Token name",
    "TOKEN_SYMBOL": "Token Symbol",
    "TOKEN_ICON": "Token ICON",
    "AVAILABLE_CURRENCY": "Available Currency",
    "MINIMUM_PURCHASE_AMOUNT": "Minimum Purchase Amount",
    "CONTENT": "content",
    "ADD_TOKEN_DETAILS": "Add Token Details",
    "BELL_EXCHANGE_LAUNCHPAD": "Bell Exchange Launchpad",
    "APPLY": "Apply",
    "ACTIVE_TOKENS": "Active Tokens",
    "COMPLETED_TOKENS": "Completed Tokens",
    "KS_COIN": "KS Coin",
    "GAMING_STAGE": "Gaming | Stage 1",
    "SESSION_SUPPLY": "Session Supply",
    "START": "Start",
    "END": "End",
    "CLOSE": "Close",
    "VIEW": "View",
    "LEVERAGE_TRADING": "Leverage Trading",
    "LEVERAGE_ENABLES": "Leverage enables you to get a much larger exposure to the market you’re trading than the amount you deposited to open the trade.",
    "LEVERAGE_CALCULATOR": "Leverage Calculator",
    "LEVERAGE": "Leverage",
    "RESULT": "Result",
    "LEVERAGE_CALCULATOR_ALLOWS": "Leverage calculator allows you to calculate the total amount of buying power that you will get on the Trading platform based on your capital",
    "INITIAL_MARGIN": "Initial Margin",
    "PNL": "PNL",
    "ROE": "ROE",
    "START_TRADING": "Start trading",
    "SIGN_IN": "Sign In",
    // "FORGOT_PASSWORD" : "Forgot Password",
    "REGISTER_HERE": "Register Here",
    "2FA_CODE": "2FA Code",
    "EMAIL_CONFIRMATION_CODE": "Email Confirmation Code",
    "CHECK_YOUR_EMAIL_CONFIRMATION_CODE": "Check your email for a confirmation code",
    "ALREADY_REGISTERED_USER": "Already registered user?",
    "LOGIN_HERE": "Login Here",
    "PLEASE_ENTER_THE_VERIFICATION_CODE": "Please enter the verification code that has been sent to",
    "SMS_VERIFICATION_CODE": "SMS verification code",
    "NO_SMS_RECEIVED": "No SMS received?",
    "LAUNCHPAD": "LaunchPad",
    "LANGUAGE": "Language",
    "ENGLISH": "English",
    "SPANISH": "español",
    "SPOT": "Spot",
    "DERIVATIVES": "Derivatives",
    "CHANGE": "Change",
    "24H_LOW": "24H Low",
    "24H_TURNOVER": "24H Turnover",
    "STOP_LIMIT": "Stop Limit",
    "MAKER_FEE": "Maker Fee",
    "TAKER_FEE": "Taker Fee",
    "STOP": "Stop",
    "BUYORSELL": "BuyorSell",
    "SEARCH_PAIRS": "Search Pairs",
    "DEPOSIT_RECEIVE": "Deposit / Receive",
    "COPIED": "Copied",
    "AMOUNT_IN": "Amount in",
    "NOTES_ONCE_THE_TRANSACTION": "Once the transaction completed, the deposited amount will be reflected",
    "RAISE_A_TICKET": "If any other queries, you can raise a ticket !",
    "BTCUSD_CONVERSION": "BTC-USD conversion",
    "BTC_BALANCE": "BTC balance",
    "USD_BALANCE": "USD balance",
    "FROM_CURRENCY": "From Currency",
    "TO_CURRENCY": "To Currency",
    "REGISTER_EMAIL": "Registered email",
    "GOOGLE_AUNTHENTICATION": "Google Authentication",
    "MY_ASSETS": "My Assets",
    "WITHDRAWAL_HISTORY": "Withdrawal History",
    "YOU_WILL_GET": "You will get",
    "MANAGE_ADDRESS": "Manage Address",
    "DERIVATIVES_BALANCE": "Derivatives Balance",
    "WALLET_CONVERSION": "Wallet conversion",
    "ADD_ADDRESS": "Add Address",
    "CURRENCY": "Currency",
    "TAG_OPTIONAL": "Tag (Optional)",
    "ADD": "Add",
    "MEMO_TAG": "Memo/Tag",
    "NO_RECORDS_FOUND": "No records found",
    "SEARCH": "Search",
    "TRADING_FESS": "Trading fees",
    "PAIRS": "Pairs",
    "FILLED_TYPE": "Filled Type",
    "FILLED_QTY": "Filled Qty",
    "FEE_RATE": "Fee Rate",
    "FEES_PAID": "Fees Paid",
    "UNFILLED_QTY": "Unfilled Qty",
    "TRANSACTION_FEE": "Transaction Fee",
    "WITHDRAWAL_REMARKS": "Withdrawal remarks",
    "PROFILE_DETAILS": "Profile Details",
    "ATTACHMENT_IF_ANY": "Attachments if any *",
    "IMAGE_SHOULD_ALLOWED_ONLY_PNG": "Image Should Allowed only png/jpeg/jpg",
    "RESIDENTIAL_DETAILS": "Residential Details",
    "OPEN_THIS_SELECT_MENU": "Open this select menu",
    "ONE": "One",
    "TWO": "Two",
    "THREE": "Three",
    "ADD_ACCOUNT_DETAILS": "Add Account Details",
    "BANK_COUNTRY": "Bank Country",
    "BANK_ADDRESS": "Bank Address",
    "BANK_CITY": "Bank City",
    "LABEL_SPACE": "Label Space",
    "CREATE_TICKET": "Create Ticket",
    "2FA_CODE_WITHDRAW_CRYPTO": "Write down 2FA code for withdraw crypto",
    "I_HAVE_BACKED_UP_SECRET_CODE": "I Have Backed Up The Secret Code",
    "ENTER_DIGIT_GOOGLE_AUTHENTICATION_CODE": "Enter 6 Digit Google Authentication Code",
    "CHANGE_PASSWORD": "Change Password",
    "ALERT_AND_NOTIFICATION": "Alerts & Notifications",
    "SHOWS_CONFIRMATION_WINDOW_ORDERS": "Shows Confirmation Window for Orders",
    "SHOW_CONFIRMATION_WINDOW_ORDERS_MOBILE_SETS": "Show Confirmation Window for Orders on Mobile Site",
    "TURN_ON": "Turn On",
    "ORDERBOOK_ANIMATION": "Orderbook Animation",
    "S_NO": "S.No",
    "OPERATING_SYSTEM_BROWSER": "Operating System/Browser",
    "COUNTRY_REGION": "Country/Region",
    "ISMOBILE": "IsMobile",
    "EMAIL_AUTHENTICATION": "Email Authentication",
    "COPY": "Copy",
    "TELEGRAM_NAME_FIELD_REQUIRED": "Your Telegram Name field is required",
    "TELEGRAM_PROJECT_FIELD_REQUIRED": "Your Project Name field is required",
    "TELEGRAM_EMAIL_FIELD_REQUIRED": "Your Email Name field is required",
    "INPUT_AMOUNT_IS_BELOW": "Input amount is below or exceeds the single limit.",
    "ERRORS": "Errors!",
    "SUCCESS": "Success!",
    "YOUR_EXCHANGE_HAS_BEEN_COMPLETED": "Your exchange has been completed.",
    "LOGOUT_SUCCESS": "Logout Success!",
    "COME_JOIN": "Come join us again soon.",
    "SUBSCRIBED_SUCCESSFULLY": "Subscribed Successfully",
    "EMAIL_ALREADY_EXISTS": "Email Id Already Exist!",
    "RESET_PASSWORD_LINK_SET": "Reset Password link sent to Registered Mail ID",
    "IMAGE_SIZE_IS_TOO_LARGE": "Image size is too large.",
    "WARNING": "Warning!",
    "LAUNCHPAD_CONTACTUS": "Launchpad Contactus added successfully",
    "LOGIN_SUCCESSFULLY": "Login Successfully!",
    "UNRECOGNIZED_IP": "Unrecognized IP Address",
    "WELCOME_TO_BELL_EXCHANGE": "Welcome to Bell Exchange. Start trading and enjoy!",
    "PLEASE_ENTER_2FA_CODE": "Please enter 2FA code.",
    "PLEASE_ENTER_YOUR_CONFIRMATION": "Please enter your confirmation code.",
    "PLEASE_CHECK_YOUR_EMAIL_VERIFICATION_CODE": "Please check your email for a verification code.",
    "DELETED_SUCCESSFULLY": "Deleted Successfully",
    "CURRENCY_NAME_SHOULD_NOT_EMPTY": "Currency Name Should Not be empty",
    "ADDRESS_SHOULD_NOT_EMPTY": "Address Should Not be empty",
    "NAME_SHOULD_NOT_EMPTY": "Name Should Not be empty",
    "FROM_TO_WALLET": "From and To wallets should be different.",
    "AMOUNT_ENTERED_IS_INVALID": "Amount entered is invalid.",
    "FROM_AND_TO_CURRENCY": "From and To Currency should be different.",
    "AUTHENTICATION_CODE_MISSING": "Authentication Code is missing",
    "REGISTRATION_SUCCESS": "Registration Success!",
    "ACTIVATION_MAIL_SENT": "Activation mail sent. Please check your email and click the activation link.",
    "CURRENCY_UPDATED": "Currency updated.",
    "PASSWORD_UPDATED": "Password updated",
    "OTP_IS_MISSING": "OTP is missing",
    "PHONE_NUMBER_IS_EMPTY": "Phone Number is empty",
    "NOTIFICATION_PREFERENCES_UPDATED": "Notification preferences updated",
    "MESSAGE_IS_EMPTY": "Message is empty",
    "QUANTITY_MUST_NOT_LESS_THAN": "Quantity must not be less than",
    "PLEASE_ENTER_QUANTITY": "Please Enter Quantity",
    "QUANTITY_MUST_NOT_MORE_THAN": "Quantity must not be more than",
    "PRICE_IS_INVALID": "Price is invalid.",
    "PRICE_LESS_THAN": "Price must not be less than 0.00000001",
    "TRIGGER_PRICE_MUST_NOT_BE_LESS_THAN": "Trigger Price must not be less than 1",
    "PLEASE_LOGIN": "Please login!",
    "CHECK_YOUR_STAKE_CURRENCY": "Check your stake currency",
    "YOU_CANNOT_POST_EMPTY_MESSAGE": "You cannot post empty message",
    "QUANTITY_CONTRACTS_MUST_NOT_LESSER_THAN": "Quantity of contract must not be lesser than",
    "QUANTITY_CONTRACTS_MUST_NOT_GREATER_THAN": "Quantity of contract must not be higher than",
    "PRICE_CONTRACTS_MUST_NOT_BE_LESSER_THAN": "Price of contract must not be lesser than 0.001",
    "TRIGGER_PRICE_CONTRACTS_MUST_NOT_LESSER_THAN": "Trigger Price of contract must not be lesser than 1",
    "MINIMUM_QUANTITY_1": "Minimum quantity is 1",
    "MINIMUM_ORDER_VALUE": "Minimum Order Value is 10 ",
    "LONG_TAKE_PROFIT_PRICE": "Long: Take profit price must be greater than market price",
    "SHORT_TAKE_PROFIT_PRICE": "Short: Take profit price must be less than market price",
    "LONG_STOP_LOSS_PRICE": "Long: Stop loss price must be less than market price",
    "SHORT_STOP_LOSS_PRICE": "Short: Stop loss price must be greater than market price",
    "CLOSE_QUANTITY_MUST_LOWER_THAN": "Close quantity you set must be lower than or equal",
    "TICKET_FOR": "Ticket For",
    "ATTACHMENT": "Attachment",
    "TICKET_SUCCESS" : "Create Ticket!",
    "TICKET_RAISED" : "Ticket raised successfully!",
    "Send_Coin" : "Send Coin"
}
