import React, { Component } from 'react'
import BannerX from "../images/xicon.png"
import CaptchaImg from "../images/captchaImg.jpg"
import {Link,withRouter} from 'react-router-dom';
import Navbar from './Navbar'
import Footer from './Footer'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { store } from 'react-notifications-component';
import { forgotUser,mobforgotUser,mobresetPassword } from "../actions/authActions";
import classnames from "classnames";
import PhoneInput from 'react-phone-number-input';
import ReCAPTCHA from "react-google-recaptcha";
import keys from "../actions/config";
import { withTranslation } from 'react-i18next';
const url = keys.baseUrl;
const Recaptchakey = keys.Recaptchakey;
const recaptchaRef = React.createRef();

const grecaptchaObject = window.grecaptcha

class ForgotPassword extends Component {

	constructor() {
			super();
			this.state = {
					email: "",
					phone: "",
					otpver: "",
					timer: "",
					resend: "",
					recaptcha: "",
					mobrecaptcha: "",
					errors: {},
					time: {}, seconds: 120
			};
		this.timer = 0;
		this.startTimer = this.startTimer.bind(this);
		this.countDown = this.countDown.bind(this);
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		
			if (this.props.auth.isAuthenticated) {
					this.props.history.push("/settings");
			}
			 let timeLeftVar = this.secondsToTime(this.state.seconds);
    	this.setState({ time: timeLeftVar });
	}

	captchaChange = e => {
			this.setState({ recaptcha : e });
	};

	captchaChange1 = e => {
			this.setState({ mobrecaptcha : e });
	};

	componentWillReceiveProps(nextProps) {
		const {t} = this.props;
		console.log("nextProps.auth.forgotuser",nextProps.auth.forgotuser,nextProps.auth.forgotuser.data.message);
			if (nextProps.errors) {
					this.setState({
							errors: nextProps.errors
					});
			}

			if (nextProps.auth.forgotuser !== undefined
					&& nextProps.auth.forgotuser.data !== undefined
					&& nextProps.auth.forgotuser.data.message !== undefined ) {
					store.addNotification({
					  title: t("SUCCESS"),
					  message: t("RESET_PASSWORD_LINK_SET"),
					  type: "success",
					  insert: "top",
					  container: "top-right",
					  animationIn: ["animated", "fadeIn"],
					  animationOut: ["animated", "fadeOut"],
					  dismiss: {
					    duration: 5000,
					    onScreen: true
					  }
					});
					this.setState({
						otpver : (this.state.phone != '')?'true':''
					});
					if(nextProps.auth.forgotuser.data.message=='Your password has been reset successfully')
					{
						nextProps.auth.forgotuser = "";
								this.props.history.push("/login");
					}
					else
					{
							if(this.state.phone != '')
							{
								this.setState({timer:'true',seconds:120});
								this.state.seconds = 120;
								this.timer = 0
								this.startTimer();
								nextProps.auth.forgotuser = "";
							}
							else
							{
								nextProps.auth.forgotuser = "";
								this.props.history.push("/login");

							}
					}
			}
	}

	onChange = e => {
			this.setState({ [e.target.id]: e.target.value });
	};

	onChangeCheckbox = e => {
			this.setState({ [e.target.id]: e.target.checked });
	};


	onSubmit = e => {
			e.preventDefault();
			const forgotUser = {
					email: this.state.email,
					recaptcha: this.state.recaptcha,
			};
			//console.log(forgotUser);	
			this.props.forgotUser(forgotUser, this.props.history);
	};

	startTimer() {
		if (this.timer == 0 && this.state.seconds > 0) {
		this.timer = setInterval(this.countDown, 1000);
		}
	}

	countDown() {
		// Remove one second, set state so a re-render happens.
		let seconds = this.state.seconds - 1;
		this.setState({
		time: this.secondsToTime(seconds),
		seconds: seconds,
		});

		// Check if we're at zero.
		if (seconds == 0) { 
		clearInterval(this.timer);
		this.setState({timer:'',resend:'true'})
		}
	}

	mobileSubmit = e => {
			e.preventDefault();
			const forgotUser = {
					phone: this.state.phone,
					mobrecaptcha: this.state.mobrecaptcha,
			};
			this.props.mobforgotUser(forgotUser, this.props.history);
	};

	mobileSubmit1 = e => {
		this.setState({resend:''})
			e.preventDefault();
			const forgotUser = {
					phone: this.state.phone,
			};
			this.props.mobforgotUser(forgotUser, this.props.history);
	};

	resetSubmit = e => {
			e.preventDefault();
			const forgotUser = {
					phone: this.state.phone,
					resetpassword: this.state.resetpassword,
					password2: this.state.password2,
					otpcode: this.state.otpcode,
					action:'reset'
			};
			this.props.mobresetPassword(forgotUser, this.props.history);
	};

	secondsToTime(secs){
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }



	render() {
		const { errors } = this.state;
		const { t } = this.props;
		return <div className='bg_strip'>
		<section class="headerCrediantials login_banner">
			<Navbar />
			<div className="container">
				<div className="row">
				<div className="col-md-7 col-lg-5 mx-auto">
					<div className="formBox mt-5 mb-5" data-aos="fade-up" data-aos-duration="2000" >
					<h2>{t("FORGOT_PASSWORD_RETRIEVE_PASSWORD")}</h2>
					
					
					<div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
					<div className="tab-pane fade show active" id="nav-emailLogin" role="tabpanel" aria-labelledby="nav-emailLogin-tab">
						<form id="" name="" className="stoForm landingForm" noValidate onSubmit={this.onSubmit} >
						<div className="form-group fadeIn second mb-4 mt-3">
						<label>{t("EMAIL_PLACEHOLDER")}</label>
									<input
											onChange={this.onChange}
											value={this.state.email}
											error={errors.email}
											id="email"
											type="email"
											className={classnames("form-control", {
													invalid: errors.email
											})}
									/>
									<span className="text-danger">{errors.email}</span>
					</div>
					<div className="form-group clearfix fadeIn third recaptchaMain ml-1">
						<ReCAPTCHA
						ref={(r) => this.recaptcha = r}
						sitekey={Recaptchakey}
						grecaptcha={grecaptchaObject}
						onChange={this.captchaChange}
						/>
						<span className="text-danger">{errors.recaptcha}</span>
						</div>               
						<div className="form-group my-4">
						<input type="submit" className="btn-block fadeIn fourth" value="Submit" />
					</div>
					</form>
					<div id="formFooter" className="fadeIn fourth">
						<h6 className="text-center mb-4">{t("NOT_REGISTER_USER")} <Link to="/register">{t("REGISTER_NOW")}</Link></h6>
						<h6 className="text-center">{t("LOST_YOUR_TOKEN_PLEASE")}  <Link to="/Contact_us">{t("CONTACT_US")}</Link>  {t("LOST_2FA_DEVICE")}</h6>
					</div>
					</div>
					<div className="tab-pane fade" id="nav-mobileLogin" role="tabpanel" aria-labelledby="nav-mobileLogin-tab">
					{ this.state.otpver==''?
						<form id="" name="" className="stoForm landingForm" noValidate onSubmit={this.mobileSubmit} >
						<div className="form-group input-group mobNumber mb-5 mt-3">
													<label>{t("MOBILE_NO")}</label>
													<PhoneInput
													placeholder="Enter phone number"
													country={'IN'}
													value={this.state.phone}
													onChange={phone => this.setState({ phone })}
													/>
													<span className="text-danger">{errors.phone}</span>
												</div>
						<div className="form-group clearfix fadeIn third recaptchaMain ml-1">
						<ReCAPTCHA
						ref={(r) => this.recaptcha = r}
						sitekey={Recaptchakey}
						grecaptcha={grecaptchaObject}
						onChange={this.captchaChange1}
						/>
						<span className="text-danger">{errors.mobrecaptcha}</span>
						</div>                       
						<div className="form-group my-4">
						<input type="submit" className="btn-block fadeIn fourth" value="Submit" />
					</div>
					</form>
					:
					<form id="" name="" className="stoForm landingForm" noValidate onSubmit={this.resetSubmit} >
						<div className="form-group fadeIn second mb-5 mt-3">
						<label>{t("NEW_PASSWORD")}</label>
									<input
											onChange={this.onChange}
											value={this.state.resetpassword}
											error={errors.resetpassword}
											id="resetpassword"
											type="password"
											className={classnames("form-control", {
													invalid: errors.resetpassword
											})}
									/>
									<span className="text-danger">{errors.resetpassword}</span>
					</div>
					<div className="form-group fadeIn second mb-5 mt-3">
						<label>{t("CONFIRM_PASSWORD")}</label>
						<input
							onChange={this.onChange}
							value={this.state.password2}
							error={errors.password2}
							id="password2"
							type="password"
							className={classnames("form-control", {
								invalid: errors.password2
							})}
						/>
						<span className="text-danger">{errors.password2}</span>
					</div>

					<div className="form-group fadeIn second mb-5 mt-3">
						<label>{t("OTP_CODE")}</label>
						<input
							onChange={this.onChange}
							value={this.state.otpcode}
							error={errors.otpcode}
							id="otpcode"
							type="password"
							className={classnames("form-control", {
								invalid: errors.otpcode
							})}
						/>
						<span className="text-danger">{errors.otpcode}</span>
					</div>
					{ this.state.timer!=''?
					<center><p>m: {this.state.time.m} s: {this.state.time.s}</p></center>
					:''}
					{ this.state.resend!=''?
					<center><p>{t("NO_SMS_RECEIVED")} <a onClick={this.mobileSubmit1}>{t("RESEND_OTP")}</a></p></center>
					:''}
						<div className="form-group my-4">
						<input type="submit" className="btn-block fadeIn fourth" value={t("SUBMIT")} />
					</div>
					</form>
				}
					<div id="formFooter" className="fadeIn fourth">
						<h6 className="text-center mb-4">{t("NOT_REGISTER_USER")} <Link to="/register">{t("REGISTER_NOW")}</Link></h6>
						<h6 className="text-center">{t("LOST_YOUR_TOKEN_PLEASE")}  <Link to="/Contact_us">{t("CONTACT_US")}</Link> {t("LOST_2FA_DEVICE")}</h6>
					</div>
					</div>
					</div>
					</div>
				</div>
				</div>
			</div>
		</section>		
				<Footer />
		</div>
	}
}





ForgotPassword.propTypes = {
    forgotUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { forgotUser,mobforgotUser,mobresetPassword }
)(withTranslation()(ForgotPassword),withRouter(ForgotPassword));
