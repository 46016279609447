import React, { Component } from 'react'
import TradeHeader from './TradeHeader'
import Footer from './Footer'
import DepositeQR from "../images/depositeQR.jpg"
import BtcIcon from "../images/btcIcon.png"
import EthIcon from "../images/ethIcon.png"
import XrpIcon from "../images/xrpIcon.png"
import LtcIcon from "../images/ltcIcon.png"
import BchIcon from "../images/bchIcon.png"
import { store } from 'react-notifications-component';
import keys from "../actions/config";
import axios from "axios";
import DatePicker from "react-datepicker";
import { tradehistory, tradeSearchdata, spotcancelTrade } from "../actions/authActions";
import { Link, withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CSVLink, CSVDownload } from "react-csv";
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import { Scrollbars } from 'react-custom-scrollbars';
const url = keys.baseUrl;


const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',
        padding: 20,
    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

const options1 = [
    { value: 'All', label: 'All' },
    { value: 'Buy', label: 'Buy' },
    { value: 'Sell', label: 'Sell' },
];

const options = [{ value: 'All', label: 'All' },];

class UserPendingOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderData: [],
            records: [],
            csvData: [],
            contract: { value: 'All', label: 'All' },
            type: { value: 'All', label: 'All' },
            startDate: '',
            endDate: '',
            // exchangetype : 'Derivatives',
            exchangetype: 'Spot',
        };
    }

    componentDidMount() {
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }

        if (nextProps.auth !== undefined
            && nextProps.auth.trade !== undefined
            && nextProps.auth.trade.data !== undefined
            && nextProps.auth.trade.data.data !== undefined
            && nextProps.auth.trade.data.type !== undefined) {
            this.buildarray(nextProps.auth.trade.data.data);
        }
    }

    getData() {
        var input = { "userid": this.props.auth.user.id, "exchangetype": this.state.exchangetype }
        this.pairdatachanges();

        axios.post(url + "cryptoapi/pending-order/", input)
            .then(res => {
                this.setState({ orderData: res.data.data })
            }).catch(err => { });
    }

    cancelFunction = (e) => {
        if (window.confirm('Are you confirm to cancel this order?.')) {
            let userid = this.props.auth.user.id;
            var iddetails = { id: e.target.id, userid: userid };
            this.props.spotcancelTrade(iddetails);

        }
    };

    changecontract = contract => {
        this.setState({ contract });
    };

    changetype = type => {
        this.setState({ type });
    };
    exchangetype = e => {
        var type = e.target.innerHTML;
        this.setState({ exchangetype: type, records: [] }
            , () => {
                this.getData();
            });
    };

    buildarray = (historydata) => {
        var csvdata = [];

        var titledata = ["Contracts", "Filled Type", "Filled Qty", "Exec price", "Value", "Fee Rate", "Fees Paid", "Order Price", "Unfilled Qty", "Type", "Transaction ID", "Order Time"];
        csvdata.push(titledata);
        if (historydata.length > 0) {
            historydata.map((item, i) => {
                var quantity = item.quantity ? item.quantity : 0;
                var price = item.price ? item.price : 0;
                var orderValue = item.orderValue ? item.orderValue : 0;
                var orderType = item.buyorsell ? item.buyorsell : 0;
                var _id = item._id ? item._id : 0;
                var classs = item.buyorsell == 'buy' ? 'greenText' : 'pinkText';
                var filledAmount = item.filledAmount ? item.filledAmount : 0;
                var Remaining = parseFloat(quantity) - parseFloat(filledAmount);
                var newarr = [];
                if (typeof item.filled != 'undefined') {
                    item.filled.map((itemdata, i) => {
                        //console.log(itemdata, 'itemdatasss');
                        var created_at = itemdata.created_at ? itemdata.created_at : '';
                        var Price = itemdata.Price ? itemdata.Price : 0;
                        var pairname = itemdata.pairname ? itemdata.pairname : 0;
                        var data1 = new Date(created_at);
                        let date = data1.getFullYear() + '-' + (data1.getMonth() + 1) + '-' + data1.getDate() + ' ' + data1.getHours() + ':' + data1.getMinutes() + ':' + data1.getSeconds();
                        newarr.push(pairname);
                        newarr.push("Trade");
                        newarr.push(filledAmount);
                        newarr.push(Price);
                        newarr.push(orderValue);
                        newarr.push(orderValue);
                        newarr.push(orderValue);
                        newarr.push(orderValue);
                        newarr.push(Remaining);
                        newarr.push(orderType);
                        newarr.push(_id);
                        newarr.push(date);
                        csvdata.push(newarr);
                    })
                }
            })
        }
        this.setState({ csvData: csvdata })
    }

    Searchdata = () => {
        var typeexchange = this.state.exchangetype == "Derivative" ? "Futures" : "Spot"

        var postdetails = {
            "contract": this.state.contract.value,
            "type": this.state.type.value,
            "startDate": this.state.startDate,
            "endDate": this.state.endDate,
            "userid": this.props.auth.user.id,
            "exchangetype": typeexchange
        }
        this.props.tradeSearchdata(postdetails);
    }

    pairdatachanges = data => {
        axios
            .post(url + "cryptoapi/pair-data", { exchangetype: this.state.exchangetype })
            .then(res => {
                if (res.data.status) {
                    options.splice(1);
                    // //console.log(res.data.data,'ressssss');
                    this.setState({ records: res.data.data });
                    res.data.data.map((item, i) => {
                        if (item.first_currency != '' && item.second_currency != '') {
                            var one = { "value": item.first_currency + item.second_currency, "label": item.first_currency + item.second_currency }
                            options.push(one);
                        }
                    });
                }
            })
            .catch();

    }

    handleChange = date => {
        this.setState({
            startDate: date
        });
    };


    handleChange1 = date => {
        this.setState({
            endDate: date
        });
    };


    render() {
        const { contract, type } = this.state
        return (
            <div>
                <TradeHeader pairdatachange={this.pairdatachange} />
                <div className="container-fluid">
                    <section className="tradeMain">
                        <div className="row mx-0 mt-5">
                            <div className="col-md-12 px-0">
                                <div className="darkBox assetsTable border-top-one py-2">
                                    <nav>
                                        <div className="nav nav-tabs" id="navLink-tab" role="tablist">
                                            <Link to="/AssetsHistory" className="nav-item nav-link active">Deposit History</Link>
                                            <Link to="/OrderHistory" className="nav-item nav-link">Order History</Link>
                                            <Link to="/tradeHistory" className="nav-item nav-link">Trade History</Link>
                                            <Link to="/withdrawalHistory" className="nav-item nav-link">Withdrawal History</Link>
                                            <Link to="/withdrawalHistory" className="nav-item nav-link">Pending Order</Link>
                                        </div>
                                    </nav>
                                    <div className="tab-content" id="nav-tabContent">
                                        <div className="tab-pane fade show active" id="nav-USD" role="tabpanel" aria-labelledby="nav-USD-tab">
                                            <div className="tab-content">
                                                <div className="assetHistoryTableTop clearfix">
                                                    <div className="row">
                                                        <div className="col-lg-10 col-md-9 col-sm-8">
                                                            <div className="row">
                                                                <div className="col-lg-2 col-md-4 col-sm-6">
                                                                    <div className="form-group clearfix">
                                                                        <label>Pairs</label>

                                                                        <Select
                                                                        isSearchable={false}
                                                                            styles={customStyles}
                                                                            width='100%'
                                                                            menuColor='red'
                                                                            options={options}
                                                                            value={contract}
                                                                            onChange={this.changecontract}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-4 col-sm-6">
                                                                    <div className="form-group clearfix">
                                                                        <label>Type</label>
                                                                        <Select isSearchable={false}
                                                                            styles={customStyles}
                                                                            width='100%'
                                                                            menuColor='red'
                                                                            options={options1}
                                                                            value={type}
                                                                            onChange={this.changetype}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-4 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label className="d-none d-md-block invisible">s</label>
                                                                        <input type="button" name="" value="Search" onClick={this.Searchdata} className="buttonType1" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="tab-content">
                                                <div className="table-responsive">
                                                    <table id="assetsTable" className="table table-striped rembordor">
                                                        <thead>
                                                            <tr className="wow flipInX" data-wow-delay=".5s;">
                                                                <th>Contracts</th>
                                                                <th>Quantity</th>
                                                                <th>Price</th>
                                                                <th>Filled Remaining</th>
                                                                <th>Order Value</th>
                                                                <th>Tp/SL</th>
                                                                <th>Type</th>
                                                                <th>Status</th>
                                                                <th>Order#</th>
                                                                <th>Order Time</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {
                                                                this.state.orderData && this.state.orderData.length > 0 && this.state.orderData.map((item, i) => {
                                                                    var pairName = item.pairName ? item.pairName : '';
                                                                    var quantity = item.quantity ? item.quantity : 0;
                                                                    var price = item.quantity ? item.price : 0;
                                                                    var filledAmount = item.filledAmount ? item.filledAmount : 0;
                                                                    var orderValue = item.orderValue ? item.orderValue : 0;
                                                                    var orderType = item.orderType ? item.orderType : 0;
                                                                    var orderDate = item.orderDate ? item.orderDate : 0;
                                                                    var buyorsellType = item.buyorsell ? item.buyorsell : "";
                                                                    var classs = (quantity > 0) ? 'greenText' : 'pinkText';
                                                                    var classsname = (buyorsellType == "buy") ? 'greenText' : 'pinkText';
                                                                    var _id = item._id ? item._id : 0;
                                                                    var status = item.status == '0' ? 'New' : 'Partial';
                                                                    var Remaining = parseFloat(quantity) - parseFloat(filledAmount);
                                                                    var data1 = new Date(orderDate);
                                                                    let date12 = data1.getFullYear() + '-' + (data1.getMonth() + 1) + '-' + data1.getDate() + ' ' + data1.getHours() + ':' + data1.getMinutes() + ':' + data1.getSeconds();
                                                                    return (
                                                                        <tr>
                                                                            <td className="yellowText">{pairName}</td>
                                                                            <td><span className={classsname}>{parseFloat(quantity).toFixed(8)}</span></td>
                                                                            <td>{parseFloat(price).toFixed(this.state.floating)}</td>
                                                                            <td>{parseFloat(filledAmount).toFixed(8) + '/' + parseFloat(Remaining).toFixed(8)}</td>
                                                                            <td>{parseFloat(orderValue).toFixed(8)}</td>
                                                                            <td>{'-/-'}</td>
                                                                            <td>{orderType}</td>
                                                                            <td>{status}</td>
                                                                            <td>{_id}</td>
                                                                            <td>{date12}</td>
                                                                            <td>
                                                                                <button
                                                                                    className="btn btn-borderButton mr-1"
                                                                                    onClick={this.cancelFunction.bind(this)}
                                                                                    id={_id}> Cancel </button>
                                                                            </td>
                                                                        </tr>
                                                                    )

                                                                })
                                                            }
                                                        </tbody>

                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        );
    }
}

UserPendingOrder.propTypes = {
    tradeSearchdata: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    spotcancelTrade: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(

    mapStateToProps,
    {
        tradeSearchdata,
        spotcancelTrade
    }
)(withRouter(UserPendingOrder));
