import React, { Component } from 'react'
import BannerX from "../images/xicon.png"
import CaptchaImg from "../images/captchaImg.jpg"
import Navbar from './Navbar'
import Footer from './Footer'
import { Link, withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import browser from 'browser-detect';
import axios from "axios";
import { store } from 'react-notifications-component';
import { loginUser, mobloginUser, tfachecking, confirmaionchecking } from "../actions/authActions";
import PhoneInput from 'react-phone-number-input'
import classnames from "classnames";
import keys from "../actions/config";
import ReCAPTCHA from "react-google-recaptcha";
import { Modal, Button } from 'react-bootstrap/';
import { withTranslation} from 'react-i18next'
const url = keys.baseUrl;
const Recaptchakey = keys.Recaptchakey;
const recaptchaRef = React.createRef();

const grecaptchaObject = window.grecaptcha
class Login extends Component {
	constructor() {
		super();
		this.state = {
			email: "",
			password: "",
			errors: {},
			countryName: "",
			countryCode: "",
			ipaddress: "",
			broswername :"",
			regionName :"",
			ismobile :"",
			os :"",
			region :"",
			recaptcha: "",
			mobrecaptcha: "",
			notify :"",
			tfacode :"",
			ccode :"",
			show:false,
			viewpassword:false
		};

		if (!localStorage.getItem('curpair1')) {
			localStorage.setItem('curpair1','BTC-USDT')
		}
	}

	componentDidMount() {
		const {t} = this.props;
		window.scrollTo(0, 0);
		if (this.props.auth.isAuthenticated) {
			this.props.history.push("/spot/"+localStorage.getItem('curpair1'));
		}

		this.getGeoInfo();
		if (this.props.auth.isAuthenticated) {
console.log("this.props.auth.isAuthenticated",this.props.auth.isAuthenticated);

				this.props.history.push("/spot/"+localStorage.getItem('curpair1'));
		}
		let activate_id = this.props.match.params.id;
		if (typeof activate_id != "undefined" && activate_id != "") {
			let passdata = {userid:activate_id}
			axios
				.post(url+"cryptoapi/user-activate",passdata)
				.then(res => {
					store.addNotification({
						title: t("LOGIN_SUCCESSFULLY"),
						message: t("WELCOME_TO_BELL_EXCHANGE"),
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
							animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 5000,
							onScreen: true
						}
					});
					this.props.history.push("/login");
				})
				.catch()
		}
	};

	componentWillReceiveProps(nextProps) {
        const {t} = this.props;
		if (nextProps.auth.isAuthenticated) {
			store.addNotification({
				title: t("LOGIN_SUCCESSFULLY"),
				message: t("WELCOME_TO_BELL_EXCHANGE"),
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1000,
					onScreen: true
				}
			});
			 this.props.history.push("/spot/"+localStorage.getItem('curpair1'));
			//  this.props.history.push("/login");
		}

		if (nextProps.errors) {
			this.setState({
				errors: nextProps.errors
			});
			if(typeof nextProps.errors.notify !="undefined" && nextProps.errors.notify!=""){
                  
					if(nextProps.errors.notify=='2fa Enabled') {
						this.setState({show:true})
					}
					if(nextProps.errors.notify=='blocking') {
						this.setState({show1:true});
						store.addNotification({
							title: t("UNRECOGNIZED_IP"),
							message: t("PLEASE_CHECK_YOUR_EMAIL_VERIFICATION_CODE"),
							type: "danger",
							insert: "top",
							container: "top-right",
							animationIn: ["animated", "fadeIn"],
							animationOut: ["animated", "fadeOut"],
							dismiss: {
								duration: 5000,
								onScreen: true
							}
						});
					}
				}
			setTimeout(function(){
				if (typeof nextProps.errors.notify !="undefined" &&
						nextProps.errors.notify != "" &&
						nextProps.errors.notify != '2fa Enabled' &&
						nextProps.errors.notify != 'blocking') {
					store.addNotification({
						title: t("ERRORS"),
						message: nextProps.errors.notify,
						type: "danger",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 5000,
							onScreen: true
						}
					});
				}
				nextProps.errors.notify = "";
			}, 100);
		}
	}

	onChange = e => {
		this.setState({ [e.target.id]: e.target.value });
	};
	tpClose = e => {
		this.setState({ show: false });
	};
	tpClose1 = e => {
		this.setState({ show1: false });
	};
	getGeoInfo = () => {
	  axios.get('https://ipapi.co/json/').then((response) => {
      let data = response.data;
      //console.log(data,'data');
      this.setState({
          countryName: data.country_name,
          countryCode: data.country_calling_code,
          ipaddress: data.ip,
          region: data.region
      });
    }).catch((error) => {
        //console.log(error);
    });
	};


	captchaChange = e => {
		this.setState({ recaptcha : e });
	};

	captchaChange1 = e => {
		this.setState({ mobrecaptcha : e });
	};


	onSubmit = e => {
		e.preventDefault();
		const result = browser();
		const loginhistory = {};

		loginhistory.countryCode = this.state.countryCode;
		loginhistory.countryName = this.state.countryName;
		loginhistory.ipaddress = this.state.ipaddress;
		loginhistory.regionName = this.state.region;
		loginhistory.broswername = result.name;
		loginhistory.ismobile = result.mobile;
		loginhistory.os = result.os;
		const userData = {
				email: this.state.email,
				password: this.state.password,
				recaptcha: this.state.recaptcha,
				logininfo: loginhistory
		};
		this.props.loginUser(userData);
	};

	loginSubmit = e => {
		e.preventDefault();
		const result = browser();
		const loginhistory = {};

		loginhistory.countryCode = this.state.countryCode;
		loginhistory.countryName = this.state.countryName;
		loginhistory.ipaddress = this.state.ipaddress;
		loginhistory.regionName = this.state.region;
		loginhistory.broswername = result.name;
		loginhistory.ismobile = result.mobile;
		loginhistory.os = result.os;
		const userData = {
				phone: this.state.phone,
				mobpassword: this.state.mobpassword,
				mobrecaptcha: this.state.mobrecaptcha,
				logininfo: loginhistory
		};
		this.props.mobloginUser(userData);
	};

	tfachecking = e => {
		const {t} = this.props;
		e.preventDefault();
			const result = browser();
			const loginhistory = {};

			if (this.state.tfacode != '') {
				loginhistory.countryCode = this.state.countryCode;
				loginhistory.countryName = this.state.countryName;
				loginhistory.ipaddress = this.state.ipaddress;
				loginhistory.regionName = this.state.region;
				loginhistory.broswername = result.name;
				loginhistory.ismobile = result.mobile;
				loginhistory.os = result.os;
				const userData = {
					email: this.state.email,
					password: this.state.password,
					recaptcha: this.state.recaptcha,
					tfacode: this.state.tfacode,
					logininfo: loginhistory
				};
				this.props.tfachecking(userData);
			}
			else {
				store.addNotification({
					title: t("ERRORS"),
					message: t("PLEASE_ENTER_2FA_CODE"),
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 1500,
						onScreen: true
					}
				});
			}
	};

	confirmaionchecking = e => {
		const {t} = this.props;
		e.preventDefault();
			const result = browser();
			const loginhistory = {};

			if (this.state.ccode != '') {
				loginhistory.countryCode = this.state.countryCode;
				loginhistory.countryName = this.state.countryName;
				loginhistory.ipaddress = this.state.ipaddress;
				loginhistory.regionName = this.state.region;
				loginhistory.broswername = result.name;
				loginhistory.ismobile = result.mobile;
				loginhistory.os = result.os;
				const userData = {
					email: this.state.email,
					password: this.state.password,
					recaptcha: this.state.recaptcha,
					ccode: this.state.ccode,
					logininfo: loginhistory
				};
				this.props.confirmaionchecking(userData);
			}
			else {
				store.addNotification({
					title: t("ERRORS"),
					message: t("PLEASE_ENTER_YOUR_CONFIRMATION"),
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 1500,
						onScreen: true
					}
				});
			}
	};

	render() {
		const { errors } = this.state;
       const { t } = this.props;
		const elements = ['one', 'two', 'three'];
		 const items = []

		 for (const [index, value] of elements.entries()) {
			 items.push(<li key={index}>{value}</li>)
		 }

		return (
			<div className='bg_strip loginsec'>

				<section class="headerCrediantials login_banner">
					<Navbar />
					<div className="container">
				      <div className="row">
				         {/* <div className="col-md-6 pt-5">
				            <div className="lgoinBannerText">
				               <h3>Exchange Top Cryptos with</h3>
				               <h2><span>150</span> <img src={BannerX} /> <small></small></h2>
				               <ul>
				                  <li><i className="fas fa-check"></i> Advanced Trade Orders</li>
				                  <li><i className="fas fa-check"></i> Safe And Secured Wallets</li>
				                  <li><i className="fas fa-check"></i> Technical Expertise</li>
				               </ul>
				            </div>
				         </div> */}
				         <div className="col-md-7 col-lg-5 m-auto">
				            <div className="formBox mb-5 mt-5" data-aos="fade-up" data-aos-duration="2000">
				               <h2>{t("SIGN_IN")}</h2>
				               <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
				                  <div className="tab-pane fade show active" id="nav-emailLogin" role="tabpanel" aria-labelledby="nav-emailLogin-tab">
				                     <form className="stoForm landingForm" noValidate onSubmit={this.onSubmit} >
				                        <div className="form-group fadeIn second">
				                           <label>{t("EMAIL_PHONE")}</label>
				                           <input
				                           onChange={this.onChange}
				                           value={this.state.email}
				                           error={errors.email}
				                           id="email"
				                           type="email"
				                           className={classnames("form-control", {
				                           invalid: errors.email
				                           })}
				                           />
				                           <span className="text-danger">{errors.email}</span>
				                        </div>
				                        <div className="form-group fadeIn third">
				                           <label>{t("PASSWORD")}</label>

										   <div class="input-group input_grp_pwd">
				                           <input
				                           onChange={this.onChange}
				                           value={this.state.password}
				                           error={errors.password}
				                           id="password"
				                           type={this.state.viewpassword ? "text":"password"}
				                           className={classnames("form-control", {
				                           invalid: errors.password
				                           })}
				                           />
										   <div class="input-group-append input_grp_pwd_append" onClick={()=>{this.setState({viewpassword:!this.state.viewpassword})}}>
											<span class="input-group-text">
												{this.state.viewpassword?
												<i class="fa fa-eye"></i>
												:
												<i class="fa fa-eye-slash"></i>
												}
												</span>
											</div>
										   </div>
				                           <span className="text-danger">{errors.password}</span>
				                        </div>
				                        <div className="form-group clearfix fadeIn third">
				                           <div className="float-left">
				                              <div className="checkbox">
				                              </div>
				                           </div>
				                           <div className="float-right">
				                              <Link to="/ForgotPassword">
				                              {t("FORGOT_PASSWORD")}</Link>
				                           </div>
				                        </div>
				                        <div className="form-group clearfix fadeIn third ml-1 recaptchaMain">
				                           <ReCAPTCHA
				                              ref={(r) =>
				                           this.recaptcha = r}
				                           sitekey={Recaptchakey}
				                           grecaptcha={grecaptchaObject}
				                           onChange={this.captchaChange}
				                           />
				                           <span className="text-danger">{errors.recaptcha}</span>
				                        </div>
				                        <div className="form-group my-4">
				                           <input type="submit" className="btn-block fadeIn fourth" value={t("SIGN_IN")} />
				                        </div>
				                     </form>
				                     <div id="formFooter" className="fadeIn fourth">
				                        <h6 className="text-center" style={{ whiteSpace: 'pre-line' }}>
										{t("NOT_REGISTER_USER")} &nbsp;&nbsp;
				                           <Link to="/register" className="underlineHover">
										   {t("REGISTER_HERE")} </Link>
				                        </h6>
				                     </div>
				                  </div>
				                  <div className="tab-pane fade" id="nav-mobileLogin" role="tabpanel" aria-labelledby="nav-mobileLogin-tab">
				                     <form className="stoForm landingForm" noValidate onSubmit={this.loginSubmit} >
				                        <div className="form-group input-group mobNumber">
				                           <label>{t("MOBILE_NO")}</label>
				                           <PhoneInput
				                              placeholder="Enter phone number"
				                              country={'IN'}
				                              value={this.state.phone}
				                              onChange={phone =>
				                           this.setState({ phone })}
				                           />
				                           <span className="text-danger">{errors.phone}</span>
				                        </div>
				                        <div className="form-group fadeIn third">
				                           <label>{t("PASSWORD")}</label>
				                           <input
				                           onChange={this.onChange}
				                           value={this.state.mobpassword}
				                           error={errors.mobpassword}
				                           id="mobpassword"
				                           type="password"
				                           className={classnames("form-control", {
				                           invalid: errors.mobpassword
				                           })}
				                           />
				                           <span className="text-danger">{errors.mobpassword}</span>
				                        </div>
				                        <div className="form-group clearfix fadeIn third">
				                           <div className="float-right">
				                              <Link to="/ForgotPassword">
				                              {t("FORGET_PASSWORD")}</Link>
				                           </div>
				                        </div>
				                        <div className="form-group clearfix fadeIn third ml-1 recaptchaMain">
				                           <ReCAPTCHA
				                              ref={(r) =>
				                           this.recaptcha = r}
				                           sitekey={Recaptchakey}
				                           grecaptcha={grecaptchaObject}
				                           onChange={this.captchaChange1} />
				                           <span className="text-danger">{errors.mobrecaptcha}</span>
				                        </div>
				                        <div className="form-group my-4">
				                           <input type="submit" className="fadeIn fourth" value="Sign In" />
				                        </div>
				                     </form>
				                     <div id="formFooter" className="fadeIn fourth">
				                        <h6 className="text-center" style={{ whiteSpace: 'pre-line' }}>
										{t("NOT_REGISTER_USER")}  &nbsp;&nbsp;
				                           <Link to="/register" className="underlineHover">
				                           {t("REGISTER_HERE")}</Link>
				                        </h6>
				                     </div>
				                  </div>
				               </div>
				            </div>
				         </div>
				      </div>
				      <Modal className="primary-modal" show={this.state.show} onHide={this.tpClose}  aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
				         <Modal.Header closeButton>
				            <Modal.Title>{t("2FA_CODE")}</Modal.Title>
				         </Modal.Header>
				         <Modal.Body>
				            <div className="form-group">
				               <div className="row">
				                  <div className="col-md-8">
				                     <div className="def-number-input safari_only">
				                        <input className="quantity twofa_input_small_h" min="0" name="tfacode" id="tfacode" onChange={this.onChange} value={this.state.tfacode} type="number" />
				                     </div>
				                  </div>
				               </div>
				            </div>
				         </Modal.Body>
				         <Modal.Footer>
				            <Button variant="secondary btnDefaultNewBlue" onClick={this.tpClose}>
							{t("CANCEL")}  
				            </Button>
				            <Button variant="primary btnDefaultNew" onClick={this.tfachecking}>
				            {t("CONFIRM")}
				            </Button>
				         </Modal.Footer>
				      </Modal>
				      <Modal className="primary-modal" show={this.state.show1} onHide={this.tpClose1}  aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
				         <Modal.Header closeButton>
				            <Modal.Title>{t("EMAIL_CONFIRMATION_CODE")}</Modal.Title>
				         </Modal.Header>
				         <Modal.Body>
				            <div className="form-group">
				               <div className="row">
				                  <div className="col-md-8">
				                     <div className="def-number-input safari_only">
				                        <p>{t("CHECK_YOUR_EMAIL_CONFIRMATION_CODE")}</p>
				                        <input className="quantity twofa_input_small_h" min="0" name="ccode" id="ccode" onChange={this.onChange} value={this.state.ccode} type="number" />
				                     </div>
				                  </div>
				               </div>
				            </div>
				         </Modal.Body>
				         <Modal.Footer>
				            <Button variant="secondary btnDefaultNewBlue" onClick={this.tpClose1}>
							{t("CANCEL")} 
				            </Button>
				            <Button variant="primary btnDefaultNew" onClick={this.confirmaionchecking}>
				            {t("CONFIRM")}
				            </Button>
				         </Modal.Footer>
				      </Modal>
				   </div>
				</section>
				<Footer />
			</div>
		);
	}

}


Login.propTypes = {
  confirmaionchecking: PropTypes.func.isRequired,
  tfachecking: PropTypes.func.isRequired,
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser, mobloginUser, tfachecking, confirmaionchecking }
)(withTranslation()(Login),withRouter(Login));
