import React, { Component } from 'react'
import TradeHeader from './TradeHeader'
import Footer from './Footer'
import DepositeQR from "../images/depositeQR.jpg"
import BtcIcon from "../images/btcIcon.png"
import EthIcon from "../images/ethIcon.png"
import XrpIcon from "../images/xrpIcon.png"
import LtcIcon from "../images/ltcIcon.png"
import BchIcon from "../images/bchIcon.png"
import { store } from 'react-notifications-component';
import keys from "../actions/config";
import Select from 'react-select';
import axios from "axios";
import DatePicker from "react-datepicker";
import { orderhistory, Searchdata } from "../actions/authActions";
import { Link, withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CSVLink, CSVDownload } from "react-csv";
import { Scrollbars } from 'react-custom-scrollbars';

import "react-datepicker/dist/react-datepicker.css";
const url = keys.baseUrl;


const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',
        padding: 20,
    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

const options1 = [
    { value: 'All', label: 'All' },
    { value: 'Buy', label: 'Buy' },
    { value: 'Sell', label: 'Sell' },
];

const options = [{ value: 'All', label: 'All' },];

class PendingHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderHistory: [],
        };
    }

    componentDidMount() {
        this.getData()
    };

    getData() {
        var input = { "userid": this.props.auth.user.id }
        axios.get(url + "cryptoapi/pending-order", input).then(res => {

        }).catch(err => { });
    }


    render() {
        return (
            <div>
                <TradeHeader pairdatachange={this.pairdatachange} />
                <div className="container-fluid">
                    <section className="tradeMain">
                        <div className="row mx-0 mt-5">
                            <div className="col-md-12 px-0">
                                <div className="darkBox assetsTable border-top-one py-2">
                                    <nav>
                                        <div className="nav nav-tabs" id="navLink-tab" role="tablist">
                                            {/* <a href="/closedPandL" className="nav-item nav-link ">Closed P&L</a> */}
                                            <a href="/AssetsHistory" className="nav-item nav-link">Deposit History</a>
                                            <a href="/OrderHistory" className="nav-item nav-link">Order History</a>
                                            <a href="/tradeHistory" className="nav-item nav-link">Trade History</a>
                                            <a href="/withdrawalHistory" className="nav-item nav-link">Withdrawal History</a>
                                            <a href="/pendingHistory" className="nav-item nav-link active">Spot Pending Order</a>
                                        </div>
                                    </nav>

                                    <div className="tab-content" id="nav-tabContent">
                                        <div className="tab-pane fade show active" id="nav-USD" role="tabpanel" aria-labelledby="nav-USD-tab">
                                            <div className="tab-content">

                                                <div className="table-responsive">
                                                    <Scrollbars style={{ width: '100%', height: 203 }} renderTrackVertical={props => <div className="track-vertical" />}>
                                                        <table id="active0Table" className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>Contracts</th>
                                                                    <th>Quantity</th>
                                                                    <th>Price</th>
                                                                    <th>Filled Remaining</th>
                                                                    <th>Order Value</th>
                                                                    <th>Tp/SL</th>
                                                                    <th>Type</th>
                                                                    <th>Status</th>
                                                                    <th>Order#</th>
                                                                    <th>Order Time</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    this.state.orderHistory.map((item, i) => {
                                                                        var pairName = item.pairName ? item.pairName : '';
                                                                        var quantity = item.quantity ? item.quantity : 0;
                                                                        var price = item.quantity ? item.price : 0;
                                                                        var filledAmount = item.filledAmount ? item.filledAmount : 0;
                                                                        var orderValue = item.orderValue ? item.orderValue : 0;
                                                                        var orderType = item.orderType ? item.orderType : 0;
                                                                        var orderDate = item.orderDate ? item.orderDate : 0;
                                                                        var classs = (quantity > 0) ? 'greenText' : 'pinkText';
                                                                        var _id = item._id ? item._id : 0;
                                                                        var status = item.status == '0' ? 'New' : 'Partial';
                                                                        var Remaining = parseFloat(quantity) - parseFloat(filledAmount);
                                                                        // //console.log(orderDate,'orderDate');
                                                                        var data1 = new Date(orderDate);
                                                                        let date12 = data1.getFullYear() + '-' + (data1.getMonth() + 1) + '-' + data1.getDate() + ' ' + data1.getHours() + ':' + data1.getMinutes() + ':' + data1.getSeconds();


                                                                        // //console.log(date12,'datezxc');
                                                                        return <tr>
                                                                            <td className="yellowText">{pairName}</td>
                                                                            <td><span className={classs}>{parseFloat(quantity).toFixed(8)}</span></td>
                                                                            <td>{parseFloat(price).toFixed(this.state.floating)}</td>
                                                                            <td>{parseFloat(filledAmount).toFixed(8) + '/' + parseFloat(Remaining).toFixed(8)}</td>
                                                                            <td>{parseFloat(orderValue).toFixed(8)}</td>
                                                                            <td>{'-/-'}</td>
                                                                            <td>{orderType}</td>
                                                                            <td>{status}</td>
                                                                            <td>{_id}</td>
                                                                            <td>{date12}</td>
                                                                            <td>
                                                                                <button className="btn btn-borderButton mr-1" onClick={this.cancelFunction.bind(this)} id={_id}> Cancel </button>
                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </Scrollbars>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        );
    }
}

PendingHistory.propTypes = {
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
});
export default connect(mapStateToProps)(withRouter(PendingHistory));
