
/** 
 * Calculate order cost
*/
export const inverseOrderCost = ({ price, quantity, leverage, takerFee, buyorsell }) => {
    console.log("inverseOrderCost",buyorsell);
    try {
        price = parseFloat(price)
        quantity = parseFloat(quantity)
        leverage = parseFloat(leverage)
        takerFee = parseFloat(takerFee)

        return (
            parseFloat(
                inverseInitialMargin({ price, quantity, leverage })
                +
                inverseFeeToOpen({ price, quantity, takerFee })
                +
                inverseFeeToClose({ price, quantity, leverage, takerFee, buyorsell })
            )
            
        )
    } catch (err) {
        return 0
    }
}

export const inverseInitialMargin = ({ price, quantity, leverage }) => {
    try {
        price = parseFloat(price)
        quantity = parseFloat(quantity)
        leverage = parseFloat(leverage)

        return quantity / (price * leverage)
    } catch (err) {
        return 0
    }
}

export const inverseFeeToOpen = ({ price, quantity, takerFee }) => {
    try {
        price = parseFloat(price)
        quantity = parseFloat(quantity)
        takerFee = parseFloat(takerFee)

        return (quantity / price) * (takerFee / 100)
    } catch (err) {
        return 0
    }
}

export const inverseFeeToClose = ({ price, quantity, leverage, takerFee, buyorsell }) => {
    try {
        price = parseFloat(price)
        quantity = parseFloat(quantity)
        takerFee = parseFloat(takerFee)

        return (quantity / inverseBankrupty({ price, leverage, buyorsell })) * (takerFee / 100)
    } catch (err) {
        return 0
    }
}

export const inverseBankrupty = ({ price, leverage, buyorsell }) => {
    try {
        price = parseFloat(price)
        leverage = parseFloat(leverage)

        if (buyorsell == 'buy') {
            return price * (leverage / (leverage + 1))
        } else if (buyorsell == 'sell') {
            return price * (leverage / (leverage - 1))
        }
        return 0
    } catch (err) {
        return 0
    }
}

export const unrealizedProfitLoss = ({ price, quantity, lastPrice, buyorsell }) => {
    try {
        console.log("eee",price, quantity, lastPrice,"buyorsell --> ",buyorsell);
        price = parseFloat(price)
        quantity = parseFloat(quantity)
        lastPrice = parseFloat(lastPrice)
        if(price == 0){
            return 0
        }
        else if (buyorsell == 'buy') {
            return quantity * (1/lastPrice - 1/price)
        } else if (buyorsell == 'sell') {
            return quantity * (1/price - 1/lastPrice)
        }
    } catch (err) {
        return 0
    }
}

export const unrealizedPnLPercentage = ({ price, quantity, lastPrice, leverage, takerFee, buyorsell }) => {
    try {
        price = parseFloat(price)
        quantity = parseFloat(quantity)
        lastPrice = parseFloat(lastPrice)
        leverage = parseFloat(leverage)
        takerFee = parseFloat(takerFee)
        console.log("-----unrealizedProfitLoss({ price, quantity, lastPrice, buyorsell })",unrealizedProfitLoss({ price, quantity, lastPrice, buyorsell }))
        console.log("-------inversePositionMargin({ price, quantity, leverage, takerFee, buyorsell })",inversePositionMargin({ price, quantity, leverage, takerFee, buyorsell }))
        return (unrealizedProfitLoss({ price, quantity, lastPrice, buyorsell }) / inversePositionMargin({ price, quantity, leverage, takerFee, buyorsell })) * 100
    } catch (err) {
        return 0
    }
}

export const inversePositionMargin = ({ price, quantity, leverage, takerFee, buyorsell }) => {
    try {
        price = parseFloat(price)
        quantity = parseFloat(quantity)
        leverage = parseFloat(leverage)
        takerFee = parseFloat(takerFee)

        return (
            inverseInitialMargin({ price, quantity, leverage })
            +
            inverseFeeToClose({ price, quantity, leverage, takerFee, buyorsell })
        )
    } catch (err) {
        return 0
    }
}
