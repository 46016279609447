import React, { Component } from 'react'
// import { useHistory } from "react-router-dom";

import Logo from "../images/logo.png"
import LogoNewFixHead from "../images/logo.png"
import LogoLightTheme from "../images/logodark.png";
import { Link, withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../actions/authActions";
import { store } from 'react-notifications-component';
import { withTranslation } from 'react-i18next';
import { Languagechange } from './i18next/i18n';
class NavbarNew extends Component {
  
  constructor(props){
    super(props);
    this.state = {
      isTop: true,
      show:false,
      showone:false
     
    };
    this.showNavbarone = this.showNavbarone.bind(this);
    this.showNavbar = this.showNavbar.bind(this);
    console.log(props,"navebar")
  }
  
 showNavbar()
 {
   this.setState({show:!this.state.show});
 }

 showNavbarone()
 {
   this.setState({showone:!this.state.showone});
 }

  onLogoutClick = e => {
    const {t} = this.props;
    console.log("logour ues");
    e.preventDefault();
    store.addNotification({
      title: t("LOGOUT_SUCCESS"),
      message: t("COME_JOIN"),
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
   let  data ={
      userId:this.props.auth.user.id
    }
    this.props.logoutUser(data);
  };

  componentDidMount() {
    console.log(this.props,"navebar")
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 15;
      if (isTop !== this.state.isTop) {
        this.setState({ isTop })
      }
    });
    if(!localStorage.getItem('curpair')||localStorage.getItem('curpair')=='null')
    {
      localStorage.setItem('curpair','BTC-USDT');
      this.setState({navpair:'BTC-USDT'});
    }
    else
    {
      this.setState({navpair:localStorage.getItem('curpair')});
    }

    if(!localStorage.getItem('curpair1')||localStorage.getItem('curpair1')=='null')
    {
      localStorage.setItem('curpair1','BTC-USDT');
      this.setState({navpair:'BTC-USDT'});
    }
    else
    {
      this.setState({navpair:localStorage.getItem('curpair1')});
    }
  }

  render() {
    const themechange = () =>{
      if(document.getElementById("root").classList.contains("lighttheme")){
        document.getElementById("root").classList.add("darktheme")
        document.getElementById("root").classList.remove("lighttheme");
        document.getElementById("modaltheme").classList.add("darkmodal")
        document.getElementById("modaltheme").classList.remove("lightmodal");
        this.setState({theme:false})
        // this.state.theme === false
      }else{
        document.getElementById("root").classList.remove("darktheme")
        document.getElementById("root").classList.add("lighttheme")
        document.getElementById("modaltheme").classList.add("lightmodal")
        document.getElementById("modaltheme").classList.remove("darkmodal");
        // this.state.theme === true
        this.setState({theme:true})

      }
     
    }
    const { user,getUser } = this.props.auth;

    let spotUrl = "/spot/BTC-USDT"
    if (localStorage.getItem('curpair1')) {
      spotUrl = "/spot/" + localStorage.getItem('curpair1')
    }
    const {t} = this.props;
    return (
      <div>
        {(typeof this.props.auth.user.id != 'undefined') ? (

          <nav className={this.state.isTop ? "navbar navbar-expand-lg customNavTrade  wow fadeIn px-0 fixed-top px-0 py-2" : "navbar navbar-expand-lg customNavTrade wow fadeIn px-0 fixed-top px-0 py-2"}>
            <div className="container-fluid px-2 px-lg-3">
              {/* <Link to="/" className="navbar-brand home_logo"><img src={Logo} alt="Bell Exchange" /></Link> */}
              <a href="/" target = "_blank">
                <img src={Logo} alt="Bell Exchange" className='logomob'/>
                </a>
              <button className="navbar-toggler customNavbarToggler" type="button" onClick={this.showNavbar}>
                <i className="fas fa-bars"></i>
              </button>
              <div className={this.state.show?"collapse navbar-collapse show collapse_menu":"collapse navbar-collapse collapse_menu"} id="navbarResponsive">
                <ul className="navbar-nav ml-auto mr-3">
                  {/* <li className="nav-item active">
                    <Link to="/login" className="nav-link">Earn</Link>
                  </li> */}

                  {/* <li className="nav-item active">
                    <Link to={ "/spot/"+localStorage.getItem('curpair1')} className="nav-link">Spot</Link>
                  </li>

                  <li className="nav-item active">
                    <Link to={ "/trade/"+localStorage.getItem('curpair')} className="nav-link">Derivatives</Link>
                   <Link to="#" className="nav-link">Derivatives <sup>Coming Soon!</sup>   </Link>
                      
                  </li> */}
                  <li className="nav-item dropdown dmenu mr-2">
                  <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                 {t("TRADE")} 
                  </a>
                  {console.log('localStorage.getIte',localStorage.getItem( 'curpair1'))}
                  <div className="dropdown-menu sm-menu menu_default_show">
                   <Link to={ "/spot/"+localStorage.getItem( 'curpair1')} className="dropdown-item nav-link">{t("SPOT")}</Link>
                   <Link to={ "/trade/"+localStorage.getItem( 'curpair')} className="dropdown-item nav-link">{t("DERIVATIVES")}</Link>
                  </div>
              </li>
                <li className="nav-item active mb-2 mb-lg-0">
                    <Link to="/Launchpad" className="nav-link">{t("LAUNCHPAD")}</Link>
                  </li> 
                  {/* <li className="nav-item">
                    <Link to="/copy-trading" className="nav-link">Copy Trading</Link>
                    <Link to="#" className="nav-link">Copy Trading <sup>Coming Soon!</sup> </Link>
                    
                  </li> */}

                  {/* <li className="nav-item active">
                    <Link to="/blog" className="nav-link">Blog</Link>
                  </li> */}
                  {/* <li className="nav-item dropdown dmenu">
                    <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">Market</a>
                    <div className="dropdown-menu sm-menu">
                      <Link to={ "/spot/"+localStorage.getItem( 'curpair1')} className="dropdown-item nav-link">Spot</Link>
                      <Link to={ "/trade/"+localStorage.getItem( 'curpair')} className="dropdown-item nav-link">Derivatives</Link>

                    </div>
                  </li> */}

                  {/* <li className="nav-item active">
                    <Link to="/p2p" className="nav-link">P2P</Link>
                  </li>

                 <li className="nav-item active">
                    <Link to="/Launchpad" className="nav-link">LaunchPad</Link>
                  </li> */}

                  
                  {/* <li className="nav-item dropdown dmenu">
                    <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">Crypto Games</a>
                    <div className="dropdown-menu sm-menu">
                      <a href="https://jkc-games.com/" target="_blank" className="dropdown-item nav-link">Junket Games</a>
                      <a href="https://time-pusher.com/" target="_blank" className="dropdown-item nav-link">Time-Pusher</a>
                    </div>
                  </li>
                  <li className="nav-item dropdown dmenu">
                    <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">Earn</a>
                    <div className="dropdown-menu sm-menu">
                      <Link to={ "/Staking/"} className="dropdown-item nav-link">Staking</Link>
                    
                    </div>
                  </li>

                  <li className="nav-item active">
                    <Link to="/about" className="nav-link">About</Link>
                  </li> */}
                  { /* <li className="nav-item ">
                    <Link to="/blog" className="nav-link">Blog</Link>
                  </li> */ }                  
             
                <ul className="navbar-nav">
                  <li className="nav-item active menu_right_after_login">
                    <Link to="/MyAssets" className="nav-link">{t("WALLET")}</Link>
                  </li>

                  <li className="nav-item dropdown dmenu mr-2 ml-lg-3">
                  <a className="nav-link dropdown-toggle mobile" href="#" id="navbardrop" data-toggle="dropdown">
                  {/* {t("LANGUAGE")}  */}
                  </a>
                  <div className="dropdown-menu sm-menu menu_default_show">
                   <p onClick={()=>{Languagechange('en')}}className="dropdown-item nav-link">{t("ENGLISH")}</p>
                   <p onClick={()=>{Languagechange('sp')}}className="dropdown-item nav-link">{t("SPANISH")}</p>
                  </div>
              </li>
                  {/* <li className="nav-item dropdown dmenu mr-2 menu_not_hide_mob">
                    <a className="nav-link dropdown-toggle navbardrop_pos menu_default_hide menu_not_hide" href="#" id="navbardrop1" data-toggle="dropdown">
                     <div className='noti_hader'>
                     <i className='fa fa-bell'></i>
                     <span className='noti_count_bue'>16</span>

                     </div>
                    </a>
                    <div className="dropdown-menu sm-menu menu_default_show dropdown_menu_notif_md">
                    <div className="notificationDropdown noti_child_po" tabindex="0" autofocus="">
    <div className="text-right">
        <button className="mark_read_link">Mark all as read </button>
    </div>
    <ul>
        <li>
            <p>43d ago</p>
            <h5>Login Successfully</h5>
        </li>
        <li>
            <p>43d ago</p>
            <h5>Login Successfully</h5>
        </li>
        <li>
            <p>43d ago</p>
            <h5>Login Successfully</h5>
        </li>
        <li>
            <p>43d ago</p>
            <h5>Login Successfully</h5>
        </li>
        <li>
            <p>43d ago</p>
            <h5>Login Successfully</h5>
        </li>
        <li>
            <p>42d ago</p>
            <h5>Login Successfully</h5>
        </li>
        <li>
            <p>42d ago</p>
            <h5>Login Successfully</h5>
        </li>
      </ul>
      
           

            <p className='mb-0 pb-3 pt-3'>
          <a className="all_noti_link_green" href="/notification">All Notifications</a>
        </p>
    </div>
                                         
                    
                    </div>
                  </li> */}

                  {/* <li className="nav-item px-0 menu_show_mobile_onlye">
                    <Link to="/notification" className="nav-link">Notifications</Link>
                  </li> */}

                  {getUser &&
                  <li className="nav-item dropdown dmenu mr-2">
                    <a className="nav-link dropdown-toggle navbardrop_pos menu_default_hide" href="#" id="navbardrop" data-toggle="dropdown">
                      {
                        getUser && getUser.name==''?
                        (user.email.split('@')[0].length<8)?user.email.split('@')[0]:user.email.split('@')[0].substring(0, 7)
                        :getUser && getUser.name
                      }
                      {/* {( user.email != 'undefined')?(user.email.split('@')[0].length<8)?user.email.split('@')[0]:user.email.split('@')[0].substring(0, 7):''} */}
                    </a>
                    <div className="dropdown-menu sm-menu menu_default_show">
                      <Link to="/Settings" className="dropdown-item nav-link">{t("SETTINGS")}</Link>
                      <Link to="/AssetsHistory" className="dropdown-item nav-link">{t("HISTORY")}</Link>
                      {/* <Link to="/Referral_Program" className="dropdown-item nav-link">Referral Program</Link> */}
                                         
                      <a className="dropdown-item nav-link" onClick={this.onLogoutClick} href="#">{t("LOGOUT")}</a>
                    </div>
                  </li>
                   
                  }
                  {/*<li className="nav-item" >
                                                        <select className="custom-select form-control lang_select">
                                                          <option selected>English</option>
                                                          <option>한국어</option>
                                                          <option>Pусский</option>
                                                          <option>Indonesia</option>
                                                          <option>Português</option>
                                                          <option>Tiếng Việt</option>
                                                        </select>
                                                      </li>*/} 
                                  {/* <ul id="root_gt"> */}
                                                 {/*       <li><a href="#">English</a></li>
                                              <li><a href="#">French</a></li>
                                              <li><a href="#">Spanish</a></li>
                                              <li><a href="#">Russian</a></li>
                                              <li><a href="#">Portugese</a></li>
                                              <li><a href="#">Mandarin</a></li> */}
                                                    {/* </ul> */}
                </ul>
                </ul>
                
              </div>
            </div>
          </nav>
        ) : (
          <nav className={this.state.isTop ? "navbar navbar-expand-lg customNavTrade wow fadeIn fixed-top px-0 py-2" : "navbar navbar-expand-lg customNavTrade wow fadeIn fixed-top px-0 py-2"}>
            <div className="container-fluid px-2 px-lg-3">
              {/* <Link to="/" className="navbar-brand home_logo">
                <img src={Logo} alt="Bell Exchange" />
                </Link> */}
                <Link to="/" className="navbar-brand innerLogo">
        <img className="top_head img-fluid logoDark" src={LogoNewFixHead} />
        <img className="top_head img-fluid logoLight" src={LogoLightTheme} />
        </Link>
              <button className="navbar-toggler customNavbarToggler" type="button" onClick={this.showNavbarone}>
                <i className="fas fa-bars"></i>
              </button>
                <div className={this.state.showone ? "collapse navbar-collapse show collapse_menu" : "collapse navbar-collapse collapse_menu"} id="navbarResponsive">
                  <ul className="navbar-nav ml-auto">
                    {localStorage.getItem('jwtToken') ?
                      <>
                        <li className="nav-item dropdown dmenu mr-2">
                          <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                          {t("TRADE")} 
                          </a>
                          <div className="dropdown-menu sm-menu menu_default_show">
                            <Link to={"/spot/" + localStorage.getItem('curpair1')} className="dropdown-item nav-link">{t("SPOT")}</Link>
                            <Link to={"/trade/" + localStorage.getItem('curpair')} className="dropdown-item nav-link">{t("DERIVATIVES")}</Link>
                          </div>
                        </li>
                        <li className="nav-item mb-2 mb-lg-0">
                          <Link to="/launchpad" className="nav-link">{t("LAUNCHPAD")}</Link>
                        </li>
                      </> :
                      <> </>
                    }

{/* 
                  <li className="nav-item active">
                    <Link to="/login" className="nav-link">Earn</Link>
                  </li> */}

                  {/* <li className="nav-item active">
                    <Link to={ "/spot/"+localStorage.getItem( 'curpair1')} className="nav-link">Spot</Link>
                  </li>

                  <li className="nav-item active">
                    <Link to={ "/trade/"+localStorage.getItem( 'curpair')} className="nav-link">Derivatives</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/copy-trading" className="nav-link">Copy Trading</Link>
                  </li> */}

                  {/* <li className="nav-item active">
                    <Link to="/blog" className="nav-link">Blog</Link>
                  </li> */}

                  {/* <li className="nav-item active">
                    <Link to="/Launchpad" className="nav-link">LaunchPad</Link>
                  </li>

                   <li className="nav-item dropdown dmenu">
                    <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">Earn</a>
                    <div className="dropdown-menu sm-menu">
                      <Link to={ "/Staking/"} className="dropdown-item nav-link">Staking</Link>

                    </div>
                  </li> */}
                  {/* <li className="nav-item dropdown dmenu">
                    <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">Crypto Games</a>
                    <div className="dropdown-menu sm-menu">
                      <a href="https://jkc-games.com/" target="_blank" className="dropdown-item nav-link">Junket Games</a>
                      <a href="https://time-pusher.com/" target="_blank" className="dropdown-item nav-link">Time-Pusher</a>

                    </div>
                  </li> */}
                  { /* <li className="nav-item ">
                    <Link to="/blog" className="nav-link">Blog</Link>
                  </li> */ }
                  {/* <li className="nav-item active">
                    <Link to="/about" className="nav-link">About</Link>
                  </li> */}
             
                <ul className="menu_right navbar-nav mt-lg-0 mt-2">
                <li className="nav-item ml-2 mb-lg-0 mb-2">
                  <Link to="/login" className="nav-link login_btn">{t("SIGN_IN")} <i className="fa fa-user pl-1" aria-hidden="true"></i></Link>
                </li>
                {/* <li className="nav-item ml-2 mb-lg-0 mb-2">
                  <Link to="/register" className="nav-link register_btn">{t("REGISTER")} <i className="fa fa-user pl-1" aria-hidden="true"></i></Link>
                </li> */}
                  {/* <li className="nav-item dropdown dmenu mr-2  ml-lg-3">
                  <a className="nav-link dropdown-toggle mobile pl-0" href="#" id="navbardrop" data-toggle="dropdown">
                  {localStorage.getItem('lang') == 'en' || localStorage.getItem('lang') == '' ? 'ENGLISH' : 'español'} 
                  </a>
                  <div className="dropdown-menu sm-menu menu_default_show">
                   <p onClick={()=>{Languagechange('en')}}className="dropdown-item nav-link">{t("ENGLISH")}</p>
                   <p onClick={()=>{Languagechange('sp')}}className="dropdown-item nav-link">{t("SPANISH")}</p>
                  </div>
              </li> */}

              <li className="nav-item dropdown dmenu mr-2  ml-lg-3">
                  <a className="nav-link dropdown-toggle mobile pl-0" href="#" id="navbardrop" data-toggle="dropdown">
                  {/* {t("LANGUAGE")}  */}
                  {localStorage.getItem('lang') == 'en' || localStorage.getItem('lang') == '' ? 'ENGLISH' : 'español'}
                  </a>
                  <div className="dropdown-menu sm-menu menu_default_show">
                   <a onClick={()=>{Languagechange('en')}} className="dropdown-item nav-link">English</a>
                   <a onClick={()=>{Languagechange('sp')}} className="dropdown-item nav-link">español</a>
                  </div>
              </li>


                  <li>
                  <button className='transbtn theme' onClick={() => themechange()}><span className={this.state.theme ? 'fa fa-moon' : 'fa fa-sun'} ></span></button>
                </li>
                  {/* <li className="nav-item px-0"> */}
                    {/* <select className="custom-select form-control lang_select">
                      <option selected>English</option>
                      <option>한국어</option>
                      <option>Pусский</option>
                      <option>Indonesia</option>
                      <option>Português</option>
                      <option>Tiếng Việt</option>
                    </select> */}
                    {/* <ul id="root_gt" >
                    </ul> */}
                  {/* </li>                   */}
                </ul>
                </ul>
                {/* <button className="btn auth_btn"><Link to="/login">Login</Link></button>
                <button className="btn auth_btn"><Link to="/register">Create an Account</Link></button> */}
              </div>
            </div>
          </nav>
        )}

      </div>
    )
  }
}

NavbarNew.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(
  mapStateToProps, { logoutUser }
)(withTranslation()(NavbarNew),withRouter(NavbarNew));
