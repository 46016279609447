import React, { Component, useEffect } from 'react'
import ReactDOM from 'react-dom';
import { store } from "react-notifications-component";
import axios from "axios";
import { Link, withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import keys from "../actions/config";
import Navbar from './Navbar'
import Footer from './Footer'
import ReactHtmlParser from 'react-html-parser';
import { Launchpadbuy } from "../actions/authActions";
import { toast } from 'react-toastify';
import $ from 'jquery';
import { withTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import clsx from 'classnames'
import Navtradebar from './Navtradebar';
var dateFormat = require('dateformat');

const url = keys.baseUrl;
const adminurl = keys.imageUrl;
const fronturl = keys.refurl;
class LaunchpadDetails extends Component {
  constructor(props) {

    super(props);

    this.state = {
      launchpaddetails: [],
      launchpadhistory: [],
      amount: 0,
      price: 0,
      amterror: '',
      accept: false,
      checkboxerr: false,
      verifiedstatus: '',
      minimumAmt: '',
      maxsupply: '',
      percentage: 0,
      currency: [],
      coin: "",
      priceConversion: [],
      launchCoin: "",
      total: 0,
      quantity: 0,
      discount: 0,
      walletData: [],
      asset: {},
      currencyId: ""
    }

    // this.Launchpaddata = this.Launchpaddata.bind(this)
    //this.checkbox = this.checkbox.bind(this);
    // this.calculation= this.calculation.bind(this)
    // this.userDetails= this.userDetails.bind(this)
  }

  onChange = e => {
    console.log(e.target.value)
    if (e.target.value == 0) {
      this.setState({ amterror: 'Field is required' })
    } else {
      this.setState({ amterror: '' })
      this.setState({ [e.target.id]: e.target.value });
    }
    // const {amount,price,} = this.state
    // this.calculation(this.state)
  };

  withoutServiceFee = ({
    price,
    serviceFee
  }) => {
    price = parseFloat(price)
    serviceFee = parseFloat(serviceFee)
    return price - (price * (serviceFee / 100))
  }

  calculation(price, quantity, coin, type = 'price') {
    console.log("Calculation", price, quantity, coin, type, this.state.launchCoin, this.state.priceConversion.length > 0);
    if (type == 'price' && this.state.priceConversion.length > 0) {
      let conversionData = this.state.priceConversion.find(el => el.baseSymbol == coin && el.convertSymbol == this.state.launchCoin);
      console.log("conversionData", conversionData)
      if (conversionData) {
        price = price / conversionData.convertPrice
        console.log("price", price, quantity)

        this.setState({ price: price, total: price * quantity, coin: coin, quantity: quantity })

        if (this.state.launchpaddetails[0].discount != null || this.state.launchpaddetails[0].discount > 0 || this.state.launchpaddetails[0].discount != undefined) {
          console.log("calculateDiscount", this.state.launchpaddetails[0].discount, "total", this.state.total);
          const discountcalculate = this.withoutServiceFee({
            'price': price * quantity,
            'serviceFee': this.state.launchpaddetails[0].discount
          })
          console.log("discountcalculate", discountcalculate);
          this.setState({ total: discountcalculate, youpay: discountcalculate })
        }
      }
    }
    else if (type == 'quantity' && this.state.price > 0) {
      this.setState({ total: price * quantity, coin: coin, quantity: quantity })
      if (this.state.launchpaddetails[0].discount != null || this.state.launchpaddetails[0].discount > 0 || this.state.launchpaddetails[0].discount != undefined) {
        const discountcalculate = this.withoutServiceFee({
          'price': price * quantity,
          'serviceFee': this.state.launchpaddetails[0].discount
        })
        console.log("discountcalculate", discountcalculate);
        this.setState({ total: discountcalculate, youpay: discountcalculate })
      }
    }
  }


  async complete(data) {
    console.log("complete:", data);
    let userid = this.props.auth.user.id;
    let launchedid = this.props.match.params.id;
    if (userid != undefined) {
      if (this.state.accept == false || this.state.accept == 'false') {
        this.setState({ checkboxerr: 'Terms and Condition is required' })
      } else {
        if (data.availablesale != 0) {
          if (this.state.quantity >= data.minAmt) {
            if (this.state.price != "" || this.state.price != 0) {
              const launchPad = {
                userId: userid,
                quantity: this.state.quantity,
                total: this.state.total,
                launchId: launchedid,
                sendCurrencyId: this.state.currencyId,
                price: this.state.price
              }
              const buylaunchpadSale = await axios.post(url + "api/launchpadbuy", launchPad);
              if (buylaunchpadSale.data.status == "true" || buylaunchpadSale.data.status == true) {
                store.addNotification({
                  title: "",
                  message: "Token purchased successfully",
                  type: "success",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animated", "fadeIn"],
                  animationOut: ["animated", "fadeOut"],
                  dismiss: {
                    duration: 5000,
                    onScreen: true
                  }
                });
              }
              console.log("buylaunchpadSale", buylaunchpadSale)
            } else {
              store.addNotification({
                title: "",
                message: "Price should not be empty",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 5000,
                  onScreen: true
                }
              });
            }
          } else {
            store.addNotification({
              title: "",
              message: "Quantity is lesser than minimim purchase",
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true
              }
            });
          }
        }
        else {
          store.addNotification({
            title: "",
            message: "Available sale is empty",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          })
        }
      }

    } else {
      this.props.history.push("/Login");
    }
  }
    componentDidMount() {
      this.getData();
      this.Launchpaddata();
      this.getMyTrade();
      this.ScrollToTopOnMount();

    }

  async getData() {

      const id = this.props.auth.user.id;
      axios
        .get(url + "api/userget/" + id)
        .then((res) => {
          this.setState({ verifiedstatus: res.verifiedstatus })
          this.setState(res.data);
        })
        .catch();
      const priceConversionData = await axios.get(url + "api/getPriceConversion");
      this.setState({
        priceConversion: priceConversionData.data,
      });
      await this.Launchpaddata()
      // console.log(",walletDetailsData",walletDetailsData);
      // console.log("USERDETAILS_DATA", priceConversionData.data, walletDetailsData.data);
    }

 async Launchpaddata() {
      try {
        let userid = this.props.auth.user.id;
        let pairdata = { exchangetype: "Spot" }
        let launchedid = this.props.match.params.id;
        let time = { timeStamp: "current" }
        console.log("launchedid", launchedid, userid, pairdata)
        await axios
          .post(url + "api/launchpad-data/" + launchedid, time)
          .then(async (res) => {
            if (res.status) {
              console.log("+++++++++++++++++_LaunchpaddataLaunchpaddata", res.data)
              this.setState({ launchpaddetails: res?.data?.data, currency: res?.data?.currency })
              let currenyData = res.data.currency.find((curId) => curId._id == res.data.data[0].availablecurrency[0])
              this.setState({ coin: currenyData.currencySymbol, launchCoin: res.data.data[0].launchCoin.currencySymbol, currencyId: currenyData._id, quantity: res.data.data[0].minAmt })
              if (res.data.data.length > 0) {
                await this.calculation(res.data.data[0].launchPrice, res.data.data[0].minAmt, currenyData.currencySymbol, 'price');
                this.handleAsset(currenyData.currencySymbol)
              } else {
                await this.calculation(res.data.data[0].launchPrice, res.data.data[0].minAmt, currenyData.currencySymbol, 'price');
                this.handleAsset(currenyData.currencySymbol)
              }
              //  res.data.data.map((data,i)=>{
              //  this.setState({price:data.launchPrice})
              //  this.setState({minimumAmt:data.minAmt})
              //  this.setState({maxsupply:data.maxsupply})
              // })
            } else {

            }
            // this.props.history.push("/Login");
          })

          .catch();
      } catch (e) {
        console.log("LaunchpadErrordata", e);
      }
    }



    componentWillReceiveProps(nextProps) {
      // console.log("helooooooooooooooooooooooooooooooooooo",nextProps.auth);
      if (nextProps?.errors) {
        this.setState({
          errors: nextProps.errors
        });
      }

      if (nextProps.auth !== undefined
        && nextProps.auth.LAUNCHPADDATA_ADD !== undefined
        && nextProps.auth.LAUNCHPADDATA_ADD.data !== undefined
        && nextProps.auth.LAUNCHPADDATA_ADD.data.retMsg !== undefined) {

        store.addNotification({
          title: "",
          message: nextProps.auth.LAUNCHPADDATA_ADD.data.retMsg,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        this.setState({
          amount: 0,
          price: '0',
          amterror: '',
          accept: false,
          checkboxerr: false,
          verifiedstatus: '',
          minimumAmt: '',
          maxsupply: '',
          percentage: ''

        });
        this.getData()
        this.Launchpaddata()
        this.getMyTrade();
      }
    }

    percentage(data) {
      if (data) {
        if (this.state.price > 0 && this.state.price != null && this.state.price != undefined) {
          let userBalance = this.state.asset.spotwallet > 0 ? this.state.asset.spotwallet : 0;
          let total = userBalance * (data / 100);
          let quantity = total / this.state.price;
          this.setState({ total: total, quantity: quantity })
        }
      }
    }

    handleAsset(coin){
      console.log("assetData coin", coin, this.state.walletData);
      if (this.state.walletData != null && this.state.walletData.length > 0) {
        let assetData = this.state.walletData.find(el => el.currencySymbol == coin);
        console.log("assetData", assetData);
        if (assetData) {
          this.setState({ asset: assetData })
        }
      }
    }

  async getMyTrade() {
      let userid = this.props.auth.user.id;
      const walletDetailsData = await axios.get(url + "api/walletDetails/" + userid);
      console.log("walletDetailsData", walletDetailsData.data);
      this.setState({ walletData: walletDetailsData.data })
      this.handleAsset(this.state.coin)
      console.log("userid_userid_userid", userid)
      axios
        .post(url + "api/launchpad-history/" + userid)
        .then((res) => {
          if (res.status) {
            console.log("++++++++++++_getMyTrade", res)
            this.setState({ launchpadhistory: res.data })
          } else {

          }
          // this.props.history.push("/Login");
        })
        .catch();
    }

    checkbox = e => {
      // if (e.target.checked != false) {
      //   this.setState({ accept: e.target.checked })
      // } else {
      //   this.setState({ checkboxerr: 'Terms and Condition is required' })

      // }
      this.setState({ accept: !this.state.accept })
    }

    Kyc() {
      let userid = this.props.auth.user.id;
      if (userid) {
        this.props.history.push("/Kyc");
      } else {
        this.props.history.push("/Login");
      }

    }

    ScrollToTopOnMount() {
      window.scrollTo(0, 0);
    }

    render() {
      const { user } = this.props.auth;
      const { currency } = this.state
      const {t} = this.props;
      return (
        <div>
          <header className="stackingList">
            <div className="container">
              {/* <Navbar /> */}
              <Navtradebar />
            </div>
          </header>
          <section className="launchPadMain">
            <div className="container">
              <div className="whiteBoxLaunchpad">
                {this.state.launchpaddetails.map((data, i) => {
                  return (
                    <div className="launchPadCoinDetails">
                      <div className="launchpadCoinName">
                        <img src={require("../images/launchpadIcon.png")} className="img-fluid" />
                        <h3>{data?.currencyId?.currencyName}<small>{t("BLOCKCHAIN_PLATFORM")}</small></h3>
                      </div>
                      {/* <h4>
                {(dateFormat(data?.startdate ? data?.startdate : new Date(), 'dd-mm-yyyy h:MM:ss TT'))} - {dateFormat(data?.enddate ? data?.enddate : new Date(), 'dd-mm-yyyy h:MM:ss TT')}
                </h4> */}
                    </div>
                  )
                })
                }
                
                {this.state.launchpaddetails && this.state.launchpaddetails.length > 0 && this.state.launchpaddetails.length > 0 && this.state.launchpaddetails.map((data, i) => {
                  return (
                    <div className="row">

                      <div className="col-md-6">
                        <div className="projectIntroduction">
                          <h3>{t("PROJECT_INTRODUCTION")}</h3>
                          <ul>
                            <li><span>{t("NAME")}</span> {data?.currencyId?.currencyName}</li>
                            <li><span>{t("INDUSTRY")}</span> {data?.industry}</li>
                            <li><span>{t("WEBSITE")}</span> {data?.website}</li>
                          </ul>
                          <p><strong className="text-dark">{data?.currencyId?.currencyName}:</strong>{
                            data && data.content &&
                            ReactHtmlParser(
                              data.content
                            )
                          }
                          </p>

                          <h3>{t("TOKEN_DETAILS")}</h3>
                          <ul>
                            <li><span>Name</span>{data?.currencyId?.currencyName}</li>
                            <li><span>Symbol</span>{data?.currencyId?.currencySymbol}</li>
                            <li><span>{t("TOKEN_SALE_START_DATE")}</span> {dateFormat(data?.startdate, 'dd-mm-yyyy h:MM:ss TT')}</li>
                            <li><span>{t("TOKEN_LAUNCH_PRICE")}</span> {data?.launchPrice} {data.launchCoin.currencyName}</li>
                            <li><span>{t("MINIMUM_PURCHASE_AMOUNT")}</span>{data?.minAmt}&nbsp;{data?.currencyId?.currencySymbol}</li>
                            <li><span>{t("ACCEPTED_CURRENCIES")}</span> {data?.availablecurrency.map((currencyId) => {
                              console.log(currency, 'Available Currency')
                              let currencyData = currency && currency.length > 0 ? currency?.find(el => el._id == currencyId) : "";
                              // console.log(currency, 'Available Currency')
                              return currencyData?.currencyName;
                            }).join(', ')}</li>
                            <li><span>{t("DISCOUNT")}</span> {data?.discount}%</li>
                            <li><span>{t("TAKE_AVAILABLE_TO_SALE")}</span> {data?.availablesale}</li>
                            <li><span>{t("TOKEN_MAX_SUPPLY")}</span> {data?.maxsupply}&nbsp;{data?.currencyId?.currencySymbol}</li>
                          </ul>
                          <h3 className='d-flex justify-content-between'>{t("DOCUMENTS")}<a href={`${keys.imageUrl}launchpad/${data.whitePaper}`} target="_blank" className="active">{t("Whitepaper")}</a></h3>
                          {/* <p className="project_docs">
                          <a href="#">Presentation</a><a href="#">Executive Summary</a>
                        </p> */}
                          {/* src={ this.state.attachment ? URL.createObjectURL(this.state.attachment) :`${keys.imageUrl}/profile_images/${this.state.profileurl}` || "" } */}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="whiteShadowBox sha_without_box">
                          <ul className="choose_coin_list">
                            {
                              data?.availablecurrency.map((currencyId) => {
                                let currencyData = currency && currency.length > 0 ? currency?.find(el => el._id == currencyId) : "";
                                return (
                                  <li><a href="#" className={clsx({ 'active': this.state.coin == currencyData.currencySymbol })}
                                    onClick={() => {
                                      this.setState({ coin: currencyData.currencySymbol, currencyId: currencyData._id });
                                      this.handleAsset(currencyData.currencySymbol)
                                      this.calculation(this.state.launchpaddetails[0].launchPrice, this.state.quantity, currencyData.currencySymbol, 'price')
                                    }}>{currencyData.currencyName}</a></li>
                                )
                              })}
                            {/* <li><a href="#" className="active">{data?.currencyId?.currencyName}</a></li>
                   <li><a href="#">ETH</a></li>
                   <li><a href="#">USDT</a></li> */}
                          </ul>
                          <div className="form-group">
                            <label>{t("PRICE")}</label>
                            <div className="input-group mb-3">
                              <input type="text" className="form-control" value={this.state.price} />
                              <div className="input-group-append"> <span className="input-group-text">{this.state.coin}</span></div>
                            </div>
                            <div className="btnGroupPercent">
                              <button className="btn btnPercent" onClick={() => this.percentage(25)}>25%</button>
                              <button className="btn btnPercent" onClick={() => this.percentage(50)}>50%</button>
                              <button className="btn btnPercent" onClick={() => this.percentage(75)}>75%</button>
                              <button className="btn btnPercent" onClick={() => this.percentage(100)}>100%</button>
                            </div>
                          </div>
                          <div className="form-group">
                            <label>{t("AMOUNT")} </label>
                            <div className="input-group mb-3">
                              <input type="text" className="form-control" id="quantity" placeholder={this.state.quantity} value={this.state.quantity} onChange={(e) => {
                                this.setState({ quantity: e.target.value })
                                this.calculation(this.state.price, e.target.value, this.state.coin, 'quantity')
                              }} />
                              <div className="input-group-append"> <span className="input-group-text">{data?.currencyId?.currencySymbol}</span></div>
                            </div>
                            <span className="text-danger">{this.state.amterror}</span>
                          </div>
                          <div className="form-group">
                            <label>{t("TOTAL")}</label>
                            <div className="input-group">
                              {this.state.percentage == '' &&
                                <input type="text" className="form-control" value={this.state.total} />
                              }
                              {this.state.percentage != '' &&
                                <input type="text" className="form-control" value={this.state.total} />
                              }

                              <div className="input-group-append"> <span className="input-group-text">{this.state.coin}</span></div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="custom-control custom-checkbox">
                              <label style={{ color: "red" }}>{t("BALANCE")} : {this.state.asset.spotwallet}</label>
                            </div>
                          </div>
                          <div className="form-group">
                            <label>You pay</label>
                            <div className="input-group">
                              {this.state.percentage == "" &&
                                <input type="text" className="form-control" value={this.state.total} />
                              }

                              {this.state.percentage != "" &&
                                <input type="text" className="form-control" value={(this.state.percentage * this.state.amount) - ((this.state.percentage * this.state.amount) * data.discount / 100)} />
                              }
                              <div className="input-group-append"> <span className="input-group-text">{data?.currencyId?.currencySymbol}</span></div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="custom-control custom-checkbox">
                              {console.log("this.state.accept", this.state.accept)}
                              <input type="checkbox" className="custom-control-input" id="accept" name="accept" value={this.state.accept} onChange={this.checkbox} />
                              <label className="custom-control-label" htmlFor="accept">{t("I_AM_NOT_CITIZEN")}.</label>
                            </div>
                            <span className="text-danger">{this.state.accept == false ? this.state.checkboxerr : ""}</span>
                          </div>

                          <div className="text-center">
                            <button className="btn btnBuyKSC" onClick={() => this.complete(data)}>{t("BUY")}</button>
                            <button className="btn btnBuyKSC" onClick={() => this.Kyc()}>{t("COMPLETE_KYC")}</button>
                          </div>
                        </div>
                    
                      
                      </div>
                      <div className='col-md-12'>
                      <div className="whiteShadowBox">
                          <h3>{t("MY_TRADES")}</h3>
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>{t("DATE")}</th>
                                  <th>{t("Send_Coin")}</th>
                                  <th>{t("PRICE")}</th>
                                  <th>{t("QUANTITY")}</th>
                                  <th>{t("DISCOUNT")}(%)</th>
                                  <th>{t("TOTAL_AMOUNT")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.launchpadhistory.map((data, i) => {
                                  console.log("datavvv", data)
                                  return (
                                    <tr>
                                      <td>{dateFormat(data.created_date, 'dd-mm-yyyy h:MM:ss TT')}</td>
                                      <td>{data.sendCoin}</td>
                                      <td>{data.price}</td>
                                      <td>{data.quantity}</td>
                                      <td>{data.discount}</td>
                                      <td>{data.youpay}</td>
                                    </tr>
                                  )
                                })
                                }
                              </tbody>

                            </table>
                          </div>

                          <div className="socialMediaCoinDetails">
                          <h3>{t("SOCIAL_MEDIA")}</h3>
                          <ul>
                            <li><a href=""><i className="fab fa-telegram-plane"></i></a></li>
                            <li><a href=""><i className="fab fa-twitter"></i></a></li>
                            <li><a href=""><i className="fab fa-facebook-f"></i></a></li>
                            <li><a href=""><i className="fab fa-youtube"></i></a></li>
                            <li><a href=""><i className="fab fa-linkedin-in"></i></a></li>
                          </ul>
                        </div>
                        </div>
                        </div>
                      <div className="col-md-12">
                        <div className="cdTeamDetail">
                          <h3>{t("TEAM")}</h3>
                          <ul>
                            <li>
                              <div className="cdTeamProfilePic">
                                <img src={require("../images/teamMemberPic.jpg")} className="img-fluid" />
                              </div>
                              <div className="cdTeamProfileDetail">
                                <h6>{t("BRIJESH")}</h6>
                                <p>{t("CEO")}</p>
                                <p><a href="#"><i className="fab fa-linkedin-in"></i></a></p>
                              </div>
                            </li>
                            <li>
                              <div className="cdTeamProfilePic">
                                <img src={require("../images/teamMemberPic.jpg")} className="img-fluid" />
                              </div>
                              <div className="cdTeamProfileDetail">
                                <h6>{t("BRIJESH")}</h6>
                                <p>{t("CEO")}</p>
                                <p><a href="#"><i className="fab fa-linkedin-in"></i></a></p>
                              </div>
                            </li>
                            <li>
                              <div className="cdTeamProfilePic">
                                <img src={require("../images/teamMemberPic.jpg")} className="img-fluid" />
                              </div>
                              <div className="cdTeamProfileDetail">
                                <h6>{t("BRIJESH")}</h6>
                                <p>{t("CEO")}</p>
                                <p><a href="#"><i className="fab fa-linkedin-in"></i></a></p>
                              </div>
                            </li>
                            <li>
                              <div className="cdTeamProfilePic">
                                <img src={require("../images/teamMemberPic.jpg")} className="img-fluid" />
                              </div>
                              <div className="cdTeamProfileDetail">
                                <h6>{t("BRIJESH")}</h6>
                                <p>{t("CEO")}</p>
                                <p><a href="#"><i className="fab fa-linkedin-in"></i></a></p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>

                    </div>
                  )
                })
                }
              </div>
            </div>
          </section>

          <Footer />
        </div>
      );
    }
  }

LaunchpadDetails.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
  };
  const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
  });
export default connect(
    mapStateToProps,
    {Launchpadbuy}
)(withTranslation()(LaunchpadDetails),withRouter(LaunchpadDetails));
