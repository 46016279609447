import React, { Component } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import ReactDOM from 'react-dom';
import { store } from "react-notifications-component";
import axios from "axios";
import {Link,withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import * as moment from "moment";

import blogfullimg from "../images/blogfullimg.jpg"
import blogthumb1 from "../images/blogthumb1.jpg"
import blogthumb2 from "../images/blogthumb2.jpg"
import blogthumb3 from "../images/blogthumb3.jpg"
import { withTranslation } from 'react-i18next';
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from "react-html-parser";
import keys from "../actions/config";
const url = keys.baseUrl;
const imageUrl=keys.imageUrl

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

class BlogCategoryListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articledata: [],
      subcatdata: [],
      articlesub:"",
      createddate:"",
      content:"",
      articleimage:"",
      catname:"",
      nextcontent:[]
    };
  }
  componentDidMount() {
    this.getData();
    this.getnextList();
  }
  getData() {
    const articleid = this.props.match.params.aid;
    axios
      .get(url + "api/article/" + articleid)
      .then(result => {
      // //console.log("ressultt",result.data);

        this.setState({ articlesub:result.data.Articlename, createddate:result.data.createdDate,content:result.data.content,
          catname:result.data.maincategoryId.categoryName,articleimage:result.data.article_image,catid:result.data._id});
      })
      .catch();
  }
  getnextList() {
      const articleid = this.props.match.params.aid;
      axios
        .post(url + "api/nextarticle/" + articleid)
        .then(result => {
        // //console.log("nextcontent",result.data);
          this.setState({nextcontent:result.data });
        })
        .catch();
  }



  render() {
    const { user } = this.props.auth;
const {t} = this.props;
    return (
<div className='bg_strip'>
  <section className="blogMainContent">
    <section class="headerCrediantials">
          <Navbar />
        </section>
          <div className="container">
            <div className="row mt25">
              <div className="col-lg-10 mx-auto">
                <div className="centerContent">
          <div className="breadCrumb">
            <ul>
              <li><a href="/">{t("HOME")}</a></li>
              <li>&#62;</li>
              <li><a href="/blog">{t("BLOG")}</a></li>
              <li>&#62;</li>
              <li><a href="/BlogCategorys">{this.state.catname}</a></li>
            </ul>
          </div>
          <div className="fullBlogPost">
            <h2>{this.state.articlesub}</h2>
            <span className="dateText">{t("CATEGORY")}:<a href={
              "/BlogCategorys/" + this.state.catid
            }>{this.state.catname}</a> |{moment(this.state.createddate).format(
                "DD-MM-YYYY  h:mm a "
              )}</span>
              {this.state.articleimage!=null?
                  <img src={imageUrl + "help_images/" +this.state.articleimage }className="img-fluid blogFullImg mx-auto d-block" />
                :
                    <img src={blogthumb1}className="img-fluid blogFullImg mx-auto d-block"/>

              }

          {/*}  <img src={blogfullimg} className="img-fluid blogFullImg mx-auto d-block" />*/}
            {ReactHtmlParser(this.state.content)}
          {/*}  <p>The Startup economy growth is fueled and it’s surely growing to get bigger and better. In the list, deep technologies are finding a greater place and are able to attract large startups. Global Startup Ecosystem Report 2019 published by Startup Genome proves it too. In the report, Blockchain is described as the 2nd fastest-growing amongst the technology sector.</p>
            <p>After going through the report, the fact that the combination of two words - blockchain and startups are going to rule the future world is undoubted. But why? What’s the reason that blockchain technology is of so much importance for Startup Growth?</p>
            <p>Through this article, we would try to answer all such questions in a very simplified manner. We would give top-5 reasons for such impact of blockchain on the startup ecosystem.</p>
            <h4>Matchless Security</h4>
            <p>That’s sort of a sunshade for any startup. In this digital world, a wave of the security breach and data leaks is no less than a destructive tornado. In no time a well performing colossal size organization fails, either due to internal security issues or attack by external forces.</p>
            <h4>Options = ∞</h4>
            <p>This is the ultimate beauty of blockchain technology. Using or applying this technology gives a feeling of contentment, no matter where it is being done. As we are talking about a startup, we would look at the options that blockchain uncovers for it.</p>
            <p>In terms of funding, you get options like ICO, STO, IEO, and CTO. Not just that, there are many regulated crowdfunding platforms coming up that help startup to access a plethora of funds and are based on blockchain, thanks to its aspects such as transparency and decentralization.</p>

          {/*}  <div className="postAction">
                <ul>
                  <li><a href="#">Like <span className="likeIcon"></span></a></li>
                  <li>
                      <div className="dropdown">
                      <a href="javascript:void(0)" className="dropdown-toggle" data-toggle="dropdown"><span>25 Share</span> <span className="shareIcon"></span></a>
                      <div className="dropdown-menu">
                        <ul>
                        <li><a href="#"><i class="fab fa-facebook-f"></i> Facebook</a></li>
                        <li><a href="#"><i class="fab fa-twitter"></i> Twitter</a></li>
                        <li><a href="#"><i class="fab fa-linkedin-in"></i> Linkedin</a></li>
                        <li><a href="#"><i class="fab fa-instagram"></i> Instagram</a></li>
                      </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              */}
          </div>
        </div>
              </div>
            </div>
          </div>
        </section>
        <section className="relatedSlider">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 mx-auto">
          <Carousel
            swipeable={false}
            draggable={false}
            showDots={false}
            responsive={responsive}
            infinite={true}
            autoPlay={false}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
          >
           {  this.state.nextcontent.map((itemnext, i) => {
                return(
            <div className="slideItem">
             <a href={
               "/BlogDetaile/" + itemnext._id
             }>

             {itemnext.article_image!=null?
                 <img src={imageUrl + "help_images/" +itemnext.article_image } className="img-fluid blogthumbimage"  alt=""/>
               :
                   <img src={blogthumb1} className="img-fluid blogthumbimage" alt="" />

             }



             </a>
             <h4><a href={
               "/BlogDetaile/" + itemnext._id
             }>{itemnext.Articlename}</a></h4>
             <p class="sliderFooter clearfix">{moment(itemnext.createdDate).format(
                 "DD-MM-YYYY  h:mm a "
               )}<span class="float-right"><a href={
                 "/BlogCategorys/" + itemnext._id
               }>{itemnext.maincategoryId.categoryName}</a></span></p>
       </div>
     )

   })}
{/*}
           <div className="slideItem">
              <a href="#"><img src={blogthumb2} className="img-fluid" alt="" /></a>
              <h4><a href="">3 ways blockchain technology will continue to impact token</a></h4>
              <p class="sliderFooter clearfix">June 25, 2019<span class="float-right"><a href="#">Social Trading</a></span></p>
            </div>
            <div className="slideItem">
              <a href="#"><img src={blogthumb3} className="img-fluid" alt="" /></a>
              <h4><a href="">3 ways blockchain technology will continue to impact token</a></h4>
              <p class="sliderFooter clearfix">June 25, 2019<span class="float-right"><a href="#">Social Trading</a></span></p>
            </div>
            <div className="slideItem">
              <a href="#"><img src={blogthumb1} className="img-fluid" alt="" /></a>
              <h4><a href="">Why blockchain technology is so crucial to support startups?</a></h4>
              <p class="sliderFooter clearfix">June 25, 2019<span class="float-right"><a href="#">Social Trading</a></span></p>
            </div>
            <div className="slideItem">
              <a href="#"><img src={blogthumb2} className="img-fluid" alt="" /></a>
              <h4><a href="">3 ways blockchain technology will continue to impact token</a></h4>
              <p class="sliderFooter clearfix">June 25, 2019<span class="float-right"><a href="#">Social Trading</a></span></p>
            </div>
            <div className="slideItem">
              <a href="#"><img src={blogthumb3} className="img-fluid" alt="" /></a>
              <h4><a href="">3 ways blockchain technology will continue to impact token</a></h4>
              <p class="sliderFooter clearfix">June 25, 2019<span class="float-right"><a href="#">Social Trading</a></span></p>
            </div> */}
          </Carousel>
        </div>
        </div>
        </div>
        </section>
  <Footer />
</div>
);
  }
}

BlogCategoryListing.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps
)(withTranslation()(BlogCategoryListing),withRouter(BlogCategoryListing));
