import React, { Component, useEffect } from 'react'
import ReactDOM from 'react-dom';
import { store } from "react-notifications-component";
import axios from "axios";
import { Link, withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import keys from "../actions/config";
import {addLaunchpadContact } from "../actions/authActions";
import Navbar from './Navbar'
import Footer from './Footer'
import classnames from "classnames";
// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Navtradebar from './Navtradebar';
import { withTranslation } from 'react-i18next';

const url = keys.baseUrl;



class LaunchpadHome extends Component {
  constructor(props) {

    super(props);

    this.state = {
      pairdetails: [],
      nav1: null,
      nav2: null,
      name:'',
      project:'',
      email:'',
      errors: {},
      lang: "",
      cmsData: []
    }
  }

  componentDidMount() {
    const {t} = this.props;
    this.ScrollToTopOnMount();
    this.getallCmsData();
    const language = localStorage.getItem('lang');
    this.setState({lang: language})
    let userid = this.props.auth.user.id;
    //console.log('userid');
    //console.log(userid);
    //console.log('typeof this.props.auth.user.id');
    //console.log(typeof this.props.auth.user.id);
    let pairdata = { exchangetype: "Spot" }
    axios
      .post(url + "cryptoapi/pair-data", pairdata)
      .then((res) => {
        if (res.status) {
          this.setState({ pairdetails: res.data.data })
        } else {

        }
        // this.props.history.push("/Login");
      })
      .catch();
    let withdraw_id = this.props.match.params.id;
    if (typeof withdraw_id != "undefined" && withdraw_id != "") {
      let passdata = { withdrawid: withdraw_id };
      axios
        .post(url + "cryptoapi/withdrawrequest", passdata)
        .then((res) => {
          if (res.data.status) {
            store.addNotification({
              title: t("SUCCESS"),
              message: res.data.message,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: t("WARNING"),
              message: res.data.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          }
          this.props.history.push("/Login");
        })
        .catch();
    }
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

     componentWillReceiveProps(nextProps) {
      const {t} = this.props;
      const language = localStorage.getItem('lang') 
      this.setState({lang : language})
      if (nextProps.errors) {
        console.log("nextprops.errors",nextProps.errors)
        this.setState({
            errors: nextProps.errors,
            disable:false,
        });
      }

      console.log("success",nextProps)
      if (nextProps.auth.Contactus !== undefined &&
          nextProps.auth.Contactus.data !== undefined &&
          nextProps.auth.Contactus.data.retMsg !== undefined) {
        store.addNotification({
          title: "",
          message: t("LAUNCHPAD_CONTACTUS"),
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        this.setState({
            email: "",
            name: "",
            project: "",
            errors: {},
            accept : "",
            disable:false
        });
       
      }

  }

  onLaunchpad=e =>{
    console.log('enter this')
    e.preventDefault();
    const Contactus={
    name:this.state.name,
    project:this.state.project,
    email:this.state.email
  }
  this.props.addLaunchpadContact(Contactus,this.props.history);

  }
   onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    // Scroll to Top
    ScrollToTopOnMount() {
        window.scrollTo(0, 0);
    }
    getallCmsData = async () => {
      const result = await axios.get(url + "cryptoapi/getallCmsData");
      this.setState({cmsData: result.data.result})
      // setCmsData(result.data.result)
      // console.log("result_result_result", result.data.result);
  }

  cmsFilter = (data) => {
      const filter = this.state.cmsData?.find((el) => el.identifier == data);
      // console.log("data_data_data", lang);
      if (filter) {
          let checkLang = this.state.lang == "en" ? filter.content : filter.spanishText
          return checkLang
      }
  }
  cmsFilterImage = data => {
      console.log("cmsFilterImage_cmsFilterImage",data);
      const filter = this.state.cmsData?.find((el) => el.identifier == data);
      console.log("cmsFilterImage_cmsFilterImage",filter);

      if(filter){
          return filter.bannerImage
      }   
  }
  render() {
    const { user } = this.props.auth;
      const { errors } = this.state;
    
    const slickSettingsVerticalNav = {
      arrows: true,
      vertical: true,
      slidesToShow: 4,
      swipeToSlide: true,
      focusOnSelect: true,
      verticalSwiping: true,
      // ref: slider => (slider2 = slider),
      // asNavFor: navData.nav1,
      asNavFor: this.state.nav2,
      ref: slider => (this.slider1 = slider),
    }

    const slickSettingsVerticalMain = {
      arrows: false,
      slidesToShow: 1,
      // asNavFor: navData.nav2,
      // ref: slider => (slider1 = slider),
      asNavFor: this.state.nav1,
      ref: slider => (this.slider2 = slider),
    }

    var box_panel_settings = {
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 990,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 850,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: true,
          }
        }
      ]
    };
   const {t} = this.props;
   console.log("this.props",this.state.lang)
    return (
      <div className=' themeinput'>
        {/* <ScrollToTopOnMount/> */}
        <header className="launchpad_bg">
          <div className="container-fluid px-0 main_bg_trade_new spot_trade_main">
            {/* <Navbar /> */}
            <Navtradebar />
            <div id="carouselExampleIndicators" className="carousel slide carousel-fade py-5" data-ride="carousel">
              <div className="carousel-inner" role="listbox">
                <div className="carousel-item active">
                  <div className="stackingBannerContent px-3">
                    <h2 className='text-center'>{this.cmsFilter("launchpad_01")}</h2>
                    <h4>{this.cmsFilter("launchpad_02")}</h4>
                    {/* <img src={require("../images/launchpad_banner_img.png")} alt="" className="img-fluid d-block mx-auto my-4 img_launchpad" /> */}
                    <img src={url + "cms_images/" + this.cmsFilterImage("launchpad_banner_image01")} alt="" className="img-fluid d-block mx-auto my-4 img_launchpad" />
                    <div className="row">
                      <div className="col-sm-6 col-lg-5 mx-auto py-4 text-center">
                      <Link to="/LaunchpadToken" className="themebtn ">{this.cmsFilter("launchpad_03")} </Link>
                        {/* <button className='themebtn mr-4'>Buy Token</button> */}
                        {/* <button className='themebtn'>Login to buy</button> */}
                        {/* <div className="input-group">
                          <input type="text" className="form-control" placeholder="Enter Your Email Address" />
                          <div className="input-group-append">
                            <button className="btn btnYellow">Submit</button>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <section className="bitAbout d-none">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="launchpad_abt wow fadeInUp">
                  <h2 className="launch_title">{t("HOW_WE_SUPPORT")} <span>{t("TOKEN_LAUNCH")} </span></h2>
                  <p>{t("LONG_ESTABLISHED")}</p>
                </div>
              </div>
              <div className="col-md-8 wow flipInX">
                <div className="d-flex align-items-center justify-content-center">
                  <img src={require("../images/support_img.png")} alt="" className="img-fluid" />
                  <h4>{t("TEAM")}</h4>
                </div>
                <div className="row">
                  <div className="col-xs-6 col-sm-6 col-md-6">
                    <div className="slick_vertical_nav">
                      <Slider {...slickSettingsVerticalNav} >
                        <div>
                          <h3>{t("SET_UP")}</h3>
                        </div>
                        <div>
                          <h3>{t("PLATFORM_INTEGRATION")} </h3>
                        </div>
                        <div>
                          <h3>{t("LAUNCH")}</h3>
                        </div>
                        <div>
                          <h3>{t("PROJECT_DEVELOPMENT")} </h3>
                        </div>
                      </Slider>
                    </div>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-6">
                    <div className="slick_vertical_mainnav">
                      <Slider {...slickSettingsVerticalMain}>
                        <div>
                          <ol className="slick_slider_list pl-0">
                            <li><span>{t("SOCIAL_MEDIA_SETUP")}</span></li>
                            <li><span>{t("FILLING_INFORMATION_ABOUT_PROJECT")}</span></li>
                          </ol>
                        </div>
                        <div>
                          <ol className="slick_slider_list pl-0">
                            <li><span>{t("ESTABLISHMENT1")}</span></li>
                            <li><span>{t("ESTABLISHMENT2")}Establishment of fully developed online casino ready to go live</span></li>
                            <li><span>{t("ESTABLISHMENT3")} </span></li>
                          </ol>
                        </div>
                        <div>
                          <ol className="slick_slider_list pl-0">
                            <li><span>{t("ESTABLISHMENT4")}</span></li>
                            <li><span>{t("ESTABLISHMENT5")}</span></li>
                            <li><span>{t("ESTABLISHMENT6")}</span></li>
                          </ol>
                        </div>
                        <div>
                          <ol className="slick_slider_list pl-0">
                            <li><span>{t("ESTABLISHMENT7")}</span></li>
                            <li><span>{t("ESTABLISHMENT8")} </span></li>
                          </ol>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="launch_program">
          <div className="container">
            <h2 className="launch_title launch_title_center wow fadeInUp pt-0 mb-0" data-wow-delay="1s">{this.cmsFilter("launchpad_04")}<span>{this.cmsFilter("launchpad_05")}</span></h2>
            <Slider {...box_panel_settings}>
              <div>
                <div className="box_panel">
                  <img src={url + "cms_images/" + this.cmsFilterImage("launchpad_banner_image02")} alt="Logo" className="img-fluid" />
                  <div className="box_panel_content">
                    <h2>{this.cmsFilter("launchpad_06")}</h2>
                    <p>{this.cmsFilter("launchpad_07")}  </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="box_panel">
                  <img src={url + "cms_images/" + this.cmsFilterImage("launchpad_banner_image03")} alt="Logo" className="img-fluid" />
                  <div className="box_panel_content">
                    <h2>{this.cmsFilter("launchpad_08")}</h2>
                    <p>{this.cmsFilter("launchpad_09")} </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="box_panel">
                  <img src={url + "cms_images/" + this.cmsFilterImage("launchpad_banner_image04")} alt="Logo" className="img-fluid" />
                  <div className="box_panel_content">
                    <h2>{this.cmsFilter("launchpad_10")} </h2>
                    <p>{this.cmsFilter("launchpad_11")}</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="box_panel">
                  <img src={url + "cms_images/" + this.cmsFilterImage("launchpad_banner_image05")} alt="Logo" className="img-fluid" />
                  <div className="box_panel_content">
                    <h2>{this.cmsFilter("launchpad_12")}</h2>
                    <p>{this.cmsFilter("launchpad_13")}</p>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </section>

        <section className="countableStake text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="launch_card wow flipInY">
                  <h2>{this.cmsFilter("launchpad_14")}</h2>
                  <p>{this.cmsFilter("launchpad_15")}</p>
                  <Link to="/LaunchpadToken" className="btn btnLandingJoin themebtn dark">{t("LAUNCHPAD")}</Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="launchpad_contact py-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 ">
                <h2 className='head'>{this.cmsFilter("launchpad_16")} <br/>{this.cmsFilter("launchpad_23")} <br/>{this.cmsFilter("launchpad_24")}</h2>
                <div className="launchpad_contact_details">
                  <div>
                    <h2>{this.cmsFilter("launchpad_25")}</h2>
                    <p>{this.cmsFilter("launchpad_17")}</p>
                    <a href="#" target="_blank"><img src={require("../images/launch_telegram.png")} alt="Logo" className="img-fluid" /></a>
                    <a href="#" target="_blank"><img src={require("../images/launch_whatsapp.png")} alt="Logo" className="img-fluid" /></a>
                    <a href="#" target="_blank"><img src={require("../images/launch_skype.png")} alt="Logo" className="img-fluid" /></a>
                  </div>
                  <img src={require("../images/contact_img.png")} alt="Logo" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-6 ">
                <div className="formBox launchpad_form wow flipInY mb-5 mb-md-0" data-wow-delay=".15s;">
                  <h2 className='text-left'>{this.cmsFilter("launchpad_18")}</h2>
                  <ul>
                    <li><a href="#" target="_blank"><i className="fab fa-telegram-plane"></i></a></li>
                    <li><a href="#" target="_blank"><i className="fab fa-whatsapp"></i></a></li>
                    <li><a href="#" target="_blank"><i className="fab fa-skype"></i></a></li>
                  </ul>
                  <form  noValidate onSubmit={this.onLaunchpad} id="contact-us" >
                    <div className="form-group fadeIn second">
                      <label>{this.cmsFilter("launchpad_19")}</label>
                      <input 
                      onChange={(e)=> {this.onChange(e)}} 
                             error={errors.name}  
                             id="name" 
                             type="text" 
                             className={classnames("form-control", {
                            invalid: errors.name
                        })}/>
                    <span className="text-danger">{errors.name ? t("TELEGRAM_NAME_FIELD_REQUIRED"): ""}</span>
                    </div>
                    <div className="form-group fadeIn third">
                      <label>{this.cmsFilter("launchpad_20")}</label>
                      <input 
                     onChange={(e)=> {this.onChange(e)}}
                             error={errors.project}  
                             id="project" 
                             type="text" 
                             className={classnames("form-control", {
                            invalid: errors.project
                        })}/>
                    <span className="text-danger">{errors.project ? t("TELEGRAM_PROJECT_FIELD_REQUIRED") : ""}</span>

                    </div>
                    <div className="form-group fadeIn third">
                      <label>{this.cmsFilter("launchpad_21")}</label>
                      <input 
                      onChange={(e)=> {this.onChange(e)}} 
                             error={errors.email}  
                             id="email" 
                             type="email"
                            className={classnames("form-control", {
                            invalid: errors.email
                        })}/>
                    <span className="text-danger">{errors.email ? t("TELEGRAM_EMAIL_FIELD_REQUIRED") : ""}</span>
                    </div>
                    <div className="form-group">
                    <button form="contact-us" type="submit" className="btn btn-primary themebtn dark">
                    {this.cmsFilter("launchpad_22")}   
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

LaunchpadHome.propTypes = {
  addLaunchpadContact:PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(
  mapStateToProps,
  {addLaunchpadContact}
)(withTranslation()(LaunchpadHome),withRouter(LaunchpadHome));
