import React, { Component, useEffect } from 'react'
import ReactDOM from 'react-dom';
import { store } from "react-notifications-component";
import axios from "axios";
import { Link, withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import keys from "../actions/config";
import Navbar from './Navbar'
import Footer from './Footer'
import { Editor } from '@tinymce/tinymce-react';
import classnames from "classnames";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { applyToken } from "../actions/authActions";
import Countdown, { zeroPad } from "react-countdown";
import $ from 'jquery';
import Navtradebar from './Navtradebar';
import { withTranslation } from 'react-i18next';
var  dateFormat   = require('dateformat');
const url = keys.baseUrl;
const adminurl = keys.imageUrl;
const fronturl=keys.refurl;
class LaunchpadToken extends Component {
  constructor(props) {

    super(props);

    this.state = {
      status: true,
      tokenname:"",
      symbol:"",
      price:"",
      minamt:"",
      discount:"",
      availablesale:"",
      availablecurrency:"",
      maxsupply:"",
      industry:"",
      website:"",
      content:"",
      curimage:null,
      attachment:"",
      imageerror:"",
      curimagesize:0,
      launchpaddetails: [],
      startDate:new Date(),
      endDate:new Date(),
      errors: {},
      currency:[],
      completedData: [],
      completed: false
   
    }
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChange1 = this.handleChange1.bind(this);

  }
 handleChange(date) {
     this.setState({
     startDate: date
     })
      
     }
 handleChange1(date1) {
  
    this.setState({
     endDate: date1
     })
     }

      handleChangeefile = (event) => {
if(event.target.files[0]){
    var filesize= event.target.files[0].size
    this.setState({
      curimage: URL.createObjectURL(event.target.files[0]),
      attachment: event.target.files[0],
    });
}

  };


   async getLaunchpadDatas(data) {
    this.setState({completed: false})
    console.log("DATA_launchpadData",data);
    let pairdata = { exchangetype: "Spot", timeStamp: data };
    const launchpadData = await axios.post(url + "api/launchpad-data", pairdata);
    console.log("getLaunchpadDatas_launchpadData", launchpadData);
    if (launchpadData.status) {
      this.setState({ launchpaddetails: launchpadData.data.data, completed: false})
      var array = [];
      launchpadData.data.data.map((item, i) => {
        this.setState({ startdate: item.startdate })
        this.setState({ enddate: item.enddate })
      })
    }
  } 

  async getLaunchpadCompleted(data) {
    console.log("DATA_launchpadData",data);
    let pairdata = { exchangetype: "Spot", timeStamp: data };
    const launchpadData = await axios.post(url + "api/launchpad-data", pairdata);
    console.log("getLaunchpadDatas_launchpadData", launchpadData);
    if (launchpadData.status) {
      this.setState({ completedData: launchpadData.data.data, completed: true})
      // var array = [];
      // launchpadData.data.data.map((item, i) => {
      //   this.setState({ startdate: item.startdate })
      //   this.setState({ enddate: item.enddate })
      // })
    }
  } 


  componentDidMount() {
    const {t} = this.props;
    axios.post(url + "api/launchpad-data", {timeStamp: 'completed'}).then((res)=> {
          console.log("completedData", res);
          this.setState({ completedData: res.data.data })
    })
    this.ScrollToTopOnMount()
    let userid = this.props.auth.user.id;
    let pairdata = { exchangetype: "Spot" ,timeStamp: 'current'}
    axios
      .post(url + "api/launchpad-data", pairdata)
      .then((res) => {
        console.log("$$$$$",res)
        if (res.status) {
          this.setState({ launchpaddetails: res.data.data , currency: res.data.currency})
          var array=[];
        res.data.data.map((item,i)=>{

       this.setState({startdate:item.startdate})
       this.setState({enddate:item.enddate})

        })
        } else {

        }
        // this.props.history.push("/Login");
      })
      .catch();
    let withdraw_id = this.props.match.params.id;
    if (typeof withdraw_id != "undefined" && withdraw_id != "") {
      let passdata = { withdrawid: withdraw_id };
      axios
        .post(url + "cryptoapi/withdrawrequest", passdata)
        .then((res) => {
          if (res.data.status) {
            store.addNotification({
              title: t("SUCCESS"),
              message: res.data.message,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: t("WARNING"),
              message: res.data.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          }
          this.props.history.push("/Login");
        })
        .catch();
    }
  }
componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }

        if (nextProps.auth !== undefined
            && nextProps.auth.APPLY_TOKEN !== undefined
            && nextProps.auth.APPLY_TOKEN.data !== undefined
            && nextProps.auth.APPLY_TOKEN.data.retMsg !== undefined) {

        store.addNotification({
          title: "",
          message: nextProps.auth.APPLY_TOKEN.data.retMsg,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
     this.getData();
        }
    }

getData(){

}
  Addtoken(){
    let userid = this.props.auth.user.id;
    if (userid!=undefined) {
    const data=new FormData();
    data.append("userid", userid);
    data.append("tokenname", this.state.tokenname);
    data.append("symbol"   , this.state.symbol);
    data.append("availablecurrency", this.state.availablecurrency);
    data.append("file1", this.state.attachment);
    data.append("price", this.state.price);
    data.append("minamt", this.state.minAmt);
    data.append("discount", this.state.discount);
    data.append("availablesale", this.state.availablesale);
    data.append("maxsupply", this.state.maxsupply);
    data.append("industry", this.state.industry);
    data.append("website", this.state.website);
    data.append("content", this.state.content);
    data.append("startdate", this.state.startDate.toISOString());
    data.append("endDate", this.state.endDate.toISOString());
    
    this.props.applyToken(data);
  }else{

   this.props.history.push("/Login");

  }

  }

 onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

   handleEditorChange(content, editor) {
       this.setState({ content });
     }
     ScrollToTopOnMount() {
      console.log('hhiiiiiiiiiiiii')
    
      window.scrollTo(0, 0);
  
  }
  render() {
    const { user } = this.props.auth;
    const { errors } = this.state;
    const {t} = this.props;
    function ScrollToTopOnMount() {
      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      return null;
    }

    const currentDate = new Date(this.state.startdate);
    const currentDate1 = new Date(this.state.enddate);
    const year = (currentDate.getMonth() === 11 && currentDate.getDate() > 23) ? currentDate.getFullYear() + 1 : currentDate.getFullYear();
    const renderer = ({ days, hours, minutes, seconds }) => {
      const {t} = this.props;
      return (
        <div className="timer_panel">
          <span><span className="timer_time">{zeroPad(days)}</span><span className="timer_label">{t("DAYS")}</span></span>
          <span className="timer_dots">:</span>
          <span><span className="timer_time">{zeroPad(hours)}</span><span className="timer_label">{t("HOURS")}</span></span>
          <span className="timer_dots">:</span>
          <span><span className="timer_time">{zeroPad(minutes)}</span><span className="timer_label">{t("MINS")}</span></span>
          <span className="timer_dots">:</span>
          <span><span className="timer_time">{zeroPad(seconds )}</span><span className="timer_label">{t("SECS")}</span></span>
        </div>
      );
    };

    return (
      <div>
        {/* <ScrollToTopOnMount/> */}
      {/* apply form*/}
         <div class="modal  thememodal" id="myModal1">
     
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div className='modal-head p-3'>
              <button type="button" class="close" data-dismiss="modal">&times;</button>
              <h3 className='text-light mb-0'>{t("LAUNCHPAD_DETAILS")}</h3>
              <hr />
            </div>
            <div class="modal-body stackPopup py-0">
              
              
              <div class="row">
                <div class="col-md-6">
                  <label>{t("TOKEN_NAME")}</label>
                  <input type="text"  id="tokenname" class="form-control"  onChange={this.onChange}/>     
                </div>
                  <div class="col-md-6">
                  <label>{t("TOKEN_SYMBOL")}</label>
                  <input type="text" name="symbol" id="symbol" class="form-control"  onChange={this.onChange}/>     
                </div>
              </div>
                      
              <div class="row mt-3">
               <div class="col-md-6">
                  <label>{t("TOKEN_ICON")}</label>
                  <input type="file" className='themebtn theme_upload_btn' accept="image/x-png,image/gif,image/jpeg" onChange={this.handleChangeefile}
                  name="file1" id="exampleInputFile"aria-describedby="fileHelp"/>
                </div>
              </div>


              <div class="row mt-3">
                  <div class="col-md-6">
                  <label>{t("AVAILABLE_CURRENCY")}</label>
                  <input type="text"  id="availablecurrency" class="form-control"  onChange={this.onChange}/>     
                </div>
                  <div class="col-md-6">
                  <label>{t("TOKEN_LAUNCH_PRICE")}</label>
                  <input type="number" id="price" class="form-control"  onChange={this.onChange}/>     
                </div>
              </div>   

              <div class="row mt-3">
                <div class="col-md-6">
                  <label>{t("MINIMUM_PURCHASE_AMOUNT")}</label>
                  <input type="number"  id="minAmt" class="form-control"  onChange={this.onChange}/>     
                </div>
                  <div class="col-md-6">
                  <label>{t("DISCOUNT")}</label>
                  <input type="number"  id="discount" class="form-control"  onChange={this.onChange}/> 
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-md-6">
                  <label>{t("TAKE_AVAILABLE_TO_SALE")}</label>
                  <input type="number"  id="availablesale" class="form-control"  onChange={this.onChange}/>     
                </div>
                  <div class="col-md-6">
                  <label>{t("TOKEN_MAX_SUPPLY")}</label>
                  <input type="number"  id="maxsupply" class="form-control"  onChange={this.onChange}/> 
                </div>
              </div>

                   <div class="row mt-3">
                    <div class="col-md-6">
                      <label>{t("INDUSTRY")}</label>
                      <input type="text"  id="industry" class="form-control"  onChange={this.onChange}/>     
                    </div>
                      <div class="col-md-6">
                      <label>{t("WEBSITE")}</label>
                      <input type="text"  id="website" class="form-control"  onChange={this.onChange}/> 
                    </div>
                  </div>

               <div class="row mt-3">
                <div class="col-md-6">
                  <label>{t("START_DATE")}</label>
                <DatePicker
                  selected={ this.state.startDate }
                  onChange={ this.handleChange }
                  dateFormat="MMMM d, yyyy"
                  className={classnames("form-control", {
                             invalid: errors.startDate
                        })}
                  />   
                </div>
                  <div class="col-md-6">
                  <label>{t("END_DATE")}</label>
                   <DatePicker
                      selected={ this.state.endDate }
                      onChange={ this.handleChange1 }
                      dateFormat="MMMM d, yyyy"
                      className={classnames("form-control", {
                               invalid: errors.endDate
                             })}
                       />
                </div>
              </div>

               <div class="row mt-3">
                <div class="col-md-12">
                  <label>{t("CONTENT")}</label>
                  
                 <Editor apiKey='5vk89nvvi2zckrb2lp2ctyyolewhq1v3pzdiwb7at68h40a5'
                        initialValue="<p>This is the initial content ofthe editor</p>"
                        value={this.state.content} onEditorChange={this.handleEditorChange}
                        init={{height: 500,menubar: false,
                            plugins: [
                                       'advlist autolink lists link image charmap print preview anchor',
                                       'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                       ],
                                       toolbar:
                                               'undo redo code | formatselect | bold italic backcolor | \
                                               alignleft aligncenter alignright alignjustify | \
                                               bullist numlist outdent indent | removeformat | help'
                                           }}
                                         />
                                         </div>
              </div>

              <div className="modal-footer">
                  <button type="button" className="btn btn-secondary modalgraybtn" data-dismiss="modal">{t("CLOSE")}</button>
                  <button type="submit" className="btn btn-primary modalbtn" onClick={()=>this.Addtoken()}>
                  {t("ADD_TOKEN_DETAILS")}
                  </button>
              </div>

            </div>
          </div>
        </div>
      </div>

        <header className="launchpadtoken_bg">
          <div className="container container-theme">
            <Navtradebar />
            {/* <Navbar /> */}
            <div id="carouselExampleIndicators" className="carousel slide carousel-fade py-5" data-ride="carousel">
              <div className="carousel-inner" role="listbox">
                <div className="carousel-item active">
                  <div className="stackingBannerContent">
                    <div className="row align-items-center">
                      <div className="col-lg-6 col-sm-6 text-left">

                        <h2>{t("BELL_EXCHANGE_LAUNCHPAD")}</h2>
                        <p className='text_them_ech'>{t("TOKEN_LAUNCH_PLATFORM")}</p>
                        <button className="btn btnLandingJoin themebtn" data-toggle="modal" data-target="#myModal1">+ {t("APPLY")}</button>
                      </div>
                      <div className="col-lg-6 col-sm-6 text-center">
                        <img src={require("../images/launchpad_token_img.png")} alt="Banner" className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      
        <section className="launchpad_token_section">
          <div className="container container-theme">
            <div className="row">
              <div className="col-md-12">
                <div className="launchpad_token_wrapper">
                  <ul>
                    <li onClick={()=>{this.getLaunchpadDatas('current') ; this.setState({status: true})}}><a href="javascript:void(0)" className={this.state.status == true ? "btn btnLandingJoin active" : "btn btnLandingJoin "}>Active Tokens <span>({this.state.launchpaddetails.length})</span></a></li>
                    <li onClick={()=>{this.getLaunchpadCompleted('completed'); this.setState({status: false})}}><a href="javascript:void(0)" className={this.state.status == false ? "btn btnLandingJoin active" : "btn btnLandingJoin "}>Completed Tokens <span>({this.state.completedData.length})</span></a></li>
                    {/* <li><a href="#" className="btn btnLandingJoin active">Active Tokens <span>({this.state.launchpaddetails.length})</span></a></li>
                    <li><a href="#" className="btn btnLandingJoin">Completed Tokens <span>(12)</span></a></li> */}
                  </ul>
                  <div className="launchpad_token_panel">
                    <div className="row">
                    { (this.state.completed ? this.state.completedData : this.state.launchpaddetails).map((data,i)=>{
                     return(
                      <div className="col-lg-4 col-sm-6">
                        {console.log("launchpaddetails",data)}
                        <div className="launchpad_token_single wow fadeInUp">
                          {/* <img src={adminurl+'currency/'+data?.currencyId?.currencyimage} alt="Banner" className="img-fluid"/> */}

                          <h4 className="text-center">{data?.currencyId?.currencyName}</h4>
                          <h6 className="text-center">{t("GAMING_STAGE")}</h6>
                          {!this.state.completed ? <Countdown date={data?.enddate} renderer={renderer} /> : <></>}
                          <hr/>
                          <div className="d-flex justify-content-between align-items-center">
                            <p>{t("AVAILABLE_CURRENCY")}</p>
                            <p>{data?.availablecurrency.map((currencyId)=> {
                               let currency = this.state.currency.find(el => el._id == currencyId);
                               console.log(currency,'Available Currency')
                               return currency?.currencyName;
                            }).join(', ')
                            }</p>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <p>{t("SESSION_SUPPLY")}</p>
                            <p>{data?.availablesale}</p>
                          </div>
                          
                          <div className="d-flex justify-content-between align-items-center">
                            <p>{t("START")}</p>
                            <p>{dateFormat(data?.startdate, 'dd-mm-yyyy h:MM:ss TT')}</p>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <p>{t("END")}</p>
                            <p>{dateFormat(data?.enddate, 'dd-mm-yyyy h:MM:ss TT')}</p>
                          </div>
                          <div className="text-center">
                            {console.log("completedddddd_Dddddd",this.state.completed)}
                         {this.state.completed == false ?
                         <a href={fronturl+'LaunchpadDetails/'+data?._id} className="btn btnLandingJoin">{t("VIEW")}</a>
                         : 
                         <></>
                         }
                          </div>
                       </div>
                      </div>
                     )
                    })    
                  }
                     {/* <div className="col-md-4 col-sm-6">
                        <div className="launchpad_token_single wow fadeInUp">
                          <img src={require("../images/launchpad_token_02.png")} alt="Banner" className="img-fluid"/>
                          <h4 className="text-center">Seven Chain</h4>
                          <h6 className="text-center">Gaming | Stage 1</h6>
                          <Countdown date={`${year}-12-31T12:00:00`} renderer={renderer} />
                          <hr/>
                          <div className="d-flex justify-content-between align-items-center">
                            <p>Available Currency</p>
                            <p>BTC, ETH, USDT</p>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <p>Session Supply</p>
                            <p>300,000,000</p>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <p>Start</p>
                            <p>04-03-2021  15:15</p>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <p>End</p>
                            <p>31-04-2021  15:15</p>
                          </div>
                          <div className="text-center">
                            <Link to="LaunchpadDetails" className="btn btnLandingJoin">View</Link>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6">
                        <div className="launchpad_token_single wow fadeInUp">
                          <img src={require("../images/launchpad_token_01.png")} alt="Banner" className="img-fluid"/>
                          <h4 className="text-center">KS Coin</h4>
                          <h6 className="text-center">Gaming | Stage 1</h6>
                          <Countdown date={`${year}-12-31T12:00:00`} renderer={renderer} />
                          <hr/>
                          <div className="d-flex justify-content-between align-items-center">
                            <p>Available Currency</p>
                            <p>BTC, ETH, USDT</p>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <p>Session Supply</p>
                            <p>300,000,000</p>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <p>Start</p>
                            <p>04-03-2021  15:15</p>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <p>End</p>
                            <p>31-04-2021  15:15</p>
                          </div>
                          <div className="text-center">
                            <Link to="LaunchpadDetails" className="btn btnLandingJoin">View</Link>
                          </div>
                        </div>
                      </div>*/}

                    </div>
                  </div>
                  <div className="launchpad_btn_center">
                    <button className="btn btnLandingJoin themebtn">+ {t("MORE")}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}

LaunchpadToken.propTypes = {
  applyToken:PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  applyToken:state.auth,
  auth: state.auth,
  errors: state.errors
});
export default connect(
  mapStateToProps,{
    applyToken
  }
)(withTranslation()(LaunchpadToken),withRouter(LaunchpadToken));
