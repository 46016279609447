import React, { Component } from 'react'
import TradeHeader from './TradeHeader'
import Footer from './Footer'
import DepositeQR from "../images/depositeQR.jpg"
import BtcIcon from "../images/btcIcon.png"
import EthIcon from "../images/ethIcon.png"
import XrpIcon from "../images/xrpIcon.png"
import LtcIcon from "../images/ltcIcon.png"
import BchIcon from "../images/bchIcon.png"
import { store } from 'react-notifications-component';
import keys from "../actions/config";
import Select from 'react-select';
import axios from "axios";
import DatePicker from "react-datepicker";
import { withdrawalhistory, cancelwithdraw, Searchwithdata } from "../actions/authActions";
import { Link, withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CSVLink, CSVDownload } from "react-csv";
import { Scrollbars } from 'react-custom-scrollbars';
import { withTranslation } from 'react-i18next';

import "react-datepicker/dist/react-datepicker.css";
const url = keys.baseUrl;


const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    color: state.isSelected ? 'red' : 'blue',
    padding: 20,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}

const options1 = [
  { value: 'All', label: 'All' },
  { value: 'Cancelled', label: 'Cancelled' },
  { value: 'Pending', label: 'Pending' },
  { value: 'Confirmed', label: 'Confirm' },
];

const options = [{ value: 'All', label: 'All' },];
const optionscurrency = { value: 'All', label: 'All' };


class WithdrawalHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      historydetails: [],
      records: [],
      currencydetails: [],
      csvData: [],
      contract: { value: 'All', label: 'All' },
      type: { value: 'All', label: 'All' },
      startDate: '',
      endDate: '',
    };
  }

  styles = {
    option: (provided, state) => ({
      ...provided,
      color: "white",
      backgroundColor: "#000",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '52px',
      padding: '0 6px',
      backgroundColor: "#03081f",
      borderColor: '#81c8f6',
    borderRadius: 10,
    borderStyle: 'solid',
    borderWidth: '1px'
     
    }),
    control: (provided, state) => ({
      ...provided,
      height: '52px',
      borderRadius:10,
      backgroundColor: "#03081f",
      border:'none'
     
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '52px',
      position: 'absolute',
      right: 0,
      top: 0,
      color:'#fff' 
    }),    
    singleValue: (provided, state) => ({
      ...provided,
      color: "#fff"
    })
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getData()
    this.getCurrencyData()
  };
  getCurrencyData() {
    axios
      .get(url + "cryptoapi/getcurrency")
      .then(res => {
        var currencyselect = [];
        var getAllCurrency = res.data.data;
        //console.log(getAllCurrency);
        for (var i = 0; i < getAllCurrency.length; i++) {
          // if(getAllCurrency[i].currencySymbol=='BTC' || getAllCurrency[i].currencySymbol=='ETH' || getAllCurrency[i].currencySymbol=='USDT')
          // {
          var from_val = {
            value: getAllCurrency[i].currencySymbol,
            label: getAllCurrency[i].currencyName
          };
          currencyselect.push(from_val);

          // }
        }
        currencyselect.push(optionscurrency)
        this.setState({ currencyData: currencyselect });
      });
  }

  componentWillReceiveProps(nextProps) {
  const {t} = this.props;
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }

    if (nextProps.auth !== undefined
      && nextProps.auth.trade !== undefined
      && nextProps.auth.trade.data !== undefined
      && nextProps.auth.trade.data.data !== undefined
      && nextProps.auth.trade.data.type !== undefined
      && nextProps.auth.trade.data.type == 'withdrawalhistory'
    ) {
      this.setState({ historydetails: nextProps.auth.trade.data.data });
      // this.buildarray(nextProps.auth.trade.data.data);
    }

    if (nextProps.auth !== undefined
      && nextProps.auth.trade !== undefined
      && nextProps.auth.trade.data !== undefined
      && nextProps.auth.trade.data.message !== undefined
      && nextProps.auth.trade.data.type == 'cancelwithdraw'
    ) {
      store.addNotification({
        title: t("SUCCESS"),
        message: nextProps.auth.trade.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true
        }
      });
    }
  }
  changecontract = contract => {
    this.setState({ contract });
  };

  changetype = type => {
    this.setState({ type });
  };

  getData() {
    var input = { "userid": this.props.auth.user.id }
    this.props.withdrawalhistory(input);

    axios
      .get(url + "cryptoapi/getcurrency")
      .then(res => {
        if (res.status === 200) {
          res.data.data.map((item, i) => {
            if (item.currencySymbol != '') {
              var one = { "value": item.currencySymbol, "label": item.currencySymbol }
              options.push(one);
            }
          });
          this.setState({ currencydetails: res.data.data })
        }
      })
      .catch();
  }
  cancelwithdrawal = (e) => {
    var input = { "userid": this.props.auth.user.id, withdrawid: e.target.id }
    this.props.cancelwithdraw(input);
    this.getData();
  }

  buildarray = (historydata) => {
    var csvdata = [];
    var titledata = ["Contracts", "Qty", "Filled remaining", "Exec price", "Trigger price", "Order price", "Order value", "Trading fess", "Type", "Status", "Order#", "Order Time"];
    //console.log(csvdata,'test');
    csvdata.push(titledata);
    //console.log('if');
    historydata.map((item, i) => {
      var pairName = item.pairName ? item.pairName : 0;
      var quantity = item.quantity ? item.quantity : 0;
      var price = item.quantity ? item.price : 0;
      var buyorsell = item.buyorsell ? item.buyorsell : 0;
      var filledAmount = item.filledAmount ? item.filledAmount : 0;
      var orderValue = item.orderValue ? item.orderValue : 0;
      var orderType = item.orderType ? item.orderType : 0;
      var orderDate = item.orderDate ? item.orderDate : 0;
      var _id = item._id ? item._id : 0;
      var status1 = item.status;
      var e_price = item.filled.length > 0 ? item.filled[0].Price : 0;
      var tradingfees = item.filled.length > 0 ? item.filled[0].Fees : 0;
      var Remaining = parseFloat(quantity) - parseFloat(filledAmount);
      var data1 = new Date(orderDate);
      let date12 = data1.getFullYear() + '-' + (data1.getMonth() + 1) + '-' + data1.getDate() + ' ' + data1.getHours() + ':' + data1.getMinutes() + ':' + data1.getSeconds();

      var status = (status1 == 0) ? "New" : (status1 == 1) ? "Completed" : (status1 == 2) ? "Partial" : (status1 == 3) ? "Cancel" : ''
      var newarr = [];
      newarr.push(pairName);
      newarr.push(quantity);
      newarr.push(filledAmount + '/' + Remaining);
      newarr.push(e_price);
      newarr.push(price);
      newarr.push('-/-');
      newarr.push(orderValue);
      newarr.push(parseFloat(tradingfees).toFixed(8));
      newarr.push(buyorsell);
      newarr.push(status);
      newarr.push(_id);
      newarr.push(date12);
      csvdata.push(newarr);
    });
    //console.log(csvdata,'csvdata');
    this.setState({ csvData: csvdata })

  }

  withdrawsearch = () => {
    var postdetails = {
      "contract": this.state.contract.value,
      "type": this.state.type.value,
      "startDate": this.state.startDate,
      "endDate": this.state.endDate,
      "userid": this.props.auth.user.id
    }
    this.props.Searchwithdata(postdetails);
  }

  pairdatachange = data => {
    this.setState({ records: data });
    if (this.state.records.length == 0) {
      data.map((item, i) => {
        if (item.first_currency != '' && item.second_currency != '') {
          var one = { "value": item.first_currency + item.second_currency, "label": item.first_currency + item.second_currency }
          options.push(one);
        }
      });
    }
  }

  handleChange = date => {
    this.setState({
      startDate: date,
      endDate: ""
    });
  };


  handleChange1 = date => {
    //console.log("dateee",date);
    this.setState({
      endDate: date
    });
  };


  render() {
    const {t} = this.props;
    const { historydetails, timeinforcetype, contract, type } = this.state
    return (
      <div className='bg_strip'>
        <div className="modal fade primary-modal" id="deposite" tabindex="-1" role="dialog" aria-labelledby="deposite" aria-hidden="true" data-backdrop="static">
          <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <div className="modal-content depositeContent">
            <div className="modal-header">
									<h4 className='modal-title h4'>{t("BTC_DEPOSIT")}</h4>
									<button type="button" className="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
              <div className="modal-body">
               
                <div className="popUpSpace">
                  <div className="depositeQr">
                    <img src={DepositeQR} className="img-fluid" />
                  </div>
                  <div className="walletKey">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group inputWithText">
                          <input name="" className="form-control" placeholder="" value="3CMCRgEm8HVz3DrWaCCid3vAANE42jcEv9" type="text" />
                          <button className="input-group-append-icon"><i className="far fa-copy"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="noteCont">
                    <h6>{t("NOTES")}:</h6>
                    <p>1. {t("NOTES1")}</p>
                    <p>2. {t("NOTES2")}</p>
                    <p>3. {t("NOTES3")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade primary-modal" id="Withdraw" tabindex="-1" role="dialog" aria-labelledby="Withdraw" aria-hidden="true" data-backdrop="static">
          <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <div className="modal-content WithdrawContent">
            <div className="modal-header">
									<h4 className='modal-title h4'>{t("BTC_WITHDRAW")}</h4>
									<button type="button" className="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
              <div className="modal-body">
               <div className="popUpSpace withdrawPopupForm">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>{t("RECEIVER_WALLET_ADDRESS")} <a href="#" className="float-right">{t("ADD_WALLET")}</a></label>
                        <input type="text" name="" className="form-control" value="3CMCRgEm8HVz3DrWaCCid3vAANE42jcEv9" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>{t("BTC_AMOUNT")} <span className="float-right">{t("BALANCE")}: <small>10.23569878 {t("BTC")}</small> | <a href="#">{t("SEND_ALL")}</a></span></label>
                        <input type="text" name="" className="form-control" value="0.00000000" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>{t("FINAL_AMOUNT")} <span className="float-right">+ {t("FEES")}: 0.00005{t("BTC")} </span></label>
                        <input type="text" name="" className="form-control" value="0.00000000" disabled="" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>{t("ENTER_AUTHENTICATION_CODE")}</label>
                        <input type="text" name="" className="form-control" value="123456" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input type="submit" name="" value="Withdraw" className="btn buttonType1 ml-0 ml-xl-auto" />
                      </div>
                    </div>
                  </div>
                  <div className="noteCont">
                    <h6>{t("NOTES")}:</h6>
                    <p>1. {t("MINUMUM_WITHDRAW")}:0.002BTC.</p>
                    <p>2.{t("INSIDE_YOUR_ACCOUNT")} .</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TradeHeader pairdatachange={this.pairdatachange} />
        <div>
          
          <section className=''>
            <div className="row mx-0 myasset">
              <div className="col-md-12 px-0">
                <div className="assetsTable ">
                  {/* <nav>
                    <div className="nav nav-tabs" id="navLink-tab" role="tablist">
                      <a href="/closedPandL" className="nav-item nav-link ">Closed P&L</a>
                      <Link to="/AssetsHistory" className="nav-item nav-link active">Deposit History</Link>
                      <Link to="/OrderHistory" className="nav-item nav-link">Order History</Link>
                      <Link to="/tradeHistory" className="nav-item nav-link">Trade History</Link>
                      <Link to="/withdrawalHistory" className="nav-item nav-link">Withdrawal History</Link>
                    </div>
                  </nav> */}
                 
                  <div className="tab-content card-body">
                  <h3 className="assetTitle">{t("WITHDRAWAL_HISTORY")}</h3>
                  <div className="">
                    <div className="assetHistoryTableTop clearfix">
                      <div className="row">
                        <div className="col-lg-12 col-xl-12 col-md-11 col-sm-12">
                          <div className="row">
                            <div className="col-lg-3 col-xl-2 col-md-4 col-sm-6">
                              <div className="form-group clearfix">
                                <label>{t("COIN")}</label>

                                <Select isSearchable={false}
                                   styles={this.styles} className="border_blue_select"
                                  width='100%'
                                  menuColor='red'
                                  options={this.state.currencyData}
                                  value={contract}
                                  onChange={this.changecontract}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 col-xl-2 col-md-4 col-sm-6">
                              <div className="form-group clearfix">
                                <label>{t("TYPE")}</label>
                                <Select isSearchable={false}
                                   styles={this.styles} className="border_blue_select"
                                  width='100%'
                                  menuColor='red'
                                  options={options1}
                                  value={type}
                                  onChange={this.changetype}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 col-xl-2 col-md-4 col-sm-6">
                              <div className="form-group">
                                <label>{t("START_DATE")}</label>
                                <DatePicker
                                  selected={this.state.startDate}
                                  onChange={this.handleChange}
                                  showYearDropdown
                                  showMonthDropdown
                                  // minDate={new Date()}
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 col-xl-2 col-md-4 col-sm-6">
                              <div className="form-group">
                                <label>{t("END_DATE")}</label>
                                <DatePicker
                                  selected={this.state.endDate}
                                  onChange={this.handleChange1}
                                  showYearDropdown
                                  showMonthDropdown
                                  minDate={new Date(new Date(this.state.startDate).setDate(new Date(this.state.startDate).getDate() + 1))}
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 col-xl-2 col-md-4 col-sm-6">
                              <div className="form-group">
                                <label className="d-none d-md-block invisible">s</label>
                                <input type="button" name="" value={t("SEARCH")} onClick={this.withdrawsearch} className="buttonType1 ml-0 ml-xl-auto themebtn" />
                              </div>
                            </div>

                            <div className="col-lg-3 col-xl-2 col-md-4 col-sm-6">
                          <div className="downLoadBTN float-right">
                            <div className="form-group">
                              <label className="d-none d-sm-block invisible">s</label>

                              {(this.state.csvData.length > 0) ?
                                <CSVLink data={this.state.csvData} className="btn transYellowButton">{t("CSV")}</CSVLink> : ''
                              }
                            </div>
                          </div>
                        </div>
                          </div>
                        </div>
                       
                      </div>
                    </div>
                  </div>

                    <div className="table-responsive table_radius walletBalanceTable_td_cen">
                      {this.state.historydetails ?
                          <table id="assetsTable" className="table">
                            <thead>
                              <tr>
                                <th>{t("COIN")}</th>
                                <th>{t("AMOUNT")}</th>
                                <th>{t("TRANSACTION_FEE")}</th>
                                <th>{t("WALLET_ADDRESS")}</th>
                                <th>{t("STATUS")}</th>
                                <th>{t("TIME")}</th>
                                <th>{t("WITHDRAWAL_REMARKS")}</th>
                                <th>{t("ACTION")}</th>
                              </tr>
                            </thead>
                            {this.state.historydetails.length>0 ?
                              <>
                            <tbody>
                              {
                                historydetails.map((item, i) => {
                                  var cryptoType = item.cryptoType ? item.cryptoType : '';
                                  var transferamount = item.transferamount ? item.transferamount : 0;
                                  var finalamount = item.finalamount ? item.finalamount : 0;
                                  // var receiveraddress = item.receiveraddress?item.receiveraddress.address:'';
                                  var receiveraddress = item.receiverCryptoAddress ? item.receiverCryptoAddress : '';
                                  var status = item.status ? item.status : '';
                                  var created_date = item.created_date ? item.created_date : '';
                                  var transactionId = item.transactionId ? item.transactionId : '';

                                  var data1 = new Date(created_date);
                                  let date12 = data1.getFullYear() + '-' + (data1.getMonth() + 1) + '-' + data1.getDate() + ' ' + data1.getHours() + ':' + data1.getMinutes() + ':' + data1.getSeconds();
                                  return <tr>
                                    <td>{cryptoType}</td>
                                    <td>{parseFloat(finalamount).toFixed(8)}</td>
                                    <td>{parseFloat(+finalamount - +transferamount).toFixed(8)}</td>
                                    <td>{receiveraddress}</td>
                                    <td>{status}</td>
                                    <td>{date12}</td>
                                    <td>{transactionId}</td>
                                    {(status == 'Pending') ?
                                      <td><a href="#" onClick={this.cancelwithdrawal.bind(this)} id={item._id}>Cancel</a></td> :
                                      <td></td>}
                                  </tr>
                                })
                              }
                            </tbody></>:
                            (<tbody>
                              <tr ><td colspan="8" className="text-center">{t("NO_RECORDS")}</td></tr></tbody>)
  }
                          </table>: ''}
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

WithdrawalHistory.propTypes = {
  Searchwithdata: PropTypes.func.isRequired,
  withdrawalhistory: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(

  mapStateToProps,
  {
    withdrawalhistory,
    cancelwithdraw,
    Searchwithdata,
  }
)(withTranslation()(WithdrawalHistory),withRouter(WithdrawalHistory));
