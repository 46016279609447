import React, { Component } from 'react'
import '../css/style-trade.css';
import LogoNewFixHead from "../images/logo.png"
import LogoLightTheme from "../images/logodark.png";
import Selicon1 from "../images/selicon1.png"
import Selicon2 from "../images/selicon2.png"
import Selicon4 from "../images/selicon4.png"
import Selicon5 from "../images/selicon5.png"
import Selicon6 from "../images/selicon6.png"
import { Link, withRouter, NavLink } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { store } from 'react-notifications-component';
import { logoutUser, getPertual, getPricevalue, getspotPertual, getspotPricevalue } from "../actions/authActions";
import classnames from "classnames";
import io from "socket.io-client";
import keys from "../actions/config";
import { withTranslation } from 'react-i18next';
import { Languagechange } from './i18next/i18n';
import { languageChanger } from '../actions/authActions';
const url = keys.baseUrl;

class TradeHeader extends Component {
  constructor(props) {
    super(props);
    if (!localStorage.getItem('curpair') || localStorage.getItem('curpair') == 'null') {
      localStorage.setItem('curpair', 'BTC-USDT')
    }
    if (!localStorage.getItem('curpair1') || localStorage.getItem('curpair1') == 'null') {
      localStorage.setItem('curpair1', 'BTC-USDT')
    }
    this.state = {
      email: "",
      password: "",
      username: "",
      errors: {},
      records: [],
      secondcurrency: localStorage.getItem('curpair1').split('-')[1],
      firstcurrency: localStorage.getItem('curpair1').split('-')[0],
      prevoiusprice: 0,
      floating: 2,
      change: 0,
      btcvolume: 0,
      btcprice: 0,
      spotpricecolor: 'greenText',
      show: false,
    };
    this.getData();

    this.socket = io(keys.socketUrl, { secure: true, transports: ['polling'], jsonp: false, rejectUnauthorized: false }); //live
    // this.socket = io(keys.socketUrl,{transports:['polling']});
    this.socket.on('TRADE', function (data) {
      this.getData();
    });
    this.socket.on('PRICEDETAILS', function (data) {
      // //console.log(data, 'PRICEDETAILS');
      priceappenddata(data);
    });


    const priceappenddata = data => {
      //console.log(data, 'data')
      if (data && data.tiker_root == 'BTCUSD') {
        this.setState({ btcprice: parseFloat(data.markprice).toFixed(2) })
      }
      if (data && data.tiker_root == localStorage.getItem('curpair').replace("-", "")) {
        var floating = (data.tiker_root == 'XRPUSD') ? 4 : 2;
        this.setState({ markprice: parseFloat(data.markprice).toFixed(floating), last_price: data.last, change: data.change });
        if (this.state.prevoiusprice != 0 && this.state.prevoiusprice < data.markprice) {
          this.setState({ spotpricecolor: "greenText" });
        }
        else {
          this.setState({ spotpricecolor: "pinkText" });
        }
        this.setState({ prevoiusprice: this.state.markprice, floating: floating });
      }

    }
    this.showNavbar = this.showNavbar.bind(this);

  }

  showNavbar() {
    this.setState({ show: !this.state.show });
  }

  onLogoutClick = e => {
    const {t} = this.props;
    e.preventDefault();
    store.addNotification({
      title: t("LOGOUT_SUCCESS"),
      message: t("COME_JOIN"),
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
    let data = { userId: this.props.auth.user.id }
    this.props.logoutUser(data);
  };

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      // this.props.history.push("/settings");
    }
    // let themeData = localStorage.getItem('theme')
    // console.log("THEMEDATA", themeData);
    // if(themeData && themeData != ""){
    //   this.themechange(themeData)
    // }else{
    //   this.themechange('darktheme')
    // }
  };

  componentWillReceiveProps(nextProps) {

    if (nextProps.auth.isAuthenticated) {
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    else {
      this.setState({ errors: {} });
    }
    if (nextProps.auth.trade !== undefined
      && nextProps.auth.trade.data !== undefined
      && nextProps.auth.trade.data.data !== undefined
      && nextProps.auth.trade.data.type !== undefined
      && nextProps.auth.trade.data.type == 'perpetual'
      && nextProps.auth.trade.data.data.length > 0
    ) {
      if (nextProps.auth.trade.data.data.length > 0) {
        //console.log(nextProps.auth.trade.data.type, 'type')
        //console.log(nextProps.auth.trade.data.data, 'Perpetual data');
        var index = nextProps.auth.trade.data.data.findIndex(x => (x.tiker_root) === this.state.firstcurrency + this.state.secondcurrency);
        var btcindex = nextProps.auth.trade.data.data.findIndex(x => (x.tiker_root) === 'BTCUSD');
        var perparray = nextProps.auth.trade.data.data;
        var indexarr = [0, 1, 2, 3, 4];
        var outputarr = indexarr.map(i => perparray[i]);

        this.setState({
          records: outputarr, markprice: (index != -1) ? nextProps.auth.trade.data.data[index].markprice : 0,
          btcprice: (btcindex != -1) ? parseFloat(nextProps.auth.trade.data.data[btcindex].markprice).toFixed(2) : 0,
        });
        //console.log(this.props.location.pathname, 'pathname')
        if (this?.props?.location?.pathname == '/closedPandL') {
          //console.log(index, 'index')
          if (index != '-1') {
            this.props.pairdatachange(nextProps.auth.trade.data.data);
          }
        }
      }
    }

    if (nextProps.auth.trade !== undefined
      && nextProps.auth.trade.data !== undefined
      && nextProps.auth.trade.data.pricedet !== undefined && nextProps.auth.trade.data.pricedet.length > 0) {
      var floating = (localStorage.getItem('curpair').replace("-", "") == 'XRPUSD') ? 4 : 2;
      var btcvalue = 0;
      //console.log(nextProps.auth.trade.data.pricedet, 'pricedet')
      if (nextProps.auth.trade.data.pricedet.length > 0) {
        var usdvalue = parseFloat(this.state.markprice) * parseFloat(nextProps.auth.trade.data.pricedet[0].volume);
        var btcvalue = parseFloat(usdvalue) / parseFloat(this.state.btcprice);
        //console.log(btcvalue, 'btcvalue')
        //console.log(usdvalue, 'usdvalue')
        //console.log(this.state.markprice, 'markprice')
        //console.log(parseFloat(this.state.btcprice), 'btcprice')
      }
      this.setState({
        pricedet: nextProps.auth.trade.data.pricedet,
        change: nextProps.auth.trade.data.pricedet[0].change,
        floating: floating,
        btcvolume: isNaN(parseFloat(btcvalue)) ? 0 : btcvalue,
      });
    }
  }

  getData() {
    var pairdata = {
      "firstCurrency": this.state.firstcurrency,
      "secondCurrency": this.state.secondcurrency,
    }
    this.props.getspotPertual();
    this.props.getspotPricevalue(pairdata);

  }

  themechange(data) {
    console.log("LOCALSTORAGE", data)
    if (document.getElementById("root").classList.contains("lighttheme")) {
      document.getElementById("root").classList.add("darktheme")
      document.getElementById("root").classList.remove("lighttheme");
      document.getElementById("modaltheme").classList.add("darkmodal")
      document.getElementById("modaltheme").classList.remove("lightmodal");
      localStorage.setItem('theme', 'darktheme')
      this.setState({ theme: false });
      console.log("LOCALSTORAGE", data)
    } else {
      document.getElementById("root").classList.remove("darktheme")
      document.getElementById("root").classList.add("lighttheme")
      document.getElementById("modaltheme").classList.add("lightmodal")
      document.getElementById("modaltheme").classList.remove("darkmodal");
      localStorage.setItem('theme', 'lighttheme')
      console.log('true enter')
      this.setState({ theme: true })
      console.log("LOCALSTORAGE", data)
    }
  }
  
  // render() {
  //   const themechange = () =>{
  //     if(document.getElementById("root").classList.contains("lighttheme")){
        
  //       document.getElementById("root").classList.add("darktheme")
  //       document.getElementById("root").classList.remove("lighttheme");
  //       document.getElementById("modaltheme").classList.add("darkmodal")
  //       document.getElementById("modaltheme").classList.remove("lightmodal");
  //       this.setState({theme:false})
  //       console.log('falseenter')
  //   //     let themes = false
  //   //     localStorage.setItem('theme',themes)
  //   //     let localstoragedata = localStorage.getItem('theme')
  //   //  console.log('localstoragedata>>>>>>>>>>>>>>>>>>>>>>>>>',localstoragedata)
  //   //  this.setState({localstoragedata : localstoragedata})

  //       // this.state.theme === false
  //     }else{
  //       document.getElementById("root").classList.remove("darktheme")
  //       document.getElementById("root").classList.add("lighttheme")
  //       document.getElementById("modaltheme").classList.add("lightmodal")
  //       document.getElementById("modaltheme").classList.remove("darkmodal");
  //       console.log('true enter')
  //       // this.state.theme === true
  //       this.setState({theme:true})
    //     let themes = true
    //     localStorage.setItem('theme',themes)
    //     let localstoragedata = localStorage.getItem('theme')
    //  console.log('localstoragedata>>>>>>>>>>>>>>>>>>>>>>>>>',localstoragedata)
    //  this.setState({localstoragedata : localstoragedata})
    swiftlanguage(data){
      console.log("swiftlanguage_swiftlanguage",data);
      const local = localStorage.getItem('lang');
      this.props.languageChanger(data == ""? local : data)
      };
  render() {
    const themechange = () =>{
          if(document.getElementById("root").classList.contains("lighttheme")){
            
            document.getElementById("root").classList.add("darktheme")
            document.getElementById("root").classList.remove("lighttheme");
            document.getElementById("modaltheme").classList.add("darkmodal")
            document.getElementById("modaltheme").classList.remove("lightmodal");
            this.setState({theme:false})
            console.log('falseenter')
          }else{
            document.getElementById("root").classList.remove("darktheme")
            document.getElementById("root").classList.add("lighttheme")
            document.getElementById("modaltheme").classList.add("lightmodal")
            document.getElementById("modaltheme").classList.remove("darkmodal");
            this.setState({theme:true})
            let themes = true
            localStorage.setItem('theme',themes)
            let localstoragedata = localStorage.getItem('theme')
         console.log('localstoragedata>>>>>>>>>>>>>>>>>>>>>>>>>',localstoragedata)
         this.setState({localstoragedata : localstoragedata})
          }}

    const { user, getUser } = this.props.auth;
    const {t} = this.props;
    console.log("yyyyyyyyyyyyyyyyyyyyyyy", getUser, getUser && getUser.name, this.props.auth)
    const { records } = this.state;

    return (
      <div>
        <nav className="navbar navbar-expand-lg fixed-top customNavTrade">
          <span className="tradeMobile tradeMobile_frlx">
            <Link to="/" className="navbar-brand innerLogo">
              <img className="top_head img-fluid logoDark" src={LogoNewFixHead} />
              <img className="top_head img-fluid logoLight" src={LogoLightTheme} />
            </Link>
            <button className="navbar-toggler customNavbarToggler" type="button" onClick={this.showNavbar}>
              <i className="fas fa-bars"></i>
            </button>
          </span>


          <div className={this.state.show ? "collapse navbar-collapse show collapse_menu" : "collapse navbar-collapse collapse_menu"} id="navbarResponsive">
            <ul className="navbar-nav ml-auto">
              {/* <li className="nav-item dropdown dmenu mr-2">
                  <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                  Trade
                  </a>
                  <div className="dropdown-menu sm-menu">
                   <Link to={"/spot/"+localStorage.getItem('curpair1')} className="dropdown-item nav-link">Spot</Link>
                   <Link to={"/trade/"+localStorage.getItem('curpair')} className="dropdown-item nav-link">Derivatives</Link>
                   <Link to="#" className="dropdown-item nav-link">Instant Exchange [Coming soon]</Link>
                  </div>
              </li> */}
              {/* <li className="nav-item dropdown dmenu mr-2">
                  <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                  Market
                  </a>
                  <div className="dropdown-menu sm-menu">
                   <Link to={"/spot/"+localStorage.getItem('curpair1')} className="dropdown-item nav-link">Spot</Link>
                   <Link to={"/trade/"+localStorage.getItem('curpair')} className="dropdown-item nav-link">Derivatives</Link>

                  </div>
              </li> */}
                <li className="nav-item dropdown dmenu mr-2">
                  <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                  {t("TRADE")} 
                  </a>
                  <div className="dropdown-menu sm-menu menu_default_show">
                   <Link to={ "/spot/"+localStorage.getItem( 'curpair1')} className="dropdown-item nav-link">{t("SPOT")}</Link>
                   <Link to={ "/trade/"+localStorage.getItem( 'curpair')} className="dropdown-item nav-link">{t("DERIVATIVES")}</Link>
                  </div>
                </li>
                  {/* <li className="nav-item">
                    <NavLink to="/copy-trading" className="nav-link">Copy Trading</NavLink>
                  </li>    */}
              {/* <li className="nav-item dropdown dmenu mr-2">
                  <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                  EARN
                  </a>
                  <div className="dropdown-menu sm-menu">
                   <Link to={"/Stacking/"} className="dropdown-item nav-link">Staking</Link>
                  
                  </div>
              </li> */}

              {/* <li className="nav-item">
                <Link to="/blog" className="nav-link">Blog</Link>
              </li> */}
               <li className="nav-item mb-2 mb-lg-0">
                    <Link to="/launchpad" className="nav-link">{t("LAUNCHPAD")}</Link>
                  </li>
              <li className="nav-item">
                <NavLink to="/MyAssets" className="nav-link">{t("WALLET")}</NavLink>
              </li>

              {/*<li className="nav-item dropdown dmenu mr-2">
                  <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                  Product
                  </a>
                  <div className="dropdown-menu sm-menu">
                   <Link to="#" className="dropdown-item nav-link">Coming Soon</Link>
                  </div>
              </li>
              <li className="nav-item dropdown dmenu mr-2">
                  <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                  Tools
                  </a>
                  <div className="dropdown-menu sm-menu">
                   <Link to="#" className="dropdown-item nav-link">Ecosystem</Link>
                   <Link to="#" className="dropdown-item nav-link">Roadmap</Link>
                   <Link to="#" className="dropdown-item nav-link">Wallet</Link>
                   <Link to="#" className="dropdown-item nav-link">Explorer</Link>
                  </div>
              </li>*/}
              {/* <li className="nav-item dropdown dmenu mr-2 menu_not_hide_mob">
                    <a className="nav-link dropdown-toggle navbardrop_pos menu_default_hide menu_not_hide" href="#" id="navbardrop1" data-toggle="dropdown">
                     <div className='noti_hader'>
                     <i className='fa fa-bell'></i>
                     <span className='noti_count_bue'>16</span>

                     </div>
                    </a>
                    <div className="dropdown-menu sm-menu menu_default_show dropdown_menu_notif_md">
                    <div className="notificationDropdown noti_child_po" tabindex="0" autofocus="">
    <div className="text-right">
        <button className="mark_read_link">Mark all as read </button>
    </div>
    <ul>
        <li>
            <p>43d ago</p>
            <h5>Login Successfully</h5>
        </li>
        <li>
            <p>43d ago</p>
            <h5>Login Successfully</h5>
        </li>
        <li>
            <p>43d ago</p>
            <h5>Login Successfully</h5>
        </li>
        <li>
            <p>43d ago</p>
            <h5>Login Successfully</h5>
        </li>
        <li>
            <p>43d ago</p>
            <h5>Login Successfully</h5>
        </li>
        <li>
            <p>42d ago</p>
            <h5>Login Successfully</h5>
        </li>
        <li>
            <p>42d ago</p>
            <h5>Login Successfully</h5>
        </li>
      </ul>
      
           

            <p className='mb-0 pb-3 pt-3'>
          <a className="all_noti_link_green" href="/notification">All Notifications</a>
        </p>
    </div>
                                         
                    
                    </div>
                  </li> */}

              {/* <li className="nav-item px-0 menu_show_mobile_onlye">
                    <Link to="/notification" className="nav-link">Notifications</Link>
                  </li> */}
              <li className="nav-item dropdown dmenu">

                <a className="nav-link dropdown-toggle menu_default_hide" href="#" id="navbardrop" data-toggle="dropdown">

                  {/* {
                   getUser&&getUser.name==''?
                
                  ( user.email != 'undefined')?(user.email.split('@')[0].length<8)?user.email.split('@')[0]:user.email.split('@')[0].substring(0, 7):''
                 :
                 ( user.email != 'undefined')?(user.email.split('@')[0].length<8)?user.email.split('@')[0]:user.email.split('@')[0].substring(0, 7):''

                 
                } */}
                  {console.log("GETUSERNAME..", user && user.name)}
                  {

                    user && user.name == '' || undefined ?

                      (user.email.split('@')[0].length < 8) ? user.email.split('@')[0] : user.email.split('@')[0].substring(0, 7)

                      :
                      user && user.name

                  }
                </a>
                <div className="dropdown-menu sm-menu menu_default_show">
                  <NavLink to="/Settings" className="dropdown-item nav-link">{t("SETTINGS")}</NavLink>
                  {/* <NavLink to="/Kyc" className="dropdown-item nav-link">KYC</NavLink> */}

                  <NavLink to="/AssetsHistory" className="dropdown-item nav-link">{t("HISTORY")}</NavLink>
                  {/* <Link to="/StakingHistory" className="dropdown-item nav-link">Staking History</Link> */}

                  {/* <NavLink to="/Referral_Program" className="dropdown-item nav-link">Referral Program</NavLink> */}

                  {/* <Link  to="/Leverage" className="dropdown-item">Leverage</Link> */}
                  <a className="dropdown-item nav-link" onClick={this.onLogoutClick} href="#">{t("LOGOUT")}</a>
                </div>
              </li>
              <li className="nav-item dropdown dmenu mr-2  ml-lg-3">
                  <a className="nav-link dropdown-toggle mobile pl-0" href="#" id="navbardrop" data-toggle="dropdown">
                  {/* {t("LANGUAGE")}  */}
                  {localStorage.getItem('lang') == 'en' || localStorage.getItem('lang') == '' ? 'ENGLISH' : 'español'}
                  </a>
                  <div className="dropdown-menu sm-menu menu_default_show">
                   <a onClick={()=>{Languagechange('en'); this.swiftlanguage("en")}} className="dropdown-item nav-link">English</a>
                   <a onClick={()=>{Languagechange('sp'); this.swiftlanguage("sp")}} className="dropdown-item nav-link">español</a>
                  </div>
              </li>
              <li>
                <button className='transbtn theme' onClick={()=>{this.themechange(localStorage.getItem('theme'))}}><span className={this.state.theme ? 'fa fa-moon' : 'fa fa-sun'} ></span></button>
              </li>

            </ul>
          </div>
        </nav>
      </div>
    );
  }
}


TradeHeader.propTypes = {
  getPricevalue: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getPertual: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  languageChanger: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { logoutUser, getPertual, getPricevalue, getspotPertual, getspotPricevalue, languageChanger }
)(withTranslation()(TradeHeader),withRouter(TradeHeader));
