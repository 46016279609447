
import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import TradeHeader from './TradeHeader'
import Footer from './Footer';
import moment  from 'moment';
import SupportTicketImg from "../images/supportTicket.png"
import $ from 'jquery';
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { store } from 'react-notifications-component';
import { connect } from "react-redux";
import { support,closeTicket } from "../actions/authActions";
import classnames from "classnames";
import keys from "../actions/config";
import axios from "axios";
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
const url = keys.baseUrl;

class SupportTicket extends Component {
	 constructor() {
        super();
        this.state = {
             id:"",
            email_add: "",
            subject:"",
            description: "",
            attachment:"",
            attachmenterr:"",
            validation:{},
            errors: {},
            records:[],
            
        };
         this.onSubmit = this.onSubmit.bind(this);
    }


    componentDidMount() {
      window.scrollTo(0, 0);
      this.getsupportData();
  };
    componentWillReceiveProps(nextProps) {

      console.log("nextpropssssssssssssssssss",nextProps)
        if (nextProps.errors) {
        	//console.log(nextProps,'nextPropsasadxsdsf');
            this.setState({
                errors: nextProps.errors
            });
            
        }
        else{
          this.setState({
            errors: {}
        });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.support !== undefined
            && nextProps.auth.support.data !== undefined
            && nextProps.auth.support.data.message !== undefined) {
           store.addNotification({
            title: "Success!",
            message: nextProps.auth.support.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
          this.setState({
            subject:"",
            description: "",
            attachment:"",
          })
            nextProps.auth.support = undefined;
            this.getsupportData();
              //console.log(this.state,'thissssssssssssssssssssssssstate');
            
        }
    }
 
    onChange = e => {
      if(e.target.id == "subject"){
        if (!/^[ A-Za-z0-9_@./,#&]*$/.test(e.target.value))
                return
         }
        this.setState({ [e.target.id]: e.target.value });
     
    };
 handleChange = (event) => {
  
      this.setState({
      //  profileurl: URL.createObjectURL(event.target.files[0]),
        attachment: event.target.files[0]
      })
    }

    getsupportData() {
      const id = this.props.auth.user.id;

           axios
           .get(url + "cryptoapi/supportget/" + id)
          .then(res => {
            console.log("getsupportData",res)
        
         this.setState({ records: res.data});
          })

        }
    onSubmit = e => {
      e.preventDefault();

      //console.log(this.props.errors,'errrrrrrr');
      if (this.validateForm()) {
        let fields = {};
        fields["email_add"] = "";
        fields["subject"] = "";
        fields["description"] = "";
        fields["attachment"] = "";
        this.setState({validation:fields});
        //alert("Form submitted");  
        const Newsupport = {
          email_add: this.state.email_add,
          subject: this.state.subject,
          description: this.state.description,
          attachment: this.state.attachment
        };
        const data = new FormData();
        console.log("eeeeeeeeeeeeeeeeeee",this.state.attachment)
        // data.append('email_add', this.state.email_add);
        data.append('subject', this.state.subject);
        data.append('description', this.state.description);
        data.append('file', this.state.attachment);
        data.append('userId', this.props.auth.user.id);
        this.props.support(data);
      }
    };

    validateForm() {

      let fields = this.state.validation;
      let errors = {};
       let imageFormat = /\.(jpg|JPG|docx|DOCX|png|PNG)$/;
      var MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
      errors["email_add"] = '';
      errors["subject"] = '';
      errors["description"] = '';
      errors["attachment"] = '';
      let formIsValid = true;

      // if (this.state.email_add=='') {
      //   formIsValid = false;
      //   errors["email_add"] = "*Please enter your email-ID.";
      // } else if (typeof fields["email_add"] !== "undefined") {
      //   var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      //   if (!pattern.test(fields["email_add"])) {
      //     formIsValid = false;
      //     errors["email_add"] = "*Please enter valid email-ID.";
      //   }
      // }
       if (this.state.attachment.size > MAX_FILE_SIZE) {
        formIsValid = false;
        errors["attachment"] = "*Image is Above 5MB";
      }
      if (!imageFormat.test(this.state.attachment.name)) {
        formIsValid = false;
        errors["attachment"] = "*Image Should Allowed only pdf/docx/jpg";
      }
      if (this.state.subject=='') {
        formIsValid = false;
        errors["subject"] = "*Please enter your Subject";
      }
      if (this.state.description=='') {
        formIsValid = false;
        errors["description"] = "*Please enter your Description";
      }

      this.setState({
        errors: errors
      });
      return formIsValid;

    }
    viewChat(id){
      this.setState({chatopen:true});
      this.props.history.push("/supportreply/"+id);

}
 closeticket=async (id)=>{

  this.props.closeTicket({id:id});

  this.getsupportData();
   }

  render() {
 
  	const { errors } = this.state;
    return (
			<div className='bg_strip'>
				<TradeHeader />
				<div className="container-fluid">
				<section className="tradeMain">
				  <div className="row py-5 mx-auto">
				    <div className="col-xl-5 col-lg-8 col-md-10 col-11 mx-auto px-0">
				      <div className="darkBox supportTicket border-top-one">
				        <div className="tableHead tableHeadBlock">
				          <h2>Support Tickeasdfasdfst</h2>
				        </div>
				        <div className="darkBoxSpace spac_pad_mob">
				          <form name="supportTicket" onSubmit={this.onSubmit}>
				            <div className="row w-100 mx-0">
				              {/* <div className="col-md-6">
				                <div className="form-group">
				                  <label>Your email address<sup>*</sup></label>
							                  <input name="email_add"
			                onChange={this.onChange}
			                value={this.state.email_add}
			                error={errors.email_add}
			                id="email_add"
			                type="email"
			                name="email_add"
			                className={classnames("form-control", {
			                    invalid: errors.email_add
			                })}
			            />
			     
            <span className="text-danger">{errors.email_add}</span>
				                </div>
				              </div> */}
				              <div className="col-md-12">
				                <div className="form-group clearfix">
				                  <label className='blue_lable_cont'>Subject</label>
				                 <input name="subject"
                onChange={this.onChange}
                value={this.state.subject}
                error={errors.subject}
                id="subject"
                type="text"
                className={classnames("form-control", {
                    invalid: errors.subject
                })}
            />
           <span className="text-danger">{errors.subject}</span>
				                </div>
				              </div>
				              <div className="col-md-12">
				                <div className="form-group">
				                  <label className='blue_lable_cont'>Description <sup>*</sup></label>
				                   <textarea name="description"
                onChange={this.onChange}
                value={this.state.description}
                error={errors.description}
                id="description"
                type="text"
                className={classnames("form-control", {
                    invalid: errors.description
                })}
            />
            
             <span className="text-danger">{errors.description}</span>

				                </div>
				              </div>

				              <div className="col-md-12">
				                <div className="form-group">
				                   <label className='blue_lable_cont'>Attachments if any *  <span style={{color:"gray"}}>Image Should Allowed only pdf/docx/jpg/png</span></label>

                           <div className="custom-file">
                           <input 
                              type="file"  
                              onChange={this.handleChange}
                               name="file1" className="custom-file-input" id="exampleInputFile" aria-describedby="fileHelp" />
                                  <label className="custom-file-label" for="exampleInputFile">
                                  {this.state.attachment != null && 
                                    this.state.attachment.name
                                  }
                                  {this.state.attachment == null && 
                                    "Select File"
                                  }
                                  </label>
                                </div>
                                <span style={{color:"red"}}>{errors.attachment}</span>

                                
				                   {/* <label className="custom-file form-control" id="customFile">
				                      <input 
                              type="file"  
                              onChange={this.handleChange}
                               name="file1" className="custom-file-input" id="exampleInputFile" aria-describedby="fileHelp" />
				                      <span className="custom-file-control form-control-file"></span>
				                  </label>
                          <span style={{color:"red"}}>{errors.attachment}</span> */}

				                </div>
				              </div>
				              <div className="col-md-12 mt-3 mb-3">
				                <div className="form-group">
				                  <input type="submit" name="" value="Submit" className="btn buttonType1" />
				                </div>
				              </div>
                      <div className="col-md-12">
				                <div className="noteText">

                     <div className="table-responsive">
								<table id="assetsTable" className="table walletBalanceTable mb-0">
									<thead>
										<tr>
											<th>Created Date</th>
											<th>Ticket ID</th>
											<th>Status</th>
											<th>Subject</th>
											<th>Action</th>
											{/* <th>Equity</th>
											<th>Derivatives Balance</th>
											<th>Position Margin</th>
											<th>Order Margin</th> */}
										</tr>
									</thead>
									<tbody>

                  {
                            this.state.records && this.state.records.map((row,i)=>{
                              console.log("row",row)
                            return(
                              <tr key={i}>
                                <td> {moment(row.created_date).format('MMMM,Do YYYY, hh:mm A')}</td>
                             <td> {row._id}</td>
                             <td>{row.ticketStatus}</td>
                             <td> {row.subject}</td>
                             <td>


                             {
                                  row.ticketStatus=="open" &&
                                  <>
                                  <button onClick={()=>this.viewChat(row._id)} className="btn buttonType1 mr-2" >View</button>

                                  <button onClick={()=>this.closeticket(row._id)} className="btn buttonType1" >Close Ticket</button>
                            </>
                             }{

                              row.ticketStatus=="closed" &&
                              // <p >View Only Chat </p>

                              <button onClick={()=>this.viewChat(row._id)} className="btn buttonType1" >View Only Chat</button>


                             }
                             </td>
                           
                             
                              </tr>
                            )
                            })
                          }
									
									</tbody>
								</table>
							</div>
				                  {/* <h6>Notes</h6>
				                  <p>1. Please enter your 6 digit account UID if you have already registered with us. This field is compulsory if you registered account with your mobile number.</p>
				                  <p>2. Please enter the details of your request. For trade related inquiries, please indicate the affected order# and describe the issue you encountered in details. 
				                  A member of our support staff will respond as soon as possible.</p> */}
				                </div>
				              </div>
				              <div className="col-md-12 mt-3">
				                <div className="noteText">
				                  <h6>Notes</h6>
				                  <p>1. Please enter your 6 digit account UID if you have already registered with us. This field is compulsory if you registered account with your mobile number.</p>
				                  <p>2. Please enter the details of your request. For trade related inquiries, please indicate the affected order# and describe the issue you encountered in details. 
				                  A member of our support staff will respond as soon as possible.</p>
				                </div>
				              </div>
				            </div>
				          </form>
				        </div>
				      </div>
				    </div>
				  </div>
				</section>
				</div>
				<Footer />
			</div>
			);
    }
}
SupportTicket.propTypes = {
  support: PropTypes.func.isRequired,
  closeTicket:PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { support ,closeTicket}
)(withRouter(SupportTicket));

