// import React from "react";
// import { Route, Redirect } from "react-router-dom";
// import { connect } from "react-redux";
// import PropTypes from "prop-types";

// const PrivateRoute = ({ component: Component, auth, ...rest }) => (
//     <Route
//         {...rest}
//         render={props => {
//             console.log(props,'proprsssssss')
//             console.log(auth,'authauthauthauth')
//             auth.isAuthenticated === true ? (
//                 <Component {...props} />
//             ) : (
//                 <Redirect to="/login" />
//             )
//         }
//        } 
//     />
// );


// PrivateRoute.propTypes = {
//     auth: PropTypes.object.isRequired
// };

// const mapStateToProps = state => ({
//     auth: state.auth
// });


// export default connect(mapStateToProps)(PrivateRoute);


import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useDispatch } from 'react-redux';

// import action
// import { setThemeData } from '../../actions/trade';

// import lib
import isLogin from '../../lib/isLogin';
// import { getTheme } from '../../lib/localStorage';

const ConditionRoute = ({ component: Component, auth, type, ...rest }) => {
    const dispatch = useDispatch();

    return (
        <Route
            {...rest}
            render={props => {
             

                if (type == 'auth' && isLogin() === true) {
                    return <Redirect to="/dashboard" />
                } else if (type == 'private' && isLogin() === false) {
                    return <Redirect to="/login" />
                }

                return <Component {...props} />
            }}
        />
    )

};

export default ConditionRoute;