import React, {
  Component
} from 'react'
import { a } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Footer from './Footer'
import axios from "axios";
import { Link, withRouter } from 'react-router-dom';
import keys from "../actions/config";

import  BecomeTrader_Ref  from './separate/becomeTrader';
import Navtradebar from './Navtradebar'

import { logoutUser } from "../actions/authActions";
import { toFixed } from "../lib/roundOf";
import { withTranslation } from 'react-i18next';
import { store } from 'react-notifications-component';
import config from '../actions/config';
import {
  getTableDataDynamic
} from "../actions/authActions";

const url = keys.baseUrl;

class CopyTrading extends Component {

  constructor(props) {
    super(props);
console.log(props,'props')
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.getTradersList = this.getTradersList.bind(this);
    this.traderFilter = this.traderFilter.bind(this);
    this.traderCronUpdate = this.traderCronUpdate.bind(this);

    this.state = {
      imTrader: false,
      isTrader: false,
      imFollower:false,
      userId: '',
      subject:"",
      content: "",
      errors: {},
      privacy : {},
      cmsData : {},
      traders: [],
      search: '',
      filter: ''
    };
    this.BecomeTrader_Ref_Forward = React.createRef();
  }

  onLogoutClick = e => {
    const {t} = this.props;
    e.preventDefault();
    store.addNotification({
      title: t("LOGOUT_SUCCESS"),
      message: t("COME_JOIN"),
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
    let data = {userId:this.props.auth.user.id}
    this.props.logoutUser(data);
  };

  async componentDidMount() {

    window.scrollTo(0, 0);
    if(this.props.auth.user && this.props.auth.user.id) {
      this.setState({userId: this.props.auth.user.id});
    }
    this.gettingCmsData();
    await this.traderCronUpdate();
    this.getTradersList();
  };

  componentWillReceiveProps(nextProps) {
    console.log('nextProps : ', nextProps);
  }

  async traderCronUpdate() {
    if(this.props.auth.user && this.props.auth.user.id) {
      const data = { userId: this.props.auth.user.id }
      axios.post(url + "cryptoapi/traderDataUpdate", data);
    }
  }

  gettingCmsData = async () => {
    const data = { identifier: "Privacy Policy" }
    const result = await axios.post(url + "cryptoapi/getSingleCmsData", data);
    const cmsData = result.data.result;
    this.setState({cmsData: cmsData})
    // var dynobj = {}
    // dynobj.find = {type: 'main'};
    // dynobj.table = {name: 'CopyTradeSettingsTable'};
    // dynobj.return = {name: 'CopyTradeSettingsData'};
    // this.props.getTableDataDynamic(dynobj);
  }

  onChangeSearch(e) {
    this.setState({search: e.target.value});
    this.getTradersList(e.target.value);
  }

  traderFilter(target) {
    console.log('traderFilter target : ', target);
    this.setState({filter: target});
    this.getTradersList(this.state.search, target);
  }

  getTradersList(searchValue = this.state.search, filterValue = this.state.filter) {
    var data = {
      search: searchValue,
      filter: filterValue,
    };
    if(this.props.auth.user && this.props.auth.user.id) {
      data.userId = this.props.auth.user.id;
    }
    axios.post(url+'cryptoapi/getAllTraders', data).then(response => {
      console.log(response.data.imTrader,response.data.imFollower,'responseeeeeeeeeeeeee')
      var jsonState = {
        traders: response.data.result,
        imTrader: false,
        imFollower:false
      }
      if(response.data.imTrader) {
        jsonState.imTrader = response.data.imTrader;
      }
      if(response.data.imFollower){
        jsonState.imFollower = response.data.imFollower
      }
      this.setState(jsonState);
      this.setState({imFollower:response.data.imFollower})
      this.setState({isTrader:response.data.imTrader})
    }).catch(e => {
      console.log(e);
    });
  }

  renderPrivacyPolicyText() {
    return (
      <div>
      </div>
    )
  }

  followTrader(traderId, e) {
    if(e.target.id && e.target.id == 'traderFollorBtn') {
      const { userId } = this.state;
      if(userId) {
        this.props.history.push("/copy-trading-setting?id="+traderId);
      } else {
        this.props.history.push("/login");
      }
    }
  }
  detailTrader(traderId, e) {
    if(e.target.id && e.target.id == 'traderFollorBtn') {
      return false;
    }
    this.props.history.push("/copy-trader-info?id="+traderId);
  }

  render() {
    const { user } = this.props.auth;
    const { traders, isTrader, userId, imTrader,imFollower } = this.state;
    const { t } = this.props;
    console.log(userId,'userIduserIduserIduserId')
    console.log(traders,'traderstraderstraderstraders')
		return (
      <div className='bg_strip'>
      <BecomeTrader_Ref ref={this.BecomeTrader_Ref_Forward} state={this.state} auth={this.props.auth}/>
      <section class="container-fluid px-0">
      <Navtradebar />
	    </section>
			<section className="px-0 pt-0">
      <div className="inner_pageheader container-fluid px-0">
          <div className="inner_heading_wrapper">
            <div className="container text-center">
              <div className="row">
                <div className="col-md-12">
                  <h2 className='mb-3'>{t("CRYPTO_COPY_TRADING")}</h2>
                  <h4 className='mb-3'>{t("TRADER_LIST")}</h4>
                  <p className='mb-3'>{t("HOURLY_DATA_UPDATE")}</p>
                  <div className="mt-4">
                  {(user && user.id && imFollower==true) ?<a href="/copy-trade-myaccount"><button className="btnLandingJoin_outline">{t("COPY_TRADE")}</button></a>:"" }
                    {(user && user.id && imTrader==false) ? <button className="btnLandingJoin ml-2" onClick={() => this.BecomeTrader_Ref_Forward.current.becomeTrader_Click()}>{t("BECOME_TRADER")}</button> : ''}
                    {(user && user.id && imTrader==true) ? <Link to={"/copy-trader-info?id="+user.id}><button className="btnLandingJoin_outline ml-2">{t("TRADER_PROFILE")}</button></Link>: ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="inner_content_wrapper">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="copy_trading_top_panel">
                    <ul class="nav nav-pills" id="pills-tab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <a class="nav-link active" id="pills-ranking-tab" data-toggle="pill" href="#pills-ranking" role="tab" aria-controls="pills-ranking" aria-selected="true" onClick={() => this.traderFilter('Ranking')}>{t("RANKING")}</a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a class="nav-link" id="pills-return-tab" data-toggle="pill" href="#pills-ranking" role="tab" aria-controls="pills-return" aria-selected="false" onClick={() => this.traderFilter('Total P/L 3W')}>{t("TOTAL_PL")}</a>{/** Total P/L 3W*/}
                      </li>
                      <li class="nav-item" role="presentation">
                        <a class="nav-link" id="pills-return-tab" data-toggle="pill" href="#pills-ranking" role="tab" aria-controls="pills-return" aria-selected="false" onClick={() => this.traderFilter('Cumulative Return')}>{t("CUMULATIVE_RETURN")}</a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a class="nav-link" id="pills-transaction-tab" data-toggle="pill" href="#pills-ranking" role="tab" aria-controls="pills-transaction" aria-selected="false" onClick={() => this.traderFilter('Accum Transactions')}>{t("ACCUM_TRANSACTIONS")}</a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a class="nav-link" id="pills-followers-tab" data-toggle="pill" href="#pills-ranking" role="tab" aria-controls="pills-followers" aria-selected="false" onClick={() => this.traderFilter('Followers')}>{t("FOLLOWERS")}</a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a class="nav-link" id="pills-return-tab" data-toggle="pill" href="#pills-ranking" role="tab" aria-controls="pills-return" aria-selected="false" onClick={() => this.traderFilter('Total P/L Ratio')}>{t("TOTAL_PL_RATIO")}</a>
                      </li>
                      {/* <li class="nav-item" role="presentation">
                        <a class="nav-link" id="pills-return-tab" data-toggle="pill" href="#pills-ranking" role="tab" aria-controls="pills-return" aria-selected="false" onClick={() => this.traderFilter('Win Rate 3W')}>Win Rate 3W</a>
                      </li> */}
                    </ul>
                    <div class="input-group">
                      <input type="text" class="form-control" placeholder="Search" aria-label="search" aria-describedby="basic-addon1" onChange={this.onChangeSearch}/>
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1" onClick={this.getTradersList}><i class="bi bi-search"></i></span>
                      </div>
                    </div>
                  </div>

                  <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-ranking" role="tabpanel" aria-labelledby="pills-ranking-tab">
                      <div className="copy_trading_wrapper row">
                        {
                          traders.length ? traders.map(trader => {
                            if(this.props.auth.user.id != trader.user._id){
                            var findex = trader.followDetail.findIndex(e => e.followerId.toString() == userId.toString());
                            var followlength = 0;
                            trader.followDetail.map(det => {
                              if(det.followStatus==1)
                              {
                                followlength++;
                              }
                            });
                          return(
                            <div className="col-12 col-sm-6 col-lg-4 col-xl-3 col_copy_trading_panel_heiggh">
                              <div className='copy_trading_panel'>
                              <a href="javascript:void(0);" hrsef="/copy-trader-info" onClick={this.detailTrader.bind(this, trader.user._id)}>
                                <div className="copy_trading_panel_top">
                                  <div>
                                    {(trader?.user?.profile ==null || trader?.user?.profile ==undefined || trader?.user?.profile == '') ? 
                                   (<img src={require("../images/user.png")} alt="User" className="" />) :
                                   (<img src={`${config.imageUrl}profile_images/${trader?.user?.profile}`} alt="User" className="" />)
                                   }
                                    {/* <img src={require("../images/user.png")} alt="User" className="" /> */}
                                    <div className="copy_trading_panel_userdetail">
                                      <h2>
                                        { trader.user.name && trader.user.name }
                                        { !trader.user.name && trader.user.email!=null ? trader.user.email.split('@')[0].substring(0, 10):'' }
                                      </h2>
                                      <h5>Earning money now {trader && trader.followersDetails &&  trader.followDetail[0].followStatus}</h5>
                                    </div>
                                  </div>

                                  {
                                    
                                    (
                                      (userId && trader.followDetail && findex != -1 && trader.followDetail[findex].followStatus==1)
                                    )
                                    ?
                                      <button className="full_btn">
                                        <span id="traderFollorBtn" className="full_btn" onClick={this.followTrader.bind(this, trader.user._id)}>{t("EDIT")}</span>
                                      </button>
                                    :
                                      (trader.followDetail.length >= trader.maxFollow)
                                      ?
                                        <button className="full_btn">
                                          <span id="traderFollorBtn" className="full_btn">{t("FULL")}</span>
                                        </button>
                                      :
                                        (
                                          (userId && trader.followDetail && findex == -1)
                                          ||
                                          (userId && trader.followDetail && trader.followDetail[findex].followStatus!=1)
                                          ||
                                          (userId == '')
                                        )
                                        && ( !isTrader ) &&
                                        <button className="full_btn">
                                          <span id="traderFollorBtn" className="full_btn" onClick={this.followTrader.bind(this, trader.user._id)}>{t("FOLLOW")}</span>
                                        </button>
                                    }
                                  </div>
                                  <div className="copy_trading_panel_middle_row">
                                    <div className="copy_trading_panel_middle">
                                      <div>
                                        <h3 className="green_txt">{toFixed(trader.history.PLRatio_3w,2)}%</h3>
                                        <p>{t("PL_RATIO")} </p>{/*P/L Ratio 3W */}
                                      </div>
                                      <div>
                                        <h3>{toFixed(trader.history.WinRate_3w,2)}%</h3>
                                        <p>{t("WIN_RATE")}</p>{/*Win Rate 3W*/}
                                      </div>
                                    </div>
                                    <div className="copy_trading_panel_middle">
                                      <div>
                                        <h3 className="green_txt">{toFixed(trader.history.TotalPL_3wUSDT,8)}</h3>{/*make changes for doubt*/}
                                        <p>{t("TOTAL_PL_USDT")}</p>{/*Total P/L 3W(USDT) */}
                                      </div>
                                      <div>
                                        <h3>{toFixed(trader.history.AccumPLRatio,2)}%</h3>
                                        <p>{t("ACCUM_PL_RATIO")}</p>
                                      </div>
                                    </div>
                                    <div className="copy_trading_panel_middle">
                                      <div>
                                        <h3 className="green_txt">{trader.history.AccumTransactions}</h3>
                                        <p>{t("ACCUM_TRANSACTIONS")}</p>
                                      </div>
                                      <div>
                                        <h3>{trader.history.AccumFollowers}</h3>
                                        <p>{t("ACCUM_FOLLOWERS")}</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="copy_trading_panel_last mt-4">
                                    <p>{t("FOLLOWERS")} {followlength}/{trader.maxFollow} {t("PEOPLE")}</p>
                                  </div>
                                </a>
                                </div>
                                
                            </div>
                                   )} } ) : <div className='col-12'><p className="text-center text-white">{t("THERE_IS_NO_TRADERS")}</p></div>
                        }
                      </div>
                      <div className="text-center mt-3">
                        { traders.length > 10 && <button className="inner_primary_btn">{t("VIEW_MORE")}</button>}
                      </div>
                    </div>
                    <div class="tab-pane fade" id="pills-return" role="tabpanel" aria-labelledby="pills-return-tab">{t("CUMULATIVE_RETURN")}</div>
                    <div class="tab-pane fade" id="pills-transaction" role="tabpanel" aria-labelledby="pills-transaction-tab">{t("ACCUM_TRANSACTIONS")}</div>
                    <div class="tab-pane fade" id="pills-followers" role="tabpanel" aria-labelledby="pills-followers-tab">{t("FOLLOWERS")}</div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        

      
			</section>
			<Footer />
		</div>
		);
	}
}

CopyTrading.propTypes = {
  getTableDataDynamic: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(  mapStateToProps,  {
  getTableDataDynamic,
  logoutUser
}  )(withTranslation()(CopyTrading),withRouter(CopyTrading));
